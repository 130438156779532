div.cadreFM {
  position: relative;
  width: 33%;
  margin: 0 auto;
  overflow: hidden;
  padding: 0%;
  border: 0;
  background-color: black;
}

div.cadreFM_pl {
  position: absolute;
  width: 1%;
  height: 1%;
  left: 0%;
  top: 0%;
  background-image: url('../assets/img/img_space/FinDuMondeSymb_01.webp'),
    url('../assets/img/img_space/FinDuMondeSymb_02.webp'),
    url('../assets/img/img_space/FinDuMondeSymb_03.webp'),
    url('../assets/img/img_space/FinDuMondeSymb_04.webp'),
    url('../assets/img/img_space/FinDuMondeSymb_05.webp'),
    url('../assets/img/img_space/FinDuMondeSymb_06.webp'),
    url('../assets/img/img_space/FinDuMondeSymb_07.webp'),
    url('../assets/img/img_space/FinDuMondeSymb_08.webp'),
    url('../assets/img/img_space/FinDuMondeSymb_09.webp'),
    url('../assets/img/img_space/FinDuMondeSymb_10.webp'),
    url('../assets/img/img_space/FinDuMonde04_montage_ca_01.webp'),
    url('../assets/img/img_space/FinDuMonde04_montage_ca_02.webp'),
    url('../assets/img/img_space/FinDuMonde04_montage_ca_03.webp'),
    url('../assets/img/img_space/FinDuMonde04_montage_ca_04.webp');
  background-position: 100% 100%;
}

img.imgFondFM {
  width: 100%;
}

.imgvideFM {
  width: 100%;
}

.imgvideFMa {
  position: relative;
  width: 100%;
}

div.cadreFM1 {
  padding: 0%;
  margin: 0;
  position: absolute;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
}

div.cadreFM1b {
  padding: 0%;
  margin: 0;
  color: #6d0909;
  position: absolute;
  text-align: center;
  width: 100%;
  height: 100%;
  top: 0%;
  left: 0%;
  background-color: rgba(0, 0, 0, 0.8);
}

div.cadreFM1 p {
  visibility: hidden;
  text-transform: uppercase;
  position: absolute;
}

div.cadreFM1bb {
  display: flex;
  text-align: center;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
}

div.cadreFM1bbb {
  display: flex;
  flex-direction: column;
  text-align: center;
  width: 100%;
}

div.cadreFM1bbb p {
  color: #043347;
}

.cadreFM .active {
  opacity: 1;
}

div.noFM1 {
  top: 0%;
  left: 0%;
  background-image: url('../assets/img/img_space/FinDuMondeSymb_01.webp');
  background-size: contain;
  width: 20%;
  height: 20%;
}

div.noFM2 {
  top: 0%;
  left: 20%;
  background-image: url('../assets/img/img_space/FinDuMondeSymb_02.webp');
  background-size: contain;
  width: 30%;
  height: 20%;
}

div.noFM3 {
  top: 0%;
  left: 50%;
  background-image: url('../assets/img/img_space/FinDuMondeSymb_03.webp');
  background-size: contain;
  width: 20%;
  height: 20%;
}

div.noFM4 {
  top: 0%;
  left: 70%;
  background-image: url('../assets/img/img_space/FinDuMondeSymb_04.webp');
  background-size: contain;
  width: 30%;
  height: 30%;
}

div.noFM5 {
  top: 30%;
  left: 80%;
  background-image: url('../assets/img/img_space/FinDuMondeSymb_05.webp');
  background-size: contain;
  width: 20%;
  height: 40%;
}

div.noFM6 {
  top: 70%;
  left: 70%;
  background-image: url('../assets/img/img_space/FinDuMondeSymb_06.webp');
  background-size: contain;
  width: 30%;
  height: 30%;
}

div.noFM7 {
  top: 75%;
  left: 45%;
  background-image: url('../assets/img/img_space/FinDuMondeSymb_07.webp');
  background-size: contain;
  width: 25%;
  height: 25%;
}

div.noFM8 {
  top: 75%;
  left: 25%;
  background-image: url('../assets/img/img_space/FinDuMondeSymb_08.webp');
  background-size: contain;
  width: 20%;
  height: 25%;
}

div.noFM9 {
  top: 70%;
  left: 0%;
  background-image: url('../assets/img/img_space/FinDuMondeSymb_09.webp');
  background-size: contain;
  width: 25%;
  height: 30%;
}

div.noFM10 {
  top: 20%;
  left: 0%;
  background-image: url('../assets/img/img_space/FinDuMondeSymb_10.webp');
  background-size: contain;
  width: 20%;
  height: 50%;
}

.cadreFM_msg {
  display: none;
  background: rgba(0, 0, 0, 0.3);
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.cadreFM3_msg {
  display: flex;
  text-align: center;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
}

.cadreFM33_msg {
  position: relative;
  width: 60%;
  margin: 20%;
}

div.cadreFM_ca {
  position: absolute;
  top: 0%;
  left: 0%;
  width: 100%;
  height: 100%;
  overflow: hidden;
  padding: 0%;
  border: 0;
  animation: animFM_ca 0.5s infinite;
}

@keyframes animFM_ca {
  0% {
    background-image: url('../assets/img/img_space/FinDuMonde04_montage_ca_01.webp');
    background-size: cover;
  }

  5% {
    background-image: url('../assets/img/img_space/FinDuMonde04_montage_ca_01.webp');
    background-size: cover;
  }

  20% {
    background-image: url('../assets/img/img_space/FinDuMonde04_montage_ca_02.webp');
    background-size: cover;
  }

  30% {
    background-image: url('../assets/img/img_space/FinDuMonde04_montage_ca_02.webp');
    background-size: cover;
  }

  55% {
    background-image: url('../assets/img/img_space/FinDuMonde04_montage_ca_03.webp');
    background-size: cover;
  }

  65% {
    background-image: url('../assets/img/img_space/FinDuMonde04_montage_ca_03.webp');
    background-size: cover;
  }

  70% {
    background-image: url('../assets/img/img_space/FinDuMonde04_montage_ca_04.webp');
    background-size: cover;
  }

  80% {
    background-image: url('../assets/img/img_space/FinDuMonde04_montage_ca_04.webp');
    background-size: cover;
  }

  95% {
    background-image: url('../assets/img/img_space/FinDuMonde04_montage_ca_01.webp');
    background-size: cover;
  }

  100% {
    background-image: url('../assets/img/img_space/FinDuMonde04_montage_ca_01.webp');
    background-size: cover;
  }
}
