div.cadreIY_v1 {
    position: relative;
    width:33%;
    margin: 0 auto;
    animation: animIV_VersaillesIY_2 1s infinite;
    background-size: cover;
 }

 div.cadreIY_v2 {
    position: relative;
    width:33%;
    margin: 0 auto;
    animation: animIV_VersaillesIY_3 1s infinite;
    background-size: cover;
 }

 div.cadreIY_pl {
    position:absolute;
    width:1%;
    height:1%;
    left:0%;
    top:0%;
    background-image:url('../assets/img/img_space/BoutonIV.webp'),
    url('../assets/img/img_space/BoutonIVactif.webp'),
    url('../assets/img/img_space/JardinVersailles03_montagne_01a.webp'),
    url('../assets/img/img_space/JardinVersailles03_montagne_01b.webp'),
    url('../assets/img/img_space/JardinVersailles03_montagne_02a.webp'),
    url('../assets/img/img_space/JardinVersailles03_montagne_02b.webp'),
    url('../assets/img/img_space/JardinVersailles03_montagne_02c.webp'),
    url('../assets/img/img_space/JardinVersailles03_montagne_02d.webp'),
    url('../assets/img/img_space/JardinVersailles03_montagne_02e.webp'),
    url('../assets/img/img_space/JardinVersailles03_montagne_02f.webp'),
    url('../assets/img/img_space/JardinVersailles03_montagne_02g.webp'),
    url('../assets/img/img_space/JardinVersailles03_montagne_02h.webp'),
    url('../assets/img/img_space/JardinVersailles03_montagne_03a.webp'),
    url('../assets/img/img_space/JardinVersailles03_montagne_03b.webp'),
    url('../assets/img/img_space/JardinVersailles03_montagne_03c.webp'),
    url('../assets/img/img_space/JardinVersailles03_montagne_03d.webp'),
    url('../assets/img/img_space/JardinVersailles03_montagne_03e.webp'),
    url('../assets/img/img_space/JardinVersailles03_montagne_03f.webp'),
    url('../assets/img/img_space/JardinVersailles03_montagne_03g.webp'),
    url('../assets/img/img_space/JardinVersailles03_montagne_03h.webp')

    ;
    background-position: 100% 100%;
  }
  
  img.imgFondIY{
    width:100%;
 }
 
 div.cadreIY9{
position:absolute;
height:100%;
width:50%;
top:0;
animation: animIV_VersaillesIY_1 2s infinite;
background-size: cover;
 }

 div.cadreIY9b{
    position:absolute;
    height:12%;
    width:12%;
    top:44%;
    left:44%;
    background-image: url('../assets/img/img_space/BoutonIV.webp'); 
    background-size: 100% 100%;
 }

 div.cadreIY9b:hover{
    position:absolute;
    height:12%;
    width:12%;
    top:44%;
    left:44%;
    background-image: url('../assets/img/img_space/BoutonIVactif.webp'); 
    
 }

 @keyframes animIV_VersaillesIY_1 {
    0% {
      background-image: url('../assets/img/img_space/JardinVersailles03_montagne_01a.webp');
    }
  
    25% {
      background-image: url('../assets/img/img_space/JardinVersailles03_montagne_01b.webp');
    }
  
    50% {
      background-image: url('../assets/img/img_space/JardinVersailles03_montagne_01a.webp');
    }
  
    75% {
      background-image: url('../assets/img/img_space/JardinVersailles03_montagne_01b.webp');
    }
  
    100% {
      background-image: url('../assets/img/img_space/JardinVersailles03_montagne_01a.webp');
    }
  }
  
  @keyframes animIV_VersaillesIY_2 {
    0% {
      background-image: url('../assets/img/img_space/JardinVersailles03_montagne_02a.webp');
    }
  
    12.5% {
      background-image: url('../assets/img/img_space/JardinVersailles03_montagne_02b.webp');
    }
  
    25% {
      background-image: url('../assets/img/img_space/JardinVersailles03_montagne_02c.webp');
    }
  
    37.5% {
      background-image: url('../assets/img/img_space/JardinVersailles03_montagne_02d.webp');
    }
  
    50% {
      background-image: url('../assets/img/img_space/JardinVersailles03_montagne_02e.webp');
    }
  
    62.5% {
      background-image: url('../assets/img/img_space/JardinVersailles03_montagne_02f.webp');
    }
  
    75% {
      background-image: url('../assets/img/img_space/JardinVersailles03_montagne_02g.webp');
    }
  
    87.5% {
      background-image: url('../assets/img/img_space/JardinVersailles03_montagne_02h.webp');
    }
  
    100% {
      background-image: url('../assets/img/img_space/JardinVersailles03_montagne_02a.webp');
    }
  }
  
  @keyframes animIV_VersaillesIY_3 {
    0% {
      background-image: url('../assets/img/img_space/JardinVersailles03_montagne_03a.webp');
    }
  
    12.5% {
      background-image: url('../assets/img/img_space/JardinVersailles03_montagne_03b.webp');
    }
  
    25% {
      background-image: url('../assets/img/img_space/JardinVersailles03_montagne_03c.webp');
    }
  
    37.5% {
      background-image: url('../assets/img/img_space/JardinVersailles03_montagne_03d.webp');
    }
  
    50% {
      background-image: url('../assets/img/img_space/JardinVersailles03_montagne_03e.webp');
    }
  
    62.5% {
      background-image: url('../assets/img/img_space/JardinVersailles03_montagne_03f.webp');
    }
  
    75% {
      background-image: url('../assets/img/img_space/JardinVersailles03_montagne_03g.webp');
    }
  
    87.5% {
      background-image: url('../assets/img/img_space/JardinVersailles03_montagne_03h.webp');
    }
  
    100% {
      background-image: url('../assets/img/img_space/JardinVersailles03_montagne_03a.webp');
    }
  }