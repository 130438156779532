
img.imgFondRM {
  width: 100%;
}

div.cadreRM {
  position: relative;
  width: 33%;
  margin: 0 auto;
  background-color: black;
  overflow: hidden;
}

div.cadreRM_pl {
  position: absolute;
  width: 1%;
  height: 1%;
  left: 0%;
  top: 0%;
  background-image: url('../assets/img/img_cadre/900_CadreRecAnim00.webp'),
    url("../assets/img/img_rm/BoutonRN01_B.webp"),
    url("../assets/img/img_rm/BoutonRN01_B2.webp") url("../assets/img/img_rm/BoutonRN01_K.webp") url("../assets/img/img_rm/BoutonRN01_K2.webp") url("../assets/img/img_rm/BoutonRN01_M.webp") url("../assets/img/img_rm/BoutonRN01_M2.webp");
  background-position: 100% 100%;
}

img.img17RM {
  width: 100%;
  position: absolute;
  top: 0;
}

div.cadreRM img.img17RM_first {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  transition: 1s ease-in-out;
  border-radius: 0;
  transform: translateX(0);
  clip-path: polygon(0 0, 100% 0, 100% 50%, 0 50%);
}

div.cadreRM img.img17RM_last {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  transition: 1s ease-in-out;
  border-radius: 0;
  transform: translateX(0);
  clip-path: polygon(0 50%, 100% 50%, 100% 100%, 0 100%);
}

div.cadreRM:hover img.img17RM7_first {
  transform: translateY(-45%);
}

div.cadreRM:hover img.img17RM7_last {
  transform: translateY(45%);
}

div.cadreRM17a {
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

div.cadreRM17aa {
  width: 100%;
  position: absolute;
  top: 0;
  left: 100%;
  transition: all 3s ease-out;
}

div.cadreRM17b {
  width: 100%;
  position: absolute;
  top: 0;
  left: 100%;
}

div.cadreRM17bb {
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  transition: all 3s ease-out;
}

div.cadreRM17c {
  width: 100%;
  position: absolute;
  top: 0;
  left: -100%;
}

div.cadreRM17cc {
  width: 100%;
  position: absolute;
  top: 0;
  left: -100%;
  transition: all 3s ease-out;
}

div.barBtRM {
  position: absolute;
  top: 85%;
  height: 10%;
  width: 30%;
  border: solid 0.5em red;
  border-radius: 1.5em;
  line-height: 1em;
  background-color: red;
  padding-left: 1em;
  padding-right: 1em;
  margin: 0;
  margin-left: 35%;
  margin-right: 35%;
  display: block;
}

div.barBtRMMarge {
  position: relative;
  float: left;
  height: 100%;
  width: 32%;
}

div.btRM17M {
  position: relative;
  float: left;
  height: 100%;
  width: 33.33%;
  background-image: url("../assets/img/img_rm/BoutonRN01_M.webp");
  background-size: cover;
  transition: all 0.5s ease-out;
  cursor: pointer;
}

div.btRM17M_active {
  position: relative;
  float: left;
  height: 100%;
  width: 33.33%;
  background-image: url("../assets/img/img_rm/BoutonRN01_M2.webp");
  background-size: cover;
  transform: rotate(180deg);
  transition: all 0.5s ease-out;
  cursor: pointer;
}

div.btRM17B {
  position: relative;
  float: left;
  height: 100%;
  width: 33.33%;
  background-image: url("../assets/img/img_rm/BoutonRN01_B.webp");
  background-size: cover;
  cursor: pointer;
}

div.btRM17B_active {
  position: relative;
  float: left;
  height: 100%;
  width: 33.33%;
  background-image: url("../assets/img/img_rm/BoutonRN01_B2.webp");
  background-size: cover;
  transform: rotate(180deg);
  transition: all 0.5s ease-out;
  cursor: pointer;
}

div.btRM17K {
  position: relative;
  float: left;
  height: 100%;
  width: 33.33%;
  background-image: url("../assets/img/img_rm/BoutonRN01_K.webp");
  background-size: cover;
  cursor: pointer;
}

div.btRM17K_active {
  position: relative;
  float: left;
  height: 100%;
  width: 33.33%;
  background-image: url("../assets/img/img_rm/BoutonRN01_K2.webp");
  background-size: cover;
  transform: rotate(180deg);
  transition: all 0.5s ease-out;
  cursor: pointer;
}

div.EtoileRM{
  width:100%;
 /* background-color: red;*/
  position: absolute;
  left:0;
  top:0;
  overflow: hidden;
}

div.EtoileRM1{
  width:16%;
  height:16%;
  position: absolute;
  left:42%;
  top:15%;
  animation: 9s rotateRMH infinite linear;
  background-image: url("../assets/img/img_rm/800RM_RoisMages_etoiles2_01.webp");
  background-size: cover;
  transform-origin:16% 800%;
}
div.EtoileRM2{
  width:16%;
  height:16%;
  position: absolute;
  left:42%;
  top:10%;
  animation: 8s rotateRMaH infinite linear;
  background-image: url("../assets/img/img_rm/800RM_RoisMages_etoiles2_02.webp");
  background-size: cover;
  transform-origin:16% 800%;
}

div.EtoileRM3{
  width:16%;
  height:16%;
  position: absolute;
  left:42%;
  top:5%;
  animation: 7s rotateRMH infinite linear;
  background-image: url("../assets/img/img_rm/800RM_RoisMages_etoiles2_03.webp");
  background-size: cover;
  transform-origin:16% 800%;
}

div.EtoileRM4{
  width:16%;
  height:16%;
  position: absolute;
  left:42%;
  top:5%;
  animation: 6s rotateRMaH infinite linear;
  background-image: url("../assets/img/img_rm/800RM_RoisMages_etoiles2_04.webp");
  background-size: cover;
  transform-origin:16% 800%;
}

div.EtoileRM5{
  width:16%;
  height:16%;
  position: absolute;
  left:42%;
  top:10%;
  animation: 5s rotateRMH infinite linear;
  background-image: url("../assets/img/img_rm/800RM_RoisMages_etoiles2_05.webp");
  background-size: cover;
  transform-origin:16% 800%;
}

div.EtoileRM6{
  width:16%;
  height:16%;
  position: absolute;
  left:42%;
  top:15%;
  animation: 4s rotateRMaH infinite linear;
  background-image: url("../assets/img/img_rm/800RM_RoisMages_etoiles2_06.webp");
  background-size: cover;
  transform-origin:16% 800%;
}

div.EtoileRM7{
  width:16%;
  height:16%;
  position: absolute;
  left:42%;
  top:5%;
  animation: 3s rotateRMH infinite linear;
  background-image: url("../assets/img/img_rm/800RM_RoisMages_etoiles2_07.webp");
  background-size: cover;
  transform-origin:16% 800%;
}

div.EtoileRM8{
  width:16%;
  height:16%;
  position: absolute;
  left:42%;
  top:10%;
  animation: 2s rotateRMaH infinite linear;
  background-image: url("../assets/img/img_rm/800RM_RoisMages_etoiles2_08.webp");
  background-size: cover;
  transform-origin:16% 800%;
}

div.EtoileRM9{
  width:16%;
  height:16%;
  position: absolute;
  left:42%;
  top:15%;
  animation: 1s rotateRMH infinite linear;
  background-image: url("../assets/img/img_rm/800RM_RoisMages_etoiles2_09.webp");
  background-size: cover;
  transform-origin:16% 800%;
}

@keyframes rotateRMH {
  to {
    transform: rotate(-360deg);
  }
}

@keyframes rotateRMaH {
  to {
    transform: rotate(360deg);
  }
}