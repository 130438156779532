

div.cadreBR {
  position: relative;
  width: 33%;
  margin: 0 auto;
  background-color: black;
  
}

div.cadreBR_pl {
  position: absolute;
  width: 1%;
  height: 1%;
  left: 0%;
  top: 0%;
  background-image: url('../assets/img/img_space/800GL_RocheFond.webp'),
  url('../assets/img/img_space/800GL_RocheFondGrotte.webp'),
    url('../assets/img/img_space/800BR_biche_01a.webp'),
    url('../assets/img/img_space/800BR_biche_01b.webp'),
    url('../assets/img/img_space/800BR_biche_01c.webp'),
    url('../assets/img/img_space/800BR_vache_01a.webp'),
    url('../assets/img/img_space/800BR_vache_01b.webp'),
    url('../assets/img/img_space/800BR_vache_01c.webp'),
    url('../assets/img/img_space/800BR_vache_02a.webp'),
    url('../assets/img/img_space/800BR_vache_02b.webp'),
    url('../assets/img/img_space/800BR_vache_02c.webp'),
    url('../assets/img/img_space/800BR_vache_03a.webp'),
    url('../assets/img/img_space/800BR_vache_03b.webp'),
    url('../assets/img/img_space/800BR_vache_03c.webp'),
    url('../assets/img/img_space/800BR_vache_04a.webp'),
    url('../assets/img/img_space/800BR_vache_04b.webp'),
    url('../assets/img/img_space/800BR_vache_04c.webp'),
    url('../assets/img/img_space/800BR_cheval_01a.webp'),
    url('../assets/img/img_space/800BR_cheval_01b.webp'),
    url('../assets/img/img_space/800BR_cheval_01c.webp'),
    url('../assets/img/img_space/800BR_cheval_02a.webp'),
    url('../assets/img/img_space/800BR_cheval_02b.webp'),
    url('../assets/img/img_space/800BR_cheval_02c.webp'),
    url('../assets/img/img_space/800BR_cheval_03a.webp'),
    url('../assets/img/img_space/800BR_cheval_03b.webp'),
    url('../assets/img/img_space/800BR_cheval_03c.webp'),
    url('../assets/img/img_space/800BR_cerf_01a.webp'),
    url('../assets/img/img_space/800BR_cerf_01b.webp'),
    url('../assets/img/img_space/800BR_cerf_01c.webp'),
    url('../assets/img/img_space/800BR_mamouth_01a.webp'),
    url('../assets/img/img_space/800BR_mamouth_01b.webp'),
    url('../assets/img/img_space/800BR_mamouth_01c.webp');
  background-position: 100% 100%;
}

html {
  scroll-behavior: smooth;
}

.cadreBR1 {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0%;
  left: 0%;
  background-image: url('../assets/img/img_space/800GL_RocheFond.webp');
  background-size: 100% 100%;
}

img.imgFondBR {
  width: 100%;
}

@keyframes animSP_800BR_biche_01 {
  0% {
    background-image: url('../assets/img/img_space/800BR_biche_01a.webp');
     background-size: 100% 100%;
    top: 0;
    left: 0;
  }

  10% {
    background-image: url('../assets/img/img_space/800BR_biche_01a.webp');
     background-size: 100% 100%;
    top: 0;
    left: 0;
  }

  35% {
    background-image: url('../assets/img/img_space/800BR_biche_01b.webp');
     background-size: 100% 100%;
    top: 0;
    left: 0;
  }

  45% {
    background-image: url('../assets/img/img_space/800BR_biche_01b.webp');
     background-size: 100% 100%;
    top: 0;
    left: 0;
  }

  65% {
    background-image: url('../assets/img/img_space/800BR_biche_01c.webp');
     background-size: 100% 100%;
    top: 0;
    left: 0;
  }

  75% {
    background-image: url('../assets/img/img_space/800BR_biche_01c.webp');
     background-size: 100% 100%;
    top: 0;
    left: 0;
  }

  100% {
    background-image: url('../assets/img/img_space/800BR_biche_01a.webp');
     background-size: 100% 100%;
    top: 0;
    left: 0;
  }
}

@keyframes animSP_800BR_vache_01 {
  0% {
    background-image: url('../assets/img/img_space/800BR_vache_01a.webp');
     background-size: 100% 100%;
    top: 0;
    left: 0;
  }

  10% {
    background-image: url('../assets/img/img_space/800BR_vache_01a.webp');
     background-size: 100% 100%;
    top: 0;
    left: 0;
  }

  35% {
    background-image: url('../assets/img/img_space/800BR_vache_01b.webp');
     background-size: 100% 100%;
    top: 0;
    left: 0;
  }

  45% {
    background-image: url('../assets/img/img_space/800BR_vache_01b.webp');
     background-size: 100% 100%;
    top: 0;
    left: 0;
  }

  65% {
    background-image: url('../assets/img/img_space/800BR_vache_01c.webp');
     background-size: 100% 100%;
    top: 0;
    left: 0;
  }

  75% {
    background-image: url('../assets/img/img_space/800BR_vache_01c.webp');
     background-size: 100% 100%;
    top: 0;
    left: 0;
  }

  100% {
    background-image: url('../assets/img/img_space/800BR_vache_01a.webp');
     background-size: 100% 100%;
    top: 0;
    left: 0;
  }
}

@keyframes animSP_800BR_vache_02 {
  0% {
    background-image: url('../assets/img/img_space/800BR_vache_02a.webp');
     background-size: 100% 100%;
    top: 0;
    left: 0;
  }

  10% {
    background-image: url('../assets/img/img_space/800BR_vache_02a.webp');
     background-size: 100% 100%;
    top: 0;
    left: 0;
  }

  35% {
    background-image: url('../assets/img/img_space/800BR_vache_02b.webp');
     background-size: 100% 100%;
    top: 0;
    left: 0;
  }

  45% {
    background-image: url('../assets/img/img_space/800BR_vache_02b.webp');
     background-size: 100% 100%;
    top: 0;
    left: 0;
  }

  65% {
    background-image: url('../assets/img/img_space/800BR_vache_02c.webp');
     background-size: 100% 100%;
    top: 0;
    left: 0;
  }

  75% {
    background-image: url('../assets/img/img_space/800BR_vache_02c.webp');
     background-size: 100% 100%;
    top: 0;
    left: 0;
  }

  100% {
    background-image: url('../assets/img/img_space/800BR_vache_02a.webp');
     background-size: 100% 100%;
    top: 0;
    left: 0;
  }
}

@keyframes animSP_800BR_vache_03 {
  0% {
    background-image: url('../assets/img/img_space/800BR_vache_03a.webp');
     background-size: 100% 100%;
    top: 0;
    left: 0;
  }

  10% {
    background-image: url('../assets/img/img_space/800BR_vache_03a.webp');
     background-size: 100% 100%;
    top: 0;
    left: 0;
  }

  35% {
    background-image: url('../assets/img/img_space/800BR_vache_03b.webp');
     background-size: 100% 100%;
    top: 0;
    left: 0;
  }

  45% {
    background-image: url('../assets/img/img_space/800BR_vache_03b.webp');
     background-size: 100% 100%;
    top: 0;
    left: 0;
  }

  65% {
    background-image: url('../assets/img/img_space/800BR_vache_03c.webp');
     background-size: 100% 100%;
    top: 0;
    left: 0;
  }

  75% {
    background-image: url('../assets/img/img_space/800BR_vache_03c.webp');
     background-size: 100% 100%;
    top: 0;
    left: 0;
  }

  100% {
    background-image: url('../assets/img/img_space/800BR_vache_03a.webp');
     background-size: 100% 100%;
    top: 0;
    left: 0;
  }
}

@keyframes animSP_800BR_vache_04 {
  0% {
    background-image: url('../assets/img/img_space/800BR_vache_04a.webp');
     background-size: 100% 100%;
    top: 0;
    left: 0;
  }

  10% {
    background-image: url('../assets/img/img_space/800BR_vache_04a.webp');
     background-size: 100% 100%;
    top: 0;
    left: 0;
  }

  35% {
    background-image: url('../assets/img/img_space/800BR_vache_04b.webp');
     background-size: 100% 100%;
    top: 0;
    left: 0;
  }

  45% {
    background-image: url('../assets/img/img_space/800BR_vache_04b.webp');
     background-size: 100% 100%;
    top: 0;
    left: 0;
  }

  65% {
    background-image: url('../assets/img/img_space/800BR_vache_04c.webp');
     background-size: 100% 100%;
    top: 0;
    left: 0;
  }

  75% {
    background-image: url('../assets/img/img_space/800BR_vache_04c.webp');
     background-size: 100% 100%;
    top: 0;
    left: 0;
  }

  100% {
    background-image: url('../assets/img/img_space/800BR_vache_04a.webp');
     background-size: 100% 100%;
    top: 0;
    left: 0;
  }
}

@keyframes animSP_800BR_cheval_01 {
  0% {
    background-image: url('../assets/img/img_space/800BR_cheval_01a.webp');
     background-size: 100% 100%;
    top: 0;
    left: 0;
  }

  10% {
    background-image: url('../assets/img/img_space/800BR_cheval_01a.webp');
     background-size: 100% 100%;
    top: 0;
    left: 0;
  }

  35% {
    background-image: url('../assets/img/img_space/800BR_cheval_01b.webp');
     background-size: 100% 100%;
    top: 0;
    left: 0;
  }

  45% {
    background-image: url('../assets/img/img_space/800BR_cheval_01b.webp');
     background-size: 100% 100%;
    top: 0;
    left: 0;
  }

  65% {
    background-image: url('../assets/img/img_space/800BR_cheval_01c.webp');
     background-size: 100% 100%;
    top: 0;
    left: 0;
  }

  75% {
    background-image: url('../assets/img/img_space/800BR_cheval_01c.webp');
     background-size: 100% 100%;
    top: 0;
    left: 0;
  }

  100% {
    background-image: url('../assets/img/img_space/800BR_cheval_01a.webp');
     background-size: 100% 100%;
    top: 0;
    left: 0;
  }
}

@keyframes animSP_800BR_cheval_02 {
  0% {
    background-image: url('../assets/img/img_space/800BR_cheval_02a.webp');
     background-size: 100% 100%;
    top: 0;
    left: 0;
  }

  10% {
    background-image: url('../assets/img/img_space/800BR_cheval_02a.webp');
     background-size: 100% 100%;
    top: 0;
    left: 0;
  }

  35% {
    background-image: url('../assets/img/img_space/800BR_cheval_02b.webp');
     background-size: 100% 100%;
    top: 0;
    left: 0;
  }

  45% {
    background-image: url('../assets/img/img_space/800BR_cheval_02b.webp');
     background-size: 100% 100%;
    top: 0;
    left: 0;
  }

  65% {
    background-image: url('../assets/img/img_space/800BR_cheval_02c.webp');
     background-size: 100% 100%;
    top: 0;
    left: 0;
  }

  75% {
    background-image: url('../assets/img/img_space/800BR_cheval_02c.webp');
     background-size: 100% 100%;
    top: 0;
    left: 0;
  }

  100% {
    background-image: url('../assets/img/img_space/800BR_cheval_02a.webp');
     background-size: 100% 100%;
    top: 0;
    left: 0;
  }
}

@keyframes animSP_800BR_cheval_03 {
  0% {
    background-image: url('../assets/img/img_space/800BR_cheval_03a.webp');
     background-size: 100% 100%;
    top: 0;
    left: 0;
  }

  10% {
    background-image: url('../assets/img/img_space/800BR_cheval_03a.webp');
     background-size: 100% 100%;
    top: 0;
    left: 0;
  }

  35% {
    background-image: url('../assets/img/img_space/800BR_cheval_03b.webp');
     background-size: 100% 100%;
    top: 0;
    left: 0;
  }

  45% {
    background-image: url('../assets/img/img_space/800BR_cheval_03b.webp');
     background-size: 100% 100%;
    top: 0;
    left: 0;
  }

  65% {
    background-image: url('../assets/img/img_space/800BR_cheval_03c.webp');
     background-size: 100% 100%;
    top: 0;
    left: 0;
  }

  75% {
    background-image: url('../assets/img/img_space/800BR_cheval_03c.webp');
     background-size: 100% 100%;
    top: 0;
    left: 0;
  }

  100% {
    background-image: url('../assets/img/img_space/800BR_cheval_03a.webp');
     background-size: 100% 100%;
    top: 0;
    left: 0;
  }
}

@keyframes animSP_800BR_cerf_01 {
  0% {
    background-image: url('../assets/img/img_space/800BR_cerf_01a.webp');
     background-size: 100% 100%;
    top: 0;
    left: 0;
  }

  10% {
    background-image: url('../assets/img/img_space/800BR_cerf_01a.webp');
     background-size: 100% 100%;
    top: 0;
    left: 0;
  }

  35% {
    background-image: url('../assets/img/img_space/800BR_cerf_01b.webp');
     background-size: 100% 100%;
    top: 0;
    left: 0;
  }

  45% {
    background-image: url('../assets/img/img_space/800BR_cerf_01b.webp');
     background-size: 100% 100%;
    top: 0;
    left: 0;
  }

  65% {
    background-image: url('../assets/img/img_space/800BR_cerf_01c.webp');
     background-size: 100% 100%;
    top: 0;
    left: 0;
  }

  75% {
    background-image: url('../assets/img/img_space/800BR_cerf_01c.webp');
     background-size: 100% 100%;
    top: 0;
    left: 0;
  }

  100% {
    background-image: url('../assets/img/img_space/800BR_cerf_01a.webp');
     background-size: 100% 100%;
    top: 0;
    left: 0;
  }
}

@keyframes animSP_800BR_mamouth_01 {
  0% {
    background-image: url('../assets/img/img_space/800BR_mamouth_01a.webp');
     background-size: 100% 100%;
    top: 0;
    left: 0;
  }

  10% {
    background-image: url('../assets/img/img_space/800BR_mamouth_01a.webp');
     background-size: 100% 100%;
    top: 0;
    left: 0;
  }

  35% {
    background-image: url('../assets/img/img_space/800BR_mamouth_01b.webp');
     background-size: 100% 100%;
    top: 0;
    left: 0;
  }

  45% {
    background-image: url('../assets/img/img_space/800BR_mamouth_01b.webp');
     background-size: 100% 100%;
    top: 0;
    left: 0;
  }

  65% {
    background-image: url('../assets/img/img_space/800BR_mamouth_01c.webp');
     background-size: 100% 100%;
    top: 0;
    left: 0;
  }

  75% {
    background-image: url('../assets/img/img_space/800BR_mamouth_01c.webp');
     background-size: 100% 100%;
    top: 0;
    left: 0;
  }

  100% {
    background-image: url('../assets/img/img_space/800BR_mamouth_01a.webp');
     background-size: 100% 100%;
    top: 0;
    left: 0;
  }
}

@keyframes animSP_mvt_01 {
  0% {
    left: -50%;
    opacity: 0;
  }

 1% {
    left: -50%;
    opacity: 1;
  }

  59% {
    left: 110%;
    opacity: 1;
  }

  100% {
    left: 110%;
    opacity: 0;
  }
}

@keyframes animSP_mvt_02 {
  0% {
    left: -50%;
    opacity: 0;
  }

  11% {
    left: -50%;
    opacity: 1;
  }

  79% {
    left: 110%;
    opacity: 1;
  }

  100% {
    left: 110%;
    opacity: 0;
  }
}

@keyframes animSP_mvt_03 {
  0% {
    left: -50%;
    opacity: 0;
  }

  21% {
    left: -50%;
    opacity: 1;
  }

  89% {
    left: 110%;
    opacity: 1;
  }

  100% {
    left: 110%;
    opacity: 0;
  }
}

@keyframes animSP_mvt_04 {
  0% {
    left: -50%;
    opacity: 0;
  }

  31% {
    left: -50%;
    opacity: 1;
  }

  99% {
    left: 110%;
    opacity: 1;
  }

  100% {
    left: 110%;
    opacity: 0;
  }
}



div.beteBR01 {
  position: absolute;
  width: 100%;
  top: 0;
  left: 0;
  animation: animSP_800BR_biche_01 1.5s linear infinite;
}

div.mvtBeteBR01a {
  position: absolute;
  width: 40%;
   
  top: 0%;
  left: 0;
  animation: animSP_mvt_01 30s linear infinite;
}

div.mvtBeteBR01b {
  position: absolute;
  width: 40%;
   
  top: 0%;
  left: 0;
  animation: animSP_mvt_02 30s linear infinite;
}

div.mvtBeteBR01c {
  position: absolute;
  width: 40%;
   
  top: 0%;
  left: 0;
  animation: animSP_mvt_03 30s linear infinite;
}

div.mvtBeteBR01d {
  position: absolute;
  width: 40%;
   
  top: 0%;
  left: 0;
  animation: animSP_mvt_04 30s linear infinite;
}

div.beteBR02 {
  position: absolute;
  width: 100%;
  top: 0;
  left: 0;
  animation: animSP_800BR_vache_01 1.5s linear infinite;
}

div.mvtBeteBR02 {
  position: absolute;
  width: 40%;
   
  top: 9%;
  left: 0;
  animation: animSP_mvt_04 41s linear infinite;
}

div.beteBR03 {
  position: absolute;
  width: 100%;
  top: 0;
  left: 0;
  animation: animSP_800BR_cheval_01 1.5s linear infinite;
}

div.mvtBeteBR03 {
  position: absolute;
  width: 40%;
   
  top: 15%;
  left: 0;
  animation: animSP_mvt_04 22s linear infinite;
}

div.beteBR04 {
  position: absolute;
  width: 100%;
  top: 0;
  left: 0;
  animation: animSP_800BR_cerf_01 1.5s linear infinite;
}

div.mvtBeteBR04 {
  position: absolute;
  width: 40%;
   
  top: 21%;
  left: 0;
  animation: animSP_mvt_04 33s linear infinite;
}

div.beteBR05 {
  position: absolute;
  width: 100%;
  top: 0;
  left: 0;
  animation: animSP_800BR_vache_02 1.5s linear infinite;
}

div.mvtBeteBR05 {
  position: absolute;
  width: 40%;
   
  top: 32%;
  left: 0;
  animation: animSP_mvt_03 40s linear infinite;
}

div.beteBR06 {
  position: absolute;
  width: 100%;
  top: 0;
  left: 0;
  animation: animSP_800BR_cheval_02 1.5s linear infinite;
}

div.mvtBeteBR06 {
  position: absolute;
  width: 40%;
   
  top: 36%;
  left: 0;
  animation: animSP_mvt_02 21s linear infinite;
}

div.beteBR07 {
  position: absolute;
  width: 100%;
  top: 0;
  left: 0;
  animation: animSP_800BR_mamouth_01 1.5s linear infinite;
}

div.mvtBeteBR07 {
  position: absolute;
  width: 40%;
   
  top: 41%;
  left: 0;
  animation: animSP_mvt_01 60s linear infinite;
}

div.beteBR08 {
  position: absolute;
  width: 100%;
  top: 0;
  left: 0;
  animation: animSP_800BR_vache_03 1.5s linear infinite;
}

div.mvtBeteBR08 {
  position: absolute;
  width: 40%;
   
  top: 49%;
  left: 0;
  animation: animSP_mvt_02 42s linear infinite;
}

div.beteBR09 {
  position: absolute;
  width: 100%;
  top: 0;
  left: 0;
  animation: animSP_800BR_cheval_03 1.5s linear infinite;
}

div.mvtBeteBR09 {
  position: absolute;
  width: 40%;
   
  top: 55%;
  left: 0;
  animation: animSP_mvt_01 23s linear infinite;
}

div.beteBR10 {
  position: absolute;
  width: 100%;
  top: 0;
  left: 0;
  animation: animSP_800BR_vache_04 1.5s linear infinite;
}

div.mvtBeteBR10 {
  position: absolute;
  width: 40%;
   
  top: 61%;
  left: 0;
  animation: animSP_mvt_01 40s linear infinite;
}

div.ombreLumiereBR {
  position: absolute;
  width: 200%;
  height: 200%;
  top: -50%;
  left: -50%;
  background-image: url('../assets/img/img_space/800GL_RocheOmbre.webp');
  background-size: 100% 100%;
  opacity: 0.9;
}

div.ombreBR {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color:black;
  background-size: 100% 100%;
  background-image: url('../assets/img/img_space/800GL_RocheFondGrotte.webp');
}

div.cadreBR3a{
  top: 0;
  left: 90%;
  position: absolute;
  width: 10%;
}


div.cadreBR3aa {
  position: relative;
  float: left;
  width: 80%;
  margin: 10%;
  margin-top: 35%
}



#button2aBRBR {
  display: block;
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: #00ff36;
  top: 0;
  left: 0;
  border-radius: 5em;
  cursor: pointer;
}

input[type='checkbox'] {
  display: none;
}

#button2aBR {
  display: block;
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: #fedcba;
  top: 0;
  left: 0;
  border-radius: 5em;
  cursor: pointer;
}

#knob_lumiere01 {
  position: absolute;
  left: 0;
  width: 80%;
  height: auto;
  border-radius: 50%;
  margin: 10%;
  background: #ffffff;
  transition: 0.4s ease left, 0.4s ease background-position;
  z-index: 2;
}

#knob_lumiere01 {
  background-image: url('../assets/img/img_space/800ER_Boutonbr02.webp');
  background-size: 100%;
  background-position: 0% 0%;
  top: 0;
}

#chkBR_lumiere01:checked+#button2aBR #knob_lumiere01 {
  top: 50%;
  background-position: 0% 100%;
}

