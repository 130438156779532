
div.cadreBK {
position: relative;
width:33%;
margin: 0 auto;
background-color:black;
}

div.cadreBK_pl {
position:absolute;
width:1%;
height:1%;
left:0%;
top:0%;
background-image:url("../assets/img/img_kaiju/111_M1.webp"),
url("../assets/img/img_kaiju/111_M2.webp"),
url("../assets/img/img_kaiju/111_M3.webp"),
url("../assets/img/img_kaiju/111_M4.webp"),
url("../assets/img/img_kaiju/111_M5.webp"),
url("../assets/img/img_kaiju/111_M6.webp"),
url("../assets/img/img_kaiju/111_M7.webp"),
url("../assets/img/img_kaiju/111_M8.webp"),
url("../assets/img/img_kaiju/111_B1.webp"),
url("../assets/img/img_kaiju/111_B2.webp"),
url("../assets/img/img_kaiju/111_B3.webp"),
url("../assets/img/img_kaiju/111_B4.webp"),
url("../assets/img/img_kaiju/111_B5.webp"),
url("../assets/img/img_kaiju/111_B6.webp"),
url("../assets/img/img_kaiju/111_B7.webp"),
url("../assets/img/img_kaiju/111_B8.webp"),
url("../assets/img/img_kaiju/800BK_main_PG.webp"),
url("../assets/img/img_kaiju/800BK_main_CG.webp"),
url("../assets/img/img_kaiju/800BK_main_FG.webp"),
url("../assets/img/img_kaiju/800BK_main_PD.webp"),
url("../assets/img/img_kaiju/800BK_main_FD.webp"),
url("../assets/img/img_kaiju/800BK_main_CD.webp");
background-position: 100% 100%;
}

html {
scroll-behavior: smooth;
}

.cadreBK9 {
transform-style: preserve-3d;
perspective: 500px;
position: absolute;
width:100%;
height:100%;
top:0%;
left:0%;
}

img.imgFondBK{
width:100%;
}

.cadreBK99 {
width:100%;
height:36.93%;
top:0%;
left:0%;
position: relative;
}

.cadreBK999 {
height:100%;
position: absolute;
top:0;
left:0;
width:100%;
}

.cadreBK99MiChoix{
height:100%;
position: absolute;
top:0;
left:0;
width:60%;
left:22%;
}

.cadreBK99Mi {
width:100%;
height:26.14%;
top:0%;
left:0%;
position: relative;
background-color: antiquewhite;
}

div.cadreBK_01 {
background-image: url("../assets/img/img_kaiju/111_BB1.webp"),
url("../assets/img/img_kaiju/111_BB2.webp"),
url("../assets/img/img_kaiju/111_BB3.webp"),linear-gradient(#6979a1,#0e3494);
}

div.cadreBK_02 {
background-image: url("../assets/img/img_kaiju/111_BB1.webp"),
url("../assets/img/img_kaiju/111_BB3.webp"),
url("../assets/img/img_kaiju/111_BB2.webp"),linear-gradient(#6979a1,#0e3494);
}

div.cadreBK_03 {
background-image: url("../assets/img/img_kaiju/111_BB2.webp"),
url("../assets/img/img_kaiju/111_BB1.webp"),
url("../assets/img/img_kaiju/111_BB3.webp"),linear-gradient(#6979a1,#0e3494);
}

div.cadreBK_04 {
background-image: url("../assets/img/img_kaiju/111_BB2.webp"),
url("../assets/img/img_kaiju/111_BB3.webp"),
url("../assets/img/img_kaiju/111_BB1.webp"),linear-gradient(#6979a1,#0e3494);
}

div.cadreBK_05 {
background-image: url("../assets/img/img_kaiju/111_BB3.webp"),
url("../assets/img/img_kaiju/111_BB1.webp"),
url("../assets/img/img_kaiju/111_BB2.webp"),linear-gradient(#6979a1,#0e3494);
}

div.cadreBK_06 {
background-image: url("../assets/img/img_kaiju/111_BB3.webp"),
url("../assets/img/img_kaiju/111_BB2.webp"),
url("../assets/img/img_kaiju/111_BB1.webp"),linear-gradient(#6979a1,#0e3494);
}

div.cadreBK {
position:relative;
overflow: hidden;
width: 100%;
padding: 0;
}

.imgvideBK{
width:100%;
}

.imgvideBKa {
position:relative;
width:100%;
height: 100%;
} 

.cadreBK .monstreBK_01 {
position:absolute;
background-image: url("../assets/img/img_kaiju/111_M1.webp");
border-radius: 0;
}

.cadreBK .monstreBK_02 {
position:absolute;
background-image: url("../assets/img/img_kaiju/111_M2.webp");
border-radius: 0;
}

.cadreBK .monstreBK_03 {
position:absolute;
background-image: url("../assets/img/img_kaiju/111_M3.webp");
border-radius: 0;
}

.cadreBK .monstreBK_04 {
position:absolute;
background-image: url("../assets/img/img_kaiju/111_M4.webp");
border-radius: 0;
}

.cadreBK .monstreBK_05 {
position:absolute;
background-image: url("../assets/img/img_kaiju/111_M5.webp");
border-radius: 0;
}

.cadreBK .monstreBK_06 {
position:absolute;
background-image: url("../assets/img/img_kaiju/111_M6.webp");
border-radius: 0;
}

.cadreBK .monstreBK_07 {
position:absolute;
background-image: url("../assets/img/img_kaiju/111_M7.webp");
border-radius: 0;
}

.cadreBK .monstreBK_08 {
position:absolute;
background-image: url("../assets/img/img_kaiju/111_M8.webp");
border-radius: 0;
}

.cadreBK .monstreBK_09 {
position:absolute;
background-image: url("../assets/img/img_kaiju/111_M9.webp");
border-radius: 0;
}

.cadreBK .villeBK_01 {
position:absolute;
background-image: url("../assets/img/img_kaiju/111_B1.webp"),linear-gradient(#6979a1,#0e3494);
border-radius: 0;
}

.cadreBK .villeBK_02 {
position:absolute;
background-image: url("../assets/img/img_kaiju/111_B2.webp"),linear-gradient(#6979a1,#0e3494);
border-radius: 0;
}

.cadreBK .villeBK_03 {
position:absolute;
background-image: url("../assets/img/img_kaiju/111_B3.webp"),linear-gradient(#6979a1,#0e3494);
border-radius: 0;
}

.cadreBK .villeBK_04 {
position:absolute;
background-image: url("../assets/img/img_kaiju/111_B4.webp"),linear-gradient(#6979a1,#0e3494);
border-radius: 0;
}

.cadreBK .villeBK_05 {
position:absolute;
background-image: url("../assets/img/img_kaiju/111_B5.webp"),linear-gradient(#6979a1,#0e3494);
border-radius: 0;
}

.cadreBK .villeBK_06 {
position:absolute;
background-image: url("../assets/img/img_kaiju/111_B6.webp"),linear-gradient(#6979a1,#0e3494);
border-radius: 0;
}

.cadreBK .villeBK_07 {
position:absolute;
background-image: url("../assets/img/img_kaiju/111_B7.webp"),linear-gradient(#6979a1,#0e3494);
border-radius: 0;
}

.cadreBK .villeBK_08 {
position:absolute;
background-image: url("../assets/img/img_kaiju/111_B8.webp"),linear-gradient(#6979a1,#0e3494);
border-radius: 0;
}

.cadreBK .villeBK_09 {
position:absolute;
background-image: url("../assets/img/img_kaiju/111_B9.webp"),linear-gradient(#6979a1,#0e3494);
border-radius: 0;
}

.cadreBK .imgBK_over01, .cadreBK .imgBK_over02, .cadreBK .imgBK_over03, .cadreBK .imgBK_over04, .cadreBK .imgBK_over05, .cadreBK .imgBK_over06  {
position:relative;
float:left;
height: 100%;
width:33.33%;
bottom:0;
background-size: contain;
}

.cadreBK .imgBK_monstreR{
position:relative;
float:left;
height: 100%;
width:33.33%;
bottom:0;
left:0%;
background-size: contain;
transition: all 2s ease;
}

.cadreBK .imgBK_monstreR1{
position:relative;
float:left;
height: 100%;
width:33.33%;
bottom:0;
background-size: contain;
left:20%;
transition: all 2s ease;
}

.cadreBK .imgBK_monstreR2{
position:relative;
float:left;
height: 100%;
width:33.33%;
bottom:0;
background-size: contain;
left:40%;
transition: all 2s ease;
}

.cadreBK .imgBK_monstreR3{
position:relative;
float:left;
height: 100%;
width:33.33%;
bottom:0;
background-size: contain;
left:60%;
transition: all 2s ease;
}

.cadreBK .imgBK_monstreRD{
position:relative;
float:left;
height: 100%;
width:33.33%;
bottom:0;
background-size: contain;
transform: rotate(180deg);
transition: all 2s ease;
}

.cadreBK .imgBK_monstreB{
position:relative;
float:right;
height: 100%;
width:33.33%;
bottom:0;
background-size: contain;
transform: scale(-1,1);
right: 0%;
transition: all 2s ease;
}

.cadreBK .imgBK_monstreB1{
position:relative;
float:right;
height: 100%;
width:33.33%;
bottom:0;
background-size: contain;
transform: scale(-1,1);
right: 20%;
transition: all 2s ease;
}

.cadreBK .imgBK_monstreB2{
position:relative;
float:right;
height: 100%;
width:33.33%;
bottom:0;
background-size: contain;
transform: scale(-1,1);
right:40%;
transition: all 2s ease;
}

.cadreBK .imgBK_monstreB3{
position:relative;
float:right;
height: 100%;
width:33.33%;
bottom:0;
background-size: contain;
transform: scale(-1,1);
right:60%;
transition: all 2s ease;
}

.cadreBK .imgBK_monstreBD{
position:relative;
float:right;
height: 100%;
width:33.33%;
bottom:0;
background-size: contain;
transform: scale(-1,1);
transform: rotate(180deg);
}

.cadreBK .eltBKR {
    position:relative;
    top:10%;
    height:80%;
    width: 32%;
    background-size: contain;
    cursor: pointer;
}

.cadreBK .pierreBKR {
float:left;
background-image: url("../assets/img/img_kaiju/800BK_main_PG.webp");

}

.cadreBK .feuilleBKR {
float:left;
background-image: url("../assets/img/img_kaiju/800BK_main_FG.webp");
}

.cadreBK .ciseauxBKR {
float:left;
background-image: url("../assets/img/img_kaiju/800BK_main_CG.webp");
}

.cadreBK .pierreBKB {
float:right;
background-image: url("../assets/img/img_kaiju/800BK_main_PD.webp");
}

.cadreBK .feuilleBKB {
float:right;
background-image: url("../assets/img/img_kaiju/800BK_main_FD.webp");
}

.cadreBK .ciseauxBKB {
float:right;
background-image: url("../assets/img/img_kaiju/800BK_main_CD.webp");
}

.cadreBK_msg {
background: rgba(0, 0, 0, 0.3);
position: absolute;
top: 0;
left: 0;
width: 100%;
height: 100%;
}

.cadreBK7_msg {
font-family: 'Fjalla One', sans-serif;
display: flex;
text-align: center;
align-items: center;
justify-content: center;
width: 100%;
height: 100%;
}

.cadreBK77_msg {
font-family: 'Fjalla One', sans-serif;
position: relative;
width: 80%;
height:60%;
margin: 20%;
margin-left: 2%;
margin-right: 2%;
/*Background-color: white;*/
}












