div.cadreSP {
    position: relative;
    width:33%;
    margin: 0 auto;
    overflow:hidden;
    padding:0%;
    border:0;
    background-color:black;
  }
  
  div.cadreSP_pl {
    position:absolute;
    width:1%;
    height:1%;
    left:0%;
    top:0%;
    background-image:url('../assets/img/img_space/Montagn03_Montage_01a.webp'),
    url('../assets/img/img_space/Montagn03_Montage_01b.webp'),
    url('../assets/img/img_space/Montagn03_Montage_01c.webp'),
    url('../assets/img/img_space/Montagn03_Montage_01d.webp'),
    url('../assets/img/img_space/Montagn03_Montage_01e.webp'),
    url('../assets/img/img_space/Montagn03_Montage_01f.webp'),
    url('../assets/img/img_space/Montagn03_Montage_01g.webp'),
    url('../assets/img/img_space/Montagn03_Montage_01h.webp'),
    url('../assets/img/img_space/Montagn03_Montage_01i.webp'),
    url('../assets/img/img_space/Montagn03_Montage_01j.webp'),
    url('../assets/img/img_space/Montagn03_Montage_01k.webp'),
    url('../assets/img/img_space/Montagn03_Montage_01l.webp'),
    url('../assets/img/img_space/Montagn03_Montage_01m.webp'),
    url('../assets/img/img_space/Montagn03_mn00.webp'),
    url('../assets/img/img_space/Montagn03_mn01.webp'),
    url('../assets/img/img_space/Montagn03_mh01.webp'),
    url('../assets/img/img_space/Montagn03_mn02.webp'),
    url('../assets/img/img_space/Montagn03_mh02.webp'),
    url('../assets/img/img_space/Montagn03_mn03.webp'),
    url('../assets/img/img_space/Montagn03_mh03.webp'),
    url('../assets/img/img_space/Montagn03_mn04.webp'),
    url('../assets/img/img_space/Montagn03_mh04.webp'),
    url('../assets/img/img_space/Montagn03_mn05.webp'),
    url('../assets/img/img_space/Montagn03_mh05.webp'),
    url('../assets/img/img_space/Montagn03_mn06.webp'),
    url('../assets/img/img_space/Montagn03_mh06.webp');
    background-position: 100% 100%;
  }
  
  img.imgFondSP{
    width:100%;
  }
  
  div.cadreSP2 {
    position: absolute;
    top:0%;
    left:0%;
    width:100%;
    height:90%;
    margin: 0 auto;
    overflow:hidden;
    padding:0%;
    border:0;
  }
  
  div.pisteSP {
    display:none;
    position: absolute;
    top:0%;
    left:0%;
    width:100%;
    height:100%;
    overflow:hidden;
    padding:0%;
    border:0;
    animation: animSP_piste 1s 1;
  }
  
  @keyframes animSP_piste {
    0% { background-image:url('../assets/img/img_space/Montagn03_Montage_01a.webp');background-size: cover; }
    7% { background-image:url('../assets/img/img_space/Montagn03_Montage_01b.webp');background-size: cover; }
    15% { background-image:url('../assets/img/img_space/Montagn03_Montage_01c.webp');background-size: cover; }
    22% { background-image:url('../assets/img/img_space/Montagn03_Montage_01d.webp');background-size: cover; }
    32% { background-image:url('../assets/img/img_space/Montagn03_Montage_01e.webp');background-size: cover; }
    36% { background-image:url('../assets/img/img_space/Montagn03_Montage_01f.webp');background-size: cover; }
    43% { background-image:url('../assets/img/img_space/Montagn03_Montage_01g.webp');background-size: cover; }
    50% { background-image:url('../assets/img/img_space/Montagn03_Montage_01h.webp');background-size: cover; }
    60% { background-image:url('../assets/img/img_space/Montagn03_Montage_01i.webp');background-size: cover; }
    70% { background-image:url('../assets/img/img_space/Montagn03_Montage_01j.webp');background-size: cover; }
    80% { background-image:url('../assets/img/img_space/Montagn03_Montage_01k.webp');background-size: cover; }
    90% { background-image:url('../assets/img/img_space/Montagn03_Montage_01l.webp');background-size: cover; }
    100% { background-image:url('../assets/img/img_space/Montagn03_Montage_01m.webp');background-size: cover; }
  }
  
  div.neigeSP {
    display:none;
    position: absolute;
    top:0%;
    left:0%;
    width:100%;
    height:100%;
    overflow:hidden;
    padding:0%;
    border:0;
    animation: animSP_neige 7s 1;
  }
  
  @keyframes animSP_neige {
    0% { background-image:url('../assets/img/img_space/Montagn02Neige.webp');background-size: cover;top:100%; }
    30% { background-image:url('../assets/img/img_space/Montagn02Neige.webp');background-size: cover;top:100%; }
    60% { background-image:url('../assets/img/img_space/Montagn02Neige.webp');background-size: cover;top:-10%; }
    80% { background-image:url('../assets/img/img_space/Montagn02Neige.webp');background-size: cover;top:-10%; }
    100% { background-image:url('../assets/img/img_space/Montagn02Neige.webp');background-size: cover; top:100%;}
  }
  
  div.persoSP_01 {
    display:none;
    position: absolute;
    top:0%;
    left:0%;
    width:100%;
    height:100%;
    overflow:hidden;
    padding:0%;
    border:0;
    animation: animSP_perso01 7s 1 ease-in-out;
  }
  
  @keyframes animSP_perso01 {
    0% { background-image:url('../assets/img/img_space/Montagn02Prso03_01b.webp');background-size: cover;top:100%; }
    20% { background-image:url('../assets/img/img_space/Montagn02Prso03_01a.webp');background-size: cover;top:100%; }
    58% { background-image:url('../assets/img/img_space/Montagn02Prso03_01a.webp');background-size: cover; }
    60% { background-image:url('../assets/img/img_space/Montagn02Prso03_01b.webp');background-size: cover; top:0%;}
    89% { background-image:url('../assets/img/img_space/Montagn02Prso03_01b.webp');background-size: cover; }
    91% { background-image:url('../assets/img/img_space/Montagn02Prso03_01a.webp');background-size: cover;}
    100% { background-image:url('../assets/img/img_space/Montagn02Prso03_01a.webp');background-size: cover; top:100%;}
  }
  
  div.persoSP_01c {
    position: absolute;
    top:0%;
    left:0%;
    width:100%;
    height:100%;
    overflow:hidden;
    padding:0%;
    border:0;
    animation: animSP_perso01c 7s 1 ease-in-out;
  }
  
  @keyframes animSP_perso01c {
    0% { background-image:url('../assets/img/img_space/Montagn02Prso03_01c.webp');background-size: cover;top:100%; }
    50% { background-image:url('../assets/img/img_space/Montagn02Prso03_01c.webp');background-size: cover;top:-20%; }
    70% { background-image:url('../assets/img/img_space/Montagn02Prso03_01c.webp');background-size: cover;top:20%; }
    100% { background-image:url('../assets/img/img_space/Montagn02Prso03_01c.webp');background-size: cover; top:100%;}
  }
  
  div.persoSP_02 {
    display:none;
    position: absolute;
    top:0%;
    left:0%;
    width:100%;
    height:100%;
    overflow:hidden;
    padding:0%;
    border:0;
    animation: animSP_perso02 7s 1 ease-in-out;
  }
  
  @keyframes animSP_perso02 {
    0% { background-image:url('../assets/img/img_space/Montagn02Prso03_02b.webp');background-size: cover;top:100%;}
    20% { background-image:url('../assets/img/img_space/Montagn02Prso03_02a.webp');background-size: cover;top:100%; }
    58% { background-image:url('../assets/img/img_space/Montagn02Prso03_02a.webp');background-size: cover; }
    60% { background-image:url('../assets/img/img_space/Montagn02Prso03_02b.webp');background-size: cover; top:0%;}
    89% { background-image:url('../assets/img/img_space/Montagn02Prso03_02b.webp');background-size: cover; }
    91% { background-image:url('../assets/img/img_space/Montagn02Prso03_02a.webp');background-size: cover;}
    100% { background-image:url('../assets/img/img_space/Montagn02Prso03_02a.webp');background-size: cover; top:100%;}
  }
  
  div.persoSP_02c {
    position: absolute;
    top:0%;
    left:0%;
    width:100%;
    height:100%;
    overflow:hidden;
    padding:0%;
    border:0;
    animation: animSP_perso02c 7s 1 ease-in-out;
  }
  
  @keyframes animSP_perso02c {
    0% { background-image:url('../assets/img/img_space/Montagn02Prso03_02c.webp');background-size: cover;top:100%; }
    50% { background-image:url('../assets/img/img_space/Montagn02Prso03_02c.webp');background-size: cover;top:20%; }
    70% { background-image:url('../assets/img/img_space/Montagn02Prso03_02c.webp');background-size: cover;top:20%; }
    100% { background-image:url('../assets/img/img_space/Montagn02Prso03_02c.webp');background-size: cover; top:100%;}
  }
  
  div.persoSP_03 {
    display:none;
    position: absolute;
    top:0%;
    left:0%;
    width:100%;
    height:100%;
    overflow:hidden;
    padding:0%;
    border:0;
    animation: animSP_perso03 7s 1 ease-in-out;
  }
  
  @keyframes animSP_perso03 {
    0% { background-image:url('../assets/img/img_space/Montagn02Prso03_03b.webp');background-size: cover;top:100%; }
    20% { background-image:url('../assets/img/img_space/Montagn02Prso03_03a.webp');background-size: cover;top:100%; }
    58% { background-image:url('../assets/img/img_space/Montagn02Prso03_03a.webp');background-size: cover; }
    60% { background-image:url('../assets/img/img_space/Montagn02Prso03_03b.webp');background-size: cover; top:0%;}
    89% { background-image:url('../assets/img/img_space/Montagn02Prso03_03b.webp');background-size: cover; }
    91% { background-image:url('../assets/img/img_space/Montagn02Prso03_03a.webp');background-size: cover;}
    100% { background-image:url('../assets/img/img_space/Montagn02Prso03_03a.webp');background-size: cover; top:100%;}
  }
  
  div.persoSP_03c {
    position: absolute;
    top:0%;
    left:0%;
    width:100%;
    height:100%;
    overflow:hidden;
    padding:0%;
    border:0;
    animation: animSP_perso03c 7s 1 ease-in-out;
  }
  
  @keyframes animSP_perso03c {
    0% { background-image:url('../assets/img/img_space/Montagn02Prso03_03c.webp');background-size: cover;top:100%; }
    50% { background-image:url('../assets/img/img_space/Montagn02Prso03_03c.webp');background-size: cover;top:-10%; }
    70% { background-image:url('../assets/img/img_space/Montagn02Prso03_03c.webp');background-size: cover;top:-10%; }
    100% { background-image:url('../assets/img/img_space/Montagn02Prso03_03c.webp');background-size: cover; top:100%;}
  }
  
  div.persoSP_04 {
    display:none;
    position: absolute;
    top:0%;
    left:0%;
    width:100%;
    height:100%;
    overflow:hidden;
    padding:0%;
    border:0;
    animation: animSP_perso04 7s 1 ease-in-out;
  }
  
  @keyframes animSP_perso04 {
    0% { background-image:url('../assets/img/img_space/Montagn02Prso03_04b.webp');background-size: cover;top:100%; }
    20% { background-image:url('../assets/img/img_space/Montagn02Prso03_04a.webp');background-size: cover;top:100%; }
    58% { background-image:url('../assets/img/img_space/Montagn02Prso03_04a.webp');background-size: cover; }
    60% { background-image:url('../assets/img/img_space/Montagn02Prso03_04b.webp');background-size: cover; top:0%;}
    89% { background-image:url('../assets/img/img_space/Montagn02Prso03_04b.webp');background-size: cover; }
    91% { background-image:url('../assets/img/img_space/Montagn02Prso03_04a.webp');background-size: cover;}
    100% { background-image:url('../assets/img/img_space/Montagn02Prso03_04a.webp');background-size: cover; top:100%;}
  }
  
  div.persoSP_05 {
    display:none;
    position: absolute;
    top:0%;
    left:0%;
    width:100%;
    height:100%;
    overflow:hidden;
    padding:0%;
    border:0;
    animation: animSP_perso05 7s 1 ease-in-out;
  }
  
  @keyframes animSP_perso05 {
    0% { background-image:url('../assets/img/img_space/Montagn02Prso03_05b.webp');background-size: cover;top:100%; }
    20% { background-image:url('../assets/img/img_space/Montagn02Prso03_05a.webp');background-size: cover;top:100%; }
    58% { background-image:url('../assets/img/img_space/Montagn02Prso03_05a.webp');background-size: cover; }
    60% { background-image:url('../assets/img/img_space/Montagn02Prso03_05b.webp');background-size: cover; top:0%;}
    89% { background-image:url('../assets/img/img_space/Montagn02Prso03_05b.webp');background-size: cover; }
    91% { background-image:url('../assets/img/img_space/Montagn02Prso03_05a.webp');background-size: cover;}
    100% { background-image:url('../assets/img/img_space/Montagn02Prso03_05a.webp');background-size: cover; top:100%;}
  }
  
  div.persoSP_06 {
    display:none;
    position: absolute;
    top:0%;
    left:0%;
    width:100%;
    height:100%;
    overflow:hidden;
    padding:0%;
    border:0;
    animation: animSP_perso06 7s 1 ease-in-out;
  }
  
  @keyframes animSP_perso06 {
    0% { background-image:url('../assets/img/img_space/Montagn02Prso03_06b.webp');background-size: cover;top:100%; }
    20% { background-image:url('../assets/img/img_space/Montagn02Prso03_06a.webp');background-size: cover;top:100%; }
    58% { background-image:url('../assets/img/img_space/Montagn02Prso03_06a.webp');background-size: cover; }
    60% { background-image:url('../assets/img/img_space/Montagn02Prso03_06b.webp');background-size: cover; top:0%;}
    89% { background-image:url('../assets/img/img_space/Montagn02Prso03_06b.webp');background-size: cover; }
    91% { background-image:url('../assets/img/img_space/Montagn02Prso03_06a.webp');background-size: cover;}
    100% { background-image:url('../assets/img/img_space/Montagn02Prso03_06a.webp');background-size: cover; top:100%;}
  }
  
  div.cadreSP2m {
    position: absolute;
    top:90%;
    left:0%;
    width:100%;
    height:10%;
    padding:0%;
    border:0;
    background-image:url('../assets/img/img_space/Montagn03_fondBarBt.webp');background-size: cover;
  }
  
  div.btSPm {
    position: relative;
    float:left;
    width:16.5%;
    height:100%;
    padding:0%;
    border:0;
    cursor: pointer;
  }
  
  div.btSPmm {
    width: 50%;
    border-radius: 49.5%;
    height: 85%;
    background-color: red;
    margin-left: 25%;
    margin-right: 25%;
    margin-top: 5%;
    margin-bottom: 10%;
  }
  
  div.btSP_b01a{
    background-size:contain;
    background-image:url('../assets/img/img_space/Montagn03_b01a.webp');
  }
  
  div.btSP_b02a{
    background-size:contain;
    background-image:url('../assets/img/img_space/Montagn03_b02a.webp');
  }
  
  div.btSP_b03a{
    background-size:contain;
    background-image:url('../assets/img/img_space/Montagn03_b03a.webp');
  }
  
  div.btSP_b04a{
    background-size:contain;
    background-image:url('../assets/img/img_space/Montagn03_b04a.webp');
  }
  
  div.btSP_b05a{
    background-size:contain;
    background-image:url('../assets/img/img_space/Montagn03_b05a.webp');
  }
  
  div.btSP_b06a{
    background-size:contain;
    background-image:url('../assets/img/img_space/Montagn03_b06a.webp');
  }
  
  