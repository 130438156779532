div.cadreMB {
  position: relative;
  width: 33%;
  margin: 0 auto;
  overflow: hidden;
  background-color: black;

}

div.cadreMB_pl {
  position: absolute;
  width: 1%;
  height: 1%;
  left: 0%;
  top: 0%;
  background-image: url('../assets/img/img_space/BoutonLA01_01.webp'),
    url('../assets/img/img_space/BoutonLA01_02.webp'),
    url('../assets/img/img_space/BoutonLA01_03.webp'),
    url('../assets/img/img_space/BoutonLA01_04.webp'),
    url('../assets/img/img_space/BoutonLA01_05.webp'),
    url('../assets/img/img_space/BoutonLA01_06.webp'),
    url('../assets/img/img_space/FondKhops01_sol_mvt_01.webp'),
    url('../assets/img/img_space/FondKhops01_sol_mvt_02.webp'),
    url("../assets/img/img_space/FondKhops01_bug_01.webp"),
    url("../assets/img/img_space/FondKhops01_bug_02.webp"),
    url("../assets/img/img_space/FondKhops01_bug_03.webp"),
    url("../assets/img/img_space/FondKhops_Tornad_Tornad_01.webp"),
    url("../assets/img/img_space/FondKhops_Tornad_Tornad_02.webp"),
    url("../assets/img/img_space/FondKhops01_brum_brum.webp"),
    url("../assets/img/img_space/FondKhops01_ombr_mvt_01.webp"),
    url("../assets/img/img_space/FondKhops01_ombr_mvt_02.webp")
    url("../assets/img/img_space/800MB_VictoireMvt00_01.webp"),
    url("../assets/img/img_space/800MB_VictoireMvt00_02.webp"),
    url("../assets/img/img_space/800MB_VictoireMvt00_03.webp"),
    url("../assets/img/img_space/800MB_VictoireMvt00_04.webp"),
    url("../assets/img/img_space/800MB_VictoireMvt00_05.webp"),
    url("../assets/img/img_space/800MB_VictoireMvt00_06.webp"),
    url("../assets/img/img_space/800MB_Colombe01.webp"),
    url("../assets/img/img_space/800MB_Colombe02.webp"),
    url("../assets/img/img_space/800MB_Colombe03.webp"),
    url("../assets/img/img_space/800MB_Colombe04.webp");
  background-position: 100% 100%;
}

html {
  scroll-behavior: smooth;
}

div.cadreMB6 {
  position: absolute;
  width: 90%;
  overflow: scroll;
  overflow: hidden;
  top: 0;
  left: 10%;
  /*scrollbar-color: transparent transparent;*/
  border-bottom: none;
}

div.cadreMB6_FondK {
  position: absolute;
  top: 0;
  left: 10%;
  width: 90%;
  background-color: grey;
}

div.cadreMB6_bouton {
  top: 0;
  left: 0;
  width: 10%;
  position: absolute;
}

div.cadreMB6::-webkit-scrollbar {
  display: none;
}

div.cadreMB66 {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}

img.imgFondMB {
  width: 100%;
}

.owwMN {
  position: relative;
  -webkit-transition: all 0.1s ease;
  -moz-transition: all 0.1s ease;
  -o-transition: all 0.1s ease;
  -ms-transition: all 0.1s ease;
  transition: all 0.1s ease;
}

.owwMN_active {
  top: 0;
}

@keyframes animMN_titre {
  0% {
    top: -100%;
    left: 0%;
    background-image: url("../assets/img/img_space/FondKhops_titre.webp");
    background-size: contain;
    transform: rotate(-5deg);
    transform-origin: bottom left;
    opacity: 0;
  }

  0.5% {
    opacity: 1;
  }

  1% {
    top: 0%;
    left: 0%;
  }

  1.25% {
    top: 0%;
    left: 0%;
    transform: rotate(-5deg);
    transform-origin: bottom left;
  }

  1.5% {
    top: 0%;
    left: 0%;
    transform: rotate(0deg);
    transform-origin: bottom left;
  }

  6.5% {
    top: 0%;
    left: 0%;
    opacity: 1;
  }

  8% {
    top: 0%;
    left: 0%;
    opacity: 0;
  }

  8.25% {
    top: -100%;
    left: 0%;
    opacity: 0;
  }

  8.5% {
    top: -100%;
    left: 0%;
    opacity: 0;
    display: none;
  }

  100% {
    top: -100%;
    left: 0%;
    opacity: 0;
  }
}

div.cadreMB6_nana {
  position: absolute;
  bottom: 0%;
  right: 0%;
  width: 50%;
  animation: animMN_nana_mvt0 1s linear infinite;
}


@keyframes animMN_nana_mvt0 {
  0% {
    background-image: url("../assets/img/img_space/800MB_VictoireMvt00_01.webp");
    background-size: contain;
  }

  5.6% {
    background-image: url("../assets/img/img_space/800MB_VictoireMvt00_01.webp");
    background-size: contain;
  }

  11.1% {
    background-image: url("../assets/img/img_space/800MB_VictoireMvt00_02.webp");
    background-size: contain;
  }

  22.3% {
    background-image: url("../assets/img/img_space/800MB_VictoireMvt00_02.webp");
    background-size: contain;
  }

  27.8% {
    background-image: url("../assets/img/img_space/800MB_VictoireMvt00_03.webp");
    background-size: contain;
  }

  39% {
    background-image: url("../assets/img/img_space/800MB_VictoireMvt00_03.webp");
    background-size: contain;
  }

  44.5% {
    background-image: url("../assets/img/img_space/800MB_VictoireMvt00_04.webp");
    background-size: contain;
  }

  55.7% {
    background-image: url("../assets/img/img_space/800MB_VictoireMvt00_04.webp");
    background-size: contain;
  }

  61.2% {
    background-image: url("../assets/img/img_space/800MB_VictoireMvt00_05.webp");
    background-size: contain;
  }

  72.4% {
    background-image: url("../assets/img/img_space/800MB_VictoireMvt00_05.webp");
    background-size: contain;
  }

  77.9% {
    background-image: url("../assets/img/img_space/800MB_VictoireMvt00_06.webp");
    background-size: contain;
  }

  89.1% {
    background-image: url("../assets/img/img_space/800MB_VictoireMvt00_06.webp");
    background-size: contain;
  }

  94.6% {
    background-image: url("../assets/img/img_space/800MB_VictoireMvt00_01.webp");
    background-size: contain;
  }

  100% {
    background-image: url("../assets/img/img_space/800MB_VictoireMvt00_01.webp");
    background-size: contain;
  }
}

div.cadreMB6_titre {
  position: absolute;
  top: 0%;
  left: 0%;
  width: 100%;
  animation: animMN_titre 60s forwards 1;
}

div.btMN_la {
  float: left;
  left: 0;
  width: 100%;
  bottom: 0;
  cursor: pointer;
  background-position: 0%;
}

div.btMN_la_active {
  float: left;
  left: 0;
  width: 100%;
  bottom: 0;
  cursor: pointer;
  background-position: 100%;
}

#owwMN_la1 {
  background-image: url("../assets/img/img_space/BoutonLA01_01.webp");
  background-size: cover;
}

#owwMN_la2 {
  background-image: url("../assets/img/img_space/BoutonLA01_02.webp");
  background-size: cover;
}

#owwMN_la3 {
  background-image: url("../assets/img/img_space/BoutonLA01_03.webp");
  background-size: cover;
}

#owwMN_la4 {
  background-image: url("../assets/img/img_space/BoutonLA01_04.webp");
  background-size: cover;
}

#owwMN_la5 {
  background-image: url("../assets/img/img_space/BoutonLA01_05.webp");
  background-size: cover;
}

#owwMN_la6 {
  background-image: url("../assets/img/img_space/BoutonLA01_06.webp");
  background-size: cover;
}

@keyframes animMN_sol {
  0% {
    background-image: url("../assets/img/img_space/FondKhops01_sol_mvt_01.webp");
    background-size: 100%;
    top: 0;
    left: 0;
  }

  50% {
    background-image: url("../assets/img/img_space/FondKhops01_sol_mvt_02.webp");
    background-size: 100%;
    top: 0;
    left: 0;
  }

  100% {
    background-image: url("../assets/img/img_space/FondKhops01_sol_mvt_01.webp");
    background-size: 100%;
    top: 0;
    left: 0;
  }
}

div.cadreMB66_01a {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  animation: animMN_sol 0.5s linear infinite;
}

@keyframes animMN_bug_old {
  0% {
    background-image: url("../assets/img/img_space/FondKhops01_bug_02.webp");
    background-size: 100%;
    top: 0;
    left: 0;
  }

  5% {
    background-image: url("../assets/img/img_space/FondKhops01_bug_02.webp");
    background-size: 100%;
    top: 0;
    left: 0;
  }

  21% {
    background-image: url("../assets/img/img_space/FondKhops01_bug_03.webp");
    background-size: 100%;
    top: 0;
    left: 0;
  }

  41% {
    background-image: url("../assets/img/img_space/FondKhops01_bug_03.webp");
    background-size: 100%;
    top: 0;
    left: 0;
  }

  58% {
    background-image: url("../assets/img/img_space/FondKhops01_bug_01.webp");
    background-size: 100%;
    top: 0;
    left: 0;
  }

  78% {
    background-image: url("../assets/img/img_space/FondKhops01_bug_01.webp");
    background-size: 100%;
    top: 0;
    left: 0;
  }

  95% {
    background-image: url("../assets/img/img_space/FondKhops01_bug_02.webp");
    background-size: 100%;
    top: 0;
    left: 0;
  }

  100% {
    background-image: url("../assets/img/img_space/FondKhops01_bug_02.webp");
    background-size: 100%;
    top: 0;
    left: 0;
  }
}

@keyframes animMN_bug {
  0% {
    background-image: url("../assets/img/img_space/800MB_Colombe04.webp");
    background-size: 100%;
    top: 0;
    left: 0;
  }

  5% {
    background-image: url("../assets/img/img_space/800MB_Colombe04.webp");
    background-size: 100%;
    top: 0;
    left: 0;
  }

  16% {
    background-image: url("../assets/img/img_space/800MB_Colombe03.webp");
    background-size: 100%;
    top: 0;
    left: 0;
  }

  32% {
    background-image: url("../assets/img/img_space/800MB_Colombe02.webp");
    background-size: 100%;
    top: 0;
    left: 0;
  }

  42% {
    background-image: url("../assets/img/img_space/800MB_Colombe01.webp");
    background-size: 100%;
    top: 0;
    left: 0;
  }

  58% {
    background-image: url("../assets/img/img_space/800MB_Colombe01.webp");
    background-size: 100%;
    top: 0;
    left: 0;
  }

  68% {
    background-image: url("../assets/img/img_space/800MB_Colombe02.webp");
    background-size: 100%;
    top: 0;
    left: 0;
  }

  84% {
    background-image: url("../assets/img/img_space/800MB_Colombe03.webp");
    background-size: 100%;
    top: 0;
    left: 0;
  }

  95% {
    background-image: url("../assets/img/img_space/800MB_Colombe04.webp");
    background-size: 100%;
    top: 0;
    left: 0;
  }

  100% {
    background-image: url("../assets/img/img_space/800MB_Colombe04.webp");
    background-size: 100%;
    top: 0;
    left: 0;
  }
}

div.cadreMB66_02bug {
  position: absolute;
  width: 100%;
  top: 0;
  left: 0;
  animation: animMN_bug 1s linear infinite;
}

@keyframes animMN_bug_mvt01 {
  0% {
    top: 80%;
    left: -30%;
    transform: rotate(75deg);
    opacity: 0;
  }

  5% {
    top: 80%;
    left: -30%;
    transform: rotate(90deg);
    opacity: 1;
  }

  75% {
    top: 20%;
    left: 130%;
    transform: rotate(75deg);
    opacity: 1;
  }

  100% {
    top: 20%;
    left: 130%;
    transform: rotate(90deg);
    opacity: 0;
  }
}

@keyframes animMN_bug_mvt02 {
  0% {
    top: 130%;
    left: 80%;
    transform: rotate(-45deg);
    opacity: 0;
  }

  10% {
    top: 130%;
    left: 80%;
    transform: rotate(-30deg);
    opacity: 1;
  }

  80% {
    top: -30%;
    left: 20%;
    transform: rotate(-45deg);
    opacity: 1;
  }

  100% {
    top: -30%;
    left: 20%;
    transform: rotate(-30deg);
    opacity: 0;
  }
}

@keyframes animMN_bug_mvt03 {
  0% {
    top: 20%;
    left: 130%;
    transform: rotate(-110deg);
    opacity: 0;
  }

  15% {
    top: 20%;
    left: 130%;
    transform: rotate(-125deg);
    opacity: 1;
  }

  85% {
    top: 80%;
    left: -50%;
    transform: rotate(-110deg);
    opacity: 1;
  }

  100% {
    top: 80%;
    left: -50%;
    transform: rotate(-125deg);
    opacity: 0;
  }
}

@keyframes animMN_bug_mvt04 {
  0% {
    top: -30%;
    left: 80%;
    transform: rotate(-135deg);
    opacity: 0;
  }

  20% {
    top: -30%;
    left: 80%;
    transform: rotate(-120deg);
    opacity: 1;
  }

  90% {
    top: 130%;
    left: 20%;
    transform: rotate(-135deg);
    opacity: 1;
  }

  100% {
    top: 130%;
    left: 20%;
    transform: rotate(-120deg);
    opacity: 0;
  }
}

@keyframes animMN_bug_mvt05 {
  0% {
    top: 130%;
    left: 20%;
    transform: rotate(55deg);
    opacity: 0;
  }

  25% {
    top: 130%;
    left: 20%;
    transform: rotate(40deg);
    opacity: 1;
  }

  95% {
    top: -30%;
    left: 80%;
    transform: rotate(55deg);
    opacity: 1;
  }

  100% {
    top: -30%;
    left: 80%;
    transform: rotate(40deg);
    opacity: 0;
  }
}

div.cadreMB66_02a {
  position: absolute;
  width: 30%;
  top: 0;
  left: 0;
  animation: animMN_bug_mvt01 8s linear infinite;
}

div.cadreMB66_02b {
  position: absolute;
  width: 30%;
  top: 0;
  left: 0;
  animation: animMN_bug_mvt02 8s linear infinite;
}

div.cadreMB66_02c {
  position: absolute;
  width: 30%;
  top: 0;
  left: 0;
  animation: animMN_bug_mvt03 8s linear infinite;
}

div.cadreMB66_02d {
  position: absolute;
  width: 30%;
  top: 0;
  left: 0;
  animation: animMN_bug_mvt04 8s linear infinite;
}

div.cadreMB66_02e {
  position: absolute;
  width: 30%;
  top: 0;
  left: 0;
  animation: animMN_bug_mvt05 8s linear infinite;
}

div.cadreMB66_03a {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-image: url("../assets/img/img_space/FondKhops01_tornad_nuag02.webp");
  background-size: cover;
}

@keyframes animMN_tornad01 {
  0% {
    top: 0%;
    left: -100%;
    background-image: url("../assets/img/img_space/800MB_Tornade.webp");
    background-size: 100%;
  }

  39% {
    top: 0%;
    left: 100%;
    background-image: url("../assets/img/img_space/800MB_Tornade.webp");
    background-size: 100%;
  }

  41% {
    top: 0%;
    left: 100%;
    background-image: url("../assets/img/img_space/800MB_Tornade.webp");
    background-size: 100%;
  }

  80% {
    top: 0%;
    left: -100%;
    background-image: url("../assets/img/img_space/800MB_Tornade.webp");
    background-size: 100%;
  }

  100% {
    top: 0%;
    left: -100%;
    background-image: url("../assets/img/img_space/800MB_Tornade.webp");
    background-size: 100%;
  }
}

@keyframes animMN_tornad02 {
  0% {
    top: 0%;
    left: 100%;
    background-image: url("../assets/img/img_space/800MB_Tornade.webp");
    background-size: 100%;
  }

  20% {
    top: 0%;
    left: 100%;
    background-image: url("../assets/img/img_space/800MB_Tornade.webp");
    background-size: 100%;
  }

  59% {
    top: 0%;
    left: -100%;
    background-image: url("../assets/img/img_space/800MB_Tornade.webp");
    background-size: 100%;
  }

  61% {
    top: 0%;
    left: -100%;
    background-image: url("../assets/img/img_space/800MB_Tornade.webp");
    background-size: 100%;
  }

  100% {
    top: 0%;
    left: 100%;
    background-image: url("../assets/img/img_space/800MB_Tornade.webp");
    background-size: 100%;
  }
}

div.cadreMB66_03b {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  animation: animMN_tornad01 8s linear infinite;
}

div.cadreMB66_03c {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  animation: animMN_tornad02 8s linear infinite;
}

div.cadreMB66_03d {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-image: url("../assets/img/img_space/FondKhops01_tornad_nuag01.webp");
  background-size: cover;
}

@keyframes animMN_brum01 {
  0% {
    background-image: url("../assets/img/img_space/FondKhops01_brum_brum.webp");
    background-size: 100%;
    top: -5%;
    left: -300%;
    opacity: 0;
  }

  2% {
    background-image: url("../assets/img/img_space/FondKhops01_brum_brum.webp");
    background-size: 100%;
    top: -5%;
    left: -300%;
    opacity: 1;
  }

  30% {
    background-image: url("../assets/img/img_space/FondKhops01_brum_brum.webp");
    background-size: 100%;
    top: -5%;
    left: -150%;
    opacity: 0.9;
  }

  58% {
    background-image: url("../assets/img/img_space/FondKhops01_brum_brum.webp");
    background-size: 100%;
    top: -5%;
    left: 0%;
    opacity: 0;
  }

  100% {
    background-image: url("../assets/img/img_space/FondKhops01_brum_brum.webp");
    background-size: 100%;
    top: -5%;
    left: -300%;
    opacity: 0;
  }
}

div.cadreMB66_04a {
  position: absolute;
  width: 300%;
  height: 100%;
  top: 0;
  left: 0;
  animation: animMN_brum01 6s linear infinite;
}

@keyframes animMN_brum02 {
  0% {
    background-image: url("../assets/img/img_space/FondKhops01_brum_brum.webp");
    background-size: 100%;
    top: -15%;
    left: -300%;
    opacity: 0;
  }

  22% {
    background-image: url("../assets/img/img_space/FondKhops01_brum_brum.webp");
    background-size: 100%;
    top: -15%;
    left: -300%;
    opacity: 1;
  }

  50% {
    background-image: url("../assets/img/img_space/FondKhops01_brum_brum.webp");
    background-size: 100%;
    top: -15%;
    left: -150%;
    opacity: 0.9;
  }

  78% {
    background-image: url("../assets/img/img_space/FondKhops01_brum_brum.webp");
    background-size: 100%;
    top: -15%;
    left: 0%;
    opacity: 0;
  }

  100% {
    background-image: url("../assets/img/img_space/FondKhops01_brum_brum.webp");
    background-size: 100%;
    top: -15%;
    left: -300%;
    opacity: 0;
  }
}

div.cadreMB66_04b {
  position: absolute;
  width: 300%;
  height: 100%;
  top: 0;
  left: 0;
  animation: animMN_brum02 6s linear infinite;
}

@keyframes animMN_brum03 {
  0% {
    background-image: url("../assets/img/img_space/FondKhops01_brum_brum.webp");
    background-size: 100%;
    top: -10%;
    left: -300%;
    opacity: 0;
  }

  42% {
    background-image: url("../assets/img/img_space/FondKhops01_brum_brum.webp");
    background-size: 100%;
    top: -10%;
    left: -300%;
    opacity: 1;
  }

  70% {
    background-image: url("../assets/img/img_space/FondKhops01_brum_brum.webp");
    background-size: 100%;
    top: -10%;
    left: -150%;
    opacity: 0.9;
  }

  98% {
    background-image: url("../assets/img/img_space/FondKhops01_brum_brum.webp");
    background-size: 100%;
    top: -10%;
    left: 0%;
    opacity: 0;
  }

  100% {
    background-image: url("../assets/img/img_space/FondKhops01_brum_brum.webp");
    background-size: 100%;
    top: -10%;
    left: -300%;
    opacity: 0;
  }
}

div.cadreMB66_04c {
  position: absolute;
  width: 300%;
  height: 100%;
  top: 0;
  left: 0;
  animation: animMN_brum03 6s linear infinite;
}

@keyframes animMN_brum04 {
  0% {
    background-image: url("../assets/img/img_space/FondKhops01_brum_brum.webp");
    background-size: 100%;
    top: -20%;
    left: -300%;
    opacity: 0;
  }

  32% {
    background-image: url("../assets/img/img_space/FondKhops01_brum_brum.webp");
    background-size: 100%;
    top: -20%;
    left: -300%;
    opacity: 1;
  }

  60% {
    background-image: url("../assets/img/img_space/FondKhops01_brum_brum.webp");
    background-size: 100%;
    top: -20%;
    left: -150%;
    opacity: 0.9;
  }

  88% {
    background-image: url("../assets/img/img_space/FondKhops01_brum_brum.webp");
    background-size: 100%;
    top: -20%;
    left: 0%;
    opacity: 0;
  }

  100% {
    background-image: url("../assets/img/img_space/FondKhops01_brum_brum.webp");
    background-size: 100%;
    top: -20%;
    left: -300%;
    opacity: 0;
  }
}

div.cadreMB66_04d {
  position: absolute;
  width: 300%;
  height: 100%;
  top: 0;
  left: 0;
  animation: animMN_brum04 6s linear infinite;
}

@keyframes animMN_brum05 {
  0% {
    background-image: url("../assets/img/img_space/FondKhops01_brum_brum.webp");
    background-size: 100%;
    top: -7%;
    left: -150%;
    opacity: 0.9;
  }

  28% {
    background-image: url("../assets/img/img_space/FondKhops01_brum_brum.webp");
    background-size: 100%;
    top: -7%;
    left: 0%;
    opacity: 0;
  }

  70% {
    background-image: url("../assets/img/img_space/FondKhops01_brum_brum.webp");
    background-size: 100%;
    top: -7%;
    left: -300%;
    opacity: 0;
  }

  72% {
    background-image: url("../assets/img/img_space/FondKhops01_brum_brum.webp");
    background-size: 100%;
    top: -7%;
    left: -300%;
    opacity: 1;
  }

  100% {
    background-image: url("../assets/img/img_space/FondKhops01_brum_brum.webp");
    background-size: 100%;
    top: -7%;
    left: -150%;
    opacity: 0.9;
  }
}

div.cadreMB66_04e {
  position: absolute;
  width: 300%;
  height: 100%;
  top: 0;
  left: 0;
  animation: animMN_brum05 6s linear infinite;
}

div.cadreMB66_05a {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-image: url("../assets/img/img_space/FondKhops01_krapo_nuag02.webp");
  background-size: cover;
}

@keyframes animMN_krapo {
  0% {
    background-image: url("../assets/img/img_space/800MB_Flzeur_Fleur.webp");
    background-size: 100%;
    top: -100%;
    left: -100%;
  }

  100% {
    background-image: url("../assets/img/img_space/800MB_Flzeur_Fleur.webp");
    background-size: 100%;
    top: 0;
    left: 0;
  }
}

div.cadreMB66_05b {
  position: absolute;
  width: 200%;
  height: 200%;
  top: 0;
  left: 0;
  animation: animMN_krapo 8s linear infinite;
}

div.cadreMB66_05c {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-image: url("../assets/img/img_space/FondKhops01_krapo_nuag01.webp");
  background-size: cover;
}

@keyframes animMN_ombr_old {
  0% {
    background-image: url("../assets/img/img_space/FondKhops01_ombr_mvt_01.webp");
    background-size: 100%;
    top: 0;
    left: 0;
  }

  50% {
    background-image: url("../assets/img/img_space/FondKhops01_ombr_mvt_02.webp");
    background-size: 100%;
    top: 0;
    left: 0;
  }

  100% {
    background-image: url("../assets/img/img_space/FondKhops01_ombr_mvt_01.webp");
    background-size: 100%;
    top: 0;
    left: 0;
  }
}

@keyframes animMN_ombr2 {
  0% {
    background-image: url("../assets/img/img_space/FondKhops01_FeuxArtifice01.webp");
    background-size: 100%;
    top: 0;
    left: 0;
  }

  12.5% {
    background-image: url("../assets/img/img_space/FondKhops01_FeuxArtifice02.webp");
    background-size: 100%;
    top: 0;
    left: 0;
  }

  25% {
    background-image: url("../assets/img/img_space/FondKhops01_FeuxArtifice03.webp");
    background-size: 100%;
    top: 0;
    left: 0;
  }

  37.5% {
    background-image: url("../assets/img/img_space/FondKhops01_FeuxArtifice04.webp");
    background-size: 100%;
    top: 0;
    left: 0;
  }

  50% {
    background-image: url("../assets/img/img_space/FondKhops01_FeuxArtifice05.webp");
    background-size: 100%;
    top: 0;
    left: 0;
  }

  62.5% {
    background-image: url("../assets/img/img_space/FondKhops01_FeuxArtifice06.webp");
    background-size: 100%;
    top: 0;
    left: 0;
  }

  75% {
    background-image: url("../assets/img/img_space/FondKhops01_FeuxArtifice07.webp");
    background-size: 100%;
    top: 0;
    left: 0;
  }

  87.5% {
    background-image: url("../assets/img/img_space/FondKhops01_FeuxArtifice08.webp");
    background-size: 100%;
    top: 0;
    left: 0;
  }

  100% {
    background-image: url("../assets/img/img_space/FondKhops01_FeuxArtifice01.webp");
    background-size: 100%;
    top: 0;
    left: 0;
  }
}

@keyframes animMN_ombr3 {
  0% {
    background-image: url("../assets/img/img_space/FondKhops01_FeuxArtifice04.webp");
    background-size: 100%;
    top: 0;
    left: 0;
    transform: scaleX(-1)
  }

  12.5% {
    background-image: url("../assets/img/img_space/FondKhops01_FeuxArtifice05.webp");
    background-size: 100%;
    top: 0;
    left: 0;
    transform: scaleX(-1)
  }

  25% {
    background-image: url("../assets/img/img_space/FondKhops01_FeuxArtifice06.webp");
    background-size: 100%;
    top: 0;
    left: 0;
    transform: scaleX(-1)
  }

  37.5% {
    background-image: url("../assets/img/img_space/FondKhops01_FeuxArtifice07.webp");
    background-size: 100%;
    top: 0;
    left: 0;
    transform: scaleX(-1)
  }

  50% {
    background-image: url("../assets/img/img_space/FondKhops01_FeuxArtifice08.webp");
    background-size: 100%;
    top: 0;
    left: 0;
    transform: scaleX(-1)
  }

  62.5% {
    background-image: url("../assets/img/img_space/FondKhops01_FeuxArtifice01.webp");
    background-size: 100%;
    top: 0;
    left: 0;
    transform: scaleX(-1)
  }

  75% {
    background-image: url("../assets/img/img_space/FondKhops01_FeuxArtifice02.webp");
    background-size: 100%;
    top: 0;
    left: 0;
    transform: scaleX(-1)
  }

  87.5% {
    background-image: url("../assets/img/img_space/FondKhops01_FeuxArtifice03.webp");
    background-size: 100%;
    top: 0;
    left: 0;
    transform: scaleX(-1)
  }

  100% {
    background-image: url("../assets/img/img_space/FondKhops01_FeuxArtifice04.webp");
    background-size: 100%;
    top: 0;
    left: 0;
    transform: scaleX(-1)
  }
}

div.cadreMB66_06a {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-image: url("../assets/img/img_space/FondKhops01_FeuxArtificeBrume.webp");
  background-size: cover;
}

div.cadreMB66_06b {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  animation: animMN_ombr2 1s linear infinite;
}

div.cadreMB66_06c {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  animation: animMN_ombr3 1s linear infinite;
}

div.topCadreMN0 {
  top: 0%;
  transition: all 2s ease-out;
}

div.topCadreMN100 {
  top: -100%;
  transition: all 2s ease-out;
}

div.topCadreMN200 {
  top: -200%;
  transition: all 2s ease-out;
}

div.topCadreMN300 {
  top: -300%;
  transition: all 2s ease-out;
}

div.topCadreMN400 {
  top: -400%;
  transition: all 2s ease-out;
}

div.topCadreMN500 {
  top: -500%;
  transition: all 2s ease-out;
}