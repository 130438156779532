div.super_cadreFF_a {
  background-image: url("../assets/img/img_cadre/CadreRecVa00.webp");
  background-size: 100% 100%;
}

div.super_cadreFF_b {
  background-image: url("../assets/img/img_cadre/CadreRecVb00.webp");
  background-size: 100% 100%;
}

div.super_cadreFF_c {
  background-image: url("../assets/img/img_cadre/CadreRecVc00.webp");
  background-size: 100% 100%;
}

div.super_cadreFF_d {
  background-image: url("../assets/img/img_cadre/CadreRecVd00.webp");
  background-size: 100% 100%;
}

.cadreFF {
  position: relative;
  padding: 0;
  width: 100%;
  transition: all 0.2s;
  float: left;
}

.front_FF {
  position: relative;
  overflow: hidden;
  top: 0;
  left: 0;
  margin: 0;
  padding: 0;
  text-align: left;
  backface-visibility: hidden;
  transform-style: preserve3d;
  transition: all 0.4s;
  width: 100%;
}

.back_FF {
  position: absolute;
  overflow: hidden;
  top: 0;
  left: 0;
  margin: 0;
  padding: 0;
  text-align: left;
  backface-visibility: hidden;
  transform-style: preserve3d;
  transition: all 0.4s;
  width: 100%;
}

.front_FF img,
.back_FF img {
  width: 100%;
}

.back_FF {
  transform: rotateY(-180deg);
}

.flippedFF .back_FF {
  transform: rotateY(0deg);
}

.flippedFF .front_FF {
  transform: rotateY(180deg);
}

.cadreFF .rondFF {
  position: absolute;
  -webkit-transition: 0.5s all ease-in-out;
  -moz-transition: 0.5s all ease-in-out;
  -o-transition: 0.5s all ease-in-out;
  transition: 0.5s all ease-in-out;
  width: 24%;
  height: 12%;
  top: -500%;
  left: 5%;
  border-radius: 50%;
  background-image: url("../assets/img/img_t/000DeuxFleches00_01.webp");
  background-size: cover;
}

.cadreFF:hover .rondFF {
  display: block;
  top: 5%;
  left: 5%;
}

.cadreFF .rondFF:hover {
  cursor: grab;
  -webkit-transform: rotate(-180deg);
  -moz-transform: rotate(-180deg);
  -o-transform: rotate(-180deg);
  -ms-transform: rotate(-180deg);
  transform: rotate(-180deg);
}

div.txtFF_d {
  position: absolute;
  bottom: 1%;
  margin: 0;
  width: 100%;
  padding: 0;
  border: 0;
  text-align: center;
  opacity: 0;
}

.cadreFF:hover .txtFF_d {
  opacity: 1;
}

h2.txtFF_h2 {
  font-family: 'Fjalla One', sans-serif;
  border: solid 0.3em #000000;
  border-radius: 1.5em;
  line-height: normal;
  background-color: #000000;
  font-size: 1.8em;
}

@media screen and (max-width: 1258px) {
  h2.txtFF_h2 {
    font-size: 1.4em;
  }
}

@media screen and (max-width: 1024px) {
  h2.txtFF_h2 {
    font-size: 1.7em;
  }
}

@media screen and (max-width: 812px) {
  h2.txtFF_h2 {
    font-size: 12em;
  }
}

@media screen and (max-width: 480px) {
  h2.txtFF_h2 {
    font-size: 7em;
  }
}



div.cadre_out1 {
  left:-200%;
  top:-200%;
	position: relative;
	margin: 3% 1% 5% 1%;
	float: left;
	transition: all 2s ease-out;
}

div.cadre_out2 {
  left:-200%;
  top:200%;
	position: relative;
	margin: 3% 1% 5% 1%;
	float: left;
	transition: all 2s ease-out;
}

div.cadre_out3 {
  left:200%;
  top:-200%;
	position: relative;
	margin: 3% 1% 5% 1%;
	float: left;
	transition: all 2s ease-out;
}

div.cadre_out4 {
  left:200%;
  top:200%;
	position: relative;
	margin: 3% 1% 5% 1%;
	float: left;
	transition: all 2s ease-out;
}
