@import url('https://fonts.googleapis.com/css?family=Roboto+Slab|Satisfy');

/* Section - Parallax */
/**************************/
 div.parallax-world-of-ugg {
   display: block;
   position: fixed;
   width: 100%;
 }


 div.parallax-one {
  background-image:url('../assets/img/img_map/500_CodexMaya24_0077.webp');
  background-attachment: fixed;
  height:50em;
  width: 100%;
  margin: 0;
  left: 0;
  bottom: 0px;
  overflow: hidden;
  margin-top:5%;
  margin-bottom:5%;
  animation: left_animation 75s linear infinite;
background-color: black;}

div.parallax-two {
  background-image:url('../assets/img/img_map/500_CodexMaya24_0077.webp');
  background-attachment: fixed;
  height:50em;
  width: 100%;
  margin: 0;
  left: 0;
  bottom: 0px;
  overflow: hidden;
  margin-top:5%;
  margin-bottom:5%;
  animation: right_animation 75s linear infinite;
}

@keyframes left_animation {

  0%  {background-position: 0 0;}
  100%  {background-position: -2122px 0;}
}

@keyframes right_animation {

  0%  {background-position: 0 0;}
  100%  {background-position: 2122px 0;}
}
