div.cadreER {
  position: relative;
  width: 33%;
  margin: 0 auto;
  background-color: black;
}

@media screen and (max-width: 1250px) {
  div.cadreER {
    width: 66%;
  }
}

@media screen and (max-width: 512px) {
  div.cadreER {
    width: 100%;
  }
}

div.cadreER3b {
  width: 90%;
  position: absolute;
  border-radius: 50%;
  overflow: hidden;
  top: 0;
  left: 0;
}

div.cadreER3a {
  top: 0;
  left: 90%;
  position: absolute;
  width: 10%;
}

div.cadreER3aa {
  position: relative;
  float: left;
  width: 80%;
  margin: 10%;
  margin-top: 35%
}

img.imgFondER {
  width: 100%;
}

div.cadreER_pl {
  position: absolute;
  width: 1%;
  height: 1%;
  left: 0%;
  top: 0%;
  background-image:  url('../assets/img/img_space/ExodeRurale_VacheOr01.webp'),
    url('../assets/img/img_space/ExodeRurale_Vegan01.webp'),
    url('../assets/img/img_space/ExodeRurale_Volaille01.webp'),
    url('../assets/img/img_space/ExodeRurale_VacheArgent01.webp'),
    url('../assets/img/img_space/ExodeRurale_Vegan02.webp'),
    url('../assets/img/img_space/ExodeRurale_Volaille02.webp'),
    url('../assets/img/img_space/ExodeRurale_Cochon01.webp'),
    url('../assets/img/img_space/ExodeRurale_Vegan03.webp'),
    url('../assets/img/img_space/ExodeRurale_Volaille03.webp'),
    url('../assets/img/img_space/ExodeRurale_Volaille04.webp'),
    url('../assets/img/img_space/ExodeRurale_Vegan04.webp'),
    url('../assets/img/img_space/ExodeRurale_Titre01.webp'),
    url('../assets/img/img_space/ExodeRurale_SoucoupeA01.webp'),
    url('../assets/img/img_space/ExodeRurale_SoucoupeB01.webp'),
    url('../assets/img/img_space/ExodeRurale_Rayon00.webp'),
    url('../assets/img/img_space/ExodeRurale_Bouton_Vegan01.webp'),
    url('../assets/img/img_space/ExodeRurale_Bouton_Cochon01.webp'),
    url('../assets/img/img_space/ExodeRurale_Bouton_Vache01.webp'),
    url('../assets/img/img_space/ExodeRurale_Bouton_Gens01.webp');
  background-position: 100% 100%;
}


div.eltER_soucoupe01 {
  position: absolute;
  width: 100%;
  height: 100%;
  animation: animER_soucoupe01 20s linear infinite;
}

div.eltER_soucoupe_luz01 {
  position: relative;
  width: 100%;
  height: 100%;
  animation: animER_soucoupe_luz01 1s linear infinite;
}

div.eltER_rayon01 {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  animation: animER_rayon01 20s linear infinite;
}

div.eltER_vache01 {
  position: absolute;
  background-image: url('../assets/img/img_space/ExodeRurale_VacheOr01.webp');
  background-size: contain;
  animation: animER_mvt01 20s linear infinite;
}

div.eltER_vegan01 {
  position: absolute;
  background-image: url('../assets/img/img_space/ExodeRurale_Vegan01.webp');
  background-size: contain;
  animation: animER_mvt01 20s linear infinite;
}

div.eltER_volaille01 {
  position: absolute;
  background-image: url('../assets/img/img_space/ExodeRurale_Volaille01.webp');
  background-size: contain;
  animation: animER_mvt01 20s linear infinite;
}

div.eltER_vache02 {
  position: absolute;
  background-image: url('../assets/img/img_space/ExodeRurale_VacheArgent01.webp');
  background-size: contain;
  animation: animER_mvt02 20s linear infinite;
}

div.eltER_vegan02 {
  position: absolute;
  background-image: url('../assets/img/img_space/ExodeRurale_Vegan02.webp');
  background-size: contain;
  animation: animER_mvt02 20s linear infinite;
}

div.eltER_volaille02 {
  position: absolute;
  background-image: url('../assets/img/img_space/ExodeRurale_Volaille02.webp');
  background-size: contain;
  animation: animER_mvt02 20s linear infinite;
}

div.eltER_cochon03 {
  position: absolute;
  background-image: url('../assets/img/img_space/ExodeRurale_Cochon01.webp');
  background-size: contain;
  animation: animER_mvt03 20s linear infinite;
}

div.eltER_vegan03 {
  position: absolute;
  background-image: url('../assets/img/img_space/ExodeRurale_Vegan03.webp');
  background-size: contain;
  animation: animER_mvt03 20s linear infinite;
}

div.eltER_volaille03 {
  position: absolute;
  background-image: url('../assets/img/img_space/ExodeRurale_Volaille03.webp');
  background-size: contain;
  animation: animER_mvt03 20s linear infinite;
}

div.eltER_gens04 {
  position: absolute;
  background-image: url('../assets/img/img_space/ExodeRurale_Gens01.webp');
  background-size: contain;
  animation: animER_mvt04 20s linear infinite;
}

div.eltER_vegan04 {
  position: absolute;
  background-image: url('../assets/img/img_space/ExodeRurale_Vegan04.webp');
  background-size: contain;
  animation: animER_mvt04 20s linear infinite;
}

div.eltER_volaille04 {
  position: absolute;
  background-image: url('../assets/img/img_space/ExodeRurale_Volaille04.webp');
  background-size: contain;
  animation: animER_mvt04 20s linear infinite;
}

div.eltER_titre01 {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url('../assets/img/img_space/ExodeRurale_Titre01.webp');
  background-size: contain;
  position: absolute;
  animation: animER_titre01 20s linear infinite;
}

img.imgER_act {
  float: left;
  width: 25%;
}

@keyframes animER_soucoupe01 {
  0% {
    top: 0;
    left: 100%;
  }

  3% {
    top: 0;
    left: 100%;
  }

  5% {
    top: 0;
    left: 0;
  }

  95% {
    top: 0;
    left: 0;
  }

  97% {
    top: 0;
    left: 100%;
  }

  100% {
    top: 0;
    left: 100%;
  }
}

@keyframes animER_soucoupe_luz01 {
  0% {
    background-image: url('../assets/img/img_space/ExodeRurale_SoucoupeA01.webp');
    background-size: contain;
  }

  100% {
    background-image: url('../assets/img/img_space/ExodeRurale_SoucoupeB01.webp');
    background-size: contain;
  }
}

@keyframes animER_rayon01 {
  8% {
    background-image: none;
  }

  9% {
    background-image: url('../assets/img/img_space/ExodeRurale_Rayon00.webp');
    background-size: contain;
  }

  91% {
    background-image: url('../assets/img/img_space/ExodeRurale_Rayon00.webp');
    background-size: contain;
  }

  92% {
    background-image: none;
  }
}

@keyframes animER_mvt01 {
  0% {
    width: 100%;
    height: 100%;
    top: 100%;
    right: 100%;
    opacity: 0;
  }

  1% {
    width: 100%;
    height: 100%;
    top: 100%;
    right: 100%;
    opacity: 1;
  }

  22% {
    width: 33%;
    height: 33%;
    top: 22%;
    right: 22%;
    opacity: 1;
  }

  23% {
    width: 15%;
    height: 15%;
    top: 22%;
    right: 22%;
    opacity: 0;
  }

  24% {
    width: 100%;
    height: 100%;
    top: 100%;
    right: 100%;
    opacity: 0;
  }

  25% {
    width: 100%;
    height: 100%;
    top: 100%;
    right: 100%;
    opacity: 1;
  }

  46% {
    width: 33%;
    height: 33%;
    top: 22%;
    right: 22%;
    opacity: 1;
  }

  47% {
    width: 15%;
    height: 15%;
    top: 22%;
    right: 22%;
    opacity: 0;
  }

  48% {
    width: 100%;
    height: 100%;
    top: 100%;
    right: 100%;
    opacity: 0;
  }

  49% {
    width: 100%;
    height: 100%;
    top: 100%;
    right: 100%;
    opacity: 1;
  }

  70% {
    width: 33%;
    height: 33%;
    top: 22%;
    right: 22%;
    opacity: 1;
  }

  71% {
    width: 15%;
    height: 15%;
    top: 22%;
    right: 22%;
    opacity: 0;
  }

  100% {
    width: 15%;
    height: 15%;
    top: 100%;
    right: 100%;
    opacity: 0;
  }
}

@keyframes animER_mvt02 {
  0% {
    width: 100%;
    height: 100%;
    top: 100%;
    right: 100%;
    opacity: 0;
  }

  8% {
    width: 100%;
    height: 100%;
    top: 100%;
    right: 100%;
    opacity: 0;
  }

  9% {
    width: 100%;
    height: 100%;
    top: 100%;
    right: 100%;
    opacity: 1;
  }

  30% {
    width: 33%;
    height: 33%;
    top: 22%;
    right: 22%;
    opacity: 1;
  }

  31% {
    width: 15%;
    height: 15%;
    top: 22%;
    right: 22%;
    opacity: 0;
  }

  32% {
    width: 100%;
    height: 100%;
    top: 100%;
    right: 100%;
    opacity: 0;
  }

  33% {
    width: 100%;
    height: 100%;
    top: 100%;
    right: 100%;
    opacity: 1;
  }

  54% {
    width: 33%;
    height: 33%;
    top: 22%;
    right: 22%;
    opacity: 1;
  }

  55% {
    width: 15%;
    height: 15%;
    top: 22%;
    right: 22%;
    opacity: 0;
  }

  56% {
    width: 100%;
    height: 100%;
    top: 100%;
    right: 100%;
    opacity: 0;
  }

  57% {
    width: 100%;
    height: 100%;
    top: 100%;
    right: 100%;
    opacity: 1;
  }

  78% {
    width: 33%;
    height: 33%;
    top: 22%;
    right: 22%;
    opacity: 1;
  }

  79% {
    width: 15%;
    height: 15%;
    top: 22%;
    right: 22%;
    opacity: 0;
  }

  100% {
    width: 15%;
    height: 15%;
    top: 100%;
    right: 100%;
    opacity: 0;
  }
}

@keyframes animER_mvt03 {
  0% {
    width: 100%;
    height: 100%;
    top: 100%;
    right: 100%;
    opacity: 0;
  }

  16% {
    width: 100%;
    height: 100%;
    top: 100%;
    right: 100%;
    opacity: 0;
  }

  17% {
    width: 100%;
    height: 100%;
    top: 100%;
    right: 100%;
    opacity: 1;
  }

  38% {
    width: 33%;
    height: 33%;
    top: 22%;
    right: 22%;
    opacity: 1;
  }

  39% {
    width: 15%;
    height: 15%;
    top: 22%;
    right: 22%;
    opacity: 0;
  }

  64% {
    width: 100%;
    height: 100%;
    top: 100%;
    right: 100%;
    opacity: 0;
  }

  65% {
    width: 100%;
    height: 100%;
    top: 100%;
    right: 100%;
    opacity: 1;
  }

  86% {
    width: 33%;
    height: 33%;
    top: 22%;
    right: 22%;
    opacity: 1;
  }

  87% {
    width: 15%;
    height: 15%;
    top: 22%;
    right: 22%;
    opacity: 0;
  }

  100% {
    width: 15%;
    height: 15%;
    top: 100%;
    right: 100%;
    opacity: 0;
  }
}

@keyframes animER_mvt04 {
  0% {
    width: 100%;
    height: 100%;
    top: 100%;
    right: 100%;
    opacity: 0;
  }

  40% {
    width: 100%;
    height: 100%;
    top: 100%;
    right: 100%;
    opacity: 0;
  }

  41% {
    width: 100%;
    height: 100%;
    top: 100%;
    right: 100%;
    opacity: 1;
  }

  62% {
    width: 33%;
    height: 33%;
    top: 22%;
    right: 22%;
    opacity: 1;
  }

  63% {
    width: 15%;
    height: 15%;
    top: 22%;
    right: 22%;
    opacity: 0;
  }

  100% {
    width: 15%;
    height: 15%;
    top: 100%;
    right: 100%;
    opacity: 0;
  }
}

@keyframes animER_titre01 {
  0% {
    opacity: 1;
  }

  5% {
    opacity: 1;
  }

  7% {
    opacity: 0;
  }

  100% {
    opacity: 0;
  }
}

input[type='checkbox'] {
  display: none;
}

#button2a,
#button2b,
#button2c,
#button2d {
  display: block;
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: #00ff36;
  top: 0;
  left: 0;
  border-radius: 5em;
  cursor: pointer;
}

#knob_vegan01,
#knob_cochon01,
#knob_vache01,
#knob_gens01 {
  position: absolute;
  left: 0;
  width: 80%;
  height: auto;
  border-radius: 50%;
  margin: 10%;
  background: #ffffff;
  transition: 0.4s ease left, 0.4s ease background-position;
  z-index: 2;
}

#knob_vegan01 {
  background-image: url('../assets/img/img_space/ExodeRurale_Bouton_Vegan01.webp');
  background-size: 100%;
  background-position: 0% 100%;
  top: 50%;
}

#knob_cochon01 {
  background-image: url('../assets/img/img_space/ExodeRurale_Bouton_Cochon01.webp');
  background-size: 100%;
  background-position: 0% 0%;
  top: 0;
}

#knob_vache01 {
  background-image: url('../assets/img/img_space/ExodeRurale_Bouton_Vache01.webp');
  background-size: 100%;
  background-position: 0% 0%;
  top: 0;
}

#knob_gens01 {
  background-image: url('../assets/img/img_space/ExodeRurale_Bouton_Gens01.webp');
  background-size: 100%;
  background-position: 0% 0%;
  top: 0;
}

#chkER_vegan01:checked+#button2d #knob_vegan01 {
  top: 0%;
  background-position: 0% 0%;
}

#chkER_cochon01:checked+#button2a #knob_cochon01 {
  top: 50%;
  background-position: 0% 100%;
}

#chkER_vache01:checked+#button2b #knob_vache01 {
  top: 50%;
  background-position: 0% 100%;
}

#chkER_gens01:checked+#button2c #knob_gens01 {
  top: 50%;
  background-position: 0% 100%;
}
