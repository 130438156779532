
img.imgFondPO {
  width: 100%;
}

div.cadrePO {
  position: relative;
  width: 33%;
  margin: 0 auto;
  background-color: black;
  overflow: hidden;
}

div.cadrePO_pl {
  position: absolute;
  width: 1%;
  height: 1%;
  left: 0%;
  top: 0%;
  background-image: url('../assets/img/img_mer/102_PlageATouteHeurePerso00h00.webp'),
    url('../assets/img/img_mer/102_PlageATouteHeureFond00h00.webp'),
    url('../assets/img/img_mer/102_PlageATouteHeurePerso04h00.webp'),
    url('../assets/img/img_mer/102_PlageATouteHeureFond04h00.webp'),
    url('../assets/img/img_mer/102_PlageATouteHeurePerso08h00.webp'),
    url('../assets/img/img_mer/102_PlageATouteHeureFond08h00.webp'),
    url('../assets/img/img_mer/102_PlageATouteHeurePerso12h00.webp'),
    url('../assets/img/img_mer/102_PlageATouteHeureFond12h00.webp'),
    url('../assets/img/img_mer/102_PlageATouteHeurePerso16h00.webp'),
    url('../assets/img/img_mer/102_PlageATouteHeureFond16h00.webp'),
    url('../assets/img/img_mer/102_PlageATouteHeurePerso20h00.webp'),
    url('../assets/img/img_mer/102_PlageATouteHeureFond20h00.webp'),
    url("../assets/img/img_mer/800FS_BoutonPO01_00h00.webp"),
    url("../assets/img/img_mer/800FS_BoutonPO01_04h00.webp"),
    url("../assets/img/img_mer/800FS_BoutonPO01_08h00.webp"),
    url("../assets/img/img_mer/800FS_BoutonPO01_12h00.webp"),
    url("../assets/img/img_mer/800FS_BoutonPO01_16h00.webp"),
    url("../assets/img/img_mer/800FS_BoutonPO01_20h00.webp");
  background-position: 100% 100%;
}

div.cadrePO38 {
  position: absolute;
  top: 0;
  left: 0;
  float: left;
  width: 100%;
  height: 100%;
}

div.cadrePO38Perso00 {
  background-image: url('../assets/img/img_mer/102_PlageATouteHeurePerso00h00.webp');
  background-size: 100% 100%;
  transition: all 3s ease-out;
}

div.cadrePO38Fond00 {
  background-image: url('../assets/img/img_mer/102_PlageATouteHeureFond00h00.webp');
  background-size: 100% 100%;
  transition: all 1.5s ease-out;
}

div.cadrePO38Perso04 {
  background-image: url('../assets/img/img_mer/102_PlageATouteHeurePerso04h00.webp');
  background-size: 100% 100%;
  transition: all 3s ease-out;
}

div.cadrePO38Fond04 {
  background-image: url('../assets/img/img_mer/102_PlageATouteHeureFond04h00.webp');
  background-size: 100% 100%;
  transition: all 1.5s ease-out;
}

div.cadrePO38Perso08 {
  background-image: url('../assets/img/img_mer/102_PlageATouteHeurePerso08h00.webp');
  background-size: 100% 100%;
  transition: all 3s ease-out;

}

div.cadrePO38Fond08 {
  background-image: url('../assets/img/img_mer/102_PlageATouteHeureFond08h00.webp');
  background-size: 100% 100%;
  transition: all 1.5s ease-out;
}

div.cadrePO38Perso12 {
  background-image: url('../assets/img/img_mer/102_PlageATouteHeurePerso12h00.webp');
  background-size: 100% 100%;
  transition: all 3s ease-out;

}

div.cadrePO38Fond12 {
  background-image: url('../assets/img/img_mer/102_PlageATouteHeureFond12h00.webp');
  background-size: 100% 100%;
  transition: all 1.5s ease-out;
}

div.cadrePO38Perso16 {
  background-image: url('../assets/img/img_mer/102_PlageATouteHeurePerso16h00.webp');
  background-size: 100% 100%;
  transition: all 3s ease-out;
}

div.cadrePO38Fond16 {
  background-image: url('../assets/img/img_mer/102_PlageATouteHeureFond16h00.webp');
  background-size: 100% 100%;
  transition: all 1.5s ease-out;
}

div.cadrePO38Perso20 {
  background-image: url('../assets/img/img_mer/102_PlageATouteHeurePerso20h00.webp');
  background-size: 100% 100%;
  transition: all 3s ease-out;

}

div.cadrePO38Fond20 {
  background-image: url('../assets/img/img_mer/102_PlageATouteHeureFond20h00.webp');
  background-size: 100% 100%;
  transition: all 1.5s ease-out;
}

p span {
  display: block;
  padding: 0.5em 0.5em;
}


p.white {
  background: white;
  color: black;
  mix-blend-mode: screen;
}

p.black {
  background: black;
  color: white;
  mix-blend-mode: multiply;
}

p.light {
  /* Add low opacity dark background to improve contrast */
  background: rgba(0, 0, 0, 0.3);
  padding: 0;
}

p.light span {
  background: #d5d2f4;
  color: black;
  mix-blend-mode: lighten;
}

h1.light {
  /* Add low opacity dark background to improve contrast */
  background: rgba(0, 0, 0, 0.3);
  padding: 0;
}

h1.light span {
  font-family: 'Fjalla One', sans-serif;
  background: #d5d2f4;
  color: black;
  mix-blend-mode: lighten;
}

p.dark {
  /* Add low opacity light background to improve contrast */
  background: rgba(255, 255, 255, 0.3);
  padding: 0;
}

p.dark span {
  background: #2f2b59;
  color: white;
  mix-blend-mode: darken;
}

div.CCSBtPO {
  cursor: pointer;
}

div.barBtPO {
  position: absolute;
  top: 3%;
  height: 14%;
  width: 100%;
  margin: 0;
  margin-left: 14%;
  margin-right: 14%;
  cursor: pointer;
}

div.horlogePO {
  position: absolute;
  top: 20%;
  height: 10%;
  width: 100%;
}

@keyframes rotatePO {
  to {
    transform: rotate(360deg);
  }
}

div.CCSBtPO {
  position: relative;
  float: left;
  height: 100%;
  width: 12%;
}

div.CCSBtPO_active {
  position: relative;
  float: left;
  height: 100%;
  width: 12%;
  animation: 2s rotatePO infinite linear;
}

div.CCSBtPOBt1 {
  background-image: url("../assets/img/img_mer/800FS_BoutonPO01_04h00.webp");
  background-size: cover;
}

div.CCSBtPOBt2 {
  background-image: url("../assets/img/img_mer/800FS_BoutonPO01_08h00.webp");
  background-size: cover;
}

div.CCSBtPOBt3 {
  background-image: url("../assets/img/img_mer/800FS_BoutonPO01_12h00.webp");
  background-size: cover;
}

div.CCSBtPOBt4 {
  background-image: url("../assets/img/img_mer/800FS_BoutonPO01_16h00.webp");
  background-size: cover;
}

div.CCSBtPOBt5 {
  background-image: url("../assets/img/img_mer/800FS_BoutonPO01_20h00.webp");
  background-size: cover;
}

div.CCSBtPOBt6 {
  background-image: url("../assets/img/img_mer/800FS_BoutonPO01_00h00.webp");
  background-size: cover;
}

h1.h2_txtPO {
  position: absolute;
  text-align: left;
  margin: 2%;
  margin-left: 5%;
  margin-top: 0.5%;
  padding: 0.5%;
  color: #000000;
  width: 90%;
  font-size: 4em;
}

h2 span.winnerPO {
  color: #000000;
  margin: 0;
  margin-left: 6%;
  top: 10%;
}

@media screen and (max-width: 1258px) {
  h1.h2_txtPO {
    position: absolute;
    text-align: left;
    margin: 2%;
    padding: 0.5%;
    color: #000000;
    width: 95%;
    font-size: 5em;
  }
}

@media screen and (max-width: 1024px) {
  h1.h2_txtPO {
    position: absolute;
    text-align: left;
    margin: 2%;
    padding: 0.5%;
    color: #000000;
    width: 95%;
    font-size: 5em;
  }
}

@media screen and (max-width: 812px) {
  h1.h2_txtPO {
    position: absolute;
    text-align: left;
    margin: 2%;
    padding: 0.5%;
    color: #000000;
    width: 95%;
    font-size: 15em;
  }
}

@media screen and (max-width: 480px) {
  h1.h2_txtPO {
    position: absolute;
    text-align: left;
    margin: 2%;
    padding: 0.5%;
    color: #000000;
    width: 95%;
    font-size: 16em;
  }
}
