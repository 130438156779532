div.cadreFS {
  position: relative;
  width: 33%;
  margin: 0 auto;
  background-color: black;
}


div.cadreFS5 {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}

div.cadreFS5_none {
  display: none;
}

div.cadreFS5_active {
  display: block;
}

img.imgFondFS {
  width: 100%;
}

div.eltFS_grue {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
}

div.eltFS_grue00 {
  position: relative;
  width: 100%;
  height:100%;
  display: none;
  background-repeat: no-repeat;
}

div.eltFS_grue01 {
  position: relative;
  width: 100%;
  height:100%;
  animation: anim25_grue_rte0 30s linear infinite;
  background-repeat: no-repeat;
}

div.eltFS_grue02 {
  position: relative;
  width: 100%;
  height:100%;
  animation: anim25_grue_rte0 30s linear infinite;
  background-repeat: no-repeat;
}

div.eltFS_grue03 {
  position: relative;
  width: 100%;
  height:100%;
  animation: anim25_grue_rte0 30s linear infinite;
  background-repeat: no-repeat;
}

div.eltFS_grue04 {
  position: relative;
  width: 100%;
  height:100%;
  animation: anim25_grue_rte0 30s linear infinite;
  background-repeat: no-repeat;
}

div.eltFS_grue05 {
  position: relative;
  width: 100%;
  height:100%;
  animation: anim25_grue_rte0 30s linear infinite;
  background-repeat: no-repeat;
}

div.cadreFS5_fuji01actif {
  position: absolute;
  left: 0;
  top: 0;
  width: 48%;
  height: 98%;
  background-image: url('../assets/img/img_space/FondFujiSan001_01.webp');
  background-size: cover;
  overflow: hidden;
  border-radius: 2em;
  margin: 1%;
  transition: all 2s ease;
}

div.cadreFS5_fuji01gauche {
  position: absolute;
  left: 0;
  top: 0;
  width: 10.5%;
  height: 98%;
  background-image: url('../assets/img/img_space/FondFujiSan001_01.webp');
  background-size: cover;
  overflow: hidden;
  border-radius: 2em;
  margin: 1%;
  transition: all 2s ease;
}

div.cadreFS5_fuji02gauche {
  position: absolute;
  left: 12.5%;
  top: 0;
  width: 10.5%;
  height: 98%;
  background-image: url('../assets/img/img_space/FondFujiSan001_02.webp');
  background-size: cover;
  overflow: hidden;
  border-radius: 2em;
  margin: 1%;
  transition: all 2s ease;
}

div.cadreFS5_fuji02actif {
  position: absolute;
  left: 12.5%;
  top: 0;
  width: 48%;
  height: 98%;
  background-image: url('../assets/img/img_space/FondFujiSan001_02.webp');
  background-size: cover;
  overflow: hidden;
  border-radius: 2em;
  margin: 1%;
  transition: all 2s ease;
}

div.cadreFS5_fuji02droite {
  position: absolute;
  left: 50%;
  top: 0;
  width: 10.5%;
  height: 98%;
  background-image: url('../assets/img/img_space/FondFujiSan001_02.webp');
  background-size: cover;
  overflow: hidden;
  border-radius: 2em;
  margin: 1%;
  transition: all 2s ease;
}

div.cadreFS5_fuji03gauche {
  position: absolute;
  left: 25%;
  top: 0;
  width: 10.5%;
  height: 98%;
  background-image: url('../assets/img/img_space/FondFujiSan001_03.webp');
  background-size: cover;
  overflow: hidden;
  border-radius: 2em;
  margin: 1%;
  transition: all 2s ease;
}

div.cadreFS5_fuji03actif {
  position: absolute;
  left: 25%;
  top: 0;
  width: 48%;
  height: 98%;
  background-image: url('../assets/img/img_space/FondFujiSan001_03.webp');
  background-size: cover;
  overflow: hidden;
  border-radius: 2em;
  margin: 1%;
  transition: all 2s ease;
}

div.cadreFS5_fuji03droite {
  position: absolute;
  left: 62.5%;
  top: 0;
  width: 10.5%;
  height: 98%;
  background-image: url('../assets/img/img_space/FondFujiSan001_03.webp');
  background-size: cover;
  overflow: hidden;
  border-radius: 2em;
  margin: 1%;
  transition: all 2s ease;
}

div.cadreFS5_fuji04gauche {
  position: absolute;
  left: 37.5%;
  top: 0;
  width: 10.5%;
  height: 98%;
  background-image: url('../assets/img/img_space/FondFujiSan001_04.webp');
  background-size: cover;
  overflow: hidden;
  border-radius: 2em;
  margin: 1%;
  transition: all 2s ease;
}

div.cadreFS5_fuji04actif {
  position: absolute;
  left: 37.5%;
  top: 0;
  width: 48%;
  height: 98%;
  background-image: url('../assets/img/img_space/FondFujiSan001_04.webp');
  background-size: cover;
  overflow: hidden;
  border-radius: 2em;
  margin: 1%;
  transition: all 2s ease;
}

div.cadreFS5_fuji04droite {
  position: absolute;
  left: 75%;
  top: 0;
  width: 10.5%;
  height: 98%;
  background-image: url('../assets/img/img_space/FondFujiSan001_04.webp');
  background-size: cover;
  overflow: hidden;
  border-radius: 2em;
  margin: 1%;
  transition: all 2s ease;
}

div.cadreFS5_fuji05actif {
  position: absolute;
  left: 50%;
  top: 0;
  width: 48%;
  height: 98%;
  background-image: url('../assets/img/img_space/FondFujiSan001_05.webp');
  background-size: cover;
  overflow: hidden;
  border-radius: 2em;
  margin: 1%;
  transition: all 2s ease;
}

div.cadreFS5_fuji05droite {
  position: absolute;
  left: 87.5%;
  top: 0;
  width: 10.5%;
  height: 98%;
  background-image: url('../assets/img/img_space/FondFujiSan001_05.webp');
  background-size: cover;
  overflow: hidden;
  border-radius: 2em;
  margin: 1%;
  transition: all 2s ease;
}

div.eltFS_grue_mvt0 {
  position: relative;
  width: 100%;
  height: 100%;
  animation: anim25_grue_mvt0 1s linear infinite;
  background-repeat: no-repeat;
}

@keyframes anim25_grue_mvt0 {
  0% {
    background-image: url('../assets/img/img_space/PiafGrueJapMvt01.webp');
    background-size: contain;
  }

  8% {
    background-image: url('../assets/img/img_space/PiafGrueJapMvt01.webp');
    background-size: contain;
  }

  17% {
    background-image: url('../assets/img/img_space/PiafGrueJapMvt02.webp');
    background-size: contain;
  }

  33% {
    background-image: url('../assets/img/img_space/PiafGrueJapMvt02.webp');
    background-size: contain;
  }

  42% {
    background-image: url('../assets/img/img_space/PiafGrueJapMvt03.webp');
    background-size: contain;
  }

  58% {
    background-image: url('../assets/img/img_space/PiafGrueJapMvt03.webp');
    background-size: contain;
  }

  67% {
    background-image: url('../assets/img/img_space/PiafGrueJapMvt04.webp');
    background-size: contain;
  }

  83% {
    background-image: url('../assets/img/img_space/PiafGrueJapMvt04.webp');
    background-size: contain;
  }

  92% {
    background-image: url('../assets/img/img_space/PiafGrueJapMvt01.webp');
    background-size: contain;
  }

  100% {
    background-image: url('../assets/img/img_space/PiafGrueJapMvt01.webp');
    background-size: contain;
  }
}

@keyframes anim25_grue_rte0 {
  0% {
    left: -120%;
    top: 30%;
    opacity: 0;
  }

  1% {
    opacity: 1;
  }

  32% {
    opacity: 1;
  }

  33% {
    left: 120%;
    top: 50%;
    opacity: 0;
  }

  34% {
    left: -120%;
    top: 20%;
    opacity: 0;
  }

  35% {
    opacity: 1;
  }

  65% {
    opacity: 1;
  }

  66% {
    left: 120%;
    top: 40%;
    opacity: 0;
  }

  67% {
    left: -120%;
    top: 10%;
    opacity: 0;
  }

  68% {
    opacity: 1;
  }

  99% {
    opacity: 1;
  }

  100% {
    left: 120%;
    top: 30%;
    opacity: 0;
  }
}

div.btFS {
  position: absolute;
  width: 9%;
  bottom: 1%;
  cursor: pointer;
  background-position: 0%;
  cursor: pointer;
}

div.btFS_active {
  cursor: pointer;
  position: absolute;
  width: 9%;
  bottom: 1%;
  background-position: 100%;
  cursor: pointer;
}

div.btFS1 {
  left: 2%;
  transition: all 2s ease;
}

div.btFS2g {
  left: 14%;
  transition: all 2s ease;
}

div.btFS2d {
  left: 52%;
  transition: all 2s ease;
}

div.btFS3g {
  left: 27%;
  transition: all 2s ease;
}

div.btFS3d {
  left: 64%;
  transition: all 2s ease;
}

div.btFS4g {
  left: 39.5%;
  transition: all 2s ease;
}

div.btFS4d {
  left: 76.5%;
  transition: all 2s ease;
}

div.btFS5g {
  left: 51%;
  transition: all 2s ease;
}

div.btFS5d {
  left: 89.5%;
  transition: all 2s ease;
}

#fuji01_b {
  background-image: url('../assets/img/img_space/BoutonFS01_01.webp');
  background-size: cover;
}

#fuji02_b {
  background-image: url('../assets/img/img_space/BoutonFS01_02.webp');
  background-size: cover;
}

#fuji03_b {
  background-image: url('../assets/img/img_space/BoutonFS01_03.webp');
  background-size: cover;
}

#fuji04_b {
  background-image: url('../assets/img/img_space/BoutonFS01_04.webp');
  background-size: cover;
}

#fuji05_b {
  background-image: url('../assets/img/img_space/BoutonFS01_05.webp');
  background-size: cover;
}

.txtFS_fs {
  position: absolute;
  bottom: 0;
  left: 0;
  padding: 5%;
  padding-top: 10%;
  width: 85%;
  height: 42%;
  margin: 5%;
  top: 2%;
}

div.txtFS_fs::before {
  position: absolute;
  top: 50%;
  right: 20%;
  bottom: 50%;
  left: 5%;
  border-top: 0.5em solid rgba(255, 255, 255, 0.8);
  border-bottom: 0.5em solid rgba(255, 255, 255, 0.8);
  content: '';
  opacity: 0;
  background-color: #ffffff;
  -webkit-transition: all 0.5s;
  transition: all 0.5s;
}

div.txtFS_fs h2 {
  text-transform: uppercase;
  color: white;
  font-family: 'Mochiy Pop One', sans-serif;
  font-family: 'Fjalla One', sans-serif;
  margin: 0;
  margin-top: 2%;
  margin-bottom: 2%;
  opacity: 0;
  font-weight: 100;
  text-transform: uppercase;
  -webkit-transform: translate3d(0%, 50%, 0);
  transform: translate3d(0%, 50%, 0);
  transition: opacity 6s, -webkit-transform 6s, -moz-transform 6s, -o-transform 6s, transform 6s;
}

div.cadreFS5_fuji01actif:hover div.txtFS_fs h2,
div.cadreFS5_fuji02actif:hover div.txtFS_fs h2,
div.cadreFS5_fuji03actif:hover div.txtFS_fs h2,
div.cadreFS5_fuji04actif:hover div.txtFS_fs h2,
div.cadreFS5_fuji05actif:hover div.txtFS_fs h2 {
  opacity: 1;
  -webkit-transform: translate3d(0%, 0%, 0);
  transform: translate3d(0%, 0%, 0);
  transition: opacity 6s, -webkit-transform 6s, -moz-transform 6s, -o-transform 6s, transform 6s;
}


div.txtFS_fs_active h2 {
  opacity: 1;
  -webkit-transform: translate3d(0%, 0%, 0);
  transform: translate3d(0%, 0%, 0);
  transition: opacity 6s, -webkit-transform 6s, -moz-transform 6s, -o-transform 6s, transform 6s;
}

div.cadreFS5_fuji01actif:hover div.txtFS_fs::before,
div.cadreFS5_fuji02actif:hover div.txtFS_fs::before,
div.cadreFS5_fuji03actif:hover div.txtFS_fs::before,
div.cadreFS5_fuji04actif:hover div.txtFS_fs::before,
div.cadreFS5_fuji05actif:hover div.txtFS_fs::before {
  background: rgba(255, 255, 255, 0);
  top: 10%;
  bottom: 10%;
  opacity: 1;
}

div.txtFS_fs_active::before {
  background: rgba(255, 255, 255, 0);
  top: 10%;
  bottom: 10%;
  opacity: 1;
}

div.txtFS_fs h2 {
  font-size: 2.9em;
  font-weight: 200;
  text-transform: uppercase;
  color: white;
  font-family: 'Mochiy Pop One', sans-serif;
  font-family: 'Fjalla One', sans-serif;
}

@media screen and (max-width: 1258px) {
  div.txtFS_fs h2 {
    font-size: 2.5em;
    font-weight: 200;
    text-transform: uppercase;
    color: white;
    font-family: 'Mochiy Pop One', sans-serif;
  }
}

@media screen and (max-width: 1024px) {
  div.txtFS_fs h2 {
    font-size: 2.8em;
    font-weight: 200;
    text-transform: uppercase;
    color: white;
    font-family: 'Mochiy Pop One', sans-serif;
  }
}

@media screen and (max-width: 812px) {
  div.txtFS_fs h2 {
    font-size: 7em;
    font-weight: 200;
    text-transform: uppercase;
    color: white;
    font-family: 'Mochiy Pop One', sans-serif;
  }
}

@media screen and (max-width: 480px) {
  div.txtFS_fs h2 {
    font-size: 5.6em;
    font-weight: 200;
    text-transform: uppercase;
    color: white;
    font-family: 'Mochiy Pop One', sans-serif;
    line-height: normal;
  }
}