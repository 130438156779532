.topNavM000 {
  font-size: 1.7em;
  overflow: hidden;
  z-index: 3000;
  padding:1%;
  position:fixed;
  top:5%;
}

.topNavM000bg {
position:relative;
width:100%;
background-color:#feb914;
padding-bottom: 4%;
background: rgba(254,185,20,0.5);
}

.topNavM000 a {
  float: left;
  display: block;
  color: #f2f2f2;
  text-align: center;
  text-decoration: none;
  font-family: sans-serif;
}

.active000 {
  background-color: #f29191;
  color: white;
}

.topNavM000 .icon {
  display: none;
}

.topNavM000 a:hover {
  color: white;
}

@media screen and (max-width: 812px) {
  .topNavM000{
    font-size: 6.0em;
    position:sticky;
    top:0;
  }

  .topNavM000 a,
  .topNavM000 button {display: none;}
  
  .topNavM000 a.icon {
    float: right;
    display: block;
    right: 1%;
    top: 1%;
  }

.topNavM000.responsive {
  position: relative;
  position:sticky;
  top:0;
}

.topNavM000.responsive a.icon {
  position: absolute;
  right: 1%;
  top: 1%;
}
.topNavM000.responsive a.top_navM000 {
  float: none;
  display: block;
  width:75%;
  text-align: left;
  margin:0;
  padding:0.4%;
}

.topNavM000.responsive {
  float: none;
}

.topNavM000.responsive{
  position: relative;
}

.topNavM000.responsive {
  display: block;
  width: 100%;
  text-align: left;
}
}

span.top_navM000 {
  text-transform: uppercase;
  font-family: 'Fjalla One', sans-serif;
  color:#ffffff;
  border:solid 0.5em #370028;
  border-radius:1.5em;
  line-height:1em;
  background-color:#370028;
  padding-left:1em;
  padding-right:1em;
  margin:0;
  display:block;
  font-size: 1.1em;
}

span.top_navM000H {
  text-transform: uppercase;
  font-family: 'Fjalla One', sans-serif;
  color:#370028;
  border:solid 0.5em #ffffff;
  border-radius:1.5em;
  line-height:1em;
  background-color: #ffffff;
  padding-left:1em;
  padding-right:1em;
  margin:0;
  display:block;
  font-size: 1.1em;
}

span.top_navM000:hover{
  border:solid 0.5em #9f3e63;
  border-radius:1.5em;
  line-height:1em;
  background-color: #9f3e63;
}

span.top_navM000H:hover{
  color:#ffffff;
  border:solid 0.5em #9f3e63;
  border-radius:1.5em;
  line-height:1em;
  background-color: #9f3e63;
}

a.top_navM000 {
text-decoration: none;
margin: 0.2em;
margin-top:1em;
padding: 0;
border:0;
}

@media screen and (max-width: 1024px) {
  span.top_navM000 {
font-size: 1.3em
  }
  
  span.top_navM000H {
    font-size: 1.3em
  }
}

@media screen and (max-width: 480px) {
  span.top_navM000 {
font-size: 1.3em
  }
  
  span.top_navM000H {
    font-size: 1.3em
  }
}