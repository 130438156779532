div.cadreGJ {
    position: relative;
    width: 33%;
    margin: 0 auto;
    overflow: hidden;
    background-image: url('../assets/img/img_space/GrueJauneEngins02_fond.webp');
    background-size: contain;
    padding: 0%;
    border: 0;
    border-radius: 1em;
}

div.cadreGJ8_fd {
    position: relative;
    width: 33%;
    margin: 0 auto;
    overflow: hidden;
    background-image: url('../assets/img/img_space/GrueJauneEngins02_fond.webp');
    background-size: contain;
    padding: 0%;
    border: 0;
    border-radius: 1em;
    background-color: black;
}

div.cadreGJ_pl {
    position: absolute;
    width: 1%;
    height: 1%;
    left: 0%;
    top: 0%;
    background-image: url('../assets/img/img_space/GrueJauneEngins02_button01.webp'),
        url('../assets/img/img_space/GrueJauneEngins02_button01_active.webp'),
        url('../assets/img/img_space/GrueJauneEngins02_button01_hov.webp'),
        url('../assets/img/img_space/GrueJauneEngins02_button02.webp'),
        url('../assets/img/img_space/GrueJauneEngins02_button02_active.webp'),
        url('../assets/img/img_space/GrueJauneEngins02_button02_hov.webp'),
        url('../assets/img/img_space/GrueJauneEngins02_button03.webp'),
        url('../assets/img/img_space/GrueJauneEngins02_button03_active.webp'),
        url('../assets/img/img_space/GrueJauneEngins02_button03_hov.webp'),
        url('../assets/img/img_space/GrueJauneEngins02_button04.webp'),
        url('../assets/img/img_space/GrueJauneEngins02_button04_active.webp'),
        url('../assets/img/img_space/GrueJauneEngins02_button04_hov.webp'),
        url('../assets/img/img_space/GrueJauneEngins02_button05.webp'),
        url('../assets/img/img_space/GrueJauneEngins02_button05_active.webp'),
        url('../assets/img/img_space/GrueJauneEngins02_button05_hov.webp'),
        url('../assets/img/img_space/GrueJauneEngins02_button06.webp'),
        url('../assets/img/img_space/GrueJauneEngins02_button06_active.webp'),
        url('../assets/img/img_space/GrueJauneEngins02_button06_hov.webp'),
        url('../assets/img/img_space/GrueJauneEngins02_button07.webp'),
        url('../assets/img/img_space/GrueJauneEngins02_button07_active.webp'),
        url('../assets/img/img_space/GrueJauneEngins02_button07_hov.webp'),
        url('../assets/img/img_space/GrueJauneEngins02_button08.webp'),
        url('../assets/img/img_space/GrueJauneEngins02_button08_active.webp'),
        url('../assets/img/img_space/GrueJauneEngins02_button08_hov.webp');
    background-position: 100% 100%;
}

div.bull_txtGJ_00 h3 {
    font-size: 1.05vw;
    line-height: 0.95vw;
    font-weight: bold;
}

html {
    scroll-behavior: smooth;
}

img.imgFondGJ {
    width: 100%;
}

.mnuGJ {
    position: absolute;
    width: 11%;
    height: 100%;
    top: 0%;
    left: 89%;
    margin: 0%;
    padding: 0%;
    z-index: 2000;
}

.mnuGJ_00 {
    float: left;
    width: 100%;
    margin: 0%;
    padding: 0%;
    cursor: pointer;
}

.mnuGJ_01 {
    background-image: url('../assets/img/img_space/GrueJauneEngins02_button01.webp');
    background-size: cover;
    z-index: 2000;
}

.mnuGJ_01_active {
    background-image: url('../assets/img/img_space/GrueJauneEngins02_button01_active.webp');
    background-size: cover;
    z-index: 2000;
}

.mnuGJ_02 {
    background-image: url('../assets/img/img_space/GrueJauneEngins02_button02.webp');
    background-size: cover;
    z-index: 2000;
}

.mnuGJ_02_active {
    background-image: url('../assets/img/img_space/GrueJauneEngins02_button02_active.webp');
    background-size: cover;
    z-index: 2000;
}

.mnuGJ_03 {
    background-image: url('../assets/img/img_space/GrueJauneEngins02_button03.webp');
    background-size: cover;
    z-index: 2000;
}

.mnuGJ_03_active {
    background-image: url('../assets/img/img_space/GrueJauneEngins02_button03_active.webp');
    background-size: cover;
    z-index: 2000;
}

.mnuGJ_04 {
    background-image: url('../assets/img/img_space/GrueJauneEngins02_button04.webp');
    background-size: cover;
    z-index: 2000;
}

.mnuGJ_04_active {
    background-image: url('../assets/img/img_space/GrueJauneEngins02_button04_active.webp');
    background-size: cover;
    z-index: 2000;
}

.mnuGJ_05 {
    background-image: url('../assets/img/img_space/GrueJauneEngins02_button05.webp');
    background-size: cover;
    z-index: 2000;
}

.mnuGJ_05_active {
    background-image: url('../assets/img/img_space/GrueJauneEngins02_button05_active.webp');
    background-size: cover;
    z-index: 2000;
}

.mnuGJ_06 {
    background-image: url('../assets/img/img_space/GrueJauneEngins02_button06.webp');
    background-size: cover;
    z-index: 2000;
}

.mnuGJ_06_active {
    background-image: url('../assets/img/img_space/GrueJauneEngins02_button06_active.webp');
    background-size: cover;
    z-index: 2000;
}

.mnuGJ_07 {
    background-image: url('../assets/img/img_space/GrueJauneEngins02_button07.webp');
    background-size: cover;
    z-index: 2000;
}

.mnuGJ_07_active {
    background-image: url('../assets/img/img_space/GrueJauneEngins02_button07_active.webp');
    background-size: cover;
    z-index: 2000;
}

.mnuGJ_08 {
    background-image: url('../assets/img/img_space/GrueJauneEngins02_button08.webp');
    background-size: cover;
    z-index: 2000;
}

.mnuGJ_08_active {
    background-image: url('../assets/img/img_space/GrueJauneEngins02_button08_active.webp');
    background-size: cover;
    z-index: 2000;
}

@media screen and (min-width: 512px) {

    .mnuGJ_01:hover {
        background-image: url('../assets/img/img_space/GrueJauneEngins02_button01_hov.webp');
        background-size: cover;
        z-index: 2000;
    }

    .mnuGJ_02:hover {
        background-image: url('../assets/img/img_space/GrueJauneEngins02_button02_hov.webp');
        background-size: cover;
        z-index: 2000;
    }

    .mnuGJ_03:hover {
        background-image: url('../assets/img/img_space/GrueJauneEngins02_button03_hov.webp');
        background-size: cover;
        z-index: 2000;
    }

    .mnuGJ_04:hover {
        background-image: url('../assets/img/img_space/GrueJauneEngins02_button04_hov.webp');
        background-size: cover;
        z-index: 2000;
    }

    .mnuGJ_05:hover {
        background-image: url('../assets/img/img_space/GrueJauneEngins02_button05_hov.webp');
        background-size: cover;
        z-index: 2000;
    }

    .mnuGJ_06:hover {
        background-image: url('../assets/img/img_space/GrueJauneEngins02_button06_hov.webp');
        background-size: cover;
        z-index: 2000;
    }

    .mnuGJ_07:hover {
        background-image: url('../assets/img/img_space/GrueJauneEngins02_button07_hov.webp');
        background-size: cover;
        z-index: 2000;
    }

    .mnuGJ_08:hover {
        background-image: url('../assets/img/img_space/GrueJauneEngins02_button08_hov.webp');
        background-size: cover;
        z-index: 2000;
    }
}

.sqGJ_01 {
    position: absolute;
    top: 800%;
    left: 0%;
    width: 100%;
    animation: animGJ_BSubmarine 1s infinite;
}

@keyframes animGJ_BSubmarine {
    0% {
        background-image: url('../assets/img/img_space/GrueJauneEngins02_BSubmarine_01.webp');
        background-size: contain;
    }

    25% {
        background-image: url('../assets/img/img_space/GrueJauneEngins02_BSubmarine_02.webp');
        background-size: contain;
    }

    50% {
        background-image: url('../assets/img/img_space/GrueJauneEngins02_BSubmarine_03.webp');
        background-size: contain;
    }

    75% {
        background-image: url('../assets/img/img_space/GrueJauneEngins02_BSubmarine_04.webp');
        background-size: contain;
    }

    100% {
        background-image: url('../assets/img/img_space/GrueJauneEngins02_BSubmarine_01.webp');
        background-size: contain;
    }
}

.sqGJ_02 {
    position: absolute;
    top: 800%;
    left: 0%;
    width: 100%;
    animation: animGJ_VTram 1s infinite;
}

@keyframes animGJ_VTram {
    0% {
        background-image: url('../assets/img/img_space/GrueJauneEngins02_VTram_01.webp');
        background-size: contain;
    }

    25% {
        background-image: url('../assets/img/img_space/GrueJauneEngins02_VTram_02.webp');
        background-size: contain;
    }

    50% {
        background-image: url('../assets/img/img_space/GrueJauneEngins02_VTram_03.webp');
        background-size: contain;
    }

    75% {
        background-image: url('../assets/img/img_space/GrueJauneEngins02_VTram_04.webp');
        background-size: contain;
    }

    100% {
        background-image: url('../assets/img/img_space/GrueJauneEngins02_VTram_01.webp');
        background-size: contain;
    }
}

.sqGJ_03 {
    position: absolute;
    top: 800%;
    left: 12%;
    width: 100%;
    animation: animGJ_VCargo 1s infinite;
}

@keyframes animGJ_VCargo {
    0% {
        background-image: url('../assets/img/img_space/GrueJauneEngins02_VCargo_01.webp');
        background-size: contain;
    }

    25% {
        background-image: url('../assets/img/img_space/GrueJauneEngins02_VCargo_02.webp');
        background-size: contain;
    }

    50% {
        background-image: url('../assets/img/img_space/GrueJauneEngins02_VCargo_03.webp');
        background-size: contain;
    }

    75% {
        background-image: url('../assets/img/img_space/GrueJauneEngins02_VCargo_04.webp');
        background-size: contain;
    }

    100% {
        background-image: url('../assets/img/img_space/GrueJauneEngins02_VCargo_01.webp');
        background-size: contain;
    }
}

.sqGJ_04 {
    position: absolute;
    top: 800%;
    left: -13%;
    width: 100%;
    animation: animGJ_BCigare 1s infinite;
}

@keyframes animGJ_BCigare {
    0% {
        background-image: url('../assets/img/img_space/GrueJauneEngins02_BCigare_01.webp');
        background-size: contain;
    }

    25% {
        background-image: url('../assets/img/img_space/GrueJauneEngins02_BCigare_02.webp');
        background-size: contain;
    }

    50% {
        background-image: url('../assets/img/img_space/GrueJauneEngins02_BCigare_03.webp');
        background-size: contain;
    }

    75% {
        background-image: url('../assets/img/img_space/GrueJauneEngins02_BCigare_04.webp');
        background-size: contain;
    }

    100% {
        background-image: url('../assets/img/img_space/GrueJauneEngins02_BCigare_01.webp');
        background-size: contain;
    }
}

.sqGJ_05 {
    position: absolute;
    top: 800%;
    left: 0%;
    width: 100%;
    animation: animGJ_VBateau 1s infinite;
}

@keyframes animGJ_VBateau {
    0% {
        background-image: url('../assets/img/img_space/GrueJauneEngins02_VBateau_01.webp');
        background-size: contain;
    }

    25% {
        background-image: url('../assets/img/img_space/GrueJauneEngins02_VBateau_02.webp');
        background-size: contain;
    }

    50% {
        background-image: url('../assets/img/img_space/GrueJauneEngins02_VBateau_03.webp');
        background-size: contain;
    }

    75% {
        background-image: url('../assets/img/img_space/GrueJauneEngins02_VBateau_04.webp');
        background-size: contain;
    }

    100% {
        background-image: url('../assets/img/img_space/GrueJauneEngins02_VBateau_01.webp');
        background-size: contain;
    }
}

.sqGJ_06 {
    position: absolute;
    top: 800%;
    left: 6%;
    width: 100%;
    animation: animGJ_BSerre 1s infinite;
}

@keyframes animGJ_BSerre {
    0% {
        background-image: url('../assets/img/img_space/GrueJauneEngins02_BSerre_01.webp');
        background-size: contain;
    }

    25% {
        background-image: url('../assets/img/img_space/GrueJauneEngins02_BSerre_02.webp');
        background-size: contain;
    }

    50% {
        background-image: url('../assets/img/img_space/GrueJauneEngins02_BSerre_03.webp');
        background-size: contain;
    }

    75% {
        background-image: url('../assets/img/img_space/GrueJauneEngins02_BSerre_04.webp');
        background-size: contain;
    }

    100% {
        background-image: url('../assets/img/img_space/GrueJauneEngins02_BSerre_01.webp');
        background-size: contain;
    }
}

.sqGJ_07 {
    position: absolute;
    top: 800%;
    left: -7%;
    width: 100%;
    animation: animGJ_VBallon 1s infinite;
}

@keyframes animGJ_VBallon {
    0% {
        background-image: url('../assets/img/img_space/GrueJauneEngins02_VBallon_01.webp');
        background-size: contain;
    }

    25% {
        background-image: url('../assets/img/img_space/GrueJauneEngins02_VBallon_02.webp');
        background-size: contain;
    }

    50% {
        background-image: url('../assets/img/img_space/GrueJauneEngins02_VBallon_03.webp');
        background-size: contain;
    }

    75% {
        background-image: url('../assets/img/img_space/GrueJauneEngins02_VBallon_04.webp');
        background-size: contain;
    }

    100% {
        background-image: url('../assets/img/img_space/GrueJauneEngins02_VBallon_01.webp');
        background-size: contain;
    }
}

.sqGJ_08 {
    position: absolute;
    top: 800%;
    left: 43%;
    width: 100%;
    animation: animGJ_BDrakkar 1s infinite;
}

@keyframes animGJ_BDrakkar {
    0% {
        background-image: url('../assets/img/img_space/GrueJauneEngins02_BDrakkar_01.webp');
        background-size: contain;
    }

    25% {
        background-image: url('../assets/img/img_space/GrueJauneEngins02_BDrakkar_02.webp');
        background-size: contain;
    }

    50% {
        background-image: url('../assets/img/img_space/GrueJauneEngins02_BDrakkar_03.webp');
        background-size: contain;
    }

    75% {
        background-image: url('../assets/img/img_space/GrueJauneEngins02_BDrakkar_04.webp');
        background-size: contain;
    }

    100% {
        background-image: url('../assets/img/img_space/GrueJauneEngins02_BDrakkar_01.webp');
        background-size: contain;
    }
}

.sqGJ_hor {
    transition: 1.5s all ease-in-out;
    transform-origin: 50% -315%;
    transform: rotate(-90deg);
}

.sqGJ_ahor {
    transition: 1.5s all ease-in-out;
    transform-origin: 50% -315%;
    transform: rotate(90deg);
}

.activeGJ_hor {
    transform: rotate(-180deg);
    display: block;
    transition: all 2s ease-out;
}

.activeGJ_ahor {
    transform: rotate(180deg);
    display: block;
    transition: all 2s ease-out;
}

.bull_txtGJ_00 {
    width: 28%;
    border-radius: 2%;
    border: 0.25em solid #000;
    padding: 0.25em;
}

.bull_txtGJ_00B {
    background-color: #000000;
}

.bull_txtGJ_00R {
    background-color: #ff0000;
}

.bull_txtGJ_00 h3 {
    margin: 0;
    padding: 0;
    text-align: center;
    font-family: 'Carrois Gothic SC', cursive;
    color: #ffffff;
}

.dinlineGJ {
    display: inline;
    animation: animGJ_bull 5s;
}

@keyframes animGJ_bull {
    0% {
        opacity: 0;
    }

    40% {
        opacity: 0;
    }

    60% {
        opacity: 1;
    }

    100% {
        opacity: 1;
    }
}

.bull_txtGJ_01 {
    position: absolute;
    top: 75%;
    left: 2%;
    z-index: 700;
}

.bull_txtGJ_02 {
    position: absolute;
    top: 28%;
    left: 2%;
    z-index: 700;
}

.bull_txtGJ_03 {
    position: absolute;
    top: 21%;
    left: 15%;
    z-index: 700;
}

.bull_txtGJ_04 {
    position: absolute;
    top: 58%;
    left: 55%;
    z-index: 700;
}

.bull_txtGJ_05 {
    position: absolute;
    top: 4%;
    left: 10%;
    z-index: 700;
}

.bull_txtGJ_06 {
    position: absolute;
    top: 52%;
    left: 62%;
    z-index: 700;
}

.bull_txtGJ_07 {
    position: absolute;
    top: 65%;
    left: 35%;
    z-index: 700;
}

.bull_txtGJ_08 {
    position: absolute;
    top: 77%;
    left: 45%;
    z-index: 700;
}

.bull_txtGJ_09 {
    position: absolute;
    top: 42%;
    left: 32%;
    z-index: 700;
}

.bull_txtGJ_10 {
    position: absolute;
    top: 55%;
    left: 25%;
    z-index: 700;
}

.bull_txtGJ_11 {
    position: absolute;
    top: 62%;
    left: 1%;
    z-index: 700;
}

.bull_txtGJ_12 {
    position: absolute;
    top: 48%;
    left: 5%;
    z-index: 700;
}

.bull_txtGJ_13 {
    position: absolute;
    top: 13%;
    left: 58%;
    z-index: 700;
}

.bull_txtGJ_14 {
    position: absolute;
    top: 28%;
    left: 62%;
    z-index: 700;
}

.bull_txtGJ_15 {
    position: absolute;
    top: 38%;
    left: 4%;
    z-index: 700;
}

.bull_txtGJ_16 {
    position: absolute;
    top: 38%;
    left: 60%;
    z-index: 700;
}

.bull_txtGJ_17 {
    position: absolute;
    top: 84%;
    left: 62%;
    z-index: 700;
}

.bull_txtGJ_18 {
    position: absolute;
    top: 5%;
    left: 62%;
    z-index: 700;
}

.bull_txtGJ_19 {
    position: absolute;
    top: 45%;
    left: 62%;
    z-index: 700;
}

.bull_txtGJ_20 {
    position: absolute;
    top: 71%;
    left: 62%;
    z-index: 700;
}

.bull_txtGJ_21 {
    position: absolute;
    top: 90%;
    left: 8%;
    z-index: 700;
}

.bull_txtGJ_22 {
    position: absolute;
    top: 12%;
    left: 4%;
    z-index: 700;
}

@keyframes anim32_brum01 {
    0% {
        background-image: url("../assets/img/img_space/GrueJauneBrum_grue_jaune.webp");
        background-size: 100%;
        top: -15%;
        left: -300%;
        opacity: 0;
    }

    2% {
        background-image: url("../assets/img/img_space/GrueJauneBrum_grue_jaune.webp");
        background-size: 100%;
        top: -15%;
        left: -300%;
        opacity: 1;
    }

    30% {
        background-image: url("../assets/img/img_space/GrueJauneBrum_grue_jaune.webp");
        background-size: 100%;
        top: -15%;
        left: -150%;
        opacity: 0.9;
    }

    58% {
        background-image: url("../assets/img/img_space/GrueJauneBrum_grue_jaune.webp");
        background-size: 100%;
        top: -15%;
        left: 0%;
        opacity: 0;
    }

    100% {
        background-image: url("../assets/img/img_space/GrueJauneBrum_grue_jaune.webp");
        background-size: 100%;
        top: -15%;
        left: -300%;
        opacity: 0;
    }
}

div.cadre3232_bk01 {
    position: absolute;
    width: 300%;
    height: 100%;
    top: 0;
    left: 0;
    animation: anim32_brum01 20s linear infinite;
}

@keyframes anim32_brum02 {
    0% {
        background-image: url("../assets/img/img_space/GrueJauneBrum_grue_jaune.webp");
        background-size: 100%;
        top: -16%;
        left: -300%;
        opacity: 0;
    }

    22% {
        background-image: url("../assets/img/img_space/GrueJauneBrum_grue_jaune.webp");
        background-size: 100%;
        top: -16%;
        left: -300%;
        opacity: 1;
    }

    50% {
        background-image: url("../assets/img/img_space/GrueJauneBrum_grue_jaune.webp");
        background-size: 100%;
        top: -16%;
        left: -150%;
        opacity: 0.9;
    }

    78% {
        background-image: url("../assets/img/img_space/GrueJauneBrum_grue_jaune.webp");
        background-size: 100%;
        top: -16%;
        left: 0%;
        opacity: 0;
    }

    100% {
        background-image: url("../assets/img/img_space/GrueJauneBrum_grue_jaune.webp");
        background-size: 100%;
        top: -16%;
        left: -300%;
        opacity: 0;
    }
}

div.cadre3232_bk02 {
    position: absolute;
    width: 300%;
    height: 100%;
    top: 0;
    left: 0;
    animation: anim32_brum02 20s linear infinite;
}

@keyframes anim32_brum03 {
    0% {
        background-image: url("../assets/img/img_space/GrueJauneBrum_grue_jaune.webp");
        background-size: 100%;
        top: 10%;
        left: -300%;
        opacity: 0;
    }

    42% {
        background-image: url("../assets/img/img_space/GrueJauneBrum_grue_jaune.webp");
        background-size: 100%;
        top: 10%;
        left: -300%;
        opacity: 1;
    }

    70% {
        background-image: url("../assets/img/img_space/GrueJauneBrum_grue_jaune.webp");
        background-size: 100%;
        top: 10%;
        left: -150%;
        opacity: 0.9;
    }

    98% {
        background-image: url("../assets/img/img_space/GrueJauneBrum_grue_jaune.webp");
        background-size: 100%;
        top: 10%;
        left: 0%;
        opacity: 0;
    }

    100% {
        background-image: url("../assets/img/img_space/GrueJauneBrum_grue_jaune.webp");
        background-size: 100%;
        top: 10%;
        left: -300%;
        opacity: 0;
    }
}

div.cadre3232_frt01 {
    position: absolute;
    width: 300%;
    height: 100%;
    top: 0;
    left: 0;
    animation: anim32_brum03 20s linear infinite;
}

@keyframes anim32_brum04 {
    0% {
        background-image: url("../assets/img/img_space/GrueJauneBrum_grue_jaune.webp");
        background-size: 100%;
        top: 20%;
        left: -300%;
        opacity: 0;
    }

    32% {
        background-image: url("../assets/img/img_space/GrueJauneBrum_grue_jaune.webp");
        background-size: 100%;
        top: 20%;
        left: -300%;
        opacity: 1;
    }

    60% {
        background-image: url("../assets/img/img_space/GrueJauneBrum_grue_jaune.webp");
        background-size: 100%;
        top: 20%;
        left: -150%;
        opacity: 0.9;
    }

    88% {
        background-image: url("../assets/img/img_space/GrueJauneBrum_grue_jaune.webp");
        background-size: 100%;
        top: 20%;
        left: 0%;
        opacity: 0;
    }

    100% {
        background-image: url("../assets/img/img_space/GrueJauneBrum_grue_jaune.webp");
        background-size: 100%;
        top: 20%;
        left: -300%;
        opacity: 0;
    }
}

div.cadre3232_frt02 {
    position: absolute;
    width: 300%;
    height: 100%;
    top: 0;
    left: 0;
    animation: anim32_brum04 20s linear infinite;
}

@keyframes anim32_brum05 {
    0% {
        background-image: url("../assets/img/img_space/GrueJauneBrum_grue_jaune.webp");
        background-size: 100%;
        top: -14%;
        left: -150%;
        opacity: 0.9;
    }

    28% {
        background-image: url("../assets/img/img_space/GrueJauneBrum_grue_jaune.webp");
        background-size: 100%;
        top: -14%;
        left: 0%;
        opacity: 0;
    }

    70% {
        background-image: url("../assets/img/img_space/GrueJauneBrum_grue_jaune.webp");
        background-size: 100%;
        top: -14%;
        left: -300%;
        opacity: 0;
    }

    72% {
        background-image: url("../assets/img/img_space/GrueJauneBrum_grue_jaune.webp");
        background-size: 100%;
        top: -14%;
        left: -300%;
        opacity: 1;
    }

    100% {
        background-image: url("../assets/img/img_space/GrueJauneBrum_grue_jaune.webp");
        background-size: 100%;
        top: -14%;
        left: -150%;
        opacity: 0.9;
    }
}

div.cadre3232_bk03 {
    position: absolute;
    width: 300%;
    height: 100%;
    top: 0;
    left: 0;
    animation: anim32_brum05 20s linear infinite;
}
