div.cadreCJ {
    position: relative;
    width: 33%;
    margin: 0 auto;
    background-color: black;
    overflow: hidden;
}

.cadreCJ1 {
    margin: 0;
    padding: 0;
    overflow: hidden;
    position: relative;
    width: 33%;
    float: left;
}

.cadreCJ1>* {
    bottom: 0;
    left: 0;
    margin: 0;
    padding: 0;
    position: absolute;
    right: 0;
    top: 0;
    transform: rotate(45deg);
    z-index: 1;
}

.cadreCJ1__left {
    left: -70.75%;
    right: 70.75%;
}

.cadreCJ1__right {
    left: 70.75%;
    right: -70.75%;
}

.cadreCJ1__top {
    bottom: 70.75%;
    top: -70.75%;
}

.cadreCJ1__bottom {
    bottom: -70.75%;
    top: 70.75%;
}

.cadreCJ1__content {
    margin: 0;
    padding: 0;
    bottom: none;
    height: 100%;
    opacity: 0;
    right: none;
    transform: none;
    transition: opacity .25s,
        left .25s ease-in-out,
        top .25s ease-in-out,
        z-index 0s linear .25s;
    width: 100%;
    z-index: -1;
}

.cadreCJ1__content2 {
    margin: 0;
    padding: 0;
    height: 100%;
    opacity: 1;
    right: none;
    transform: none;
    width: 100%;
    z-index: 1;
    /*transition: all 0.5s ease;*/
}

.imgVideCJ {
    position: relative;
    bottom: none;
    width: 100%;
    right: none;
    transform: none;
}

.cadreCJ1__content:after {
    bottom: -100%;
    content: "";
    left: -100%;
    position: absolute;
    right: -100%;
    top: -100%;
    z-index: -1;
}

*:hover~.cadreCJ1__content {
    transition: opacity 0s,
        left 0s ease-in-out,
        top 0s ease-in-out,
        z-index 0s linear 0s;
    z-index: 1;
}

.cadreCJ1__left:hover~.cadreCJ1__content {
    left: -100%;
}

.cadreCJ1__right:hover~.cadreCJ1__content {
    left: 100%;
}

.cadreCJ1__top:hover~.cadreCJ1__content {
    top: -100%;
}

.cadreCJ1__bottom:hover~.cadreCJ1__content {
    top: 100%;
}

.cadreCJ1__content:hover {
    left: 0;
    opacity: 1;
    top: 0;
    transition: opacity 0.25s,
        left .25s ease-in-out,
        top .25s ease-in-out,
        z-index 0s linear 0s;
    z-index: 1;
}

.cadreCJ1_01 {
    background-image: url("../assets/img/img_jap/500_CarreJapMotif_000_01.webp");
    background-size: cover;
}

.cadreCJ1_02 {
    background-image: url("../assets/img/img_jap/500_CarreJapMotif_000_02.webp");
    background-size: cover;
}

.cadreCJ1_03 {
    background-image: url("../assets/img/img_jap/500_CarreJapMotif_000_03.webp");
    background-size: cover;
}

.cadreCJ1_04 {
    background-image: url("../assets/img/img_jap/500_CarreJapMotif_000_04.webp");
    background-size: cover;
}

.cadreCJ1_05 {
    background-image: url("../assets/img/img_jap/500_CarreJapMotif_000_05.webp");
    background-size: cover;
}

.cadreCJ1_06 {
    background-image: url("../assets/img/img_jap/500_CarreJapMotif_000_06.webp");
    background-size: cover;
}

.cadreCJ1_07 {
    background-image: url("../assets/img/img_jap/500_CarreJapMotif_000_07.webp");
    background-size: cover;
}

.cadreCJ1_08 {
    background-image: url("../assets/img/img_jap/500_CarreJapMotif_000_08.webp");
    background-size: cover;
}

.cadreCJ1_09 {
    background-image: url("../assets/img/img_jap/500_CarreJapMotif_000_09.webp");
    background-size: cover;
}

.cadreCJ11_01 {
    background-image: url("../assets/img/img_jap/500_CarreJap_000_01.webp");
    background-size: cover;
}

.cadreCJ11_02 {
    background-image: url("../assets/img/img_jap/500_CarreJap_000_02.webp");
    background-size: cover;
}

.cadreCJ11_03 {
    background-image: url("../assets/img/img_jap/500_CarreJap_000_03.webp");
    background-size: cover;
    background-color: #de9d41;
}

.cadreCJ11_04 {
    background-image: url("../assets/img/img_jap/500_CarreJap_000_04.webp");
    background-size: cover;
}

.cadreCJ11_05 {
    background-image: url("../assets/img/img_jap/500_CarreJap_000_05.webp");
    background-size: cover;
}

.cadreCJ11_06 {
    background-image: url("../assets/img/img_jap/500_CarreJap_000_06.webp");
    background-size: cover;
}

.cadreCJ11_07 {
    background-image: url("../assets/img/img_jap/500_CarreJap_000_07.webp");
    background-size: cover;
}

.cadreCJ11_08 {
    background-image: url("../assets/img/img_jap/500_CarreJap_000_08.webp");
    background-size: cover;
}

.cadreCJ11_09 {
    background-image: url("../assets/img/img_jap/500_CarreJap_000_09.webp");
    background-size: cover;
}


.cadreCJ .rond14 {
    position: absolute;
    width: 20%;
    height: 20%;
    top: 40%;
    left: 40%;
    background-color: red;
    border-radius: 50%;
    z-index: 1000;
    background-image: url("../assets/img/img_kaiju/111_A6.webp");
    background-size: cover;
}


.cadreCJ_msg {
    background: rgba(0, 0, 0, 0.3);
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 10;
    }
    
    .cadreCJ7_msg {
    font-family: 'Fjalla One', sans-serif;
    display: flex;
    text-align: center;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    z-index: 10;
    }
    
    .cadreCJ77_msg {
    font-family: 'Fjalla One', sans-serif;
    position: relative;
    width: 80%;
    height:60%;
    margin: 20%;
    margin-left: 2%;
    margin-right: 2%;
    z-index: 10;
    /*Background-color: white;*/
    }