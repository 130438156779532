div.super_cadre11{
    background-image: url("../assets/img/img_cadre/CadreRecP00.webp");
    background-size:100% 100%;
    margin-right:20%
  }
  
  div.cadre11{
    float:left;
    position: relative;
    width: 100%;
    padding: 0;
    background:white;
  }
  
  div.cadre11 .imgvide11{
    width:100%;
  }
  
  div.cadre11 .cadre111{
    cursor: pointer;
    float: left;
    overflow: hidden;
   /* position: absolute;*/
    margin: 0px;
    padding: 0px;
    top:0;
    height:100%;
    width:100%;
  }
  
  div.cadre11 .cadre111 .cadre_img11{
    cursor: pointer;
    float: left;
    overflow: hidden;
    position: relative;
    margin: 0px;
    padding: 0px;
    top:0;
    height:100%;
    width:16.66%;
    transition: all 0.5s ease;
    background-color:white;
  }
  
  h2.h2_txt11 {
    font-size: 2em;
    text-transform: uppercase;
    font-family: 'Fjalla One', sans-serif;
    text-align: left;
    margin: 0%;
    padding: 0.5%;
    color: #000000;
    width:95%;
    line-height: normal;
  }

  img.image_logo11{
    float: left;
    width:15%;
  }
  
  @media screen and (max-width: 1258px) {
    h2.h2_txt11 {
      font-size: 3.0em;
      text-transform: uppercase;
      line-height: normal;
    }

    img.image_logo11{
        float: left;
        width:8%;
        margin:2%
      }
  }
  
  @media screen and (max-width: 1024px) {
    h2.h2_txt11 {
      font-size: 3.0em;
      text-transform: uppercase;
      line-height: normal;
    }

    img.image_logo11{
        float: left;
        width:12%;
      }
  }
  
  @media screen and (max-width: 812px) {
    h2.h2_txt11 {
      font-size: 5em;
      text-transform: uppercase;
      line-height: normal;
    }

    img.image_logo11{
        float: left;
        width:15%;
      }
  }
  
  
  @media screen and (max-width: 480px) {
    h2.h2_txt11 {
      font-size: 7.8em;
      text-transform: uppercase;
      line-height: normal;
    }

    img.image_logo11{
        float: left;
        width:15%;
        margin:1%;
        padding:0;
      }
  }
  

  
  
  