@import url(https://fonts.googleapis.com/css?family=Pattaya|Work+Sans:400,200,300);
@import url(https://fonts.googleapis.com/css2?family=Gloria+Hallelujah&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Fjalla+One&display=swap);
@import url(https://fonts.googleapis.com/css?family=Roboto+Slab|Satisfy);
@import url(https://fonts.googleapis.com/css2?family=Mochiy+Pop+One&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Gloria+Hallelujah&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Acme&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Pacifico&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Fjalla+One&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Dosis:wght@500&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Square+Peg&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Edu+NSW+ACT+Foundation:wght@500&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Carrois+Gothic+SC&display=swap);
@import url(https://fonts.googleapis.com/css2?family=League+Gothic&display=swap);
/*@import url(font-awesome.min.css);*/


html, body, div, span, applet, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr, acronym, address, big, cite, code, del, dfn, em, img, ins, kbd, q, s, samp, small, strike, strong, sub, sup, tt, var, b, u, i, center, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, canvas, details, embed, figure, figcaption, footer, header, hgroup, menu, nav, output, ruby, section, summary, time, mark, audio, video {
	margin: 0;
	padding: 0;
	border: 0;
	font-size: 100%;
	font: inherit;
	vertical-align: baseline;
}

article, aside, details, figcaption, figure, footer, header, hgroup, menu, nav, section {
	display: block;
}

body {
	line-height: 1;
	background: #feb914;
}

body {
	-webkit-text-size-adjust: none;
}

/* Basic */

body.is-loading *, body.is-loading *:before, body.is-loading *:after {
	animation: none !important;
	transition: none !important;
}

/* Type */

body {
	color: #feb914;
}


/*@import url(https://fonts.googleapis.com/css2?family=Mochiy+Pop+One&display=swap);
/*font-family: 'Mochiy Pop One', sans-serif;*/
/*font-family: 'Gloria Hallelujah', cursive;*/
/*@import url(https://fonts.googleapis.com/css2?family=Acme&display=swap);
/*font-family:'Acme', sans-serif;*/
/*@import url(https://fonts.googleapis.com/css2?family=Pacifico&display=swap);
/*font-family: 'Pacifico', cursive;*/
/*font-family: 'Fjalla One', sans-serif;*/
/*@import url(https://fonts.googleapis.com/css2?family=Dosis:wght@500&display=swap);
/*font-family: 'Dosis', sans-serif;*/
/*@import url(https://fonts.googleapis.com/css2?family=Square+Peg&display=swap);
/*font-family: 'Square Peg', cursive;*/
/*@import url(https://fonts.googleapis.com/css2?family=Edu+NSW+ACT+Foundation:wght@500&display=swap);
/*font-family: 'Edu NSW ACT Foundation', cursive;*/
/*@import url(https://fonts.googleapis.com/css2?family=Carrois+Gothic+SC&display=swap);
/*font-family: 'Carrois Gothic SC', sans-serif;*/
/*@import url(https://fonts.googleapis.com/css2?family=League+Gothic&display=swap);
/*font-family: 'League Gothic', sans-serif;*/

html, body {
	height: 100%;
}
	html, body {
	font-size: 0.5vw;
}

h2 {
	font-size:1.1vw;
}

h3 {
	font-size:0.85vw;
	font-weight: normal;
}

html {
	scroll-behavior: smooth;
}


div.clear {
	clear: both;
 	height: 0; 
	overflow: hidden; /* Précaution pour IE 7 */
}
/*Avantage: technique éprouvée, pas ou peu d'effets indésirables.*/

a {
	text-decoration: underline;
	outline: 0;
}

a:hover {
	text-decoration: none;
}



div.content h1 {
	font-size: 4em;
	margin: 0 0 .25em 0;
	color: #fff;
}

div.content span {
	font-family: 'Pattaya', sans-serif;
	font-family: 'Fjalla One', sans-serif;
}

div.inner div.content {
	margin-top:5%;
	padding-bottom:0;
}

div.inner div.content h4 {
	color:white;
	font-size: 125%;
}

h1 a, h2 a, h3 a, h4 a, h5 a, h6 a {
	color: inherit;
	text-decoration: none;
}




/* Button */

input[type="submit"],
input[type="reset"],
input[type="button"],
button,
.button {
	-webkit-appearance: none;
	appearance: none;
	transition: background-color 0.2s ease-in-out, color 0.2s ease-in-out;
	border-radius: 2px;
	border: 0;
	cursor: pointer;
	display: inline-block;
	font-weight: 300;
	height: 2.85em;
	line-height: 3em;
	padding: 0 2.5em;
	text-align: center;
	text-decoration: none;
	white-space: nowrap;
}

input[type="submit"].alt,
input[type="reset"].alt,
input[type="button"].alt,
button.alt,
.button.alt {
	background: #FFF;
	color: #feb914;
}

input[type="submit"].big,
input[type="reset"].big,
input[type="button"].big,
button.big,
.button.big {
	font-size: 2em;
}



#header {
	transition: background 1s ease-in-out, height 0.2s ease-in-out;
	display: -moz-flex;
	display: -ms-flex;
	display: flex;
	-moz-justify-content: center;
	-ms-justify-content: center;
	justify-content: center;
	-moz-align-items: center;
	-ms-align-items: center;
	align-items: center;
	display: -ms-flexbox;
	-ms-flex-align: center;
	-ms-flex-pack: center;
	background: rgba(254, 254, 254, 0.85);
	position: fixed;
	top: 0;
	width: 100%;
	height: 100vh;
	z-index: 10001;
}

#header .inner {
	transition: height 0.2s ease-in-out, width 0.2s ease-in-out;
	display: -moz-flex;
	display: -ms-flex;
	display: flex;
	-moz-flex-direction: column;
	flex-direction: column;
	-moz-justify-content: center;
	-ms-justify-content: center;
	justify-content: center;
	-moz-align-items: center;
	-ms-align-items: center;
	align-items: center;
	display: -ms-flexbox;
	-ms-flex-align: center;
	-ms-flex-pack: center;
	background: #feb914;
	border-radius: 100%;
	width: 45em;
	height: 45em;
	padding: 4em;
	text-align: center;
	box-shadow: 0 0 0 1em #FFF;
	cursor: default;
}

#header .inner .content {
	transition: opacity 1s ease-in-out;
	opacity: 1;
}

#header .inner .content h1 {
	font-size: 4em;
	font-family: 'Pattaya', sans-serif;
	margin: 0 0 .25em 0;
	color: #fff;
}

#header .inner .content h2 {
	font-size: 2.5em;
	color: #fff;
	margin: 0 0 1em 0;
	line-height: 1.5em;
}

#header .inner .button {
	transition: background 1s ease-in-out;
	margin: 0;
	border-radius: 30px;
}

#header .inner .button.hidden {
	display: none;
	cursor: pointer;
}

#header .inner.hidden {
	display: none;
}

#header.hide, #header.preview {
	transition: background 0.2s ease-in-out, height 1s ease-in-out;
	background: none;
	height: 0;
}

#header.hide .inner, #header.preview .inner {
	height: 6em;
	width: 6em;
	padding: 2em;
}

#header.hide .inner .content, #header.preview .inner .content {
	opacity: 0;
}

#header.hide .inner .content h1,
#header.preview .inner .content h1 {
display: none;
}

#header.hide .inner .content h2,
#header.preview .inner .content h2 {
	display: none;
}

#header.hide .button.big,
#header.preview .button.big {
	display: none;
}

#header.hide .button.hidden,
#header.preview .button.hidden {
	text-decoration: none;
	padding-top: 1.5em;
	padding-left: 0;
	padding-right: 0;
	background: none;
	display: inline-block;
}

#header.hide .button.hidden:before,
#header.preview .button.hidden:before {
	-moz-osx-font-smoothing: grayscale;
	-webkit-font-smoothing: antialiased;
	font-family: FontAwesome;
	font-style: normal;
	font-weight: normal;
	text-transform: none !important;
}

#header.hide .button.hidden:before,
#header.preview .button.hidden:before {
	content: '\f015';
	color: #FFF;
	font-size: 1.5em;
}

#header.hide .button.hidden span,
#header.preview .button.hidden span {
	display: none;
}

/* Main */

#main {
	padding: 2em;
	padding-top:  0.5em;
	padding-bottom: 0.5em;
}

#main .inner {
	/*max-width: 100%;*/
	/*max-width: 800px;*/
	margin: auto;
	background-color: #fff;
	padding:10px;
}

/* Footer */

#footer {
	transition: bottom 0.2s ease;
	position: fixed;
	bottom: -45em;
	color: #000;
	left: 0;
	width: 100%;
	text-align: center;
	z-index: 9999;
}

#footer.show {
	bottom: 0;
}

#footer .info {
	position: relative;
	display: inline-block;
	text-decoration: none;
	border-radius: 50% 50% 0 0;
	background: #feb914;
	width: 3em;
	height: 3em;
	margin-bottom: -1.75em;
	color: #000;
	font-size: 1.25em;
	padding-top: .35em;
}

#footer .info span {
	display: none;
}


#footer .inner {
	display: -moz-flex;
	display: -ms-flex;
	display: flex;
	-moz-justify-content: center;
	-ms-justify-content: center;
	justify-content: center;
	-moz-align-items: stretch;
	-ms-align-items: stretch;
	align-items: stretch;
	display: -ms-flexbox;
	-ms-flex-align: center;
	-ms-flex-pack: center;
	position: relative;
	z-index: 10003;
	background: #feb914;
	padding: 2em;
	/*height: 50em;*/
	height: 45em;
}

#footer .inner .content,
#footer .inner .copyright {
	width: 75%;
	text-align: left;
	color: rgba(255, 255, 255, 0.75);
	padding: 2em;
	display: inline-block;
}

#footer .inner .content h3,
#footer .inner .copyright h3 {
	color: #000;
	font-size: 1.75em;
}


		
	


img.icone_change {
  width: 18px;
  height: 18px;
margin-top:15px;
}



/**icone **/


#footer  a.info-foot {
  /*background-image: url('img_t/info-circle-solid.svg');*/
  background-repeat: no-repeat;
  background-position: center right;
  padding-right: 18px;
}
#footer  a.info-foot span{
 display: none;
}


p.ceel_hide{
	Visibility: hidden;
	height: 1px;
	margin:0px;
	padding:0px;
}

div.content div.content2{
	padding:1%;
	margin:0;
	color:#fff;
	font-family: 'Pattaya', sans-serif;
	line-height: 2em;
	font-size: 2.5em;
}




div.lazy{
 visibility: hidden;
}





h1,
h2,
h3,
h4,
h5,
h6 {
	font-size: 100%;
	font-family: "Pattaya", sans-serif;
}

div.bande_large00:hover {
	z-index: 500;
	box-shadow: 0 3em 2em -1em #000;
	transform: scale(1.03);
}

div.rect00:hover {
	z-index: 500;
	box-shadow: 0 3em 2em -1em #000;
	transform: scale(1.03);
}

div.cadreanim00:hover {
	z-index: 500;
	box-shadow: 0 3em 2em -1em #000;
	transform: scale(1.03);
}

div.bande_large00,
div.rect00,
div.cadreanim00 {
	box-shadow: 0 3em 2em -2em #000;
	transition: transform 1s linear;
}

div.mega_cadre {
	position: absolute;
	width: 80%;
	top: 12em;
	left: 0;
	margin-left: 10%;
	margin-right: 10%;
}

#parallax-world-of-ugg .parallax-one,
#parallax-world-of-ugg .parallax-two,
#parallax-world-of-ugg .parallax-three,
#parallax-world-of-ugg .parallax-foor,
#parallax-world-of-ugg .parallax-five {
	padding-top: 20%;
	padding-bottom: 20%;
}

div.cadreanim00 {
	width: 47%;
	padding: 3%;
	z-index: 50;
	margin-top: 3%;
	margin-bottom: 3%;
	transition: transform 1s linear;
}

div.bande_large00 {
	width: 70%;
	padding: 1%;
	z-index: 50;
	margin-left: 10%;
	float: left;
}

div.rect00 {
	width: 22%;
	padding: 2%;
	margin-top: 3%;
	margin-bottom: 3%;
	z-index: 50;
	transition: transform 1s linear;
}

h2.h2-00 {
	font-size: 100%;
	font-family: "Pattaya", sans-serif;
	text-align: center;
	margin: 2%;
	padding: 1%;
	color: #fff;
}

@keyframes cadre_out_a {
	0% {
		position: relative;
		margin: 0;
		margin-left: 5%;
		margin-top: 3%;
		margin-bottom: 3%;
		float: left;
		top: 4em;
		Left: 0%;
		opacity: 0;
	}

	45% {
		position: relative;
		margin: 0;
		margin-left: 3%;
		margin-top: 3%;
		margin-bottom: 3%;
		float: left;
		top: 2em;
		Left: 0%;
		opacity: 1;
	}

	100% {
		position: relative;
		margin: 0;
		margin-left: 1%;
		margin-top: 3%;
		margin-bottom: 3%;
		float: left;
		top: 0em;
		Left: 0%;
	}
}

div.cadre_a {
	position: relative;
	margin: 0;
	margin-left: 1%;
	margin-top: 3%;
	margin-bottom: 3%;
	float: left;
	top: 0em;
	Left: 0%;
	animation: cadre_out_a 4s;
}

@keyframes cadre_out_b {
	0% {
		position: relative;
		margin: 0;
		margin-left: 7%;
		margin-top: 3%;
		margin-bottom: 3%;
		float: left;
		top: 6em;
		Left: 0%;
		opacity: 0;
	}

	45% {
		position: relative;
		margin: 0;
		margin-left: 5%;
		margin-top: 3%;
		margin-bottom: 3%;
		float: left;
		top: 4em;
		Left: 0%;
		opacity: 1;
	}

	100% {
		position: relative;
		margin: 0;
		margin-left: 3%;
		margin-top: 3%;
		margin-bottom: 3%;
		float: left;
		top: 2em;
		Left: 0%;
	}
}

div.cadre_b {
	position: relative;
	margin: 0;
	margin-left: 3%;
	margin-top: 3%;
	margin-bottom: 3%;
	float: left;
	top: 2em;
	Left: 0%;
	animation: cadre_out_b 4s;
}

@keyframes cadre_out_c {
	0% {
		position: relative;
		margin: 0;
		margin-left: 1%;
		margin-top: 3%;
		margin-bottom: 3%;
		float: left;
		top: 0em;
		Left: 0%;
		opacity: 0;
	}

	45% {
		position: relative;
		margin: 0;
		margin-left: 7%;
		margin-top: 3%;
		margin-bottom: 3%;
		float: left;
		top: 6em;
		Left: 0%;
		opacity: 1;
	}

	100% {
		position: relative;
		margin: 0;
		margin-left: 5%;
		margin-top: 3%;
		margin-bottom: 3%;
		float: left;
		top: 4em;
		Left: 0%;
	}
}

div.cadre_c {
	position: relative;
	margin: 0;
	margin-left: 5%;
	margin-top: 3%;
	margin-bottom: 3%;
	float: left;
	top: 4em;
	Left: 0%;
	animation: cadre_out_c 4s;
}

@keyframes cadre_out_d {
	0% {
		position: relative;
		margin: 0;
		margin-left: 3%;
		margin-top: 3%;
		margin-bottom: 3%;
		float: left;
		top: 2em;
		Left: 0%;
		opacity: 0;
	}

	45% {
		position: relative;
		margin: 0;
		margin-left: 1%;
		margin-top: 3%;
		margin-bottom: 3%;
		float: left;
		top: 0em;
		Left: 0%;
		opacity: 1;
	}

	100% {
		position: relative;
		margin: 0;
		margin-left: 7%;
		margin-top: 3%;
		margin-bottom: 3%;
		float: left;
		top: 6em;
		Left: 0%;
	}
}

div.cadre_d {
	position: relative;
	margin: 0;
	margin-left: 7%;
	margin-top: 3%;
	margin-bottom: 3%;
	float: left;
	top: 6em;
	Left: 0%;
	animation: cadre_out_d 4s;
}


@keyframes cadre_out_e {
	0% {
		position: relative;
		margin: 0;
		margin-left: 1%;
		margin-top: 3%;
		margin-bottom: 3%;
		float: left;
		top: 0em;
		Left: 0%;
		opacity: 0;
	}

	45% {
		position: relative;
		margin: 0;
		margin-left: 7%;
		margin-top: 3%;
		margin-bottom: 3%;
		float: left;
		top: 6em;
		Left: 0%;
		opacity: 1;
	}

	100% {
		position: relative;
		margin-left: 1%;
		margin-top: 3%;
		margin-bottom: 3%;
		float: left;
		top: 0%;
		Left: 0%;
	}
}

@keyframes cadre_out_f {
	0% {
		position: relative;
		margin: 0;
		margin-left: 3%;
		margin-top: 3%;
		margin-bottom: 3%;
		float: left;
		top: 2em;
		Left: 0%;
		opacity: 0;
	}

	45% {
		position: relative;
		margin: 0;
		margin-left: 1%;
		margin-top: 3%;
		margin-bottom: 3%;
		float: left;
		top: 0em;
		Left: 0%;
		opacity: 1;
	}

	100% {
		margin-left: 12%;
		margin-top: 3%;
		margin-bottom: 3%;
		float: left;
		top: 0%;
		Left: 0%;
	}
}

@keyframes cadre_out_g {
	0% {
		position: relative;
		margin: 0;
		margin-left: 5%;
		margin-top: 3%;
		margin-bottom: 3%;
		float: left;
		top: 4em;
		Left: 0%;
		opacity: 0;
	}

	45% {
		position: relative;
		margin: 0;
		margin-left: 3%;
		margin-top: 3%;
		margin-bottom: 3%;
		float: left;
		top: 2em;
		Left: 0%;
		opacity: 1;
	}

	100% {
		position: relative;
		margin-left: 23%;
		margin-top: 3%;
		margin-bottom: 3%;
		float: left;
		top: 0%;
		Left: 0%;
	}
}

@keyframes cadre_out_h {
	0% {
		position: relative;
		margin: 0;
		margin-left: 7%;
		margin-top: 3%;
		margin-bottom: 3%;
		float: left;
		top: 6em;
		Left: 0%;
		opacity: 0;
	}

	45% {
		position: relative;
		margin: 0;
		margin-left: 5%;
		margin-top: 3%;
		margin-bottom: 3%;
		float: left;
		top: 4em;
		Left: 0%;
		opacity: 1;
	}

	100% {
		position: relative;
		margin-left: 34%;
		margin-top: 3%;
		margin-bottom: 3%;
		float: left;
		top: 0%;
		Left: 0%;
	}
}

div.cadre_e {
	position: relative;
	margin-left: 1%;
	margin-top: 3%;
	margin-bottom: 3%;
	float: left;
	top: 0%;
	Left: 0%;
	animation: cadre_out_e 4s;
}

div.cadre_f {
	margin-left: 12%;
	margin-top: 3%;
	margin-bottom: 3%;
	float: left;
	top: 0%;
	Left: 0%;
	animation: cadre_out_f 4s;
}

div.cadre_g {
	position: relative;
	margin-left: 23%;
	margin-top: 3%;
	margin-bottom: 3%;
	float: left;
	top: 0%;
	Left: 0%;
	animation: cadre_out_g 4s;
}

div.cadre_h {
	position: relative;
	margin-left: 34%;
	margin-top: 3%;
	margin-bottom: 3%;
	float: left;
	top: 0%;
	Left: 0%;
	animation: cadre_out_h 4s;
}

div.idAncre00 {
	position: absolute;
	width: 1px;
	height: 1px;
	top: -20%;
}

.restart000,
.reponse000 {
	font-family: 'Fjalla One', sans-serif;
	font-size: 200%;
	margin: 03% auto;
	/*padding: 8% 20%;*/
	display: block;
	border: none;
	background: #4d4d4d;
	background: linear-gradient(#4d4d4d, #222);
	border: 0.1em solid #222;
	border-radius: 2em;
	color: white;
	text-shadow: 0em 0.2em 0 black;
	cursor: pointer;
	font-size: 2.8em;
	line-height: 0.5em;
}

h2.winner000 {
	text-align: center;
	font-family: 'Fjalla One', sans-serif;
	line-height: normal;
	color: #4d4d4d;
	font-size: 2.8em;
	Background-color: white;
	padding: 5%;
	border-radius: 1em;
}

h2.winner000 span {
	font-size: 1.0em;
}

.restart000,
.reponse000 {
	font-size: 2.6em;
	line-height: normal;
}

h2.winner000 {
	font-size: 3.8em;
	line-height: normal;
}

h2.winner000 span {
	font-size: 0.6em;
	line-height: normal;
}


@media screen and (max-width: 1680px) {
	div.mega_cadre {
		position: absolute;
		width: 84%;
		top: 12em;
		left: 0;
		margin-left: 8%;
		margin-right: 8%;
	}

	div.cadreanim00 {
		width: 49%;
		padding: 3%;
		z-index: 50;
	}

	div.bande_large00 {
		width: 72%;
		padding: 1%;
	}

	div.rect00 {
		width: 23%;
		padding: 2%;
	}
}


@media screen and (max-width: 1258px) {

	div.mega_cadre {
		position: absolute;
		width: 90%;
		top: 12em;
		left: 0;
		margin-left: 5%;
		margin-right: 5%;
	}

	#parallax-world-of-ugg .parallax-one,
	#parallax-world-of-ugg .parallax-two,
	#parallax-world-of-ugg .parallax-three,
	#parallax-world-of-ugg .parallax-foor,
	#parallax-world-of-ugg .parallax-five {
		padding-top: 40%;
		padding-bottom: 40%;
	}

	div.cadreanim00 {
		width: 51%;
		padding: 3%;
		z-index: 50;
	}

	div.bande_large00 {
		width: 81%;
		padding: 1%;
	}

	div.rect00 {
		width: 30%;
		padding: 2%;
	}

	.restart000,
	.reponse000 {
		font-size: 3.6em;
		line-height: normal;
	}

	h2.winner000 {
		font-size: 3.8em;
		line-height: normal;
	}

	h2.winner000 span {
		font-size: 1.3em;
		line-height: normal;
	}
}


@media screen and (max-width: 1024px) {

	div.mega_cadre {
		position: absolute;
		width: 94%;
		top: 5em;
		left: 0;
		margin-left: 3%;
		margin-right: 3%;
	}

	div.cadreanim00 {
		width: 65%;
		padding: 3%;
		z-index: 50;
	}

	div.bande_large00 {
		width: 80%;
		padding: 1%;
	}

	div.rect00 {
		width: 36%;
		padding: 2%;
	}

	.restart000,
	.reponse000 {
		font-size: 4.0em;
		line-height: normal;
	}

	h2.winner000 {
		font-size: 3.6em;
		line-height: normal;
	}


	h2.winner000 span {
		font-size: 1.3em;
		line-height: normal;
	}
}


@media screen and (max-width: 812px) {

	div.mega_cadre {
		width: 100%;
		position: absolute;
		top: 15em;
		left: 0;
		margin-left: 0%;
		margin-right: 0%;
		padding-left: 0%;
		padding-bottom: 0%;
	}

	#parallax-world-of-ugg .parallax-one,
	#parallax-world-of-ugg .parallax-two,
	#parallax-world-of-ugg .parallax-three,
	#parallax-world-of-ugg .parallax-foor,
	#parallax-world-of-ugg .parallax-five {
		display: none;
	}

	div.cadreanim00 {
		width: 97%;
		top: auto;
		left: auto;
		padding: 1%;
	}

	div.bande_large00 {
		width: 97%;
		top: auto;
		left: auto;
		margin: 0;
		padding: 1%;
	}

	div.rect00 {
		width: 47%;
		margin: 0%;
		top: auto;
		left: auto;
		padding: 1%;
	}

	
@keyframes cadre_out_a {
	0% {
		opacity: 0;
	}

	45% {
		opacity: 1;
	}

	100% {
		opacity: 1;
	}
}


@keyframes cadre_out_b {
	0% {
		opacity: 0;
	}

	45% {
		opacity: 1;
	}

	100% {
		opacity: 1;
	}
}

@keyframes cadre_out_c {
	0% {
		opacity: 0;
	}

	45% {
		opacity: 1;
	}

	100% {
		opacity: 1;
	}
}


@keyframes cadre_out_d {
	0% {
		opacity: 0;
	}

	45% {
		opacity: 1;
	}

	100% {
		opacity: 1;
	}
}


@keyframes cadre_out_e {
	0% {
		opacity: 0;
	}

	45% {
		opacity: 1;
	}

	100% {
		opacity: 1;
	}
}

@keyframes cadre_out_f {
	0% {
		opacity: 0;
	}

	45% {
		opacity: 1;
	}

	100% {
		opacity: 1;
	}
}

@keyframes cadre_out_g {
	0% {
		opacity: 0;
	}

	45% {
		opacity: 1;
	}

	100% {
		opacity: 1;
	}
}

@keyframes cadre_out_h {
	0% {
		opacity: 0;
	}

	45% {
		opacity: 1;
	}

	100% {
		opacity: 1;
	}
}

	div.cadre_a {
		position: relative;
		float: left;
		margin: 0.5%;
		margin-bottom: 3em;
	}

	div.cadre_b {
		position: relative;
		float: left;
		margin: 0.5%;
		margin-bottom: 3em;
	}

	div.cadre_c {
		position: relative;
		float: left;
		margin: 0.5%;
		margin-bottom: 3em;
	}

	div.cadre_d {
		position: relative;
		float: left;
		margin: 0.5%;
		margin-bottom: 3em;
	}

	div.cadre_e {
		position: relative;
		float: left;
		margin: 0.5%;
		margin-bottom: 3em;
	}

	div.cadre_f {
		position: relative;
		float: left;
		margin: 0.5%;
		margin-bottom: 3em;
	}

	div.cadre_g {
		position: relative;
		float: left;
		margin: 0.5%;
		margin-bottom: 3em;
	}

	div.cadre_h {
		position: relative;
		float: left;
		margin: 0.5%;
		margin-bottom: 3em;
	}

	div.cadre_z {
		position: relative;
		float: left;
	}

	.restart000,
	.reponse000 {
		font-size: 12.0em;
		line-height: normal;
	}

	h2.winner000 {
		line-height: normal;
	}

	h2.winner000 span {
		font-size: 2.8em;
		line-height: normal;
	}
}


@media screen and (max-width: 480px) {

	body {
		/*font-size: 1.6vw;*/
	}

	h2 {
		font-size: 3.5vw;
		line-height: 2.9vw;
	}

	.restart000,
	.reponse000 {
		font-size: 15.0em;
		line-height: normal;
	}

	h2.winner000 {
		font-size: 5.0em;
		line-height: normal;
	}

	div.cadreanim00 {
		width: 98%;
		top: auto;
		left: auto;
		padding: 1%;
		margin: 0;
		box-shadow: none;
		margin-bottom: 1%;
	}

	div.cadreanim00:hover {
		z-index: 500;
		box-shadow: none;
		transform: none;
		margin-bottom: 1%;
	}

	div.bande_large00 {
		width: 98%;
		top: auto;
		left: auto;
		margin: 0;
		padding: 1%;
		margin: 0;
		box-shadow: none;
	}

	div.bande_large00:hover {
		z-index: 500;
		box-shadow: none;
		transform: none;
	}

	div.rect00 {
		width: 48%;
		margin: 0%;
		top: auto;
		left: auto;
		padding: 1%;
		margin: 0;
		box-shadow: none;
	}

	div.rect00:hover {
		z-index: 500;
		box-shadow: none;
		transform: none;
	}

	.restart000,
	.reponse000 {
		font-family: 'Fjalla One', sans-serif;
		font-size: 200%;
		/*padding: 8% 20%;*/
		display: block;
		border: none;
		background: #4d4d4d;
		background: linear-gradient(#4d4d4d, #222);
		border: 0.1em solid #222;
		border-radius: 2em;
		color: white;
		text-shadow: 0em 0.2em 0 black;
		cursor: pointer;
		font-size: 8.0em;
		line-height: 0.5em;
	}

	h2.winner000 {
		text-align: center;
		font-family: 'Fjalla One', sans-serif;
		line-height: normal;
		color: #4d4d4d;
		font-size: 6.5em;
		Background-color: white;
		padding: 5%;
		border-radius: 1em;
	}

	h2.winner000 span {
		font-size: 1.5em;
		line-height: normal;
	}
}
.topNavM000 {
  font-size: 1.7em;
  overflow: hidden;
  z-index: 3000;
  padding:1%;
  position:fixed;
  top:5%;
}

.topNavM000bg {
position:relative;
width:100%;
background-color:#feb914;
padding-bottom: 4%;
background: rgba(254,185,20,0.5);
}

.topNavM000 a {
  float: left;
  display: block;
  color: #f2f2f2;
  text-align: center;
  text-decoration: none;
  font-family: sans-serif;
}

.active000 {
  background-color: #f29191;
  color: white;
}

.topNavM000 .icon {
  display: none;
}

.topNavM000 a:hover {
  color: white;
}

@media screen and (max-width: 812px) {
  .topNavM000{
    font-size: 6.0em;
    position:-webkit-sticky;
    position:sticky;
    top:0;
  }

  .topNavM000 a,
  .topNavM000 button {display: none;}
  
  .topNavM000 a.icon {
    float: right;
    display: block;
    right: 1%;
    top: 1%;
  }

.topNavM000.responsive {
  position: relative;
  position:-webkit-sticky;
  position:sticky;
  top:0;
}

.topNavM000.responsive a.icon {
  position: absolute;
  right: 1%;
  top: 1%;
}
.topNavM000.responsive a.top_navM000 {
  float: none;
  display: block;
  width:75%;
  text-align: left;
  margin:0;
  padding:0.4%;
}

.topNavM000.responsive {
  float: none;
}

.topNavM000.responsive{
  position: relative;
}

.topNavM000.responsive {
  display: block;
  width: 100%;
  text-align: left;
}
}

span.top_navM000 {
  text-transform: uppercase;
  font-family: 'Fjalla One', sans-serif;
  color:#ffffff;
  border:solid 0.5em #370028;
  border-radius:1.5em;
  line-height:1em;
  background-color:#370028;
  padding-left:1em;
  padding-right:1em;
  margin:0;
  display:block;
  font-size: 1.1em;
}

span.top_navM000H {
  text-transform: uppercase;
  font-family: 'Fjalla One', sans-serif;
  color:#370028;
  border:solid 0.5em #ffffff;
  border-radius:1.5em;
  line-height:1em;
  background-color: #ffffff;
  padding-left:1em;
  padding-right:1em;
  margin:0;
  display:block;
  font-size: 1.1em;
}

span.top_navM000:hover{
  border:solid 0.5em #9f3e63;
  border-radius:1.5em;
  line-height:1em;
  background-color: #9f3e63;
}

span.top_navM000H:hover{
  color:#ffffff;
  border:solid 0.5em #9f3e63;
  border-radius:1.5em;
  line-height:1em;
  background-color: #9f3e63;
}

a.top_navM000 {
text-decoration: none;
margin: 0.2em;
margin-top:1em;
padding: 0;
border:0;
}

@media screen and (max-width: 1024px) {
  span.top_navM000 {
font-size: 1.3em
  }
  
  span.top_navM000H {
    font-size: 1.3em
  }
}

@media screen and (max-width: 480px) {
  span.top_navM000 {
font-size: 1.3em
  }
  
  span.top_navM000H {
    font-size: 1.3em
  }
}
/* Section - Parallax */
/**************************/
 div.parallax-world-of-ugg {
   display: block;
   position: fixed;
   width: 100%;
 }


 div.parallax-one {
  background-image:url(/static/media/500_CodexMaya24_0077.650d53dd.webp);
  background-attachment: fixed;
  height:50em;
  width: 100%;
  margin: 0;
  left: 0;
  bottom: 0px;
  overflow: hidden;
  margin-top:5%;
  margin-bottom:5%;
  animation: left_animation 75s linear infinite;
background-color: black;}

div.parallax-two {
  background-image:url(/static/media/500_CodexMaya24_0077.650d53dd.webp);
  background-attachment: fixed;
  height:50em;
  width: 100%;
  margin: 0;
  left: 0;
  bottom: 0px;
  overflow: hidden;
  margin-top:5%;
  margin-bottom:5%;
  animation: right_animation 75s linear infinite;
}

@keyframes left_animation {

  0%  {background-position: 0 0;}
  100%  {background-position: -2122px 0;}
}

@keyframes right_animation {

  0%  {background-position: 0 0;}
  100%  {background-position: 2122px 0;}
}

div.super_cadreFF_a {
  background-image: url(/static/media/CadreRecVa00.b4f030c5.webp);
  background-size: 100% 100%;
}

div.super_cadreFF_b {
  background-image: url(/static/media/CadreRecVb00.55ec4804.webp);
  background-size: 100% 100%;
}

div.super_cadreFF_c {
  background-image: url(/static/media/CadreRecVc00.59bb3bff.webp);
  background-size: 100% 100%;
}

div.super_cadreFF_d {
  background-image: url(/static/media/CadreRecVd00.581fcd50.webp);
  background-size: 100% 100%;
}

.cadreFF {
  position: relative;
  padding: 0;
  width: 100%;
  transition: all 0.2s;
  float: left;
}

.front_FF {
  position: relative;
  overflow: hidden;
  top: 0;
  left: 0;
  margin: 0;
  padding: 0;
  text-align: left;
  -webkit-backface-visibility: hidden;
          backface-visibility: hidden;
  transform-style: preserve3d;
  transition: all 0.4s;
  width: 100%;
}

.back_FF {
  position: absolute;
  overflow: hidden;
  top: 0;
  left: 0;
  margin: 0;
  padding: 0;
  text-align: left;
  -webkit-backface-visibility: hidden;
          backface-visibility: hidden;
  transform-style: preserve3d;
  transition: all 0.4s;
  width: 100%;
}

.front_FF img,
.back_FF img {
  width: 100%;
}

.back_FF {
  transform: rotateY(-180deg);
}

.flippedFF .back_FF {
  transform: rotateY(0deg);
}

.flippedFF .front_FF {
  transform: rotateY(180deg);
}

.cadreFF .rondFF {
  position: absolute;
  transition: 0.5s all ease-in-out;
  width: 24%;
  height: 12%;
  top: -500%;
  left: 5%;
  border-radius: 50%;
  background-image: url(/static/media/000DeuxFleches00_01.8ee2138b.webp);
  background-size: cover;
}

.cadreFF:hover .rondFF {
  display: block;
  top: 5%;
  left: 5%;
}

.cadreFF .rondFF:hover {
  cursor: grab;
  transform: rotate(-180deg);
}

div.txtFF_d {
  position: absolute;
  bottom: 1%;
  margin: 0;
  width: 100%;
  padding: 0;
  border: 0;
  text-align: center;
  opacity: 0;
}

.cadreFF:hover .txtFF_d {
  opacity: 1;
}

h2.txtFF_h2 {
  font-family: 'Fjalla One', sans-serif;
  border: solid 0.3em #000000;
  border-radius: 1.5em;
  line-height: normal;
  background-color: #000000;
  font-size: 1.8em;
}

@media screen and (max-width: 1258px) {
  h2.txtFF_h2 {
    font-size: 1.4em;
  }
}

@media screen and (max-width: 1024px) {
  h2.txtFF_h2 {
    font-size: 1.7em;
  }
}

@media screen and (max-width: 812px) {
  h2.txtFF_h2 {
    font-size: 12em;
  }
}

@media screen and (max-width: 480px) {
  h2.txtFF_h2 {
    font-size: 7em;
  }
}



div.cadre_out1 {
  left:-200%;
  top:-200%;
	position: relative;
	margin: 3% 1% 5% 1%;
	float: left;
	transition: all 2s ease-out;
}

div.cadre_out2 {
  left:-200%;
  top:200%;
	position: relative;
	margin: 3% 1% 5% 1%;
	float: left;
	transition: all 2s ease-out;
}

div.cadre_out3 {
  left:200%;
  top:-200%;
	position: relative;
	margin: 3% 1% 5% 1%;
	float: left;
	transition: all 2s ease-out;
}

div.cadre_out4 {
  left:200%;
  top:200%;
	position: relative;
	margin: 3% 1% 5% 1%;
	float: left;
	transition: all 2s ease-out;
}

div.super_cadre11{
    background-image: url(/static/media/CadreRecP00.c37e9b22.webp);
    background-size:100% 100%;
    margin-right:20%
  }
  
  div.cadre11{
    float:left;
    position: relative;
    width: 100%;
    padding: 0;
    background:white;
  }
  
  div.cadre11 .imgvide11{
    width:100%;
  }
  
  div.cadre11 .cadre111{
    cursor: pointer;
    float: left;
    overflow: hidden;
   /* position: absolute;*/
    margin: 0px;
    padding: 0px;
    top:0;
    height:100%;
    width:100%;
  }
  
  div.cadre11 .cadre111 .cadre_img11{
    cursor: pointer;
    float: left;
    overflow: hidden;
    position: relative;
    margin: 0px;
    padding: 0px;
    top:0;
    height:100%;
    width:16.66%;
    transition: all 0.5s ease;
    background-color:white;
  }
  
  h2.h2_txt11 {
    font-size: 2em;
    text-transform: uppercase;
    font-family: 'Fjalla One', sans-serif;
    text-align: left;
    margin: 0%;
    padding: 0.5%;
    color: #000000;
    width:95%;
    line-height: normal;
  }

  img.image_logo11{
    float: left;
    width:15%;
  }
  
  @media screen and (max-width: 1258px) {
    h2.h2_txt11 {
      font-size: 3.0em;
      text-transform: uppercase;
      line-height: normal;
    }

    img.image_logo11{
        float: left;
        width:8%;
        margin:2%
      }
  }
  
  @media screen and (max-width: 1024px) {
    h2.h2_txt11 {
      font-size: 3.0em;
      text-transform: uppercase;
      line-height: normal;
    }

    img.image_logo11{
        float: left;
        width:12%;
      }
  }
  
  @media screen and (max-width: 812px) {
    h2.h2_txt11 {
      font-size: 5em;
      text-transform: uppercase;
      line-height: normal;
    }

    img.image_logo11{
        float: left;
        width:15%;
      }
  }
  
  
  @media screen and (max-width: 480px) {
    h2.h2_txt11 {
      font-size: 7.8em;
      text-transform: uppercase;
      line-height: normal;
    }

    img.image_logo11{
        float: left;
        width:15%;
        margin:1%;
        padding:0;
      }
  }
  

  
  
  
div.super_cadre00a{
background-image: url(/static/media/900_CadreRecAnim0a.0b470082.webp);
background-size:100% 100%;
}

div.super_cadre00b{
background-image: url(/static/media/900_CadreRecAnim0b.9d7200de.webp);
background-size:100% 100%;
}
/*font-family: 'Mochiy Pop One', sans-serif;*/
/*font-family: 'Gloria Hallelujah', cursive;*/
/*font-family:'Acme', sans-serif;*/
/*font-family: 'Pacifico', cursive;*/
/*font-family: 'Fjalla One', sans-serif;*/
/*font-family: 'Dosis', sans-serif;*/
/*font-family: 'Square Peg', cursive;*/
/*font-family: 'Edu NSW ACT Foundation', cursive;*/
/*font-family: 'Carrois Gothic SC', sans-serif;*/
/*font-family: 'League Gothic', sans-serif;*/


header.heade000 {
  font-family: 'Fjalla One', sans-serif;
  font-size: 0.5vw;
}

h2 {
  font-family: 'Fjalla One', sans-serif;
  font-size: 1.1vw;
}


@media screen and (max-width: 1250px) {

  header.heade000 {
    font-size: 0.95vw;
  }

  h2 {
    font-family: 'Fjalla One', sans-serif;
    font-size: 2.3vw;
  }
}

@media screen and (max-width: 512px) {
  header.heade000 {
    font-size: 1.6vw;
  }

  h2 {
    font-size: 3.5vw;
    line-height: 2.9vw;
  }
}

.buttonH000 {
  font-family: 'Fjalla One', sans-serif;
  -webkit-appearance: none;
  appearance: none;
  transition: background-color 0.2s ease-in-out, color 0.2s ease-in-out;
  border-radius: 2px;
  border: 0;
  cursor: pointer;
  display: inline-block;
  font-weight: 300;
  height: 2.85em;
  line-height: 3em;
  padding: 0 2.5em;
  text-align: center;
  text-decoration: none;
  white-space: nowrap;
}

.buttonH000.alt {
  background: #FFF;
  color: #feb914;
}

.buttonH000.big {
  font-size: 2.8em;
}

header.heade000 {
  transition: background 1s ease-in-out, height 0.2s ease-in-out;
  display: -moz-flex;
  display: -ms-flex;
  display: flex;
  -moz-justify-content: center;
  -ms-justify-content: center;
  justify-content: center;
  -moz-align-items: center;
  -ms-align-items: center;
  align-items: center;
  display: -ms-flexbox;
  -ms-flex-align: center;
  -ms-flex-pack: center;
  background: rgba(254, 254, 254, 0.85);
  position: fixed;
  top: 0;
  width: 100%;
  height: 100vh;
  z-index: 10001;
  padding: 0;
  margin: 0;
  border: 0;
}

header.heade000 .inner {
  transition: height 0.2s ease-in-out, width 0.2s ease-in-out;
  display: -moz-flex;
  display: -ms-flex;
  display: flex;
  -moz-flex-direction: column;
  flex-direction: column;
  -moz-justify-content: center;
  -ms-justify-content: center;
  justify-content: center;
  -moz-align-items: center;
  -ms-align-items: center;
  align-items: center;
  display: -ms-flexbox;
  -ms-flex-align: center;
  -ms-flex-pack: center;
  background: #feb914;
  border-radius: 100%;
  width: 45em;
  height: 45em;
  padding: 4em;
  text-align: center;
  box-shadow: 0 0 0 1em #FFF;
  cursor: default;
}

header.heade000 .inner .content {
  transition: opacity 1s ease-in-out;
  opacity: 1;
}

header.heade000 .inner .content h1 {
  font-size: 4em;
  font-family: 'Fjalla One', sans-serif;
  margin: 0 0 .25em 0;
  color: #fff;
}

header.heade000 .inner .content h2 {
  font-size: 2.5em;
  color: #fff;
  margin: 0 0 1em 0;
  line-height: 1.5em;
}

header.heade000 .inner .buttonH000 {
  font-family: 'Fjalla One', sans-serif;
  transition: background 1s ease-in-out;
  margin: 0;
  border-radius: 30px;
}

header.heade000 .inner .buttonH000.hidden {
  display: none;
  cursor: pointer;
}

header.heade000 .inner.hidden {
  display: none;
}

header.hide,
header.preview {
  transition: background 0.2s ease-in-out, height 2s ease-in-out;
  background: none;
  height: 0;
}

header.hide .inner,
header.preview .inner {
  height: 3em;
  width: 3em;
  padding: 2em;
}

header.hide .inner .content,
header.preview .inner .content {
  opacity: 0;
}

header.hide .inner .content h1,
header.preview .inner .content h1 {
  display: none;
}

header.hide .inner .content h2,
header.preview .inner .content h2 {
  display: none;
}

header.hide .buttonH000.big,
header.preview .buttonH000.big {
  display: none;
}

header.hide .buttonH000.hidden,
header.preview .buttonH000.hidden {
  text-decoration: none;
  padding-top: 1.5em;
  padding-left: 0;
  padding-right: 0;
  background: none;
  display: inline-block;
}

header.hide .buttonH000.hidden:before,
header.preview .buttonH000.hidden:before {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: FontAwesome;
  font-family: 'Fjalla One', sans-serif;
  font-style: normal;
  font-weight: normal;
  text-transform: none !important;
}

header.hide .buttonH000.hidden:before,
header.preview .buttonH000.hidden:before {
  content: '\f015';
  color: #FFF;
  font-size: 1.5em;
}

header.hide .buttonH000.hidden span,
header.preview .buttonH000.hidden span {
  display: none;
}


img.icone_change {
  width: 18px;
  height: 18px;
  margin-top: 15px;
}


p.ceel_hide {
  Visibility: hidden;
  height: 1px;
  margin: 0px;
  padding: 0px;
}

div.content div.content2 {
  padding: 1%;
  margin: 0;
  color: #fff;
  font-family: 'Fjalla One', sans-serif;
  line-height: 2em;
  font-size: 2.5em;
}

div.cadreIY_v1 {
    position: relative;
    width:33%;
    margin: 0 auto;
    animation: animIV_VersaillesIY_2 1s infinite;
    background-size: cover;
 }

 div.cadreIY_v2 {
    position: relative;
    width:33%;
    margin: 0 auto;
    animation: animIV_VersaillesIY_3 1s infinite;
    background-size: cover;
 }

 div.cadreIY_pl {
    position:absolute;
    width:1%;
    height:1%;
    left:0%;
    top:0%;
    background-image:url(/static/media/BoutonIV.7756bf0e.webp),
    url(/static/media/BoutonIVactif.feec2d06.webp),
    url(/static/media/JardinVersailles03_montagne_01a.191b3eac.webp),
    url(/static/media/JardinVersailles03_montagne_01b.321da64e.webp),
    url(/static/media/JardinVersailles03_montagne_02a.c97c5788.webp),
    url(/static/media/JardinVersailles03_montagne_02b.45cf6f15.webp),
    url(/static/media/JardinVersailles03_montagne_02c.23bf6571.webp),
    url(/static/media/JardinVersailles03_montagne_02d.176a2fa8.webp),
    url(/static/media/JardinVersailles03_montagne_02e.5d83655e.webp),
    url(/static/media/JardinVersailles03_montagne_02f.59752c86.webp),
    url(/static/media/JardinVersailles03_montagne_02g.a75abdd5.webp),
    url(/static/media/JardinVersailles03_montagne_02h.3a223cff.webp),
    url(/static/media/JardinVersailles03_montagne_03a.b9899117.webp),
    url(/static/media/JardinVersailles03_montagne_03b.cdff55be.webp),
    url(/static/media/JardinVersailles03_montagne_03c.14df0ffd.webp),
    url(/static/media/JardinVersailles03_montagne_03d.1df11bf2.webp),
    url(/static/media/JardinVersailles03_montagne_03e.f4625650.webp),
    url(/static/media/JardinVersailles03_montagne_03f.59eb9992.webp),
    url(/static/media/JardinVersailles03_montagne_03g.a6328a94.webp),
    url(/static/media/JardinVersailles03_montagne_03h.683941fc.webp);
    background-position: 100% 100%;
  }
  
  img.imgFondIY{
    width:100%;
 }
 
 div.cadreIY9{
position:absolute;
height:100%;
width:50%;
top:0;
animation: animIV_VersaillesIY_1 2s infinite;
background-size: cover;
 }

 div.cadreIY9b{
    position:absolute;
    height:12%;
    width:12%;
    top:44%;
    left:44%;
    background-image: url(/static/media/BoutonIV.7756bf0e.webp); 
    background-size: 100% 100%;
 }

 div.cadreIY9b:hover{
    position:absolute;
    height:12%;
    width:12%;
    top:44%;
    left:44%;
    background-image: url(/static/media/BoutonIVactif.feec2d06.webp); 
    
 }

 @keyframes animIV_VersaillesIY_1 {
    0% {
      background-image: url(/static/media/JardinVersailles03_montagne_01a.191b3eac.webp);
    }
  
    25% {
      background-image: url(/static/media/JardinVersailles03_montagne_01b.321da64e.webp);
    }
  
    50% {
      background-image: url(/static/media/JardinVersailles03_montagne_01a.191b3eac.webp);
    }
  
    75% {
      background-image: url(/static/media/JardinVersailles03_montagne_01b.321da64e.webp);
    }
  
    100% {
      background-image: url(/static/media/JardinVersailles03_montagne_01a.191b3eac.webp);
    }
  }
  
  @keyframes animIV_VersaillesIY_2 {
    0% {
      background-image: url(/static/media/JardinVersailles03_montagne_02a.c97c5788.webp);
    }
  
    12.5% {
      background-image: url(/static/media/JardinVersailles03_montagne_02b.45cf6f15.webp);
    }
  
    25% {
      background-image: url(/static/media/JardinVersailles03_montagne_02c.23bf6571.webp);
    }
  
    37.5% {
      background-image: url(/static/media/JardinVersailles03_montagne_02d.176a2fa8.webp);
    }
  
    50% {
      background-image: url(/static/media/JardinVersailles03_montagne_02e.5d83655e.webp);
    }
  
    62.5% {
      background-image: url(/static/media/JardinVersailles03_montagne_02f.59752c86.webp);
    }
  
    75% {
      background-image: url(/static/media/JardinVersailles03_montagne_02g.a75abdd5.webp);
    }
  
    87.5% {
      background-image: url(/static/media/JardinVersailles03_montagne_02h.3a223cff.webp);
    }
  
    100% {
      background-image: url(/static/media/JardinVersailles03_montagne_02a.c97c5788.webp);
    }
  }
  
  @keyframes animIV_VersaillesIY_3 {
    0% {
      background-image: url(/static/media/JardinVersailles03_montagne_03a.b9899117.webp);
    }
  
    12.5% {
      background-image: url(/static/media/JardinVersailles03_montagne_03b.cdff55be.webp);
    }
  
    25% {
      background-image: url(/static/media/JardinVersailles03_montagne_03c.14df0ffd.webp);
    }
  
    37.5% {
      background-image: url(/static/media/JardinVersailles03_montagne_03d.1df11bf2.webp);
    }
  
    50% {
      background-image: url(/static/media/JardinVersailles03_montagne_03e.f4625650.webp);
    }
  
    62.5% {
      background-image: url(/static/media/JardinVersailles03_montagne_03f.59eb9992.webp);
    }
  
    75% {
      background-image: url(/static/media/JardinVersailles03_montagne_03g.a6328a94.webp);
    }
  
    87.5% {
      background-image: url(/static/media/JardinVersailles03_montagne_03h.683941fc.webp);
    }
  
    100% {
      background-image: url(/static/media/JardinVersailles03_montagne_03a.b9899117.webp);
    }
  }
div.cadreER {
  position: relative;
  width: 33%;
  margin: 0 auto;
  background-color: black;
}

@media screen and (max-width: 1250px) {
  div.cadreER {
    width: 66%;
  }
}

@media screen and (max-width: 512px) {
  div.cadreER {
    width: 100%;
  }
}

div.cadreER3b {
  width: 90%;
  position: absolute;
  border-radius: 50%;
  overflow: hidden;
  top: 0;
  left: 0;
}

div.cadreER3a {
  top: 0;
  left: 90%;
  position: absolute;
  width: 10%;
}

div.cadreER3aa {
  position: relative;
  float: left;
  width: 80%;
  margin: 10%;
  margin-top: 35%
}

img.imgFondER {
  width: 100%;
}

div.cadreER_pl {
  position: absolute;
  width: 1%;
  height: 1%;
  left: 0%;
  top: 0%;
  background-image:  url(/static/media/ExodeRurale_VacheOr01.1533b8b3.webp),
    url(/static/media/ExodeRurale_Vegan01.a1729e60.webp),
    url(/static/media/ExodeRurale_Volaille01.d2f3c9ca.webp),
    url(/static/media/ExodeRurale_VacheArgent01.d25cbf4c.webp),
    url(/static/media/ExodeRurale_Vegan02.158a2e8f.webp),
    url(/static/media/ExodeRurale_Volaille02.ef4a0eac.webp),
    url(/static/media/ExodeRurale_Cochon01.81943e11.webp),
    url(/static/media/ExodeRurale_Vegan03.ffb55aff.webp),
    url(/static/media/ExodeRurale_Volaille03.6b4749ec.webp),
    url(/static/media/ExodeRurale_Volaille04.dc3d8eea.webp),
    url(/static/media/ExodeRurale_Vegan04.c201cd80.webp),
    url(/static/media/ExodeRurale_Titre01.678f0ca7.webp),
    url(/static/media/ExodeRurale_SoucoupeA01.d47c6404.webp),
    url(/static/media/ExodeRurale_SoucoupeB01.a9afaa9e.webp),
    url(/static/media/ExodeRurale_Rayon00.72d85be8.webp),
    url(/static/media/ExodeRurale_Bouton_Vegan01.fb83fde1.webp),
    url(/static/media/ExodeRurale_Bouton_Cochon01.3b13a899.webp),
    url(/static/media/ExodeRurale_Bouton_Vache01.905c9b25.webp),
    url(/static/media/ExodeRurale_Bouton_Gens01.4cc34941.webp);
  background-position: 100% 100%;
}


div.eltER_soucoupe01 {
  position: absolute;
  width: 100%;
  height: 100%;
  animation: animER_soucoupe01 20s linear infinite;
}

div.eltER_soucoupe_luz01 {
  position: relative;
  width: 100%;
  height: 100%;
  animation: animER_soucoupe_luz01 1s linear infinite;
}

div.eltER_rayon01 {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  animation: animER_rayon01 20s linear infinite;
}

div.eltER_vache01 {
  position: absolute;
  background-image: url(/static/media/ExodeRurale_VacheOr01.1533b8b3.webp);
  background-size: contain;
  animation: animER_mvt01 20s linear infinite;
}

div.eltER_vegan01 {
  position: absolute;
  background-image: url(/static/media/ExodeRurale_Vegan01.a1729e60.webp);
  background-size: contain;
  animation: animER_mvt01 20s linear infinite;
}

div.eltER_volaille01 {
  position: absolute;
  background-image: url(/static/media/ExodeRurale_Volaille01.d2f3c9ca.webp);
  background-size: contain;
  animation: animER_mvt01 20s linear infinite;
}

div.eltER_vache02 {
  position: absolute;
  background-image: url(/static/media/ExodeRurale_VacheArgent01.d25cbf4c.webp);
  background-size: contain;
  animation: animER_mvt02 20s linear infinite;
}

div.eltER_vegan02 {
  position: absolute;
  background-image: url(/static/media/ExodeRurale_Vegan02.158a2e8f.webp);
  background-size: contain;
  animation: animER_mvt02 20s linear infinite;
}

div.eltER_volaille02 {
  position: absolute;
  background-image: url(/static/media/ExodeRurale_Volaille02.ef4a0eac.webp);
  background-size: contain;
  animation: animER_mvt02 20s linear infinite;
}

div.eltER_cochon03 {
  position: absolute;
  background-image: url(/static/media/ExodeRurale_Cochon01.81943e11.webp);
  background-size: contain;
  animation: animER_mvt03 20s linear infinite;
}

div.eltER_vegan03 {
  position: absolute;
  background-image: url(/static/media/ExodeRurale_Vegan03.ffb55aff.webp);
  background-size: contain;
  animation: animER_mvt03 20s linear infinite;
}

div.eltER_volaille03 {
  position: absolute;
  background-image: url(/static/media/ExodeRurale_Volaille03.6b4749ec.webp);
  background-size: contain;
  animation: animER_mvt03 20s linear infinite;
}

div.eltER_gens04 {
  position: absolute;
  background-image: url(/static/media/ExodeRurale_Gens01.2cea29f3.webp);
  background-size: contain;
  animation: animER_mvt04 20s linear infinite;
}

div.eltER_vegan04 {
  position: absolute;
  background-image: url(/static/media/ExodeRurale_Vegan04.c201cd80.webp);
  background-size: contain;
  animation: animER_mvt04 20s linear infinite;
}

div.eltER_volaille04 {
  position: absolute;
  background-image: url(/static/media/ExodeRurale_Volaille04.dc3d8eea.webp);
  background-size: contain;
  animation: animER_mvt04 20s linear infinite;
}

div.eltER_titre01 {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url(/static/media/ExodeRurale_Titre01.678f0ca7.webp);
  background-size: contain;
  position: absolute;
  animation: animER_titre01 20s linear infinite;
}

img.imgER_act {
  float: left;
  width: 25%;
}

@keyframes animER_soucoupe01 {
  0% {
    top: 0;
    left: 100%;
  }

  3% {
    top: 0;
    left: 100%;
  }

  5% {
    top: 0;
    left: 0;
  }

  95% {
    top: 0;
    left: 0;
  }

  97% {
    top: 0;
    left: 100%;
  }

  100% {
    top: 0;
    left: 100%;
  }
}

@keyframes animER_soucoupe_luz01 {
  0% {
    background-image: url(/static/media/ExodeRurale_SoucoupeA01.d47c6404.webp);
    background-size: contain;
  }

  100% {
    background-image: url(/static/media/ExodeRurale_SoucoupeB01.a9afaa9e.webp);
    background-size: contain;
  }
}

@keyframes animER_rayon01 {
  8% {
    background-image: none;
  }

  9% {
    background-image: url(/static/media/ExodeRurale_Rayon00.72d85be8.webp);
    background-size: contain;
  }

  91% {
    background-image: url(/static/media/ExodeRurale_Rayon00.72d85be8.webp);
    background-size: contain;
  }

  92% {
    background-image: none;
  }
}

@keyframes animER_mvt01 {
  0% {
    width: 100%;
    height: 100%;
    top: 100%;
    right: 100%;
    opacity: 0;
  }

  1% {
    width: 100%;
    height: 100%;
    top: 100%;
    right: 100%;
    opacity: 1;
  }

  22% {
    width: 33%;
    height: 33%;
    top: 22%;
    right: 22%;
    opacity: 1;
  }

  23% {
    width: 15%;
    height: 15%;
    top: 22%;
    right: 22%;
    opacity: 0;
  }

  24% {
    width: 100%;
    height: 100%;
    top: 100%;
    right: 100%;
    opacity: 0;
  }

  25% {
    width: 100%;
    height: 100%;
    top: 100%;
    right: 100%;
    opacity: 1;
  }

  46% {
    width: 33%;
    height: 33%;
    top: 22%;
    right: 22%;
    opacity: 1;
  }

  47% {
    width: 15%;
    height: 15%;
    top: 22%;
    right: 22%;
    opacity: 0;
  }

  48% {
    width: 100%;
    height: 100%;
    top: 100%;
    right: 100%;
    opacity: 0;
  }

  49% {
    width: 100%;
    height: 100%;
    top: 100%;
    right: 100%;
    opacity: 1;
  }

  70% {
    width: 33%;
    height: 33%;
    top: 22%;
    right: 22%;
    opacity: 1;
  }

  71% {
    width: 15%;
    height: 15%;
    top: 22%;
    right: 22%;
    opacity: 0;
  }

  100% {
    width: 15%;
    height: 15%;
    top: 100%;
    right: 100%;
    opacity: 0;
  }
}

@keyframes animER_mvt02 {
  0% {
    width: 100%;
    height: 100%;
    top: 100%;
    right: 100%;
    opacity: 0;
  }

  8% {
    width: 100%;
    height: 100%;
    top: 100%;
    right: 100%;
    opacity: 0;
  }

  9% {
    width: 100%;
    height: 100%;
    top: 100%;
    right: 100%;
    opacity: 1;
  }

  30% {
    width: 33%;
    height: 33%;
    top: 22%;
    right: 22%;
    opacity: 1;
  }

  31% {
    width: 15%;
    height: 15%;
    top: 22%;
    right: 22%;
    opacity: 0;
  }

  32% {
    width: 100%;
    height: 100%;
    top: 100%;
    right: 100%;
    opacity: 0;
  }

  33% {
    width: 100%;
    height: 100%;
    top: 100%;
    right: 100%;
    opacity: 1;
  }

  54% {
    width: 33%;
    height: 33%;
    top: 22%;
    right: 22%;
    opacity: 1;
  }

  55% {
    width: 15%;
    height: 15%;
    top: 22%;
    right: 22%;
    opacity: 0;
  }

  56% {
    width: 100%;
    height: 100%;
    top: 100%;
    right: 100%;
    opacity: 0;
  }

  57% {
    width: 100%;
    height: 100%;
    top: 100%;
    right: 100%;
    opacity: 1;
  }

  78% {
    width: 33%;
    height: 33%;
    top: 22%;
    right: 22%;
    opacity: 1;
  }

  79% {
    width: 15%;
    height: 15%;
    top: 22%;
    right: 22%;
    opacity: 0;
  }

  100% {
    width: 15%;
    height: 15%;
    top: 100%;
    right: 100%;
    opacity: 0;
  }
}

@keyframes animER_mvt03 {
  0% {
    width: 100%;
    height: 100%;
    top: 100%;
    right: 100%;
    opacity: 0;
  }

  16% {
    width: 100%;
    height: 100%;
    top: 100%;
    right: 100%;
    opacity: 0;
  }

  17% {
    width: 100%;
    height: 100%;
    top: 100%;
    right: 100%;
    opacity: 1;
  }

  38% {
    width: 33%;
    height: 33%;
    top: 22%;
    right: 22%;
    opacity: 1;
  }

  39% {
    width: 15%;
    height: 15%;
    top: 22%;
    right: 22%;
    opacity: 0;
  }

  64% {
    width: 100%;
    height: 100%;
    top: 100%;
    right: 100%;
    opacity: 0;
  }

  65% {
    width: 100%;
    height: 100%;
    top: 100%;
    right: 100%;
    opacity: 1;
  }

  86% {
    width: 33%;
    height: 33%;
    top: 22%;
    right: 22%;
    opacity: 1;
  }

  87% {
    width: 15%;
    height: 15%;
    top: 22%;
    right: 22%;
    opacity: 0;
  }

  100% {
    width: 15%;
    height: 15%;
    top: 100%;
    right: 100%;
    opacity: 0;
  }
}

@keyframes animER_mvt04 {
  0% {
    width: 100%;
    height: 100%;
    top: 100%;
    right: 100%;
    opacity: 0;
  }

  40% {
    width: 100%;
    height: 100%;
    top: 100%;
    right: 100%;
    opacity: 0;
  }

  41% {
    width: 100%;
    height: 100%;
    top: 100%;
    right: 100%;
    opacity: 1;
  }

  62% {
    width: 33%;
    height: 33%;
    top: 22%;
    right: 22%;
    opacity: 1;
  }

  63% {
    width: 15%;
    height: 15%;
    top: 22%;
    right: 22%;
    opacity: 0;
  }

  100% {
    width: 15%;
    height: 15%;
    top: 100%;
    right: 100%;
    opacity: 0;
  }
}

@keyframes animER_titre01 {
  0% {
    opacity: 1;
  }

  5% {
    opacity: 1;
  }

  7% {
    opacity: 0;
  }

  100% {
    opacity: 0;
  }
}

input[type='checkbox'] {
  display: none;
}

#button2a,
#button2b,
#button2c,
#button2d {
  display: block;
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: #00ff36;
  top: 0;
  left: 0;
  border-radius: 5em;
  cursor: pointer;
}

#knob_vegan01,
#knob_cochon01,
#knob_vache01,
#knob_gens01 {
  position: absolute;
  left: 0;
  width: 80%;
  height: auto;
  border-radius: 50%;
  margin: 10%;
  background: #ffffff;
  transition: 0.4s ease left, 0.4s ease background-position;
  z-index: 2;
}

#knob_vegan01 {
  background-image: url(/static/media/ExodeRurale_Bouton_Vegan01.fb83fde1.webp);
  background-size: 100%;
  background-position: 0% 100%;
  top: 50%;
}

#knob_cochon01 {
  background-image: url(/static/media/ExodeRurale_Bouton_Cochon01.3b13a899.webp);
  background-size: 100%;
  background-position: 0% 0%;
  top: 0;
}

#knob_vache01 {
  background-image: url(/static/media/ExodeRurale_Bouton_Vache01.905c9b25.webp);
  background-size: 100%;
  background-position: 0% 0%;
  top: 0;
}

#knob_gens01 {
  background-image: url(/static/media/ExodeRurale_Bouton_Gens01.4cc34941.webp);
  background-size: 100%;
  background-position: 0% 0%;
  top: 0;
}

#chkER_vegan01:checked+#button2d #knob_vegan01 {
  top: 0%;
  background-position: 0% 0%;
}

#chkER_cochon01:checked+#button2a #knob_cochon01 {
  top: 50%;
  background-position: 0% 100%;
}

#chkER_vache01:checked+#button2b #knob_vache01 {
  top: 50%;
  background-position: 0% 100%;
}

#chkER_gens01:checked+#button2c #knob_gens01 {
  top: 50%;
  background-position: 0% 100%;
}

div.cadreFS {
  position: relative;
  width: 33%;
  margin: 0 auto;
  background-color: black;
}


div.cadreFS5 {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}

div.cadreFS5_none {
  display: none;
}

div.cadreFS5_active {
  display: block;
}

img.imgFondFS {
  width: 100%;
}

div.eltFS_grue {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
}

div.eltFS_grue00 {
  position: relative;
  width: 100%;
  height:100%;
  display: none;
  background-repeat: no-repeat;
}

div.eltFS_grue01 {
  position: relative;
  width: 100%;
  height:100%;
  animation: anim25_grue_rte0 30s linear infinite;
  background-repeat: no-repeat;
}

div.eltFS_grue02 {
  position: relative;
  width: 100%;
  height:100%;
  animation: anim25_grue_rte0 30s linear infinite;
  background-repeat: no-repeat;
}

div.eltFS_grue03 {
  position: relative;
  width: 100%;
  height:100%;
  animation: anim25_grue_rte0 30s linear infinite;
  background-repeat: no-repeat;
}

div.eltFS_grue04 {
  position: relative;
  width: 100%;
  height:100%;
  animation: anim25_grue_rte0 30s linear infinite;
  background-repeat: no-repeat;
}

div.eltFS_grue05 {
  position: relative;
  width: 100%;
  height:100%;
  animation: anim25_grue_rte0 30s linear infinite;
  background-repeat: no-repeat;
}

div.cadreFS5_fuji01actif {
  position: absolute;
  left: 0;
  top: 0;
  width: 48%;
  height: 98%;
  background-image: url(/static/media/FondFujiSan001_01.c4d7d8cc.webp);
  background-size: cover;
  overflow: hidden;
  border-radius: 2em;
  margin: 1%;
  transition: all 2s ease;
}

div.cadreFS5_fuji01gauche {
  position: absolute;
  left: 0;
  top: 0;
  width: 10.5%;
  height: 98%;
  background-image: url(/static/media/FondFujiSan001_01.c4d7d8cc.webp);
  background-size: cover;
  overflow: hidden;
  border-radius: 2em;
  margin: 1%;
  transition: all 2s ease;
}

div.cadreFS5_fuji02gauche {
  position: absolute;
  left: 12.5%;
  top: 0;
  width: 10.5%;
  height: 98%;
  background-image: url(/static/media/FondFujiSan001_02.77fcd27f.webp);
  background-size: cover;
  overflow: hidden;
  border-radius: 2em;
  margin: 1%;
  transition: all 2s ease;
}

div.cadreFS5_fuji02actif {
  position: absolute;
  left: 12.5%;
  top: 0;
  width: 48%;
  height: 98%;
  background-image: url(/static/media/FondFujiSan001_02.77fcd27f.webp);
  background-size: cover;
  overflow: hidden;
  border-radius: 2em;
  margin: 1%;
  transition: all 2s ease;
}

div.cadreFS5_fuji02droite {
  position: absolute;
  left: 50%;
  top: 0;
  width: 10.5%;
  height: 98%;
  background-image: url(/static/media/FondFujiSan001_02.77fcd27f.webp);
  background-size: cover;
  overflow: hidden;
  border-radius: 2em;
  margin: 1%;
  transition: all 2s ease;
}

div.cadreFS5_fuji03gauche {
  position: absolute;
  left: 25%;
  top: 0;
  width: 10.5%;
  height: 98%;
  background-image: url(/static/media/FondFujiSan001_03.91f9560f.webp);
  background-size: cover;
  overflow: hidden;
  border-radius: 2em;
  margin: 1%;
  transition: all 2s ease;
}

div.cadreFS5_fuji03actif {
  position: absolute;
  left: 25%;
  top: 0;
  width: 48%;
  height: 98%;
  background-image: url(/static/media/FondFujiSan001_03.91f9560f.webp);
  background-size: cover;
  overflow: hidden;
  border-radius: 2em;
  margin: 1%;
  transition: all 2s ease;
}

div.cadreFS5_fuji03droite {
  position: absolute;
  left: 62.5%;
  top: 0;
  width: 10.5%;
  height: 98%;
  background-image: url(/static/media/FondFujiSan001_03.91f9560f.webp);
  background-size: cover;
  overflow: hidden;
  border-radius: 2em;
  margin: 1%;
  transition: all 2s ease;
}

div.cadreFS5_fuji04gauche {
  position: absolute;
  left: 37.5%;
  top: 0;
  width: 10.5%;
  height: 98%;
  background-image: url(/static/media/FondFujiSan001_04.df9bc653.webp);
  background-size: cover;
  overflow: hidden;
  border-radius: 2em;
  margin: 1%;
  transition: all 2s ease;
}

div.cadreFS5_fuji04actif {
  position: absolute;
  left: 37.5%;
  top: 0;
  width: 48%;
  height: 98%;
  background-image: url(/static/media/FondFujiSan001_04.df9bc653.webp);
  background-size: cover;
  overflow: hidden;
  border-radius: 2em;
  margin: 1%;
  transition: all 2s ease;
}

div.cadreFS5_fuji04droite {
  position: absolute;
  left: 75%;
  top: 0;
  width: 10.5%;
  height: 98%;
  background-image: url(/static/media/FondFujiSan001_04.df9bc653.webp);
  background-size: cover;
  overflow: hidden;
  border-radius: 2em;
  margin: 1%;
  transition: all 2s ease;
}

div.cadreFS5_fuji05actif {
  position: absolute;
  left: 50%;
  top: 0;
  width: 48%;
  height: 98%;
  background-image: url(/static/media/FondFujiSan001_05.871b00d7.webp);
  background-size: cover;
  overflow: hidden;
  border-radius: 2em;
  margin: 1%;
  transition: all 2s ease;
}

div.cadreFS5_fuji05droite {
  position: absolute;
  left: 87.5%;
  top: 0;
  width: 10.5%;
  height: 98%;
  background-image: url(/static/media/FondFujiSan001_05.871b00d7.webp);
  background-size: cover;
  overflow: hidden;
  border-radius: 2em;
  margin: 1%;
  transition: all 2s ease;
}

div.eltFS_grue_mvt0 {
  position: relative;
  width: 100%;
  height: 100%;
  animation: anim25_grue_mvt0 1s linear infinite;
  background-repeat: no-repeat;
}

@keyframes anim25_grue_mvt0 {
  0% {
    background-image: url(/static/media/PiafGrueJapMvt01.89eb2e24.webp);
    background-size: contain;
  }

  8% {
    background-image: url(/static/media/PiafGrueJapMvt01.89eb2e24.webp);
    background-size: contain;
  }

  17% {
    background-image: url(/static/media/PiafGrueJapMvt02.65ab5768.webp);
    background-size: contain;
  }

  33% {
    background-image: url(/static/media/PiafGrueJapMvt02.65ab5768.webp);
    background-size: contain;
  }

  42% {
    background-image: url(/static/media/PiafGrueJapMvt03.05ad5d6e.webp);
    background-size: contain;
  }

  58% {
    background-image: url(/static/media/PiafGrueJapMvt03.05ad5d6e.webp);
    background-size: contain;
  }

  67% {
    background-image: url(/static/media/PiafGrueJapMvt04.e12a6d24.webp);
    background-size: contain;
  }

  83% {
    background-image: url(/static/media/PiafGrueJapMvt04.e12a6d24.webp);
    background-size: contain;
  }

  92% {
    background-image: url(/static/media/PiafGrueJapMvt01.89eb2e24.webp);
    background-size: contain;
  }

  100% {
    background-image: url(/static/media/PiafGrueJapMvt01.89eb2e24.webp);
    background-size: contain;
  }
}

@keyframes anim25_grue_rte0 {
  0% {
    left: -120%;
    top: 30%;
    opacity: 0;
  }

  1% {
    opacity: 1;
  }

  32% {
    opacity: 1;
  }

  33% {
    left: 120%;
    top: 50%;
    opacity: 0;
  }

  34% {
    left: -120%;
    top: 20%;
    opacity: 0;
  }

  35% {
    opacity: 1;
  }

  65% {
    opacity: 1;
  }

  66% {
    left: 120%;
    top: 40%;
    opacity: 0;
  }

  67% {
    left: -120%;
    top: 10%;
    opacity: 0;
  }

  68% {
    opacity: 1;
  }

  99% {
    opacity: 1;
  }

  100% {
    left: 120%;
    top: 30%;
    opacity: 0;
  }
}

div.btFS {
  position: absolute;
  width: 9%;
  bottom: 1%;
  cursor: pointer;
  background-position: 0%;
  cursor: pointer;
}

div.btFS_active {
  cursor: pointer;
  position: absolute;
  width: 9%;
  bottom: 1%;
  background-position: 100%;
  cursor: pointer;
}

div.btFS1 {
  left: 2%;
  transition: all 2s ease;
}

div.btFS2g {
  left: 14%;
  transition: all 2s ease;
}

div.btFS2d {
  left: 52%;
  transition: all 2s ease;
}

div.btFS3g {
  left: 27%;
  transition: all 2s ease;
}

div.btFS3d {
  left: 64%;
  transition: all 2s ease;
}

div.btFS4g {
  left: 39.5%;
  transition: all 2s ease;
}

div.btFS4d {
  left: 76.5%;
  transition: all 2s ease;
}

div.btFS5g {
  left: 51%;
  transition: all 2s ease;
}

div.btFS5d {
  left: 89.5%;
  transition: all 2s ease;
}

#fuji01_b {
  background-image: url(/static/media/BoutonFS01_01.1f5bec2e.webp);
  background-size: cover;
}

#fuji02_b {
  background-image: url(/static/media/BoutonFS01_02.a2e26b1f.webp);
  background-size: cover;
}

#fuji03_b {
  background-image: url(/static/media/BoutonFS01_03.2a310a92.webp);
  background-size: cover;
}

#fuji04_b {
  background-image: url(/static/media/BoutonFS01_04.0db9d8b8.webp);
  background-size: cover;
}

#fuji05_b {
  background-image: url(/static/media/BoutonFS01_05.3a27e1b6.webp);
  background-size: cover;
}

.txtFS_fs {
  position: absolute;
  bottom: 0;
  left: 0;
  padding: 5%;
  padding-top: 10%;
  width: 85%;
  height: 42%;
  margin: 5%;
  top: 2%;
}

div.txtFS_fs::before {
  position: absolute;
  top: 50%;
  right: 20%;
  bottom: 50%;
  left: 5%;
  border-top: 0.5em solid rgba(255, 255, 255, 0.8);
  border-bottom: 0.5em solid rgba(255, 255, 255, 0.8);
  content: '';
  opacity: 0;
  background-color: #ffffff;
  transition: all 0.5s;
}

div.txtFS_fs h2 {
  text-transform: uppercase;
  color: white;
  font-family: 'Mochiy Pop One', sans-serif;
  font-family: 'Fjalla One', sans-serif;
  margin: 0;
  margin-top: 2%;
  margin-bottom: 2%;
  opacity: 0;
  font-weight: 100;
  text-transform: uppercase;
  transform: translate3d(0%, 50%, 0);
  transition: opacity 6s, transform 6s;
}

div.cadreFS5_fuji01actif:hover div.txtFS_fs h2,
div.cadreFS5_fuji02actif:hover div.txtFS_fs h2,
div.cadreFS5_fuji03actif:hover div.txtFS_fs h2,
div.cadreFS5_fuji04actif:hover div.txtFS_fs h2,
div.cadreFS5_fuji05actif:hover div.txtFS_fs h2 {
  opacity: 1;
  transform: translate3d(0%, 0%, 0);
  transition: opacity 6s, transform 6s;
}


div.txtFS_fs_active h2 {
  opacity: 1;
  transform: translate3d(0%, 0%, 0);
  transition: opacity 6s, transform 6s;
}

div.cadreFS5_fuji01actif:hover div.txtFS_fs::before,
div.cadreFS5_fuji02actif:hover div.txtFS_fs::before,
div.cadreFS5_fuji03actif:hover div.txtFS_fs::before,
div.cadreFS5_fuji04actif:hover div.txtFS_fs::before,
div.cadreFS5_fuji05actif:hover div.txtFS_fs::before {
  background: rgba(255, 255, 255, 0);
  top: 10%;
  bottom: 10%;
  opacity: 1;
}

div.txtFS_fs_active::before {
  background: rgba(255, 255, 255, 0);
  top: 10%;
  bottom: 10%;
  opacity: 1;
}

div.txtFS_fs h2 {
  font-size: 2.9em;
  font-weight: 200;
  text-transform: uppercase;
  color: white;
  font-family: 'Mochiy Pop One', sans-serif;
  font-family: 'Fjalla One', sans-serif;
}

@media screen and (max-width: 1258px) {
  div.txtFS_fs h2 {
    font-size: 2.5em;
    font-weight: 200;
    text-transform: uppercase;
    color: white;
    font-family: 'Mochiy Pop One', sans-serif;
  }
}

@media screen and (max-width: 1024px) {
  div.txtFS_fs h2 {
    font-size: 2.8em;
    font-weight: 200;
    text-transform: uppercase;
    color: white;
    font-family: 'Mochiy Pop One', sans-serif;
  }
}

@media screen and (max-width: 812px) {
  div.txtFS_fs h2 {
    font-size: 7em;
    font-weight: 200;
    text-transform: uppercase;
    color: white;
    font-family: 'Mochiy Pop One', sans-serif;
  }
}

@media screen and (max-width: 480px) {
  div.txtFS_fs h2 {
    font-size: 5.6em;
    font-weight: 200;
    text-transform: uppercase;
    color: white;
    font-family: 'Mochiy Pop One', sans-serif;
    line-height: normal;
  }
}
div.cadreFM {
  position: relative;
  width: 33%;
  margin: 0 auto;
  overflow: hidden;
  padding: 0%;
  border: 0;
  background-color: black;
}

div.cadreFM_pl {
  position: absolute;
  width: 1%;
  height: 1%;
  left: 0%;
  top: 0%;
  background-image: url(/static/media/FinDuMondeSymb_01.eab4de75.webp),
    url(/static/media/FinDuMondeSymb_02.a43ced9f.webp),
    url(/static/media/FinDuMondeSymb_03.e96985ab.webp),
    url(/static/media/FinDuMondeSymb_04.de6a9163.webp),
    url(/static/media/FinDuMondeSymb_05.d5e87f24.webp),
    url(/static/media/FinDuMondeSymb_06.2706324e.webp),
    url(/static/media/FinDuMondeSymb_07.3b47d192.webp),
    url(/static/media/FinDuMondeSymb_08.b7dc9ee3.webp),
    url(/static/media/FinDuMondeSymb_09.788b05ad.webp),
    url(/static/media/FinDuMondeSymb_10.f7616a34.webp),
    url(/static/media/FinDuMonde04_montage_ca_01.10c949a7.webp),
    url(/static/media/FinDuMonde04_montage_ca_02.4ea71470.webp),
    url(/static/media/FinDuMonde04_montage_ca_03.30985994.webp),
    url(/static/media/FinDuMonde04_montage_ca_04.38e15c5e.webp);
  background-position: 100% 100%;
}

img.imgFondFM {
  width: 100%;
}

.imgvideFM {
  width: 100%;
}

.imgvideFMa {
  position: relative;
  width: 100%;
}

div.cadreFM1 {
  padding: 0%;
  margin: 0;
  position: absolute;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
}

div.cadreFM1b {
  padding: 0%;
  margin: 0;
  color: #6d0909;
  position: absolute;
  text-align: center;
  width: 100%;
  height: 100%;
  top: 0%;
  left: 0%;
  background-color: rgba(0, 0, 0, 0.8);
}

div.cadreFM1 p {
  visibility: hidden;
  text-transform: uppercase;
  position: absolute;
}

div.cadreFM1bb {
  display: flex;
  text-align: center;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
}

div.cadreFM1bbb {
  display: flex;
  flex-direction: column;
  text-align: center;
  width: 100%;
}

div.cadreFM1bbb p {
  color: #043347;
}

.cadreFM .active {
  opacity: 1;
}

div.noFM1 {
  top: 0%;
  left: 0%;
  background-image: url(/static/media/FinDuMondeSymb_01.eab4de75.webp);
  background-size: contain;
  width: 20%;
  height: 20%;
}

div.noFM2 {
  top: 0%;
  left: 20%;
  background-image: url(/static/media/FinDuMondeSymb_02.a43ced9f.webp);
  background-size: contain;
  width: 30%;
  height: 20%;
}

div.noFM3 {
  top: 0%;
  left: 50%;
  background-image: url(/static/media/FinDuMondeSymb_03.e96985ab.webp);
  background-size: contain;
  width: 20%;
  height: 20%;
}

div.noFM4 {
  top: 0%;
  left: 70%;
  background-image: url(/static/media/FinDuMondeSymb_04.de6a9163.webp);
  background-size: contain;
  width: 30%;
  height: 30%;
}

div.noFM5 {
  top: 30%;
  left: 80%;
  background-image: url(/static/media/FinDuMondeSymb_05.d5e87f24.webp);
  background-size: contain;
  width: 20%;
  height: 40%;
}

div.noFM6 {
  top: 70%;
  left: 70%;
  background-image: url(/static/media/FinDuMondeSymb_06.2706324e.webp);
  background-size: contain;
  width: 30%;
  height: 30%;
}

div.noFM7 {
  top: 75%;
  left: 45%;
  background-image: url(/static/media/FinDuMondeSymb_07.3b47d192.webp);
  background-size: contain;
  width: 25%;
  height: 25%;
}

div.noFM8 {
  top: 75%;
  left: 25%;
  background-image: url(/static/media/FinDuMondeSymb_08.b7dc9ee3.webp);
  background-size: contain;
  width: 20%;
  height: 25%;
}

div.noFM9 {
  top: 70%;
  left: 0%;
  background-image: url(/static/media/FinDuMondeSymb_09.788b05ad.webp);
  background-size: contain;
  width: 25%;
  height: 30%;
}

div.noFM10 {
  top: 20%;
  left: 0%;
  background-image: url(/static/media/FinDuMondeSymb_10.f7616a34.webp);
  background-size: contain;
  width: 20%;
  height: 50%;
}

.cadreFM_msg {
  display: none;
  background: rgba(0, 0, 0, 0.3);
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.cadreFM3_msg {
  display: flex;
  text-align: center;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
}

.cadreFM33_msg {
  position: relative;
  width: 60%;
  margin: 20%;
}

div.cadreFM_ca {
  position: absolute;
  top: 0%;
  left: 0%;
  width: 100%;
  height: 100%;
  overflow: hidden;
  padding: 0%;
  border: 0;
  animation: animFM_ca 0.5s infinite;
}

@keyframes animFM_ca {
  0% {
    background-image: url(/static/media/FinDuMonde04_montage_ca_01.10c949a7.webp);
    background-size: cover;
  }

  5% {
    background-image: url(/static/media/FinDuMonde04_montage_ca_01.10c949a7.webp);
    background-size: cover;
  }

  20% {
    background-image: url(/static/media/FinDuMonde04_montage_ca_02.4ea71470.webp);
    background-size: cover;
  }

  30% {
    background-image: url(/static/media/FinDuMonde04_montage_ca_02.4ea71470.webp);
    background-size: cover;
  }

  55% {
    background-image: url(/static/media/FinDuMonde04_montage_ca_03.30985994.webp);
    background-size: cover;
  }

  65% {
    background-image: url(/static/media/FinDuMonde04_montage_ca_03.30985994.webp);
    background-size: cover;
  }

  70% {
    background-image: url(/static/media/FinDuMonde04_montage_ca_04.38e15c5e.webp);
    background-size: cover;
  }

  80% {
    background-image: url(/static/media/FinDuMonde04_montage_ca_04.38e15c5e.webp);
    background-size: cover;
  }

  95% {
    background-image: url(/static/media/FinDuMonde04_montage_ca_01.10c949a7.webp);
    background-size: cover;
  }

  100% {
    background-image: url(/static/media/FinDuMonde04_montage_ca_01.10c949a7.webp);
    background-size: cover;
  }
}

div.cadreSP {
    position: relative;
    width:33%;
    margin: 0 auto;
    overflow:hidden;
    padding:0%;
    border:0;
    background-color:black;
  }
  
  div.cadreSP_pl {
    position:absolute;
    width:1%;
    height:1%;
    left:0%;
    top:0%;
    background-image:url(/static/media/Montagn03_Montage_01a.57caebc6.webp),
    url(/static/media/Montagn03_Montage_01b.aefc9753.webp),
    url(/static/media/Montagn03_Montage_01c.89d04c1e.webp),
    url(/static/media/Montagn03_Montage_01d.f24cc457.webp),
    url(/static/media/Montagn03_Montage_01e.728615c8.webp),
    url(/static/media/Montagn03_Montage_01f.2f292b2d.webp),
    url(/static/media/Montagn03_Montage_01g.278b2213.webp),
    url(/static/media/Montagn03_Montage_01h.4f99a25b.webp),
    url(/static/media/Montagn03_Montage_01i.efd20dad.webp),
    url(/static/media/Montagn03_Montage_01j.6a7c9c1a.webp),
    url(/static/media/Montagn03_Montage_01k.c9bba11a.webp),
    url(/static/media/Montagn03_Montage_01l.dbca7867.webp),
    url(/static/media/Montagn03_Montage_01m.39855612.webp),
    url(/static/media/Montagn03_mn00.01abb99f.webp),
    url(/static/media/Montagn03_mn01.9eff7eaf.webp),
    url(/static/media/Montagn03_mh01.2bbcddc1.webp),
    url(/static/media/Montagn03_mn02.4fc6ace9.webp),
    url(/static/media/Montagn03_mh02.3e6fea84.webp),
    url(/static/media/Montagn03_mn03.a7ee1dc4.webp),
    url(/static/media/Montagn03_mh03.4bf34328.webp),
    url(/static/media/Montagn03_mn04.1b359ffb.webp),
    url(/static/media/Montagn03_mh04.a746512e.webp),
    url(/static/media/Montagn03_mn05.9c969777.webp),
    url(/static/media/Montagn03_mh05.723bc8ea.webp),
    url(/static/media/Montagn03_mn06.df8a9cd0.webp),
    url(/static/media/Montagn03_mh06.e5313a9e.webp);
    background-position: 100% 100%;
  }
  
  img.imgFondSP{
    width:100%;
  }
  
  div.cadreSP2 {
    position: absolute;
    top:0%;
    left:0%;
    width:100%;
    height:90%;
    margin: 0 auto;
    overflow:hidden;
    padding:0%;
    border:0;
  }
  
  div.pisteSP {
    display:none;
    position: absolute;
    top:0%;
    left:0%;
    width:100%;
    height:100%;
    overflow:hidden;
    padding:0%;
    border:0;
    animation: animSP_piste 1s 1;
  }
  
  @keyframes animSP_piste {
    0% { background-image:url(/static/media/Montagn03_Montage_01a.57caebc6.webp);background-size: cover; }
    7% { background-image:url(/static/media/Montagn03_Montage_01b.aefc9753.webp);background-size: cover; }
    15% { background-image:url(/static/media/Montagn03_Montage_01c.89d04c1e.webp);background-size: cover; }
    22% { background-image:url(/static/media/Montagn03_Montage_01d.f24cc457.webp);background-size: cover; }
    32% { background-image:url(/static/media/Montagn03_Montage_01e.728615c8.webp);background-size: cover; }
    36% { background-image:url(/static/media/Montagn03_Montage_01f.2f292b2d.webp);background-size: cover; }
    43% { background-image:url(/static/media/Montagn03_Montage_01g.278b2213.webp);background-size: cover; }
    50% { background-image:url(/static/media/Montagn03_Montage_01h.4f99a25b.webp);background-size: cover; }
    60% { background-image:url(/static/media/Montagn03_Montage_01i.efd20dad.webp);background-size: cover; }
    70% { background-image:url(/static/media/Montagn03_Montage_01j.6a7c9c1a.webp);background-size: cover; }
    80% { background-image:url(/static/media/Montagn03_Montage_01k.c9bba11a.webp);background-size: cover; }
    90% { background-image:url(/static/media/Montagn03_Montage_01l.dbca7867.webp);background-size: cover; }
    100% { background-image:url(/static/media/Montagn03_Montage_01m.39855612.webp);background-size: cover; }
  }
  
  div.neigeSP {
    display:none;
    position: absolute;
    top:0%;
    left:0%;
    width:100%;
    height:100%;
    overflow:hidden;
    padding:0%;
    border:0;
    animation: animSP_neige 7s 1;
  }
  
  @keyframes animSP_neige {
    0% { background-image:url(/static/media/Montagn02Neige.518e8067.webp);background-size: cover;top:100%; }
    30% { background-image:url(/static/media/Montagn02Neige.518e8067.webp);background-size: cover;top:100%; }
    60% { background-image:url(/static/media/Montagn02Neige.518e8067.webp);background-size: cover;top:-10%; }
    80% { background-image:url(/static/media/Montagn02Neige.518e8067.webp);background-size: cover;top:-10%; }
    100% { background-image:url(/static/media/Montagn02Neige.518e8067.webp);background-size: cover; top:100%;}
  }
  
  div.persoSP_01 {
    display:none;
    position: absolute;
    top:0%;
    left:0%;
    width:100%;
    height:100%;
    overflow:hidden;
    padding:0%;
    border:0;
    animation: animSP_perso01 7s 1 ease-in-out;
  }
  
  @keyframes animSP_perso01 {
    0% { background-image:url(/static/media/Montagn02Prso03_01b.a91466b4.webp);background-size: cover;top:100%; }
    20% { background-image:url(/static/media/Montagn02Prso03_01a.babb0e38.webp);background-size: cover;top:100%; }
    58% { background-image:url(/static/media/Montagn02Prso03_01a.babb0e38.webp);background-size: cover; }
    60% { background-image:url(/static/media/Montagn02Prso03_01b.a91466b4.webp);background-size: cover; top:0%;}
    89% { background-image:url(/static/media/Montagn02Prso03_01b.a91466b4.webp);background-size: cover; }
    91% { background-image:url(/static/media/Montagn02Prso03_01a.babb0e38.webp);background-size: cover;}
    100% { background-image:url(/static/media/Montagn02Prso03_01a.babb0e38.webp);background-size: cover; top:100%;}
  }
  
  div.persoSP_01c {
    position: absolute;
    top:0%;
    left:0%;
    width:100%;
    height:100%;
    overflow:hidden;
    padding:0%;
    border:0;
    animation: animSP_perso01c 7s 1 ease-in-out;
  }
  
  @keyframes animSP_perso01c {
    0% { background-image:url(/static/media/Montagn02Prso03_01c.852e7f53.webp);background-size: cover;top:100%; }
    50% { background-image:url(/static/media/Montagn02Prso03_01c.852e7f53.webp);background-size: cover;top:-20%; }
    70% { background-image:url(/static/media/Montagn02Prso03_01c.852e7f53.webp);background-size: cover;top:20%; }
    100% { background-image:url(/static/media/Montagn02Prso03_01c.852e7f53.webp);background-size: cover; top:100%;}
  }
  
  div.persoSP_02 {
    display:none;
    position: absolute;
    top:0%;
    left:0%;
    width:100%;
    height:100%;
    overflow:hidden;
    padding:0%;
    border:0;
    animation: animSP_perso02 7s 1 ease-in-out;
  }
  
  @keyframes animSP_perso02 {
    0% { background-image:url(/static/media/Montagn02Prso03_02b.6e496e34.webp);background-size: cover;top:100%;}
    20% { background-image:url(/static/media/Montagn02Prso03_02a.d206d934.webp);background-size: cover;top:100%; }
    58% { background-image:url(/static/media/Montagn02Prso03_02a.d206d934.webp);background-size: cover; }
    60% { background-image:url(/static/media/Montagn02Prso03_02b.6e496e34.webp);background-size: cover; top:0%;}
    89% { background-image:url(/static/media/Montagn02Prso03_02b.6e496e34.webp);background-size: cover; }
    91% { background-image:url(/static/media/Montagn02Prso03_02a.d206d934.webp);background-size: cover;}
    100% { background-image:url(/static/media/Montagn02Prso03_02a.d206d934.webp);background-size: cover; top:100%;}
  }
  
  div.persoSP_02c {
    position: absolute;
    top:0%;
    left:0%;
    width:100%;
    height:100%;
    overflow:hidden;
    padding:0%;
    border:0;
    animation: animSP_perso02c 7s 1 ease-in-out;
  }
  
  @keyframes animSP_perso02c {
    0% { background-image:url(/static/media/Montagn02Prso03_02c.6f3b74be.webp);background-size: cover;top:100%; }
    50% { background-image:url(/static/media/Montagn02Prso03_02c.6f3b74be.webp);background-size: cover;top:20%; }
    70% { background-image:url(/static/media/Montagn02Prso03_02c.6f3b74be.webp);background-size: cover;top:20%; }
    100% { background-image:url(/static/media/Montagn02Prso03_02c.6f3b74be.webp);background-size: cover; top:100%;}
  }
  
  div.persoSP_03 {
    display:none;
    position: absolute;
    top:0%;
    left:0%;
    width:100%;
    height:100%;
    overflow:hidden;
    padding:0%;
    border:0;
    animation: animSP_perso03 7s 1 ease-in-out;
  }
  
  @keyframes animSP_perso03 {
    0% { background-image:url(/static/media/Montagn02Prso03_03b.710b8597.webp);background-size: cover;top:100%; }
    20% { background-image:url(/static/media/Montagn02Prso03_03a.5f520d28.webp);background-size: cover;top:100%; }
    58% { background-image:url(/static/media/Montagn02Prso03_03a.5f520d28.webp);background-size: cover; }
    60% { background-image:url(/static/media/Montagn02Prso03_03b.710b8597.webp);background-size: cover; top:0%;}
    89% { background-image:url(/static/media/Montagn02Prso03_03b.710b8597.webp);background-size: cover; }
    91% { background-image:url(/static/media/Montagn02Prso03_03a.5f520d28.webp);background-size: cover;}
    100% { background-image:url(/static/media/Montagn02Prso03_03a.5f520d28.webp);background-size: cover; top:100%;}
  }
  
  div.persoSP_03c {
    position: absolute;
    top:0%;
    left:0%;
    width:100%;
    height:100%;
    overflow:hidden;
    padding:0%;
    border:0;
    animation: animSP_perso03c 7s 1 ease-in-out;
  }
  
  @keyframes animSP_perso03c {
    0% { background-image:url(/static/media/Montagn02Prso03_03c.84af5007.webp);background-size: cover;top:100%; }
    50% { background-image:url(/static/media/Montagn02Prso03_03c.84af5007.webp);background-size: cover;top:-10%; }
    70% { background-image:url(/static/media/Montagn02Prso03_03c.84af5007.webp);background-size: cover;top:-10%; }
    100% { background-image:url(/static/media/Montagn02Prso03_03c.84af5007.webp);background-size: cover; top:100%;}
  }
  
  div.persoSP_04 {
    display:none;
    position: absolute;
    top:0%;
    left:0%;
    width:100%;
    height:100%;
    overflow:hidden;
    padding:0%;
    border:0;
    animation: animSP_perso04 7s 1 ease-in-out;
  }
  
  @keyframes animSP_perso04 {
    0% { background-image:url(/static/media/Montagn02Prso03_04b.a8e2adf0.webp);background-size: cover;top:100%; }
    20% { background-image:url(/static/media/Montagn02Prso03_04a.41c120f9.webp);background-size: cover;top:100%; }
    58% { background-image:url(/static/media/Montagn02Prso03_04a.41c120f9.webp);background-size: cover; }
    60% { background-image:url(/static/media/Montagn02Prso03_04b.a8e2adf0.webp);background-size: cover; top:0%;}
    89% { background-image:url(/static/media/Montagn02Prso03_04b.a8e2adf0.webp);background-size: cover; }
    91% { background-image:url(/static/media/Montagn02Prso03_04a.41c120f9.webp);background-size: cover;}
    100% { background-image:url(/static/media/Montagn02Prso03_04a.41c120f9.webp);background-size: cover; top:100%;}
  }
  
  div.persoSP_05 {
    display:none;
    position: absolute;
    top:0%;
    left:0%;
    width:100%;
    height:100%;
    overflow:hidden;
    padding:0%;
    border:0;
    animation: animSP_perso05 7s 1 ease-in-out;
  }
  
  @keyframes animSP_perso05 {
    0% { background-image:url(/static/media/Montagn02Prso03_05b.89a4659f.webp);background-size: cover;top:100%; }
    20% { background-image:url(/static/media/Montagn02Prso03_05a.ad7af779.webp);background-size: cover;top:100%; }
    58% { background-image:url(/static/media/Montagn02Prso03_05a.ad7af779.webp);background-size: cover; }
    60% { background-image:url(/static/media/Montagn02Prso03_05b.89a4659f.webp);background-size: cover; top:0%;}
    89% { background-image:url(/static/media/Montagn02Prso03_05b.89a4659f.webp);background-size: cover; }
    91% { background-image:url(/static/media/Montagn02Prso03_05a.ad7af779.webp);background-size: cover;}
    100% { background-image:url(/static/media/Montagn02Prso03_05a.ad7af779.webp);background-size: cover; top:100%;}
  }
  
  div.persoSP_06 {
    display:none;
    position: absolute;
    top:0%;
    left:0%;
    width:100%;
    height:100%;
    overflow:hidden;
    padding:0%;
    border:0;
    animation: animSP_perso06 7s 1 ease-in-out;
  }
  
  @keyframes animSP_perso06 {
    0% { background-image:url(/static/media/Montagn02Prso03_06b.dc2a815d.webp);background-size: cover;top:100%; }
    20% { background-image:url(/static/media/Montagn02Prso03_06a.55780779.webp);background-size: cover;top:100%; }
    58% { background-image:url(/static/media/Montagn02Prso03_06a.55780779.webp);background-size: cover; }
    60% { background-image:url(/static/media/Montagn02Prso03_06b.dc2a815d.webp);background-size: cover; top:0%;}
    89% { background-image:url(/static/media/Montagn02Prso03_06b.dc2a815d.webp);background-size: cover; }
    91% { background-image:url(/static/media/Montagn02Prso03_06a.55780779.webp);background-size: cover;}
    100% { background-image:url(/static/media/Montagn02Prso03_06a.55780779.webp);background-size: cover; top:100%;}
  }
  
  div.cadreSP2m {
    position: absolute;
    top:90%;
    left:0%;
    width:100%;
    height:10%;
    padding:0%;
    border:0;
    background-image:url(/static/media/Montagn03_fondBarBt.7da4b26e.webp);background-size: cover;
  }
  
  div.btSPm {
    position: relative;
    float:left;
    width:16.5%;
    height:100%;
    padding:0%;
    border:0;
    cursor: pointer;
  }
  
  div.btSPmm {
    width: 50%;
    border-radius: 49.5%;
    height: 85%;
    background-color: red;
    margin-left: 25%;
    margin-right: 25%;
    margin-top: 5%;
    margin-bottom: 10%;
  }
  
  div.btSP_b01a{
    background-size:contain;
    background-image:url(/static/media/Montagn03_b01a.69980a2d.webp);
  }
  
  div.btSP_b02a{
    background-size:contain;
    background-image:url(/static/media/Montagn03_b02a.cd6bca37.webp);
  }
  
  div.btSP_b03a{
    background-size:contain;
    background-image:url(/static/media/Montagn03_b03a.39fe9f31.webp);
  }
  
  div.btSP_b04a{
    background-size:contain;
    background-image:url(/static/media/Montagn03_b04a.ec42b31f.webp);
  }
  
  div.btSP_b05a{
    background-size:contain;
    background-image:url(/static/media/Montagn03_b05a.3d63bf4d.webp);
  }
  
  div.btSP_b06a{
    background-size:contain;
    background-image:url(/static/media/Montagn03_b06a.245e5d49.webp);
  }
  
  
div.cadreMB {
  position: relative;
  width: 33%;
  margin: 0 auto;
  overflow: hidden;
  background-color: black;

}

div.cadreMB_pl {
  position: absolute;
  width: 1%;
  height: 1%;
  left: 0%;
  top: 0%;
  background-image: url(/static/media/BoutonLA01_01.0d4dcc3a.webp),
    url(/static/media/BoutonLA01_02.9530d5cb.webp),
    url(/static/media/BoutonLA01_03.aac4a275.webp),
    url(/static/media/BoutonLA01_04.54e30774.webp),
    url(/static/media/BoutonLA01_05.d78a2857.webp),
    url(/static/media/BoutonLA01_06.d3d7db27.webp),
    url(/static/media/FondKhops01_sol_mvt_01.623d29e5.webp),
    url(/static/media/FondKhops01_sol_mvt_02.63b530ac.webp),
    url(/static/media/FondKhops01_bug_01.aaa51e6b.webp),
    url(/static/media/FondKhops01_bug_02.1746980e.webp),
    url(/static/media/FondKhops01_bug_03.728cdf3c.webp),
    url(/static/media/FondKhops_Tornad_Tornad_01.f5db6336.webp),
    url(/static/media/FondKhops_Tornad_Tornad_02.60f55d17.webp),
    url(/static/media/FondKhops01_brum_brum.d2ff212d.webp),
    url(/static/media/FondKhops01_ombr_mvt_01.c3f3d772.webp),
    url(/static/media/FondKhops01_ombr_mvt_02.98232c16.webp)
    url(/static/media/800MB_VictoireMvt00_01.66696aa8.webp),
    url(/static/media/800MB_VictoireMvt00_02.c521df6e.webp),
    url(/static/media/800MB_VictoireMvt00_03.9ac94c59.webp),
    url(/static/media/800MB_VictoireMvt00_04.3bb6d542.webp),
    url(/static/media/800MB_VictoireMvt00_05.fc22844d.webp),
    url(/static/media/800MB_VictoireMvt00_06.0cdefd31.webp),
    url(/static/media/800MB_Colombe01.dd7bf6cd.webp),
    url(/static/media/800MB_Colombe02.d74120c5.webp),
    url(/static/media/800MB_Colombe03.7a73e9c6.webp),
    url(/static/media/800MB_Colombe04.1da7bd31.webp);
  background-position: 100% 100%;
}

html {
  scroll-behavior: smooth;
}

div.cadreMB6 {
  position: absolute;
  width: 90%;
  overflow: scroll;
  overflow: hidden;
  top: 0;
  left: 10%;
  /*scrollbar-color: transparent transparent;*/
  border-bottom: none;
}

div.cadreMB6_FondK {
  position: absolute;
  top: 0;
  left: 10%;
  width: 90%;
  background-color: grey;
}

div.cadreMB6_bouton {
  top: 0;
  left: 0;
  width: 10%;
  position: absolute;
}

div.cadreMB6::-webkit-scrollbar {
  display: none;
}

div.cadreMB66 {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}

img.imgFondMB {
  width: 100%;
}

.owwMN {
  position: relative;
  transition: all 0.1s ease;
}

.owwMN_active {
  top: 0;
}

@keyframes animMN_titre {
  0% {
    top: -100%;
    left: 0%;
    background-image: url(/static/media/FondKhops_titre.de5b42b5.webp);
    background-size: contain;
    transform: rotate(-5deg);
    transform-origin: bottom left;
    opacity: 0;
  }

  0.5% {
    opacity: 1;
  }

  1% {
    top: 0%;
    left: 0%;
  }

  1.25% {
    top: 0%;
    left: 0%;
    transform: rotate(-5deg);
    transform-origin: bottom left;
  }

  1.5% {
    top: 0%;
    left: 0%;
    transform: rotate(0deg);
    transform-origin: bottom left;
  }

  6.5% {
    top: 0%;
    left: 0%;
    opacity: 1;
  }

  8% {
    top: 0%;
    left: 0%;
    opacity: 0;
  }

  8.25% {
    top: -100%;
    left: 0%;
    opacity: 0;
  }

  8.5% {
    top: -100%;
    left: 0%;
    opacity: 0;
    display: none;
  }

  100% {
    top: -100%;
    left: 0%;
    opacity: 0;
  }
}

div.cadreMB6_nana {
  position: absolute;
  bottom: 0%;
  right: 0%;
  width: 50%;
  animation: animMN_nana_mvt0 1s linear infinite;
}


@keyframes animMN_nana_mvt0 {
  0% {
    background-image: url(/static/media/800MB_VictoireMvt00_01.66696aa8.webp);
    background-size: contain;
  }

  5.6% {
    background-image: url(/static/media/800MB_VictoireMvt00_01.66696aa8.webp);
    background-size: contain;
  }

  11.1% {
    background-image: url(/static/media/800MB_VictoireMvt00_02.c521df6e.webp);
    background-size: contain;
  }

  22.3% {
    background-image: url(/static/media/800MB_VictoireMvt00_02.c521df6e.webp);
    background-size: contain;
  }

  27.8% {
    background-image: url(/static/media/800MB_VictoireMvt00_03.9ac94c59.webp);
    background-size: contain;
  }

  39% {
    background-image: url(/static/media/800MB_VictoireMvt00_03.9ac94c59.webp);
    background-size: contain;
  }

  44.5% {
    background-image: url(/static/media/800MB_VictoireMvt00_04.3bb6d542.webp);
    background-size: contain;
  }

  55.7% {
    background-image: url(/static/media/800MB_VictoireMvt00_04.3bb6d542.webp);
    background-size: contain;
  }

  61.2% {
    background-image: url(/static/media/800MB_VictoireMvt00_05.fc22844d.webp);
    background-size: contain;
  }

  72.4% {
    background-image: url(/static/media/800MB_VictoireMvt00_05.fc22844d.webp);
    background-size: contain;
  }

  77.9% {
    background-image: url(/static/media/800MB_VictoireMvt00_06.0cdefd31.webp);
    background-size: contain;
  }

  89.1% {
    background-image: url(/static/media/800MB_VictoireMvt00_06.0cdefd31.webp);
    background-size: contain;
  }

  94.6% {
    background-image: url(/static/media/800MB_VictoireMvt00_01.66696aa8.webp);
    background-size: contain;
  }

  100% {
    background-image: url(/static/media/800MB_VictoireMvt00_01.66696aa8.webp);
    background-size: contain;
  }
}

div.cadreMB6_titre {
  position: absolute;
  top: 0%;
  left: 0%;
  width: 100%;
  animation: animMN_titre 60s forwards 1;
}

div.btMN_la {
  float: left;
  left: 0;
  width: 100%;
  bottom: 0;
  cursor: pointer;
  background-position: 0%;
}

div.btMN_la_active {
  float: left;
  left: 0;
  width: 100%;
  bottom: 0;
  cursor: pointer;
  background-position: 100%;
}

#owwMN_la1 {
  background-image: url(/static/media/BoutonLA01_01.0d4dcc3a.webp);
  background-size: cover;
}

#owwMN_la2 {
  background-image: url(/static/media/BoutonLA01_02.9530d5cb.webp);
  background-size: cover;
}

#owwMN_la3 {
  background-image: url(/static/media/BoutonLA01_03.aac4a275.webp);
  background-size: cover;
}

#owwMN_la4 {
  background-image: url(/static/media/BoutonLA01_04.54e30774.webp);
  background-size: cover;
}

#owwMN_la5 {
  background-image: url(/static/media/BoutonLA01_05.d78a2857.webp);
  background-size: cover;
}

#owwMN_la6 {
  background-image: url(/static/media/BoutonLA01_06.d3d7db27.webp);
  background-size: cover;
}

@keyframes animMN_sol {
  0% {
    background-image: url(/static/media/FondKhops01_sol_mvt_01.623d29e5.webp);
    background-size: 100%;
    top: 0;
    left: 0;
  }

  50% {
    background-image: url(/static/media/FondKhops01_sol_mvt_02.63b530ac.webp);
    background-size: 100%;
    top: 0;
    left: 0;
  }

  100% {
    background-image: url(/static/media/FondKhops01_sol_mvt_01.623d29e5.webp);
    background-size: 100%;
    top: 0;
    left: 0;
  }
}

div.cadreMB66_01a {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  animation: animMN_sol 0.5s linear infinite;
}

@keyframes animMN_bug_old {
  0% {
    background-image: url(/static/media/FondKhops01_bug_02.1746980e.webp);
    background-size: 100%;
    top: 0;
    left: 0;
  }

  5% {
    background-image: url(/static/media/FondKhops01_bug_02.1746980e.webp);
    background-size: 100%;
    top: 0;
    left: 0;
  }

  21% {
    background-image: url(/static/media/FondKhops01_bug_03.728cdf3c.webp);
    background-size: 100%;
    top: 0;
    left: 0;
  }

  41% {
    background-image: url(/static/media/FondKhops01_bug_03.728cdf3c.webp);
    background-size: 100%;
    top: 0;
    left: 0;
  }

  58% {
    background-image: url(/static/media/FondKhops01_bug_01.aaa51e6b.webp);
    background-size: 100%;
    top: 0;
    left: 0;
  }

  78% {
    background-image: url(/static/media/FondKhops01_bug_01.aaa51e6b.webp);
    background-size: 100%;
    top: 0;
    left: 0;
  }

  95% {
    background-image: url(/static/media/FondKhops01_bug_02.1746980e.webp);
    background-size: 100%;
    top: 0;
    left: 0;
  }

  100% {
    background-image: url(/static/media/FondKhops01_bug_02.1746980e.webp);
    background-size: 100%;
    top: 0;
    left: 0;
  }
}

@keyframes animMN_bug {
  0% {
    background-image: url(/static/media/800MB_Colombe04.1da7bd31.webp);
    background-size: 100%;
    top: 0;
    left: 0;
  }

  5% {
    background-image: url(/static/media/800MB_Colombe04.1da7bd31.webp);
    background-size: 100%;
    top: 0;
    left: 0;
  }

  16% {
    background-image: url(/static/media/800MB_Colombe03.7a73e9c6.webp);
    background-size: 100%;
    top: 0;
    left: 0;
  }

  32% {
    background-image: url(/static/media/800MB_Colombe02.d74120c5.webp);
    background-size: 100%;
    top: 0;
    left: 0;
  }

  42% {
    background-image: url(/static/media/800MB_Colombe01.dd7bf6cd.webp);
    background-size: 100%;
    top: 0;
    left: 0;
  }

  58% {
    background-image: url(/static/media/800MB_Colombe01.dd7bf6cd.webp);
    background-size: 100%;
    top: 0;
    left: 0;
  }

  68% {
    background-image: url(/static/media/800MB_Colombe02.d74120c5.webp);
    background-size: 100%;
    top: 0;
    left: 0;
  }

  84% {
    background-image: url(/static/media/800MB_Colombe03.7a73e9c6.webp);
    background-size: 100%;
    top: 0;
    left: 0;
  }

  95% {
    background-image: url(/static/media/800MB_Colombe04.1da7bd31.webp);
    background-size: 100%;
    top: 0;
    left: 0;
  }

  100% {
    background-image: url(/static/media/800MB_Colombe04.1da7bd31.webp);
    background-size: 100%;
    top: 0;
    left: 0;
  }
}

div.cadreMB66_02bug {
  position: absolute;
  width: 100%;
  top: 0;
  left: 0;
  animation: animMN_bug 1s linear infinite;
}

@keyframes animMN_bug_mvt01 {
  0% {
    top: 80%;
    left: -30%;
    transform: rotate(75deg);
    opacity: 0;
  }

  5% {
    top: 80%;
    left: -30%;
    transform: rotate(90deg);
    opacity: 1;
  }

  75% {
    top: 20%;
    left: 130%;
    transform: rotate(75deg);
    opacity: 1;
  }

  100% {
    top: 20%;
    left: 130%;
    transform: rotate(90deg);
    opacity: 0;
  }
}

@keyframes animMN_bug_mvt02 {
  0% {
    top: 130%;
    left: 80%;
    transform: rotate(-45deg);
    opacity: 0;
  }

  10% {
    top: 130%;
    left: 80%;
    transform: rotate(-30deg);
    opacity: 1;
  }

  80% {
    top: -30%;
    left: 20%;
    transform: rotate(-45deg);
    opacity: 1;
  }

  100% {
    top: -30%;
    left: 20%;
    transform: rotate(-30deg);
    opacity: 0;
  }
}

@keyframes animMN_bug_mvt03 {
  0% {
    top: 20%;
    left: 130%;
    transform: rotate(-110deg);
    opacity: 0;
  }

  15% {
    top: 20%;
    left: 130%;
    transform: rotate(-125deg);
    opacity: 1;
  }

  85% {
    top: 80%;
    left: -50%;
    transform: rotate(-110deg);
    opacity: 1;
  }

  100% {
    top: 80%;
    left: -50%;
    transform: rotate(-125deg);
    opacity: 0;
  }
}

@keyframes animMN_bug_mvt04 {
  0% {
    top: -30%;
    left: 80%;
    transform: rotate(-135deg);
    opacity: 0;
  }

  20% {
    top: -30%;
    left: 80%;
    transform: rotate(-120deg);
    opacity: 1;
  }

  90% {
    top: 130%;
    left: 20%;
    transform: rotate(-135deg);
    opacity: 1;
  }

  100% {
    top: 130%;
    left: 20%;
    transform: rotate(-120deg);
    opacity: 0;
  }
}

@keyframes animMN_bug_mvt05 {
  0% {
    top: 130%;
    left: 20%;
    transform: rotate(55deg);
    opacity: 0;
  }

  25% {
    top: 130%;
    left: 20%;
    transform: rotate(40deg);
    opacity: 1;
  }

  95% {
    top: -30%;
    left: 80%;
    transform: rotate(55deg);
    opacity: 1;
  }

  100% {
    top: -30%;
    left: 80%;
    transform: rotate(40deg);
    opacity: 0;
  }
}

div.cadreMB66_02a {
  position: absolute;
  width: 30%;
  top: 0;
  left: 0;
  animation: animMN_bug_mvt01 8s linear infinite;
}

div.cadreMB66_02b {
  position: absolute;
  width: 30%;
  top: 0;
  left: 0;
  animation: animMN_bug_mvt02 8s linear infinite;
}

div.cadreMB66_02c {
  position: absolute;
  width: 30%;
  top: 0;
  left: 0;
  animation: animMN_bug_mvt03 8s linear infinite;
}

div.cadreMB66_02d {
  position: absolute;
  width: 30%;
  top: 0;
  left: 0;
  animation: animMN_bug_mvt04 8s linear infinite;
}

div.cadreMB66_02e {
  position: absolute;
  width: 30%;
  top: 0;
  left: 0;
  animation: animMN_bug_mvt05 8s linear infinite;
}

div.cadreMB66_03a {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-image: url(/static/media/FondKhops01_tornad_nuag02.fbc814c2.webp);
  background-size: cover;
}

@keyframes animMN_tornad01 {
  0% {
    top: 0%;
    left: -100%;
    background-image: url(/static/media/800MB_Tornade.f77c5040.webp);
    background-size: 100%;
  }

  39% {
    top: 0%;
    left: 100%;
    background-image: url(/static/media/800MB_Tornade.f77c5040.webp);
    background-size: 100%;
  }

  41% {
    top: 0%;
    left: 100%;
    background-image: url(/static/media/800MB_Tornade.f77c5040.webp);
    background-size: 100%;
  }

  80% {
    top: 0%;
    left: -100%;
    background-image: url(/static/media/800MB_Tornade.f77c5040.webp);
    background-size: 100%;
  }

  100% {
    top: 0%;
    left: -100%;
    background-image: url(/static/media/800MB_Tornade.f77c5040.webp);
    background-size: 100%;
  }
}

@keyframes animMN_tornad02 {
  0% {
    top: 0%;
    left: 100%;
    background-image: url(/static/media/800MB_Tornade.f77c5040.webp);
    background-size: 100%;
  }

  20% {
    top: 0%;
    left: 100%;
    background-image: url(/static/media/800MB_Tornade.f77c5040.webp);
    background-size: 100%;
  }

  59% {
    top: 0%;
    left: -100%;
    background-image: url(/static/media/800MB_Tornade.f77c5040.webp);
    background-size: 100%;
  }

  61% {
    top: 0%;
    left: -100%;
    background-image: url(/static/media/800MB_Tornade.f77c5040.webp);
    background-size: 100%;
  }

  100% {
    top: 0%;
    left: 100%;
    background-image: url(/static/media/800MB_Tornade.f77c5040.webp);
    background-size: 100%;
  }
}

div.cadreMB66_03b {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  animation: animMN_tornad01 8s linear infinite;
}

div.cadreMB66_03c {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  animation: animMN_tornad02 8s linear infinite;
}

div.cadreMB66_03d {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-image: url(/static/media/FondKhops01_tornad_nuag01.f765660c.webp);
  background-size: cover;
}

@keyframes animMN_brum01 {
  0% {
    background-image: url(/static/media/FondKhops01_brum_brum.d2ff212d.webp);
    background-size: 100%;
    top: -5%;
    left: -300%;
    opacity: 0;
  }

  2% {
    background-image: url(/static/media/FondKhops01_brum_brum.d2ff212d.webp);
    background-size: 100%;
    top: -5%;
    left: -300%;
    opacity: 1;
  }

  30% {
    background-image: url(/static/media/FondKhops01_brum_brum.d2ff212d.webp);
    background-size: 100%;
    top: -5%;
    left: -150%;
    opacity: 0.9;
  }

  58% {
    background-image: url(/static/media/FondKhops01_brum_brum.d2ff212d.webp);
    background-size: 100%;
    top: -5%;
    left: 0%;
    opacity: 0;
  }

  100% {
    background-image: url(/static/media/FondKhops01_brum_brum.d2ff212d.webp);
    background-size: 100%;
    top: -5%;
    left: -300%;
    opacity: 0;
  }
}

div.cadreMB66_04a {
  position: absolute;
  width: 300%;
  height: 100%;
  top: 0;
  left: 0;
  animation: animMN_brum01 6s linear infinite;
}

@keyframes animMN_brum02 {
  0% {
    background-image: url(/static/media/FondKhops01_brum_brum.d2ff212d.webp);
    background-size: 100%;
    top: -15%;
    left: -300%;
    opacity: 0;
  }

  22% {
    background-image: url(/static/media/FondKhops01_brum_brum.d2ff212d.webp);
    background-size: 100%;
    top: -15%;
    left: -300%;
    opacity: 1;
  }

  50% {
    background-image: url(/static/media/FondKhops01_brum_brum.d2ff212d.webp);
    background-size: 100%;
    top: -15%;
    left: -150%;
    opacity: 0.9;
  }

  78% {
    background-image: url(/static/media/FondKhops01_brum_brum.d2ff212d.webp);
    background-size: 100%;
    top: -15%;
    left: 0%;
    opacity: 0;
  }

  100% {
    background-image: url(/static/media/FondKhops01_brum_brum.d2ff212d.webp);
    background-size: 100%;
    top: -15%;
    left: -300%;
    opacity: 0;
  }
}

div.cadreMB66_04b {
  position: absolute;
  width: 300%;
  height: 100%;
  top: 0;
  left: 0;
  animation: animMN_brum02 6s linear infinite;
}

@keyframes animMN_brum03 {
  0% {
    background-image: url(/static/media/FondKhops01_brum_brum.d2ff212d.webp);
    background-size: 100%;
    top: -10%;
    left: -300%;
    opacity: 0;
  }

  42% {
    background-image: url(/static/media/FondKhops01_brum_brum.d2ff212d.webp);
    background-size: 100%;
    top: -10%;
    left: -300%;
    opacity: 1;
  }

  70% {
    background-image: url(/static/media/FondKhops01_brum_brum.d2ff212d.webp);
    background-size: 100%;
    top: -10%;
    left: -150%;
    opacity: 0.9;
  }

  98% {
    background-image: url(/static/media/FondKhops01_brum_brum.d2ff212d.webp);
    background-size: 100%;
    top: -10%;
    left: 0%;
    opacity: 0;
  }

  100% {
    background-image: url(/static/media/FondKhops01_brum_brum.d2ff212d.webp);
    background-size: 100%;
    top: -10%;
    left: -300%;
    opacity: 0;
  }
}

div.cadreMB66_04c {
  position: absolute;
  width: 300%;
  height: 100%;
  top: 0;
  left: 0;
  animation: animMN_brum03 6s linear infinite;
}

@keyframes animMN_brum04 {
  0% {
    background-image: url(/static/media/FondKhops01_brum_brum.d2ff212d.webp);
    background-size: 100%;
    top: -20%;
    left: -300%;
    opacity: 0;
  }

  32% {
    background-image: url(/static/media/FondKhops01_brum_brum.d2ff212d.webp);
    background-size: 100%;
    top: -20%;
    left: -300%;
    opacity: 1;
  }

  60% {
    background-image: url(/static/media/FondKhops01_brum_brum.d2ff212d.webp);
    background-size: 100%;
    top: -20%;
    left: -150%;
    opacity: 0.9;
  }

  88% {
    background-image: url(/static/media/FondKhops01_brum_brum.d2ff212d.webp);
    background-size: 100%;
    top: -20%;
    left: 0%;
    opacity: 0;
  }

  100% {
    background-image: url(/static/media/FondKhops01_brum_brum.d2ff212d.webp);
    background-size: 100%;
    top: -20%;
    left: -300%;
    opacity: 0;
  }
}

div.cadreMB66_04d {
  position: absolute;
  width: 300%;
  height: 100%;
  top: 0;
  left: 0;
  animation: animMN_brum04 6s linear infinite;
}

@keyframes animMN_brum05 {
  0% {
    background-image: url(/static/media/FondKhops01_brum_brum.d2ff212d.webp);
    background-size: 100%;
    top: -7%;
    left: -150%;
    opacity: 0.9;
  }

  28% {
    background-image: url(/static/media/FondKhops01_brum_brum.d2ff212d.webp);
    background-size: 100%;
    top: -7%;
    left: 0%;
    opacity: 0;
  }

  70% {
    background-image: url(/static/media/FondKhops01_brum_brum.d2ff212d.webp);
    background-size: 100%;
    top: -7%;
    left: -300%;
    opacity: 0;
  }

  72% {
    background-image: url(/static/media/FondKhops01_brum_brum.d2ff212d.webp);
    background-size: 100%;
    top: -7%;
    left: -300%;
    opacity: 1;
  }

  100% {
    background-image: url(/static/media/FondKhops01_brum_brum.d2ff212d.webp);
    background-size: 100%;
    top: -7%;
    left: -150%;
    opacity: 0.9;
  }
}

div.cadreMB66_04e {
  position: absolute;
  width: 300%;
  height: 100%;
  top: 0;
  left: 0;
  animation: animMN_brum05 6s linear infinite;
}

div.cadreMB66_05a {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-image: url(/static/media/FondKhops01_krapo_nuag02.1cc9336f.webp);
  background-size: cover;
}

@keyframes animMN_krapo {
  0% {
    background-image: url(/static/media/800MB_Flzeur_Fleur.2041cc44.webp);
    background-size: 100%;
    top: -100%;
    left: -100%;
  }

  100% {
    background-image: url(/static/media/800MB_Flzeur_Fleur.2041cc44.webp);
    background-size: 100%;
    top: 0;
    left: 0;
  }
}

div.cadreMB66_05b {
  position: absolute;
  width: 200%;
  height: 200%;
  top: 0;
  left: 0;
  animation: animMN_krapo 8s linear infinite;
}

div.cadreMB66_05c {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-image: url(/static/media/FondKhops01_krapo_nuag01.ef670f85.webp);
  background-size: cover;
}

@keyframes animMN_ombr_old {
  0% {
    background-image: url(/static/media/FondKhops01_ombr_mvt_01.c3f3d772.webp);
    background-size: 100%;
    top: 0;
    left: 0;
  }

  50% {
    background-image: url(/static/media/FondKhops01_ombr_mvt_02.98232c16.webp);
    background-size: 100%;
    top: 0;
    left: 0;
  }

  100% {
    background-image: url(/static/media/FondKhops01_ombr_mvt_01.c3f3d772.webp);
    background-size: 100%;
    top: 0;
    left: 0;
  }
}

@keyframes animMN_ombr2 {
  0% {
    background-image: url(/static/media/FondKhops01_FeuxArtifice01.5fac3798.webp);
    background-size: 100%;
    top: 0;
    left: 0;
  }

  12.5% {
    background-image: url(/static/media/FondKhops01_FeuxArtifice02.b8d11674.webp);
    background-size: 100%;
    top: 0;
    left: 0;
  }

  25% {
    background-image: url(/static/media/FondKhops01_FeuxArtifice03.99036d4e.webp);
    background-size: 100%;
    top: 0;
    left: 0;
  }

  37.5% {
    background-image: url(/static/media/FondKhops01_FeuxArtifice04.aa1de951.webp);
    background-size: 100%;
    top: 0;
    left: 0;
  }

  50% {
    background-image: url(/static/media/FondKhops01_FeuxArtifice05.624146f6.webp);
    background-size: 100%;
    top: 0;
    left: 0;
  }

  62.5% {
    background-image: url(/static/media/FondKhops01_FeuxArtifice06.518537ab.webp);
    background-size: 100%;
    top: 0;
    left: 0;
  }

  75% {
    background-image: url(/static/media/FondKhops01_FeuxArtifice07.6da7426d.webp);
    background-size: 100%;
    top: 0;
    left: 0;
  }

  87.5% {
    background-image: url(/static/media/FondKhops01_FeuxArtifice08.598d2554.webp);
    background-size: 100%;
    top: 0;
    left: 0;
  }

  100% {
    background-image: url(/static/media/FondKhops01_FeuxArtifice01.5fac3798.webp);
    background-size: 100%;
    top: 0;
    left: 0;
  }
}

@keyframes animMN_ombr3 {
  0% {
    background-image: url(/static/media/FondKhops01_FeuxArtifice04.aa1de951.webp);
    background-size: 100%;
    top: 0;
    left: 0;
    transform: scaleX(-1)
  }

  12.5% {
    background-image: url(/static/media/FondKhops01_FeuxArtifice05.624146f6.webp);
    background-size: 100%;
    top: 0;
    left: 0;
    transform: scaleX(-1)
  }

  25% {
    background-image: url(/static/media/FondKhops01_FeuxArtifice06.518537ab.webp);
    background-size: 100%;
    top: 0;
    left: 0;
    transform: scaleX(-1)
  }

  37.5% {
    background-image: url(/static/media/FondKhops01_FeuxArtifice07.6da7426d.webp);
    background-size: 100%;
    top: 0;
    left: 0;
    transform: scaleX(-1)
  }

  50% {
    background-image: url(/static/media/FondKhops01_FeuxArtifice08.598d2554.webp);
    background-size: 100%;
    top: 0;
    left: 0;
    transform: scaleX(-1)
  }

  62.5% {
    background-image: url(/static/media/FondKhops01_FeuxArtifice01.5fac3798.webp);
    background-size: 100%;
    top: 0;
    left: 0;
    transform: scaleX(-1)
  }

  75% {
    background-image: url(/static/media/FondKhops01_FeuxArtifice02.b8d11674.webp);
    background-size: 100%;
    top: 0;
    left: 0;
    transform: scaleX(-1)
  }

  87.5% {
    background-image: url(/static/media/FondKhops01_FeuxArtifice03.99036d4e.webp);
    background-size: 100%;
    top: 0;
    left: 0;
    transform: scaleX(-1)
  }

  100% {
    background-image: url(/static/media/FondKhops01_FeuxArtifice04.aa1de951.webp);
    background-size: 100%;
    top: 0;
    left: 0;
    transform: scaleX(-1)
  }
}

div.cadreMB66_06a {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-image: url(/static/media/FondKhops01_FeuxArtificeBrume.2c7f2319.webp);
  background-size: cover;
}

div.cadreMB66_06b {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  animation: animMN_ombr2 1s linear infinite;
}

div.cadreMB66_06c {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  animation: animMN_ombr3 1s linear infinite;
}

div.topCadreMN0 {
  top: 0%;
  transition: all 2s ease-out;
}

div.topCadreMN100 {
  top: -100%;
  transition: all 2s ease-out;
}

div.topCadreMN200 {
  top: -200%;
  transition: all 2s ease-out;
}

div.topCadreMN300 {
  top: -300%;
  transition: all 2s ease-out;
}

div.topCadreMN400 {
  top: -400%;
  transition: all 2s ease-out;
}

div.topCadreMN500 {
  top: -500%;
  transition: all 2s ease-out;
}
div.cadreGJ {
    position: relative;
    width: 33%;
    margin: 0 auto;
    overflow: hidden;
    background-image: url(/static/media/GrueJauneEngins02_fond.f19ea6d5.webp);
    background-size: contain;
    padding: 0%;
    border: 0;
    border-radius: 1em;
}

div.cadreGJ8_fd {
    position: relative;
    width: 33%;
    margin: 0 auto;
    overflow: hidden;
    background-image: url(/static/media/GrueJauneEngins02_fond.f19ea6d5.webp);
    background-size: contain;
    padding: 0%;
    border: 0;
    border-radius: 1em;
    background-color: black;
}

div.cadreGJ_pl {
    position: absolute;
    width: 1%;
    height: 1%;
    left: 0%;
    top: 0%;
    background-image: url(/static/media/GrueJauneEngins02_button01.dcd17f88.webp),
        url(/static/media/GrueJauneEngins02_button01_active.286997a5.webp),
        url(/static/media/GrueJauneEngins02_button01_hov.948600b6.webp),
        url(/static/media/GrueJauneEngins02_button02.96d2a6a2.webp),
        url(/static/media/GrueJauneEngins02_button02_active.658c3529.webp),
        url(/static/media/GrueJauneEngins02_button02_hov.caa65b36.webp),
        url(/static/media/GrueJauneEngins02_button03.063f00d3.webp),
        url(/static/media/GrueJauneEngins02_button03_active.81280a95.webp),
        url(/static/media/GrueJauneEngins02_button03_hov.e61a179d.webp),
        url(/static/media/GrueJauneEngins02_button04.0f4f9051.webp),
        url(/static/media/GrueJauneEngins02_button04_active.5c9af36b.webp),
        url(/static/media/GrueJauneEngins02_button04_hov.ea2bc8d3.webp),
        url(/static/media/GrueJauneEngins02_button05.b7b47b54.webp),
        url(/static/media/GrueJauneEngins02_button05_active.8c1d7923.webp),
        url(/static/media/GrueJauneEngins02_button05_hov.bdcf884d.webp),
        url(/static/media/GrueJauneEngins02_button06.e3099d26.webp),
        url(/static/media/GrueJauneEngins02_button06_active.600e8c24.webp),
        url(/static/media/GrueJauneEngins02_button06_hov.061a7e6c.webp),
        url(/static/media/GrueJauneEngins02_button07.8c0535e5.webp),
        url(/static/media/GrueJauneEngins02_button07_active.34e8055d.webp),
        url(/static/media/GrueJauneEngins02_button07_hov.89cc17e0.webp),
        url(/static/media/GrueJauneEngins02_button08.c4348d41.webp),
        url(/static/media/GrueJauneEngins02_button08_active.8e6fad4a.webp),
        url(/static/media/GrueJauneEngins02_button08_hov.9220083f.webp);
    background-position: 100% 100%;
}

div.bull_txtGJ_00 h3 {
    font-size: 1.05vw;
    line-height: 0.95vw;
    font-weight: bold;
}

html {
    scroll-behavior: smooth;
}

img.imgFondGJ {
    width: 100%;
}

.mnuGJ {
    position: absolute;
    width: 11%;
    height: 100%;
    top: 0%;
    left: 89%;
    margin: 0%;
    padding: 0%;
    z-index: 2000;
}

.mnuGJ_00 {
    float: left;
    width: 100%;
    margin: 0%;
    padding: 0%;
    cursor: pointer;
}

.mnuGJ_01 {
    background-image: url(/static/media/GrueJauneEngins02_button01.dcd17f88.webp);
    background-size: cover;
    z-index: 2000;
}

.mnuGJ_01_active {
    background-image: url(/static/media/GrueJauneEngins02_button01_active.286997a5.webp);
    background-size: cover;
    z-index: 2000;
}

.mnuGJ_02 {
    background-image: url(/static/media/GrueJauneEngins02_button02.96d2a6a2.webp);
    background-size: cover;
    z-index: 2000;
}

.mnuGJ_02_active {
    background-image: url(/static/media/GrueJauneEngins02_button02_active.658c3529.webp);
    background-size: cover;
    z-index: 2000;
}

.mnuGJ_03 {
    background-image: url(/static/media/GrueJauneEngins02_button03.063f00d3.webp);
    background-size: cover;
    z-index: 2000;
}

.mnuGJ_03_active {
    background-image: url(/static/media/GrueJauneEngins02_button03_active.81280a95.webp);
    background-size: cover;
    z-index: 2000;
}

.mnuGJ_04 {
    background-image: url(/static/media/GrueJauneEngins02_button04.0f4f9051.webp);
    background-size: cover;
    z-index: 2000;
}

.mnuGJ_04_active {
    background-image: url(/static/media/GrueJauneEngins02_button04_active.5c9af36b.webp);
    background-size: cover;
    z-index: 2000;
}

.mnuGJ_05 {
    background-image: url(/static/media/GrueJauneEngins02_button05.b7b47b54.webp);
    background-size: cover;
    z-index: 2000;
}

.mnuGJ_05_active {
    background-image: url(/static/media/GrueJauneEngins02_button05_active.8c1d7923.webp);
    background-size: cover;
    z-index: 2000;
}

.mnuGJ_06 {
    background-image: url(/static/media/GrueJauneEngins02_button06.e3099d26.webp);
    background-size: cover;
    z-index: 2000;
}

.mnuGJ_06_active {
    background-image: url(/static/media/GrueJauneEngins02_button06_active.600e8c24.webp);
    background-size: cover;
    z-index: 2000;
}

.mnuGJ_07 {
    background-image: url(/static/media/GrueJauneEngins02_button07.8c0535e5.webp);
    background-size: cover;
    z-index: 2000;
}

.mnuGJ_07_active {
    background-image: url(/static/media/GrueJauneEngins02_button07_active.34e8055d.webp);
    background-size: cover;
    z-index: 2000;
}

.mnuGJ_08 {
    background-image: url(/static/media/GrueJauneEngins02_button08.c4348d41.webp);
    background-size: cover;
    z-index: 2000;
}

.mnuGJ_08_active {
    background-image: url(/static/media/GrueJauneEngins02_button08_active.8e6fad4a.webp);
    background-size: cover;
    z-index: 2000;
}

@media screen and (min-width: 512px) {

    .mnuGJ_01:hover {
        background-image: url(/static/media/GrueJauneEngins02_button01_hov.948600b6.webp);
        background-size: cover;
        z-index: 2000;
    }

    .mnuGJ_02:hover {
        background-image: url(/static/media/GrueJauneEngins02_button02_hov.caa65b36.webp);
        background-size: cover;
        z-index: 2000;
    }

    .mnuGJ_03:hover {
        background-image: url(/static/media/GrueJauneEngins02_button03_hov.e61a179d.webp);
        background-size: cover;
        z-index: 2000;
    }

    .mnuGJ_04:hover {
        background-image: url(/static/media/GrueJauneEngins02_button04_hov.ea2bc8d3.webp);
        background-size: cover;
        z-index: 2000;
    }

    .mnuGJ_05:hover {
        background-image: url(/static/media/GrueJauneEngins02_button05_hov.bdcf884d.webp);
        background-size: cover;
        z-index: 2000;
    }

    .mnuGJ_06:hover {
        background-image: url(/static/media/GrueJauneEngins02_button06_hov.061a7e6c.webp);
        background-size: cover;
        z-index: 2000;
    }

    .mnuGJ_07:hover {
        background-image: url(/static/media/GrueJauneEngins02_button07_hov.89cc17e0.webp);
        background-size: cover;
        z-index: 2000;
    }

    .mnuGJ_08:hover {
        background-image: url(/static/media/GrueJauneEngins02_button08_hov.9220083f.webp);
        background-size: cover;
        z-index: 2000;
    }
}

.sqGJ_01 {
    position: absolute;
    top: 800%;
    left: 0%;
    width: 100%;
    animation: animGJ_BSubmarine 1s infinite;
}

@keyframes animGJ_BSubmarine {
    0% {
        background-image: url(/static/media/GrueJauneEngins02_BSubmarine_01.599d42c1.webp);
        background-size: contain;
    }

    25% {
        background-image: url(/static/media/GrueJauneEngins02_BSubmarine_02.70086101.webp);
        background-size: contain;
    }

    50% {
        background-image: url(/static/media/GrueJauneEngins02_BSubmarine_03.099f2ea1.webp);
        background-size: contain;
    }

    75% {
        background-image: url(/static/media/GrueJauneEngins02_BSubmarine_04.326190c0.webp);
        background-size: contain;
    }

    100% {
        background-image: url(/static/media/GrueJauneEngins02_BSubmarine_01.599d42c1.webp);
        background-size: contain;
    }
}

.sqGJ_02 {
    position: absolute;
    top: 800%;
    left: 0%;
    width: 100%;
    animation: animGJ_VTram 1s infinite;
}

@keyframes animGJ_VTram {
    0% {
        background-image: url(/static/media/GrueJauneEngins02_VTram_01.1d4b7288.webp);
        background-size: contain;
    }

    25% {
        background-image: url(/static/media/GrueJauneEngins02_VTram_02.fe211a77.webp);
        background-size: contain;
    }

    50% {
        background-image: url(/static/media/GrueJauneEngins02_VTram_03.e48345fc.webp);
        background-size: contain;
    }

    75% {
        background-image: url(/static/media/GrueJauneEngins02_VTram_04.186d9a9a.webp);
        background-size: contain;
    }

    100% {
        background-image: url(/static/media/GrueJauneEngins02_VTram_01.1d4b7288.webp);
        background-size: contain;
    }
}

.sqGJ_03 {
    position: absolute;
    top: 800%;
    left: 12%;
    width: 100%;
    animation: animGJ_VCargo 1s infinite;
}

@keyframes animGJ_VCargo {
    0% {
        background-image: url(/static/media/GrueJauneEngins02_VCargo_01.bf9ae3d5.webp);
        background-size: contain;
    }

    25% {
        background-image: url(/static/media/GrueJauneEngins02_VCargo_02.3ebf250a.webp);
        background-size: contain;
    }

    50% {
        background-image: url(/static/media/GrueJauneEngins02_VCargo_03.c8f50c3f.webp);
        background-size: contain;
    }

    75% {
        background-image: url(/static/media/GrueJauneEngins02_VCargo_04.58f7206d.webp);
        background-size: contain;
    }

    100% {
        background-image: url(/static/media/GrueJauneEngins02_VCargo_01.bf9ae3d5.webp);
        background-size: contain;
    }
}

.sqGJ_04 {
    position: absolute;
    top: 800%;
    left: -13%;
    width: 100%;
    animation: animGJ_BCigare 1s infinite;
}

@keyframes animGJ_BCigare {
    0% {
        background-image: url(/static/media/GrueJauneEngins02_BCigare_01.15a2f8d1.webp);
        background-size: contain;
    }

    25% {
        background-image: url(/static/media/GrueJauneEngins02_BCigare_02.ea41320a.webp);
        background-size: contain;
    }

    50% {
        background-image: url(/static/media/GrueJauneEngins02_BCigare_03.3abeecb8.webp);
        background-size: contain;
    }

    75% {
        background-image: url(/static/media/GrueJauneEngins02_BCigare_04.69ca0dff.webp);
        background-size: contain;
    }

    100% {
        background-image: url(/static/media/GrueJauneEngins02_BCigare_01.15a2f8d1.webp);
        background-size: contain;
    }
}

.sqGJ_05 {
    position: absolute;
    top: 800%;
    left: 0%;
    width: 100%;
    animation: animGJ_VBateau 1s infinite;
}

@keyframes animGJ_VBateau {
    0% {
        background-image: url(/static/media/GrueJauneEngins02_VBateau_01.0c04dda7.webp);
        background-size: contain;
    }

    25% {
        background-image: url(/static/media/GrueJauneEngins02_VBateau_02.f73a1473.webp);
        background-size: contain;
    }

    50% {
        background-image: url(/static/media/GrueJauneEngins02_VBateau_03.cbef9eda.webp);
        background-size: contain;
    }

    75% {
        background-image: url(/static/media/GrueJauneEngins02_VBateau_04.d5de3fe8.webp);
        background-size: contain;
    }

    100% {
        background-image: url(/static/media/GrueJauneEngins02_VBateau_01.0c04dda7.webp);
        background-size: contain;
    }
}

.sqGJ_06 {
    position: absolute;
    top: 800%;
    left: 6%;
    width: 100%;
    animation: animGJ_BSerre 1s infinite;
}

@keyframes animGJ_BSerre {
    0% {
        background-image: url(/static/media/GrueJauneEngins02_BSerre_01.8a3847c7.webp);
        background-size: contain;
    }

    25% {
        background-image: url(/static/media/GrueJauneEngins02_BSerre_02.9e8d9c3d.webp);
        background-size: contain;
    }

    50% {
        background-image: url(/static/media/GrueJauneEngins02_BSerre_03.a441a210.webp);
        background-size: contain;
    }

    75% {
        background-image: url(/static/media/GrueJauneEngins02_BSerre_04.8bd1f4cd.webp);
        background-size: contain;
    }

    100% {
        background-image: url(/static/media/GrueJauneEngins02_BSerre_01.8a3847c7.webp);
        background-size: contain;
    }
}

.sqGJ_07 {
    position: absolute;
    top: 800%;
    left: -7%;
    width: 100%;
    animation: animGJ_VBallon 1s infinite;
}

@keyframes animGJ_VBallon {
    0% {
        background-image: url(/static/media/GrueJauneEngins02_VBallon_01.33c2214b.webp);
        background-size: contain;
    }

    25% {
        background-image: url(/static/media/GrueJauneEngins02_VBallon_02.47425ab0.webp);
        background-size: contain;
    }

    50% {
        background-image: url(/static/media/GrueJauneEngins02_VBallon_03.bc2ad006.webp);
        background-size: contain;
    }

    75% {
        background-image: url(/static/media/GrueJauneEngins02_VBallon_04.3efc6051.webp);
        background-size: contain;
    }

    100% {
        background-image: url(/static/media/GrueJauneEngins02_VBallon_01.33c2214b.webp);
        background-size: contain;
    }
}

.sqGJ_08 {
    position: absolute;
    top: 800%;
    left: 43%;
    width: 100%;
    animation: animGJ_BDrakkar 1s infinite;
}

@keyframes animGJ_BDrakkar {
    0% {
        background-image: url(/static/media/GrueJauneEngins02_BDrakkar_01.0d008c5a.webp);
        background-size: contain;
    }

    25% {
        background-image: url(/static/media/GrueJauneEngins02_BDrakkar_02.cdcfe2ee.webp);
        background-size: contain;
    }

    50% {
        background-image: url(/static/media/GrueJauneEngins02_BDrakkar_03.64eecc98.webp);
        background-size: contain;
    }

    75% {
        background-image: url(/static/media/GrueJauneEngins02_BDrakkar_04.f53778b9.webp);
        background-size: contain;
    }

    100% {
        background-image: url(/static/media/GrueJauneEngins02_BDrakkar_01.0d008c5a.webp);
        background-size: contain;
    }
}

.sqGJ_hor {
    transition: 1.5s all ease-in-out;
    transform-origin: 50% -315%;
    transform: rotate(-90deg);
}

.sqGJ_ahor {
    transition: 1.5s all ease-in-out;
    transform-origin: 50% -315%;
    transform: rotate(90deg);
}

.activeGJ_hor {
    transform: rotate(-180deg);
    display: block;
    transition: all 2s ease-out;
}

.activeGJ_ahor {
    transform: rotate(180deg);
    display: block;
    transition: all 2s ease-out;
}

.bull_txtGJ_00 {
    width: 28%;
    border-radius: 2%;
    border: 0.25em solid #000;
    padding: 0.25em;
}

.bull_txtGJ_00B {
    background-color: #000000;
}

.bull_txtGJ_00R {
    background-color: #ff0000;
}

.bull_txtGJ_00 h3 {
    margin: 0;
    padding: 0;
    text-align: center;
    font-family: 'Carrois Gothic SC', cursive;
    color: #ffffff;
}

.dinlineGJ {
    display: inline;
    animation: animGJ_bull 5s;
}

@keyframes animGJ_bull {
    0% {
        opacity: 0;
    }

    40% {
        opacity: 0;
    }

    60% {
        opacity: 1;
    }

    100% {
        opacity: 1;
    }
}

.bull_txtGJ_01 {
    position: absolute;
    top: 75%;
    left: 2%;
    z-index: 700;
}

.bull_txtGJ_02 {
    position: absolute;
    top: 28%;
    left: 2%;
    z-index: 700;
}

.bull_txtGJ_03 {
    position: absolute;
    top: 21%;
    left: 15%;
    z-index: 700;
}

.bull_txtGJ_04 {
    position: absolute;
    top: 58%;
    left: 55%;
    z-index: 700;
}

.bull_txtGJ_05 {
    position: absolute;
    top: 4%;
    left: 10%;
    z-index: 700;
}

.bull_txtGJ_06 {
    position: absolute;
    top: 52%;
    left: 62%;
    z-index: 700;
}

.bull_txtGJ_07 {
    position: absolute;
    top: 65%;
    left: 35%;
    z-index: 700;
}

.bull_txtGJ_08 {
    position: absolute;
    top: 77%;
    left: 45%;
    z-index: 700;
}

.bull_txtGJ_09 {
    position: absolute;
    top: 42%;
    left: 32%;
    z-index: 700;
}

.bull_txtGJ_10 {
    position: absolute;
    top: 55%;
    left: 25%;
    z-index: 700;
}

.bull_txtGJ_11 {
    position: absolute;
    top: 62%;
    left: 1%;
    z-index: 700;
}

.bull_txtGJ_12 {
    position: absolute;
    top: 48%;
    left: 5%;
    z-index: 700;
}

.bull_txtGJ_13 {
    position: absolute;
    top: 13%;
    left: 58%;
    z-index: 700;
}

.bull_txtGJ_14 {
    position: absolute;
    top: 28%;
    left: 62%;
    z-index: 700;
}

.bull_txtGJ_15 {
    position: absolute;
    top: 38%;
    left: 4%;
    z-index: 700;
}

.bull_txtGJ_16 {
    position: absolute;
    top: 38%;
    left: 60%;
    z-index: 700;
}

.bull_txtGJ_17 {
    position: absolute;
    top: 84%;
    left: 62%;
    z-index: 700;
}

.bull_txtGJ_18 {
    position: absolute;
    top: 5%;
    left: 62%;
    z-index: 700;
}

.bull_txtGJ_19 {
    position: absolute;
    top: 45%;
    left: 62%;
    z-index: 700;
}

.bull_txtGJ_20 {
    position: absolute;
    top: 71%;
    left: 62%;
    z-index: 700;
}

.bull_txtGJ_21 {
    position: absolute;
    top: 90%;
    left: 8%;
    z-index: 700;
}

.bull_txtGJ_22 {
    position: absolute;
    top: 12%;
    left: 4%;
    z-index: 700;
}

@keyframes anim32_brum01 {
    0% {
        background-image: url(/static/media/GrueJauneBrum_grue_jaune.9bf390cd.webp);
        background-size: 100%;
        top: -15%;
        left: -300%;
        opacity: 0;
    }

    2% {
        background-image: url(/static/media/GrueJauneBrum_grue_jaune.9bf390cd.webp);
        background-size: 100%;
        top: -15%;
        left: -300%;
        opacity: 1;
    }

    30% {
        background-image: url(/static/media/GrueJauneBrum_grue_jaune.9bf390cd.webp);
        background-size: 100%;
        top: -15%;
        left: -150%;
        opacity: 0.9;
    }

    58% {
        background-image: url(/static/media/GrueJauneBrum_grue_jaune.9bf390cd.webp);
        background-size: 100%;
        top: -15%;
        left: 0%;
        opacity: 0;
    }

    100% {
        background-image: url(/static/media/GrueJauneBrum_grue_jaune.9bf390cd.webp);
        background-size: 100%;
        top: -15%;
        left: -300%;
        opacity: 0;
    }
}

div.cadre3232_bk01 {
    position: absolute;
    width: 300%;
    height: 100%;
    top: 0;
    left: 0;
    animation: anim32_brum01 20s linear infinite;
}

@keyframes anim32_brum02 {
    0% {
        background-image: url(/static/media/GrueJauneBrum_grue_jaune.9bf390cd.webp);
        background-size: 100%;
        top: -16%;
        left: -300%;
        opacity: 0;
    }

    22% {
        background-image: url(/static/media/GrueJauneBrum_grue_jaune.9bf390cd.webp);
        background-size: 100%;
        top: -16%;
        left: -300%;
        opacity: 1;
    }

    50% {
        background-image: url(/static/media/GrueJauneBrum_grue_jaune.9bf390cd.webp);
        background-size: 100%;
        top: -16%;
        left: -150%;
        opacity: 0.9;
    }

    78% {
        background-image: url(/static/media/GrueJauneBrum_grue_jaune.9bf390cd.webp);
        background-size: 100%;
        top: -16%;
        left: 0%;
        opacity: 0;
    }

    100% {
        background-image: url(/static/media/GrueJauneBrum_grue_jaune.9bf390cd.webp);
        background-size: 100%;
        top: -16%;
        left: -300%;
        opacity: 0;
    }
}

div.cadre3232_bk02 {
    position: absolute;
    width: 300%;
    height: 100%;
    top: 0;
    left: 0;
    animation: anim32_brum02 20s linear infinite;
}

@keyframes anim32_brum03 {
    0% {
        background-image: url(/static/media/GrueJauneBrum_grue_jaune.9bf390cd.webp);
        background-size: 100%;
        top: 10%;
        left: -300%;
        opacity: 0;
    }

    42% {
        background-image: url(/static/media/GrueJauneBrum_grue_jaune.9bf390cd.webp);
        background-size: 100%;
        top: 10%;
        left: -300%;
        opacity: 1;
    }

    70% {
        background-image: url(/static/media/GrueJauneBrum_grue_jaune.9bf390cd.webp);
        background-size: 100%;
        top: 10%;
        left: -150%;
        opacity: 0.9;
    }

    98% {
        background-image: url(/static/media/GrueJauneBrum_grue_jaune.9bf390cd.webp);
        background-size: 100%;
        top: 10%;
        left: 0%;
        opacity: 0;
    }

    100% {
        background-image: url(/static/media/GrueJauneBrum_grue_jaune.9bf390cd.webp);
        background-size: 100%;
        top: 10%;
        left: -300%;
        opacity: 0;
    }
}

div.cadre3232_frt01 {
    position: absolute;
    width: 300%;
    height: 100%;
    top: 0;
    left: 0;
    animation: anim32_brum03 20s linear infinite;
}

@keyframes anim32_brum04 {
    0% {
        background-image: url(/static/media/GrueJauneBrum_grue_jaune.9bf390cd.webp);
        background-size: 100%;
        top: 20%;
        left: -300%;
        opacity: 0;
    }

    32% {
        background-image: url(/static/media/GrueJauneBrum_grue_jaune.9bf390cd.webp);
        background-size: 100%;
        top: 20%;
        left: -300%;
        opacity: 1;
    }

    60% {
        background-image: url(/static/media/GrueJauneBrum_grue_jaune.9bf390cd.webp);
        background-size: 100%;
        top: 20%;
        left: -150%;
        opacity: 0.9;
    }

    88% {
        background-image: url(/static/media/GrueJauneBrum_grue_jaune.9bf390cd.webp);
        background-size: 100%;
        top: 20%;
        left: 0%;
        opacity: 0;
    }

    100% {
        background-image: url(/static/media/GrueJauneBrum_grue_jaune.9bf390cd.webp);
        background-size: 100%;
        top: 20%;
        left: -300%;
        opacity: 0;
    }
}

div.cadre3232_frt02 {
    position: absolute;
    width: 300%;
    height: 100%;
    top: 0;
    left: 0;
    animation: anim32_brum04 20s linear infinite;
}

@keyframes anim32_brum05 {
    0% {
        background-image: url(/static/media/GrueJauneBrum_grue_jaune.9bf390cd.webp);
        background-size: 100%;
        top: -14%;
        left: -150%;
        opacity: 0.9;
    }

    28% {
        background-image: url(/static/media/GrueJauneBrum_grue_jaune.9bf390cd.webp);
        background-size: 100%;
        top: -14%;
        left: 0%;
        opacity: 0;
    }

    70% {
        background-image: url(/static/media/GrueJauneBrum_grue_jaune.9bf390cd.webp);
        background-size: 100%;
        top: -14%;
        left: -300%;
        opacity: 0;
    }

    72% {
        background-image: url(/static/media/GrueJauneBrum_grue_jaune.9bf390cd.webp);
        background-size: 100%;
        top: -14%;
        left: -300%;
        opacity: 1;
    }

    100% {
        background-image: url(/static/media/GrueJauneBrum_grue_jaune.9bf390cd.webp);
        background-size: 100%;
        top: -14%;
        left: -150%;
        opacity: 0.9;
    }
}

div.cadre3232_bk03 {
    position: absolute;
    width: 300%;
    height: 100%;
    top: 0;
    left: 0;
    animation: anim32_brum05 20s linear infinite;
}

div.cadreMN {
   position: relative;
   width: 33%;
   margin: 0 auto;
   background-color: black;
}

div.cadreMN_pl {
   position: absolute;
   width: 1%;
   height: 1%;
   left: 0%;
   top: 0%;
   background-image: url(/static/media/102_PlageATouteHeurePerso00h00.fed00bf2.webp),
      url(/static/media/MemoryFondElts003_01Y.b105fd33.webp),
      url(/static/media/MemoryFondElts003_01Z.73975eb3.webp),
      url(/static/media/MemoryFondElts003_02Y.c7c92ab2.webp),
      url(/static/media/MemoryFondElts003_02Z.bb7ee67d.webp),
      url(/static/media/MemoryFondElts003_03Y.b41ff6de.webp),
      url(/static/media/MemoryFondElts003_03Z.0702473b.webp),
      url(/static/media/MemoryFondElts003_04Y.9bd60620.webp),
      url(/static/media/MemoryFondElts003_04Z.d80bc0ce.webp),
      url(/static/media/MemoryFondElts003_05Y.dafe74e3.webp),
      url(/static/media/MemoryFondElts003_05Z.3304b28e.webp),
      url(/static/media/MemoryFondElts003_06Y.b281bf64.webp),
      url(/static/media/MemoryFondElts003_06Z.c3b1a706.webp),
      url(/static/media/MemoryFondElts003_07Y.a9a47196.webp),
      url(/static/media/MemoryFondElts003_07Z.eadfbaa1.webp),
      url(/static/media/MemoryFondElts003_08Y.850975b5.webp),
      url(/static/media/MemoryFondElts003_08Z.dfbfd8d4.webp),
      url(/static/media/Memory_titr.825eadb4.webp);
   background-position: 100% 100%;
}



html {
   scroll-behavior: smooth;
}

.cadreMN7 {
   transform-style: preserve-3d;
   perspective: 500px;
   position: absolute;
   width: 100%;
   height: 100%;
   top: 0%;
   left: 0%;
}

img.imgFondMN {
   width: 100%;
}

@keyframes matchAnim {
   0% {
      background: #bcffcc;
   }

   100% {
      background: white;
   }
}

.card {
   float: left;
   width: 16.66%;
   height: 50%;
   position: relative;
   cursor: pointer;
   padding: 0;
   margin: 0;
   border: 0;
}

.insideMN {
   width: 100%;
   height: 100%;
   display: block;
   transform-style: preserve-3d;
   transition: .4s ease-in-out;
   background: white;
   padding: 0;
   margin: 0;
   border: 0;
}

div.pickedMN {
   transform: rotateY(180deg);
}

div.matchMN {
   transform: rotateY(180deg);
}

.insideMN .matchMN {
   animation: 1s matchAnim ease-in-out;
   animation-delay: .4s;
}

.frontMN,
.backMN {
   padding: 0;
   margin: 0;
   border: 0;
   -webkit-backface-visibility: hidden;
           backface-visibility: hidden;
   position: absolute;
   top: 0;
   left: 0;
   width: 100%;
   height: 100%;
   background-color: white;
   z-index: 300;
}

.frontMN:hover .frontMN2 {
   display: block;
}

.frontMN:hover .frontMN1 {
   display: none;
}

.frontMN1 {
   display: block;
   position: absolute;
   top: 0;
   left: 0;
   width: 100%;
   height: 100%;
}

.frontMN2 {
   display: none;
   position: absolute;
   top: 0;
   left: 0;
   width: 100%;
   height: 100%;
}

.frontMN1_perso01 {
   z-index: 500;
   animation: anim1_perso01 15s linear infinite;
}

@keyframes anim1_perso01 {
   0% {
      background-image: url(/static/media/MemoryFondElts003_01Y.b105fd33.webp);
      background-size: cover;
      top: 0%;
      opacity: 1;
   }

   10% {
      top: 3%;
      opacity: 1;
   }

   20% {
      top: -3%;
      opacity: 1;
   }

   30% {
      top: 4%;
      opacity: 0;
   }

   40% {
      top: 3%;
      opacity: 0;
   }

   50% {
      top: -3%;
      opacity: 0;
   }

   60% {
      top: 5%;
      opacity: 0;
   }

   70% {
      top: 0%;
      opacity: 0;
   }

   80% {
      top: 4%;
      opacity: 0;
   }

   90% {
      top: 5%;
      opacity: 0;
   }

   100% {
      top: -3%;
      opacity: 0;
   }
}

.frontMN2_perso01 {
   z-index: 500;
   animation: anim2_perso01 15s linear infinite;
}

@keyframes anim2_perso01 {
   0% {
      background-image: url(/static/media/MemoryFondElts003_01Y.b105fd33.webp);
      background-size: cover;
      top: 0%;
   }

   10% {
      background-image: url(/static/media/MemoryFondElts003_01Z.73975eb3.webp);
      background-size: cover;
      top: 3%;
   }

   20% {
      top: -3%;
   }

   30% {
      top: 4%;
   }

   40% {
      top: 3%;
   }

   50% {
      top: -3%;
   }

   60% {
      top: 5%;
   }

   70% {
      top: 0%;
   }

   80% {
      top: 4%;
   }

   90% {
      background-image: url(/static/media/MemoryFondElts003_01Z.73975eb3.webp);
      background-size: cover;
      top: 5%;
   }

   100% {
      background-image: url(/static/media/MemoryFondElts003_01Y.b105fd33.webp);
      background-size: cover;
      top: -3%;
   }
}

.frontMN1_perso02 {
   z-index: 500;
   animation: anim1_perso02 15s linear infinite;
}

@keyframes anim1_perso02 {
   0% {
      top: 0%;
      opacity: 0;
   }

   10% {
      background-image: url(/static/media/MemoryFondElts003_02Y.c7c92ab2.webp);
      background-size: cover;
      top: 3%;
      opacity: 1;
   }

   20% {
      top: -3%;
      opacity: 1;
   }

   30% {
      top: 4%;
      opacity: 1;
   }

   40% {
      top: 3%;
      opacity: 0;
   }

   50% {
      top: -3%;
      opacity: 0;
   }

   60% {
      top: 5%;
      opacity: 0;
   }

   70% {
      top: 0%;
      opacity: 0;
   }

   80% {
      top: 4%;
      opacity: 0;
   }

   90% {
      top: 5%;
      opacity: 0;
   }

   100% {
      top: -3%;
      opacity: 0;
   }
}

.frontMN2_perso02 {
   z-index: 500;
   animation: anim2_perso02 15s linear infinite;
}

@keyframes anim2_perso02 {
   0% {
      background-image: url(/static/media/MemoryFondElts003_02Y.c7c92ab2.webp);
      background-size: cover;
      top: 0%;
   }

   10% {
      background-image: url(/static/media/MemoryFondElts003_02Z.bb7ee67d.webp);
      background-size: cover;
      top: 3%;
   }

   20% {
      top: -3%;
   }

   30% {
      top: 4%;
   }

   40% {
      top: 3%;
   }

   50% {
      top: -3%;
   }

   60% {
      top: 5%;
   }

   70% {
      top: 0%;
   }

   80% {
      top: 4%;
   }

   90% {
      background-image: url(/static/media/MemoryFondElts003_02Z.bb7ee67d.webp);
      background-size: cover;
      top: 5%;
   }

   100% {
      background-image: url(/static/media/MemoryFondElts003_02Y.c7c92ab2.webp);
      background-size: cover;
      top: -3%;
   }
}

.frontMN1_perso03 {
   z-index: 500;
   animation: anim1_perso03 15s linear infinite;
}

@keyframes anim1_perso03 {
   0% {
      top: 0%;
      opacity: 0;
   }

   10% {
      top: 3%;
      opacity: 0;
   }

   20% {
      background-image: url(/static/media/MemoryFondElts003_03Y.b41ff6de.webp);
      background-size: cover;
      top: -3%;
      opacity: 1;
   }

   30% {
      top: 4%;
      opacity: 1;
   }

   40% {
      top: 3%;
      opacity: 1;
   }

   50% {
      top: -3%;
      opacity: 0;
   }

   60% {
      top: 5%;
      opacity: 0;
   }

   70% {
      top: 0%;
      opacity: 0;
   }

   80% {
      top: 4%;
      opacity: 0;
   }

   90% {
      top: 5%;
      opacity: 0;
   }

   100% {
      top: -3%;
      opacity: 0;
   }
}

.frontMN2_perso03 {
   z-index: 500;
   animation: anim2_perso03 15s linear infinite;
}

@keyframes anim2_perso03 {
   0% {
      background-image: url(/static/media/MemoryFondElts003_03Y.b41ff6de.webp);
      background-size: cover;
      top: 0%;
   }

   10% {
      background-image: url(/static/media/MemoryFondElts003_03Z.0702473b.webp);
      background-size: cover;
      top: 3%;
   }

   20% {
      top: -3%;
   }

   30% {
      top: 4%;
   }

   40% {
      top: 3%;
   }

   50% {
      top: -3%;
   }

   60% {
      top: 5%;
   }

   70% {
      top: 0%;
   }

   80% {
      top: 4%;
   }

   90% {
      background-image: url(/static/media/MemoryFondElts003_03Z.0702473b.webp);
      background-size: cover;
      top: 5%;
   }

   100% {
      background-image: url(/static/media/MemoryFondElts003_03Y.b41ff6de.webp);
      background-size: cover;
      top: -3%;
   }
}

.frontMN1_perso04 {
   z-index: 500;
   animation: anim1_perso04 15s linear infinite;
}

@keyframes anim1_perso04 {
   0% {
      top: 0%;
      opacity: 0;
   }

   10% {
      top: 3%;
      opacity: 0;
   }

   20% {
      top: -3%;
      opacity: 0;
   }

   30% {
      background-image: url(/static/media/MemoryFondElts003_04Y.9bd60620.webp);
      background-size: cover;
      top: 4%;
      opacity: 1;
   }

   40% {
      top: 3%;
      opacity: 1;
   }

   50% {
      top: -3%;
      opacity: 1;
   }

   60% {
      top: 5%;
      opacity: 0;
   }

   70% {
      top: 0%;
      opacity: 0;
   }

   80% {
      top: 4%;
      opacity: 0;
   }

   90% {
      top: 5%;
      opacity: 0;
   }

   100% {
      top: -3%;
      opacity: 0;
   }
}

.frontMN2_perso04 {
   z-index: 500;
   animation: anim2_perso04 15s linear infinite;
}

@keyframes anim2_perso04 {
   0% {
      background-image: url(/static/media/MemoryFondElts003_04Y.9bd60620.webp);
      background-size: cover;
      top: 0%;
   }

   10% {
      background-image: url(/static/media/MemoryFondElts003_04Z.d80bc0ce.webp);
      background-size: cover;
      top: 3%;
   }

   20% {
      top: -3%;
   }

   30% {
      top: 4%;
   }

   40% {
      top: 3%;
   }

   50% {
      top: -3%;
   }

   60% {
      top: 5%;
   }

   70% {
      top: 0%;
   }

   80% {
      top: 4%;
   }

   90% {
      background-image: url(/static/media/MemoryFondElts003_04Z.d80bc0ce.webp);
      background-size: cover;
      top: 5%;
   }

   100% {
      background-image: url(/static/media/MemoryFondElts003_04Y.9bd60620.webp);
      background-size: cover;
      top: -3%;
   }
}

.frontMN1_perso05 {
   z-index: 500;
   animation: anim1_perso05 15s linear infinite;
}

@keyframes anim1_perso05 {
   0% {
      top: 0%;
      opacity: 0;
   }

   10% {
      top: 3%;
      opacity: 0;
   }

   20% {
      top: -3%;
      opacity: 0;
   }

   30% {
      top: 4%;
      opacity: 0;
   }

   40% {
      background-image: url(/static/media/MemoryFondElts003_05Y.dafe74e3.webp);
      background-size: cover;
      top: 3%;
      opacity: 1;
   }

   50% {
      top: -3%;
      opacity: 1;
   }

   60% {
      top: 5%;
      opacity: 1;
   }

   70% {
      top: 0%;
      opacity: 0;
   }

   80% {
      top: 4%;
      opacity: 0;
   }

   90% {
      top: 5%;
      opacity: 0;
   }

   100% {
      top: -3%;
      opacity: 0;
   }
}

.frontMN2_perso05 {
   z-index: 500;
   animation: anim2_perso05 15s linear infinite;
}

@keyframes anim2_perso05 {
   0% {
      background-image: url(/static/media/MemoryFondElts003_05Y.dafe74e3.webp);
      background-size: cover;
      top: 0%;
   }

   10% {
      background-image: url(/static/media/MemoryFondElts003_05Z.3304b28e.webp);
      background-size: cover;
      top: 3%;
   }

   20% {
      top: -3%;
   }

   30% {
      top: 4%;
   }

   40% {
      top: 3%;
   }

   50% {
      top: -3%;
   }

   60% {
      top: 5%;
   }

   70% {
      top: 0%;
   }

   80% {
      top: 4%;
   }

   90% {
      background-image: url(/static/media/MemoryFondElts003_05Z.3304b28e.webp);
      background-size: cover;
      top: 5%;
   }

   100% {
      background-image: url(/static/media/MemoryFondElts003_05Y.dafe74e3.webp);
      background-size: cover;
      top: -3%;
   }
}

.frontMN1_perso06 {
   z-index: 500;
   animation: anim1_perso06 15s linear infinite;
}

@keyframes anim1_perso06 {
   0% {
      top: 0%;
      opacity: 0;
   }

   10% {
      top: 3%;
      opacity: 0;
   }

   20% {
      top: -3%;
      opacity: 0;
   }

   30% {
      top: 4%;
      opacity: 0;
   }

   40% {
      top: 3%;
      opacity: 0;
   }

   50% {
      background-image: url(/static/media/MemoryFondElts003_06Y.b281bf64.webp);
      background-size: cover;
      top: -3%;
      opacity: 1;
   }

   60% {
      top: 5%;
      opacity: 1;
   }

   70% {
      top: 0%;
      opacity: 1;
   }

   80% {
      top: 4%;
      opacity: 0;
   }

   90% {
      top: 5%;
      opacity: 0;
   }

   100% {
      top: -3%;
      opacity: 0;
   }
}

.frontMN2_perso06 {
   z-index: 500;
   animation: anim2_perso06 15s linear infinite;
}

@keyframes anim2_perso06 {
   0% {
      background-image: url(/static/media/MemoryFondElts003_06Y.b281bf64.webp);
      background-size: cover;
      top: 0%;
   }

   10% {
      background-image: url(/static/media/MemoryFondElts003_06Z.c3b1a706.webp);
      background-size: cover;
      top: 3%;
   }

   20% {
      top: -3%;
   }

   30% {
      top: 4%;
   }

   40% {
      top: 3%;
   }

   50% {
      top: -3%;
   }

   60% {
      top: 5%;
   }

   70% {
      top: 0%;
   }

   80% {
      top: 4%;
   }

   90% {
      background-image: url(/static/media/MemoryFondElts003_06Z.c3b1a706.webp);
      background-size: cover;
      top: 5%;
   }

   100% {
      background-image: url(/static/media/MemoryFondElts003_06Y.b281bf64.webp);
      background-size: cover;
      top: -3%;
   }
}

.frontMN1_perso07 {
   z-index: 500;
   animation: anim1_perso07 15s linear infinite;
}

@keyframes anim1_perso07 {
   0% {
      top: 0%;
      opacity: 0;
   }

   10% {
      top: 3%;
      opacity: 0;
   }

   20% {
      top: -3%;
      opacity: 0;
   }

   30% {
      top: 4%;
      opacity: 0;
   }

   40% {
      top: 3%;
      opacity: 0;
   }

   50% {
      top: -3%;
      opacity: 0;
   }

   60% {
      background-image: url(/static/media/MemoryFondElts003_07Y.a9a47196.webp);
      background-size: cover;
      top: 5%;
      opacity: 1;
   }

   70% {
      top: 0%;
      opacity: 1;
   }

   80% {
      top: 4%;
      opacity: 1;
   }

   90% {
      top: 5%;
      opacity: 0;
   }

   100% {
      top: -3%;
      opacity: 0;
   }
}

.frontMN2_perso07 {
   z-index: 500;
   animation: anim2_perso07 15s linear infinite;
}

@keyframes anim2_perso07 {
   0% {
      background-image: url(/static/media/MemoryFondElts003_07Y.a9a47196.webp);
      background-size: cover;
      top: 0%;
   }

   10% {
      background-image: url(/static/media/MemoryFondElts003_07Z.eadfbaa1.webp);
      background-size: cover;
      top: 3%;
   }

   20% {
      top: -3%;
   }

   30% {
      top: 4%;
   }

   40% {
      top: 3%;
   }

   50% {
      top: -3%;
   }

   60% {
      top: 5%;
   }

   70% {
      top: 0%;
   }

   80% {
      top: 4%;
   }

   90% {
      background-image: url(/static/media/MemoryFondElts003_07Z.eadfbaa1.webp);
      background-size: cover;
      top: 5%;
   }

   100% {
      background-image: url(/static/media/MemoryFondElts003_07Y.a9a47196.webp);
      background-size: cover;
      top: -3%;
   }
}

.frontMN1_perso08 {
   z-index: 500;
   animation: anim1_perso08 15s linear infinite;
}

@keyframes anim1_perso08 {
   0% {
      top: 0%;
      opacity: 0;
   }

   10% {
      top: 3%;
      opacity: 0;
   }

   20% {
      top: -3%;
      opacity: 0;
   }

   30% {
      top: 4%;
      opacity: 0;
   }

   40% {
      top: 3%;
      opacity: 0;
   }

   50% {
      top: -3%;
      opacity: 0;
   }

   60% {
      top: 5%;
      opacity: 0;
   }

   70% {
      background-image: url(/static/media/MemoryFondElts003_08Y.850975b5.webp);
      background-size: cover;
      top: 0%;
      opacity: 1;
   }

   80% {
      top: 4%;
      opacity: 1;
   }

   90% {
      top: 5%;
      opacity: 1;
   }

   100% {
      top: -3%;
      opacity: 0;
   }
}

.frontMN2_perso08 {
   z-index: 500;
   animation: anim2_perso08 15s linear infinite;
}

@keyframes anim2_perso08 {
   0% {
      background-image: url(/static/media/MemoryFondElts003_08Y.850975b5.webp);
      background-size: cover;
      top: 0%;
   }

   10% {
      background-image: url(/static/media/MemoryFondElts003_08Z.dfbfd8d4.webp);
      background-size: cover;
      top: 3%;
   }

   20% {
      top: -3%;
   }

   30% {
      top: 4%;
   }

   40% {
      top: 3%;
   }

   50% {
      top: -3%;
   }

   60% {
      top: 5%;
   }

   70% {
      top: 0%;
   }

   80% {
      top: 4%;
   }

   90% {
      background-image: url(/static/media/MemoryFondElts003_08Z.dfbfd8d4.webp);
      background-size: cover;
      top: 5%;
   }

   100% {
      background-image: url(/static/media/MemoryFondElts003_08Y.850975b5.webp);
      background-size: cover;
      top: -3%;
   }
}

.frontMN img,
.backMN img {
   width: 100%;
   height: 100%;
}

.frontMN {
   transform: rotateY(-180deg);
}

.backMN {
   transform: rotateX(0);
}

.cadreMN_msg {
   background: rgba(0, 0, 0, 0.3);
   position: absolute;
   top: 0;
   left: 0;
   width: 100%;
   height: 100%;
}

.cadreMN7_msg {
   font-family: 'Fjalla One', sans-serif;
   display: flex;
   text-align: center;
   align-items: center;
   justify-content: center;
   width: 100%;
   height: 100%;
}

.cadreMN77_msg {
   font-family: 'Fjalla One', sans-serif;
   position: relative;
   width: 60%;
   margin: 20%;
   Background-color: white;
}

.winner {
   font-family: 'Fjalla One', sans-serif;
   text-align: center;
   font-family: 'Oswald', sans-serif;
   color: #4d4d4d;
   font-size: 400%;
}

@keyframes anim27_titre {
   0% {
      top: -100%;
      left: 0%;
      background-image: url(/static/media/Memory_titr.825eadb4.webp);
      background-size: contain;
      transform: rotate(-5deg);
      transform-origin: bottom left;
      opacity: 0;
   }

   0.5% {
      opacity: 1;
   }

   1% {
      top: 0%;
      left: 0%;
   }

   1.25% {
      top: 0%;
      left: 0%;
      transform: rotate(-5deg);
      transform-origin: bottom left;
   }

   1.5% {
      top: 0%;
      left: 0%;
      transform: rotate(0deg);
      transform-origin: bottom left;
   }

   6.5% {
      top: 0%;
      left: 0%;
      opacity: 1;
   }

   8% {
      top: 0%;
      left: 0%;
      opacity: 0;
   }

   8.25% {
      top: -100%;
      left: 0%;
      opacity: 0;
   }

   100% {
      top: -100%;
      left: 0%;
      opacity: 0;
   }
}

div.cadreMN7_titre {
   position: absolute;
   top: 0%;
   left: 0%;
   width: 100%;
   animation: anim27_titre 30s forwards 1;
}

img.imgFondRM {
  width: 100%;
}

div.cadreRM {
  position: relative;
  width: 33%;
  margin: 0 auto;
  background-color: black;
  overflow: hidden;
}

div.cadreRM_pl {
  position: absolute;
  width: 1%;
  height: 1%;
  left: 0%;
  top: 0%;
  background-image: url(/static/media/900_CadreRecAnim00.0b470082.webp),
    url(/static/media/BoutonRN01_B.f1378d9a.webp),
    url(/static/media/BoutonRN01_B2.dff9228f.webp) url(/static/media/BoutonRN01_K.de7e687a.webp) url(/static/media/BoutonRN01_K2.3e8ef92a.webp) url(/static/media/BoutonRN01_M.7556185f.webp) url(/static/media/BoutonRN01_M2.bb11d09a.webp);
  background-position: 100% 100%;
}

img.img17RM {
  width: 100%;
  position: absolute;
  top: 0;
}

div.cadreRM img.img17RM_first {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  transition: 1s ease-in-out;
  border-radius: 0;
  transform: translateX(0);
  -webkit-clip-path: polygon(0 0, 100% 0, 100% 50%, 0 50%);
          clip-path: polygon(0 0, 100% 0, 100% 50%, 0 50%);
}

div.cadreRM img.img17RM_last {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  transition: 1s ease-in-out;
  border-radius: 0;
  transform: translateX(0);
  -webkit-clip-path: polygon(0 50%, 100% 50%, 100% 100%, 0 100%);
          clip-path: polygon(0 50%, 100% 50%, 100% 100%, 0 100%);
}

div.cadreRM:hover img.img17RM7_first {
  transform: translateY(-45%);
}

div.cadreRM:hover img.img17RM7_last {
  transform: translateY(45%);
}

div.cadreRM17a {
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

div.cadreRM17aa {
  width: 100%;
  position: absolute;
  top: 0;
  left: 100%;
  transition: all 3s ease-out;
}

div.cadreRM17b {
  width: 100%;
  position: absolute;
  top: 0;
  left: 100%;
}

div.cadreRM17bb {
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  transition: all 3s ease-out;
}

div.cadreRM17c {
  width: 100%;
  position: absolute;
  top: 0;
  left: -100%;
}

div.cadreRM17cc {
  width: 100%;
  position: absolute;
  top: 0;
  left: -100%;
  transition: all 3s ease-out;
}

div.barBtRM {
  position: absolute;
  top: 85%;
  height: 10%;
  width: 30%;
  border: solid 0.5em red;
  border-radius: 1.5em;
  line-height: 1em;
  background-color: red;
  padding-left: 1em;
  padding-right: 1em;
  margin: 0;
  margin-left: 35%;
  margin-right: 35%;
  display: block;
}

div.barBtRMMarge {
  position: relative;
  float: left;
  height: 100%;
  width: 32%;
}

div.btRM17M {
  position: relative;
  float: left;
  height: 100%;
  width: 33.33%;
  background-image: url(/static/media/BoutonRN01_M.7556185f.webp);
  background-size: cover;
  transition: all 0.5s ease-out;
  cursor: pointer;
}

div.btRM17M_active {
  position: relative;
  float: left;
  height: 100%;
  width: 33.33%;
  background-image: url(/static/media/BoutonRN01_M2.bb11d09a.webp);
  background-size: cover;
  transform: rotate(180deg);
  transition: all 0.5s ease-out;
  cursor: pointer;
}

div.btRM17B {
  position: relative;
  float: left;
  height: 100%;
  width: 33.33%;
  background-image: url(/static/media/BoutonRN01_B.f1378d9a.webp);
  background-size: cover;
  cursor: pointer;
}

div.btRM17B_active {
  position: relative;
  float: left;
  height: 100%;
  width: 33.33%;
  background-image: url(/static/media/BoutonRN01_B2.dff9228f.webp);
  background-size: cover;
  transform: rotate(180deg);
  transition: all 0.5s ease-out;
  cursor: pointer;
}

div.btRM17K {
  position: relative;
  float: left;
  height: 100%;
  width: 33.33%;
  background-image: url(/static/media/BoutonRN01_K.de7e687a.webp);
  background-size: cover;
  cursor: pointer;
}

div.btRM17K_active {
  position: relative;
  float: left;
  height: 100%;
  width: 33.33%;
  background-image: url(/static/media/BoutonRN01_K2.3e8ef92a.webp);
  background-size: cover;
  transform: rotate(180deg);
  transition: all 0.5s ease-out;
  cursor: pointer;
}

div.EtoileRM{
  width:100%;
 /* background-color: red;*/
  position: absolute;
  left:0;
  top:0;
  overflow: hidden;
}

div.EtoileRM1{
  width:16%;
  height:16%;
  position: absolute;
  left:42%;
  top:15%;
  animation: 9s rotateRMH infinite linear;
  background-image: url(/static/media/800RM_RoisMages_etoiles2_01.5ad296fa.webp);
  background-size: cover;
  transform-origin:16% 800%;
}
div.EtoileRM2{
  width:16%;
  height:16%;
  position: absolute;
  left:42%;
  top:10%;
  animation: 8s rotateRMaH infinite linear;
  background-image: url(/static/media/800RM_RoisMages_etoiles2_02.fa3d206b.webp);
  background-size: cover;
  transform-origin:16% 800%;
}

div.EtoileRM3{
  width:16%;
  height:16%;
  position: absolute;
  left:42%;
  top:5%;
  animation: 7s rotateRMH infinite linear;
  background-image: url(/static/media/800RM_RoisMages_etoiles2_03.ed0eae20.webp);
  background-size: cover;
  transform-origin:16% 800%;
}

div.EtoileRM4{
  width:16%;
  height:16%;
  position: absolute;
  left:42%;
  top:5%;
  animation: 6s rotateRMaH infinite linear;
  background-image: url(/static/media/800RM_RoisMages_etoiles2_04.5034d7e9.webp);
  background-size: cover;
  transform-origin:16% 800%;
}

div.EtoileRM5{
  width:16%;
  height:16%;
  position: absolute;
  left:42%;
  top:10%;
  animation: 5s rotateRMH infinite linear;
  background-image: url(/static/media/800RM_RoisMages_etoiles2_05.3f32ab2e.webp);
  background-size: cover;
  transform-origin:16% 800%;
}

div.EtoileRM6{
  width:16%;
  height:16%;
  position: absolute;
  left:42%;
  top:15%;
  animation: 4s rotateRMaH infinite linear;
  background-image: url(/static/media/800RM_RoisMages_etoiles2_06.28b8f3e0.webp);
  background-size: cover;
  transform-origin:16% 800%;
}

div.EtoileRM7{
  width:16%;
  height:16%;
  position: absolute;
  left:42%;
  top:5%;
  animation: 3s rotateRMH infinite linear;
  background-image: url(/static/media/800RM_RoisMages_etoiles2_07.dba66435.webp);
  background-size: cover;
  transform-origin:16% 800%;
}

div.EtoileRM8{
  width:16%;
  height:16%;
  position: absolute;
  left:42%;
  top:10%;
  animation: 2s rotateRMaH infinite linear;
  background-image: url(/static/media/800RM_RoisMages_etoiles2_08.9d792ab9.webp);
  background-size: cover;
  transform-origin:16% 800%;
}

div.EtoileRM9{
  width:16%;
  height:16%;
  position: absolute;
  left:42%;
  top:15%;
  animation: 1s rotateRMH infinite linear;
  background-image: url(/static/media/800RM_RoisMages_etoiles2_09.adf7c298.webp);
  background-size: cover;
  transform-origin:16% 800%;
}

@keyframes rotateRMH {
  to {
    transform: rotate(-360deg);
  }
}

@keyframes rotateRMaH {
  to {
    transform: rotate(360deg);
  }
}
div.cadreCJ {
    position: relative;
    width: 33%;
    margin: 0 auto;
    background-color: black;
    overflow: hidden;
}

.cadreCJ1 {
    margin: 0;
    padding: 0;
    overflow: hidden;
    position: relative;
    width: 33%;
    float: left;
}

.cadreCJ1>* {
    bottom: 0;
    left: 0;
    margin: 0;
    padding: 0;
    position: absolute;
    right: 0;
    top: 0;
    transform: rotate(45deg);
    z-index: 1;
}

.cadreCJ1__left {
    left: -70.75%;
    right: 70.75%;
}

.cadreCJ1__right {
    left: 70.75%;
    right: -70.75%;
}

.cadreCJ1__top {
    bottom: 70.75%;
    top: -70.75%;
}

.cadreCJ1__bottom {
    bottom: -70.75%;
    top: 70.75%;
}

.cadreCJ1__content {
    margin: 0;
    padding: 0;
    bottom: none;
    height: 100%;
    opacity: 0;
    right: none;
    transform: none;
    transition: opacity .25s,
        left .25s ease-in-out,
        top .25s ease-in-out,
        z-index 0s linear .25s;
    width: 100%;
    z-index: -1;
}

.cadreCJ1__content2 {
    margin: 0;
    padding: 0;
    height: 100%;
    opacity: 1;
    right: none;
    transform: none;
    width: 100%;
    z-index: 1;
    /*transition: all 0.5s ease;*/
}

.imgVideCJ {
    position: relative;
    bottom: none;
    width: 100%;
    right: none;
    transform: none;
}

.cadreCJ1__content:after {
    bottom: -100%;
    content: "";
    left: -100%;
    position: absolute;
    right: -100%;
    top: -100%;
    z-index: -1;
}

*:hover~.cadreCJ1__content {
    transition: opacity 0s,
        left 0s ease-in-out,
        top 0s ease-in-out,
        z-index 0s linear 0s;
    z-index: 1;
}

.cadreCJ1__left:hover~.cadreCJ1__content {
    left: -100%;
}

.cadreCJ1__right:hover~.cadreCJ1__content {
    left: 100%;
}

.cadreCJ1__top:hover~.cadreCJ1__content {
    top: -100%;
}

.cadreCJ1__bottom:hover~.cadreCJ1__content {
    top: 100%;
}

.cadreCJ1__content:hover {
    left: 0;
    opacity: 1;
    top: 0;
    transition: opacity 0.25s,
        left .25s ease-in-out,
        top .25s ease-in-out,
        z-index 0s linear 0s;
    z-index: 1;
}

.cadreCJ1_01 {
    background-image: url(/static/media/500_CarreJapMotif_000_01.b97b5c7e.webp);
    background-size: cover;
}

.cadreCJ1_02 {
    background-image: url(/static/media/500_CarreJapMotif_000_02.ec977e2e.webp);
    background-size: cover;
}

.cadreCJ1_03 {
    background-image: url(/static/media/500_CarreJapMotif_000_03.3b1fc71a.webp);
    background-size: cover;
}

.cadreCJ1_04 {
    background-image: url(/static/media/500_CarreJapMotif_000_04.bd0563ff.webp);
    background-size: cover;
}

.cadreCJ1_05 {
    background-image: url(/static/media/500_CarreJapMotif_000_05.3fe7db99.webp);
    background-size: cover;
}

.cadreCJ1_06 {
    background-image: url(/static/media/500_CarreJapMotif_000_06.f9cd743f.webp);
    background-size: cover;
}

.cadreCJ1_07 {
    background-image: url(/static/media/500_CarreJapMotif_000_07.8d4c065d.webp);
    background-size: cover;
}

.cadreCJ1_08 {
    background-image: url(/static/media/500_CarreJapMotif_000_08.399013a4.webp);
    background-size: cover;
}

.cadreCJ1_09 {
    background-image: url(/static/media/500_CarreJapMotif_000_09.0b214daa.webp);
    background-size: cover;
}

.cadreCJ11_01 {
    background-image: url(/static/media/500_CarreJap_000_01.ea7753ba.webp);
    background-size: cover;
}

.cadreCJ11_02 {
    background-image: url(/static/media/500_CarreJap_000_02.54bd90ce.webp);
    background-size: cover;
}

.cadreCJ11_03 {
    background-image: url(/static/media/500_CarreJap_000_03.aa2b10e1.webp);
    background-size: cover;
    background-color: #de9d41;
}

.cadreCJ11_04 {
    background-image: url(/static/media/500_CarreJap_000_04.e9da97b0.webp);
    background-size: cover;
}

.cadreCJ11_05 {
    background-image: url(/static/media/500_CarreJap_000_05.2f001e8d.webp);
    background-size: cover;
}

.cadreCJ11_06 {
    background-image: url(/static/media/500_CarreJap_000_06.7fcb9926.webp);
    background-size: cover;
}

.cadreCJ11_07 {
    background-image: url(/static/media/500_CarreJap_000_07.516b1de9.webp);
    background-size: cover;
}

.cadreCJ11_08 {
    background-image: url(/static/media/500_CarreJap_000_08.779faf7b.webp);
    background-size: cover;
}

.cadreCJ11_09 {
    background-image: url(/static/media/500_CarreJap_000_09.37ce7d31.webp);
    background-size: cover;
}


.cadreCJ .rond14 {
    position: absolute;
    width: 20%;
    height: 20%;
    top: 40%;
    left: 40%;
    background-color: red;
    border-radius: 50%;
    z-index: 1000;
    background-image: url(/static/media/111_A6.f39163b1.webp);
    background-size: cover;
}


.cadreCJ_msg {
    background: rgba(0, 0, 0, 0.3);
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 10;
    }
    
    .cadreCJ7_msg {
    font-family: 'Fjalla One', sans-serif;
    display: flex;
    text-align: center;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    z-index: 10;
    }
    
    .cadreCJ77_msg {
    font-family: 'Fjalla One', sans-serif;
    position: relative;
    width: 80%;
    height:60%;
    margin: 20%;
    margin-left: 2%;
    margin-right: 2%;
    z-index: 10;
    /*Background-color: white;*/
    }

img.imgFondPO {
  width: 100%;
}

div.cadrePO {
  position: relative;
  width: 33%;
  margin: 0 auto;
  background-color: black;
  overflow: hidden;
}

div.cadrePO_pl {
  position: absolute;
  width: 1%;
  height: 1%;
  left: 0%;
  top: 0%;
  background-image: url(/static/media/102_PlageATouteHeurePerso00h00.fed00bf2.webp),
    url(/static/media/102_PlageATouteHeureFond00h00.dbc618bd.webp),
    url(/static/media/102_PlageATouteHeurePerso04h00.8ee223a2.webp),
    url(/static/media/102_PlageATouteHeureFond04h00.b4825e12.webp),
    url(/static/media/102_PlageATouteHeurePerso08h00.60c7f224.webp),
    url(/static/media/102_PlageATouteHeureFond08h00.85370433.webp),
    url(/static/media/102_PlageATouteHeurePerso12h00.587962dd.webp),
    url(/static/media/102_PlageATouteHeureFond12h00.3000bf91.webp),
    url(/static/media/102_PlageATouteHeurePerso16h00.b678a82c.webp),
    url(/static/media/102_PlageATouteHeureFond16h00.1cdda56b.webp),
    url(/static/media/102_PlageATouteHeurePerso20h00.cad98d64.webp),
    url(/static/media/102_PlageATouteHeureFond20h00.56e9fd13.webp),
    url(/static/media/800FS_BoutonPO01_00h00.dbff3f79.webp),
    url(/static/media/800FS_BoutonPO01_04h00.8a5a6750.webp),
    url(/static/media/800FS_BoutonPO01_08h00.03d2c1c5.webp),
    url(/static/media/800FS_BoutonPO01_12h00.78de9a83.webp),
    url(/static/media/800FS_BoutonPO01_16h00.dcf79571.webp),
    url(/static/media/800FS_BoutonPO01_20h00.58219e89.webp);
  background-position: 100% 100%;
}

div.cadrePO38 {
  position: absolute;
  top: 0;
  left: 0;
  float: left;
  width: 100%;
  height: 100%;
}

div.cadrePO38Perso00 {
  background-image: url(/static/media/102_PlageATouteHeurePerso00h00.fed00bf2.webp);
  background-size: 100% 100%;
  transition: all 3s ease-out;
}

div.cadrePO38Fond00 {
  background-image: url(/static/media/102_PlageATouteHeureFond00h00.dbc618bd.webp);
  background-size: 100% 100%;
  transition: all 1.5s ease-out;
}

div.cadrePO38Perso04 {
  background-image: url(/static/media/102_PlageATouteHeurePerso04h00.8ee223a2.webp);
  background-size: 100% 100%;
  transition: all 3s ease-out;
}

div.cadrePO38Fond04 {
  background-image: url(/static/media/102_PlageATouteHeureFond04h00.b4825e12.webp);
  background-size: 100% 100%;
  transition: all 1.5s ease-out;
}

div.cadrePO38Perso08 {
  background-image: url(/static/media/102_PlageATouteHeurePerso08h00.60c7f224.webp);
  background-size: 100% 100%;
  transition: all 3s ease-out;

}

div.cadrePO38Fond08 {
  background-image: url(/static/media/102_PlageATouteHeureFond08h00.85370433.webp);
  background-size: 100% 100%;
  transition: all 1.5s ease-out;
}

div.cadrePO38Perso12 {
  background-image: url(/static/media/102_PlageATouteHeurePerso12h00.587962dd.webp);
  background-size: 100% 100%;
  transition: all 3s ease-out;

}

div.cadrePO38Fond12 {
  background-image: url(/static/media/102_PlageATouteHeureFond12h00.3000bf91.webp);
  background-size: 100% 100%;
  transition: all 1.5s ease-out;
}

div.cadrePO38Perso16 {
  background-image: url(/static/media/102_PlageATouteHeurePerso16h00.b678a82c.webp);
  background-size: 100% 100%;
  transition: all 3s ease-out;
}

div.cadrePO38Fond16 {
  background-image: url(/static/media/102_PlageATouteHeureFond16h00.1cdda56b.webp);
  background-size: 100% 100%;
  transition: all 1.5s ease-out;
}

div.cadrePO38Perso20 {
  background-image: url(/static/media/102_PlageATouteHeurePerso20h00.cad98d64.webp);
  background-size: 100% 100%;
  transition: all 3s ease-out;

}

div.cadrePO38Fond20 {
  background-image: url(/static/media/102_PlageATouteHeureFond20h00.56e9fd13.webp);
  background-size: 100% 100%;
  transition: all 1.5s ease-out;
}

p span {
  display: block;
  padding: 0.5em 0.5em;
}


p.white {
  background: white;
  color: black;
  mix-blend-mode: screen;
}

p.black {
  background: black;
  color: white;
  mix-blend-mode: multiply;
}

p.light {
  /* Add low opacity dark background to improve contrast */
  background: rgba(0, 0, 0, 0.3);
  padding: 0;
}

p.light span {
  background: #d5d2f4;
  color: black;
  mix-blend-mode: lighten;
}

h1.light {
  /* Add low opacity dark background to improve contrast */
  background: rgba(0, 0, 0, 0.3);
  padding: 0;
}

h1.light span {
  font-family: 'Fjalla One', sans-serif;
  background: #d5d2f4;
  color: black;
  mix-blend-mode: lighten;
}

p.dark {
  /* Add low opacity light background to improve contrast */
  background: rgba(255, 255, 255, 0.3);
  padding: 0;
}

p.dark span {
  background: #2f2b59;
  color: white;
  mix-blend-mode: darken;
}

div.CCSBtPO {
  cursor: pointer;
}

div.barBtPO {
  position: absolute;
  top: 3%;
  height: 14%;
  width: 100%;
  margin: 0;
  margin-left: 14%;
  margin-right: 14%;
  cursor: pointer;
}

div.horlogePO {
  position: absolute;
  top: 20%;
  height: 10%;
  width: 100%;
}

@keyframes rotatePO {
  to {
    transform: rotate(360deg);
  }
}

div.CCSBtPO {
  position: relative;
  float: left;
  height: 100%;
  width: 12%;
}

div.CCSBtPO_active {
  position: relative;
  float: left;
  height: 100%;
  width: 12%;
  animation: 2s rotatePO infinite linear;
}

div.CCSBtPOBt1 {
  background-image: url(/static/media/800FS_BoutonPO01_04h00.8a5a6750.webp);
  background-size: cover;
}

div.CCSBtPOBt2 {
  background-image: url(/static/media/800FS_BoutonPO01_08h00.03d2c1c5.webp);
  background-size: cover;
}

div.CCSBtPOBt3 {
  background-image: url(/static/media/800FS_BoutonPO01_12h00.78de9a83.webp);
  background-size: cover;
}

div.CCSBtPOBt4 {
  background-image: url(/static/media/800FS_BoutonPO01_16h00.dcf79571.webp);
  background-size: cover;
}

div.CCSBtPOBt5 {
  background-image: url(/static/media/800FS_BoutonPO01_20h00.58219e89.webp);
  background-size: cover;
}

div.CCSBtPOBt6 {
  background-image: url(/static/media/800FS_BoutonPO01_00h00.dbff3f79.webp);
  background-size: cover;
}

h1.h2_txtPO {
  position: absolute;
  text-align: left;
  margin: 2%;
  margin-left: 5%;
  margin-top: 0.5%;
  padding: 0.5%;
  color: #000000;
  width: 90%;
  font-size: 4em;
}

h2 span.winnerPO {
  color: #000000;
  margin: 0;
  margin-left: 6%;
  top: 10%;
}

@media screen and (max-width: 1258px) {
  h1.h2_txtPO {
    position: absolute;
    text-align: left;
    margin: 2%;
    padding: 0.5%;
    color: #000000;
    width: 95%;
    font-size: 5em;
  }
}

@media screen and (max-width: 1024px) {
  h1.h2_txtPO {
    position: absolute;
    text-align: left;
    margin: 2%;
    padding: 0.5%;
    color: #000000;
    width: 95%;
    font-size: 5em;
  }
}

@media screen and (max-width: 812px) {
  h1.h2_txtPO {
    position: absolute;
    text-align: left;
    margin: 2%;
    padding: 0.5%;
    color: #000000;
    width: 95%;
    font-size: 15em;
  }
}

@media screen and (max-width: 480px) {
  h1.h2_txtPO {
    position: absolute;
    text-align: left;
    margin: 2%;
    padding: 0.5%;
    color: #000000;
    width: 95%;
    font-size: 16em;
  }
}



div.cadreBR {
  position: relative;
  width: 33%;
  margin: 0 auto;
  background-color: black;
  
}

div.cadreBR_pl {
  position: absolute;
  width: 1%;
  height: 1%;
  left: 0%;
  top: 0%;
  background-image: url(/static/media/800GL_RocheFond.a3fe896e.webp),
  url(/static/media/800GL_RocheFondGrotte.939a8616.webp),
    url(/static/media/800BR_biche_01a.8735b0a3.webp),
    url(/static/media/800BR_biche_01b.a4342e99.webp),
    url(/static/media/800BR_biche_01c.f2b7e4bc.webp),
    url(/static/media/800BR_vache_01a.4dc9e6d2.webp),
    url(/static/media/800BR_vache_01b.483ec4ea.webp),
    url(/static/media/800BR_vache_01c.e9ade474.webp),
    url(/static/media/800BR_vache_02a.3f1c5fdc.webp),
    url(/static/media/800BR_vache_02b.bb91edfc.webp),
    url(/static/media/800BR_vache_02c.5fcb6854.webp),
    url(/static/media/800BR_vache_03a.73360acb.webp),
    url(/static/media/800BR_vache_03b.84fcbbe4.webp),
    url(/static/media/800BR_vache_03c.916d2eb1.webp),
    url(/static/media/800BR_vache_04a.a1db7355.webp),
    url(/static/media/800BR_vache_04b.c68e1034.webp),
    url(/static/media/800BR_vache_04c.5b8ef322.webp),
    url(/static/media/800BR_cheval_01a.3c6cb0b5.webp),
    url(/static/media/800BR_cheval_01b.24d62243.webp),
    url(/static/media/800BR_cheval_01c.ba4d8cb6.webp),
    url(/static/media/800BR_cheval_02a.310bcf90.webp),
    url(/static/media/800BR_cheval_02b.3dd71ca4.webp),
    url(/static/media/800BR_cheval_02c.c5f4c635.webp),
    url(/static/media/800BR_cheval_03a.dd7f081a.webp),
    url(/static/media/800BR_cheval_03b.5f9b720e.webp),
    url(/static/media/800BR_cheval_03c.de174665.webp),
    url(/static/media/800BR_cerf_01a.ffd7d2cc.webp),
    url(/static/media/800BR_cerf_01b.86ca4da3.webp),
    url(/static/media/800BR_cerf_01c.01432651.webp),
    url(/static/media/800BR_mamouth_01a.baf7ef67.webp),
    url(/static/media/800BR_mamouth_01b.a656dff2.webp),
    url(/static/media/800BR_mamouth_01c.3d445a31.webp);
  background-position: 100% 100%;
}

html {
  scroll-behavior: smooth;
}

.cadreBR1 {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0%;
  left: 0%;
  background-image: url(/static/media/800GL_RocheFond.a3fe896e.webp);
  background-size: 100% 100%;
}

img.imgFondBR {
  width: 100%;
}

@keyframes animSP_800BR_biche_01 {
  0% {
    background-image: url(/static/media/800BR_biche_01a.8735b0a3.webp);
     background-size: 100% 100%;
    top: 0;
    left: 0;
  }

  10% {
    background-image: url(/static/media/800BR_biche_01a.8735b0a3.webp);
     background-size: 100% 100%;
    top: 0;
    left: 0;
  }

  35% {
    background-image: url(/static/media/800BR_biche_01b.a4342e99.webp);
     background-size: 100% 100%;
    top: 0;
    left: 0;
  }

  45% {
    background-image: url(/static/media/800BR_biche_01b.a4342e99.webp);
     background-size: 100% 100%;
    top: 0;
    left: 0;
  }

  65% {
    background-image: url(/static/media/800BR_biche_01c.f2b7e4bc.webp);
     background-size: 100% 100%;
    top: 0;
    left: 0;
  }

  75% {
    background-image: url(/static/media/800BR_biche_01c.f2b7e4bc.webp);
     background-size: 100% 100%;
    top: 0;
    left: 0;
  }

  100% {
    background-image: url(/static/media/800BR_biche_01a.8735b0a3.webp);
     background-size: 100% 100%;
    top: 0;
    left: 0;
  }
}

@keyframes animSP_800BR_vache_01 {
  0% {
    background-image: url(/static/media/800BR_vache_01a.4dc9e6d2.webp);
     background-size: 100% 100%;
    top: 0;
    left: 0;
  }

  10% {
    background-image: url(/static/media/800BR_vache_01a.4dc9e6d2.webp);
     background-size: 100% 100%;
    top: 0;
    left: 0;
  }

  35% {
    background-image: url(/static/media/800BR_vache_01b.483ec4ea.webp);
     background-size: 100% 100%;
    top: 0;
    left: 0;
  }

  45% {
    background-image: url(/static/media/800BR_vache_01b.483ec4ea.webp);
     background-size: 100% 100%;
    top: 0;
    left: 0;
  }

  65% {
    background-image: url(/static/media/800BR_vache_01c.e9ade474.webp);
     background-size: 100% 100%;
    top: 0;
    left: 0;
  }

  75% {
    background-image: url(/static/media/800BR_vache_01c.e9ade474.webp);
     background-size: 100% 100%;
    top: 0;
    left: 0;
  }

  100% {
    background-image: url(/static/media/800BR_vache_01a.4dc9e6d2.webp);
     background-size: 100% 100%;
    top: 0;
    left: 0;
  }
}

@keyframes animSP_800BR_vache_02 {
  0% {
    background-image: url(/static/media/800BR_vache_02a.3f1c5fdc.webp);
     background-size: 100% 100%;
    top: 0;
    left: 0;
  }

  10% {
    background-image: url(/static/media/800BR_vache_02a.3f1c5fdc.webp);
     background-size: 100% 100%;
    top: 0;
    left: 0;
  }

  35% {
    background-image: url(/static/media/800BR_vache_02b.bb91edfc.webp);
     background-size: 100% 100%;
    top: 0;
    left: 0;
  }

  45% {
    background-image: url(/static/media/800BR_vache_02b.bb91edfc.webp);
     background-size: 100% 100%;
    top: 0;
    left: 0;
  }

  65% {
    background-image: url(/static/media/800BR_vache_02c.5fcb6854.webp);
     background-size: 100% 100%;
    top: 0;
    left: 0;
  }

  75% {
    background-image: url(/static/media/800BR_vache_02c.5fcb6854.webp);
     background-size: 100% 100%;
    top: 0;
    left: 0;
  }

  100% {
    background-image: url(/static/media/800BR_vache_02a.3f1c5fdc.webp);
     background-size: 100% 100%;
    top: 0;
    left: 0;
  }
}

@keyframes animSP_800BR_vache_03 {
  0% {
    background-image: url(/static/media/800BR_vache_03a.73360acb.webp);
     background-size: 100% 100%;
    top: 0;
    left: 0;
  }

  10% {
    background-image: url(/static/media/800BR_vache_03a.73360acb.webp);
     background-size: 100% 100%;
    top: 0;
    left: 0;
  }

  35% {
    background-image: url(/static/media/800BR_vache_03b.84fcbbe4.webp);
     background-size: 100% 100%;
    top: 0;
    left: 0;
  }

  45% {
    background-image: url(/static/media/800BR_vache_03b.84fcbbe4.webp);
     background-size: 100% 100%;
    top: 0;
    left: 0;
  }

  65% {
    background-image: url(/static/media/800BR_vache_03c.916d2eb1.webp);
     background-size: 100% 100%;
    top: 0;
    left: 0;
  }

  75% {
    background-image: url(/static/media/800BR_vache_03c.916d2eb1.webp);
     background-size: 100% 100%;
    top: 0;
    left: 0;
  }

  100% {
    background-image: url(/static/media/800BR_vache_03a.73360acb.webp);
     background-size: 100% 100%;
    top: 0;
    left: 0;
  }
}

@keyframes animSP_800BR_vache_04 {
  0% {
    background-image: url(/static/media/800BR_vache_04a.a1db7355.webp);
     background-size: 100% 100%;
    top: 0;
    left: 0;
  }

  10% {
    background-image: url(/static/media/800BR_vache_04a.a1db7355.webp);
     background-size: 100% 100%;
    top: 0;
    left: 0;
  }

  35% {
    background-image: url(/static/media/800BR_vache_04b.c68e1034.webp);
     background-size: 100% 100%;
    top: 0;
    left: 0;
  }

  45% {
    background-image: url(/static/media/800BR_vache_04b.c68e1034.webp);
     background-size: 100% 100%;
    top: 0;
    left: 0;
  }

  65% {
    background-image: url(/static/media/800BR_vache_04c.5b8ef322.webp);
     background-size: 100% 100%;
    top: 0;
    left: 0;
  }

  75% {
    background-image: url(/static/media/800BR_vache_04c.5b8ef322.webp);
     background-size: 100% 100%;
    top: 0;
    left: 0;
  }

  100% {
    background-image: url(/static/media/800BR_vache_04a.a1db7355.webp);
     background-size: 100% 100%;
    top: 0;
    left: 0;
  }
}

@keyframes animSP_800BR_cheval_01 {
  0% {
    background-image: url(/static/media/800BR_cheval_01a.3c6cb0b5.webp);
     background-size: 100% 100%;
    top: 0;
    left: 0;
  }

  10% {
    background-image: url(/static/media/800BR_cheval_01a.3c6cb0b5.webp);
     background-size: 100% 100%;
    top: 0;
    left: 0;
  }

  35% {
    background-image: url(/static/media/800BR_cheval_01b.24d62243.webp);
     background-size: 100% 100%;
    top: 0;
    left: 0;
  }

  45% {
    background-image: url(/static/media/800BR_cheval_01b.24d62243.webp);
     background-size: 100% 100%;
    top: 0;
    left: 0;
  }

  65% {
    background-image: url(/static/media/800BR_cheval_01c.ba4d8cb6.webp);
     background-size: 100% 100%;
    top: 0;
    left: 0;
  }

  75% {
    background-image: url(/static/media/800BR_cheval_01c.ba4d8cb6.webp);
     background-size: 100% 100%;
    top: 0;
    left: 0;
  }

  100% {
    background-image: url(/static/media/800BR_cheval_01a.3c6cb0b5.webp);
     background-size: 100% 100%;
    top: 0;
    left: 0;
  }
}

@keyframes animSP_800BR_cheval_02 {
  0% {
    background-image: url(/static/media/800BR_cheval_02a.310bcf90.webp);
     background-size: 100% 100%;
    top: 0;
    left: 0;
  }

  10% {
    background-image: url(/static/media/800BR_cheval_02a.310bcf90.webp);
     background-size: 100% 100%;
    top: 0;
    left: 0;
  }

  35% {
    background-image: url(/static/media/800BR_cheval_02b.3dd71ca4.webp);
     background-size: 100% 100%;
    top: 0;
    left: 0;
  }

  45% {
    background-image: url(/static/media/800BR_cheval_02b.3dd71ca4.webp);
     background-size: 100% 100%;
    top: 0;
    left: 0;
  }

  65% {
    background-image: url(/static/media/800BR_cheval_02c.c5f4c635.webp);
     background-size: 100% 100%;
    top: 0;
    left: 0;
  }

  75% {
    background-image: url(/static/media/800BR_cheval_02c.c5f4c635.webp);
     background-size: 100% 100%;
    top: 0;
    left: 0;
  }

  100% {
    background-image: url(/static/media/800BR_cheval_02a.310bcf90.webp);
     background-size: 100% 100%;
    top: 0;
    left: 0;
  }
}

@keyframes animSP_800BR_cheval_03 {
  0% {
    background-image: url(/static/media/800BR_cheval_03a.dd7f081a.webp);
     background-size: 100% 100%;
    top: 0;
    left: 0;
  }

  10% {
    background-image: url(/static/media/800BR_cheval_03a.dd7f081a.webp);
     background-size: 100% 100%;
    top: 0;
    left: 0;
  }

  35% {
    background-image: url(/static/media/800BR_cheval_03b.5f9b720e.webp);
     background-size: 100% 100%;
    top: 0;
    left: 0;
  }

  45% {
    background-image: url(/static/media/800BR_cheval_03b.5f9b720e.webp);
     background-size: 100% 100%;
    top: 0;
    left: 0;
  }

  65% {
    background-image: url(/static/media/800BR_cheval_03c.de174665.webp);
     background-size: 100% 100%;
    top: 0;
    left: 0;
  }

  75% {
    background-image: url(/static/media/800BR_cheval_03c.de174665.webp);
     background-size: 100% 100%;
    top: 0;
    left: 0;
  }

  100% {
    background-image: url(/static/media/800BR_cheval_03a.dd7f081a.webp);
     background-size: 100% 100%;
    top: 0;
    left: 0;
  }
}

@keyframes animSP_800BR_cerf_01 {
  0% {
    background-image: url(/static/media/800BR_cerf_01a.ffd7d2cc.webp);
     background-size: 100% 100%;
    top: 0;
    left: 0;
  }

  10% {
    background-image: url(/static/media/800BR_cerf_01a.ffd7d2cc.webp);
     background-size: 100% 100%;
    top: 0;
    left: 0;
  }

  35% {
    background-image: url(/static/media/800BR_cerf_01b.86ca4da3.webp);
     background-size: 100% 100%;
    top: 0;
    left: 0;
  }

  45% {
    background-image: url(/static/media/800BR_cerf_01b.86ca4da3.webp);
     background-size: 100% 100%;
    top: 0;
    left: 0;
  }

  65% {
    background-image: url(/static/media/800BR_cerf_01c.01432651.webp);
     background-size: 100% 100%;
    top: 0;
    left: 0;
  }

  75% {
    background-image: url(/static/media/800BR_cerf_01c.01432651.webp);
     background-size: 100% 100%;
    top: 0;
    left: 0;
  }

  100% {
    background-image: url(/static/media/800BR_cerf_01a.ffd7d2cc.webp);
     background-size: 100% 100%;
    top: 0;
    left: 0;
  }
}

@keyframes animSP_800BR_mamouth_01 {
  0% {
    background-image: url(/static/media/800BR_mamouth_01a.baf7ef67.webp);
     background-size: 100% 100%;
    top: 0;
    left: 0;
  }

  10% {
    background-image: url(/static/media/800BR_mamouth_01a.baf7ef67.webp);
     background-size: 100% 100%;
    top: 0;
    left: 0;
  }

  35% {
    background-image: url(/static/media/800BR_mamouth_01b.a656dff2.webp);
     background-size: 100% 100%;
    top: 0;
    left: 0;
  }

  45% {
    background-image: url(/static/media/800BR_mamouth_01b.a656dff2.webp);
     background-size: 100% 100%;
    top: 0;
    left: 0;
  }

  65% {
    background-image: url(/static/media/800BR_mamouth_01c.3d445a31.webp);
     background-size: 100% 100%;
    top: 0;
    left: 0;
  }

  75% {
    background-image: url(/static/media/800BR_mamouth_01c.3d445a31.webp);
     background-size: 100% 100%;
    top: 0;
    left: 0;
  }

  100% {
    background-image: url(/static/media/800BR_mamouth_01a.baf7ef67.webp);
     background-size: 100% 100%;
    top: 0;
    left: 0;
  }
}

@keyframes animSP_mvt_01 {
  0% {
    left: -50%;
    opacity: 0;
  }

 1% {
    left: -50%;
    opacity: 1;
  }

  59% {
    left: 110%;
    opacity: 1;
  }

  100% {
    left: 110%;
    opacity: 0;
  }
}

@keyframes animSP_mvt_02 {
  0% {
    left: -50%;
    opacity: 0;
  }

  11% {
    left: -50%;
    opacity: 1;
  }

  79% {
    left: 110%;
    opacity: 1;
  }

  100% {
    left: 110%;
    opacity: 0;
  }
}

@keyframes animSP_mvt_03 {
  0% {
    left: -50%;
    opacity: 0;
  }

  21% {
    left: -50%;
    opacity: 1;
  }

  89% {
    left: 110%;
    opacity: 1;
  }

  100% {
    left: 110%;
    opacity: 0;
  }
}

@keyframes animSP_mvt_04 {
  0% {
    left: -50%;
    opacity: 0;
  }

  31% {
    left: -50%;
    opacity: 1;
  }

  99% {
    left: 110%;
    opacity: 1;
  }

  100% {
    left: 110%;
    opacity: 0;
  }
}



div.beteBR01 {
  position: absolute;
  width: 100%;
  top: 0;
  left: 0;
  animation: animSP_800BR_biche_01 1.5s linear infinite;
}

div.mvtBeteBR01a {
  position: absolute;
  width: 40%;
   
  top: 0%;
  left: 0;
  animation: animSP_mvt_01 30s linear infinite;
}

div.mvtBeteBR01b {
  position: absolute;
  width: 40%;
   
  top: 0%;
  left: 0;
  animation: animSP_mvt_02 30s linear infinite;
}

div.mvtBeteBR01c {
  position: absolute;
  width: 40%;
   
  top: 0%;
  left: 0;
  animation: animSP_mvt_03 30s linear infinite;
}

div.mvtBeteBR01d {
  position: absolute;
  width: 40%;
   
  top: 0%;
  left: 0;
  animation: animSP_mvt_04 30s linear infinite;
}

div.beteBR02 {
  position: absolute;
  width: 100%;
  top: 0;
  left: 0;
  animation: animSP_800BR_vache_01 1.5s linear infinite;
}

div.mvtBeteBR02 {
  position: absolute;
  width: 40%;
   
  top: 9%;
  left: 0;
  animation: animSP_mvt_04 41s linear infinite;
}

div.beteBR03 {
  position: absolute;
  width: 100%;
  top: 0;
  left: 0;
  animation: animSP_800BR_cheval_01 1.5s linear infinite;
}

div.mvtBeteBR03 {
  position: absolute;
  width: 40%;
   
  top: 15%;
  left: 0;
  animation: animSP_mvt_04 22s linear infinite;
}

div.beteBR04 {
  position: absolute;
  width: 100%;
  top: 0;
  left: 0;
  animation: animSP_800BR_cerf_01 1.5s linear infinite;
}

div.mvtBeteBR04 {
  position: absolute;
  width: 40%;
   
  top: 21%;
  left: 0;
  animation: animSP_mvt_04 33s linear infinite;
}

div.beteBR05 {
  position: absolute;
  width: 100%;
  top: 0;
  left: 0;
  animation: animSP_800BR_vache_02 1.5s linear infinite;
}

div.mvtBeteBR05 {
  position: absolute;
  width: 40%;
   
  top: 32%;
  left: 0;
  animation: animSP_mvt_03 40s linear infinite;
}

div.beteBR06 {
  position: absolute;
  width: 100%;
  top: 0;
  left: 0;
  animation: animSP_800BR_cheval_02 1.5s linear infinite;
}

div.mvtBeteBR06 {
  position: absolute;
  width: 40%;
   
  top: 36%;
  left: 0;
  animation: animSP_mvt_02 21s linear infinite;
}

div.beteBR07 {
  position: absolute;
  width: 100%;
  top: 0;
  left: 0;
  animation: animSP_800BR_mamouth_01 1.5s linear infinite;
}

div.mvtBeteBR07 {
  position: absolute;
  width: 40%;
   
  top: 41%;
  left: 0;
  animation: animSP_mvt_01 60s linear infinite;
}

div.beteBR08 {
  position: absolute;
  width: 100%;
  top: 0;
  left: 0;
  animation: animSP_800BR_vache_03 1.5s linear infinite;
}

div.mvtBeteBR08 {
  position: absolute;
  width: 40%;
   
  top: 49%;
  left: 0;
  animation: animSP_mvt_02 42s linear infinite;
}

div.beteBR09 {
  position: absolute;
  width: 100%;
  top: 0;
  left: 0;
  animation: animSP_800BR_cheval_03 1.5s linear infinite;
}

div.mvtBeteBR09 {
  position: absolute;
  width: 40%;
   
  top: 55%;
  left: 0;
  animation: animSP_mvt_01 23s linear infinite;
}

div.beteBR10 {
  position: absolute;
  width: 100%;
  top: 0;
  left: 0;
  animation: animSP_800BR_vache_04 1.5s linear infinite;
}

div.mvtBeteBR10 {
  position: absolute;
  width: 40%;
   
  top: 61%;
  left: 0;
  animation: animSP_mvt_01 40s linear infinite;
}

div.ombreLumiereBR {
  position: absolute;
  width: 200%;
  height: 200%;
  top: -50%;
  left: -50%;
  background-image: url(/static/media/800GL_RocheOmbre.e6a2b0d1.webp);
  background-size: 100% 100%;
  opacity: 0.9;
}

div.ombreBR {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color:black;
  background-size: 100% 100%;
  background-image: url(/static/media/800GL_RocheFondGrotte.939a8616.webp);
}

div.cadreBR3a{
  top: 0;
  left: 90%;
  position: absolute;
  width: 10%;
}


div.cadreBR3aa {
  position: relative;
  float: left;
  width: 80%;
  margin: 10%;
  margin-top: 35%
}



#button2aBRBR {
  display: block;
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: #00ff36;
  top: 0;
  left: 0;
  border-radius: 5em;
  cursor: pointer;
}

input[type='checkbox'] {
  display: none;
}

#button2aBR {
  display: block;
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: #fedcba;
  top: 0;
  left: 0;
  border-radius: 5em;
  cursor: pointer;
}

#knob_lumiere01 {
  position: absolute;
  left: 0;
  width: 80%;
  height: auto;
  border-radius: 50%;
  margin: 10%;
  background: #ffffff;
  transition: 0.4s ease left, 0.4s ease background-position;
  z-index: 2;
}

#knob_lumiere01 {
  background-image: url(/static/media/800ER_Boutonbr02.aae59a05.webp);
  background-size: 100%;
  background-position: 0% 0%;
  top: 0;
}

#chkBR_lumiere01:checked+#button2aBR #knob_lumiere01 {
  top: 50%;
  background-position: 0% 100%;
}



div.cadreBK {
position: relative;
width:33%;
margin: 0 auto;
background-color:black;
}

div.cadreBK_pl {
position:absolute;
width:1%;
height:1%;
left:0%;
top:0%;
background-image:url(/static/media/111_M1.c746f2d6.webp),
url(/static/media/111_M2.875875ae.webp),
url(/static/media/111_M3.3832fe7d.webp),
url(/static/media/111_M4.d5602a83.webp),
url(/static/media/111_M5.7768232f.webp),
url(/static/media/111_M6.4ea8307d.webp),
url(/static/media/111_M7.0ac08ec5.webp),
url(/static/media/111_M8.217989c5.webp),
url(/static/media/111_B1.bf738e91.webp),
url(/static/media/111_B2.5d5625f2.webp),
url(/static/media/111_B3.5afb1be4.webp),
url(/static/media/111_B4.36ee98dc.webp),
url(/static/media/111_B5.18ebe46d.webp),
url(/static/media/111_B6.3698680a.webp),
url(/static/media/111_B7.34a64c61.webp),
url(/static/media/111_B8.2b1af5ad.webp),
url(/static/media/800BK_main_PG.cdadd67c.webp),
url(/static/media/800BK_main_CG.f03e50a2.webp),
url(/static/media/800BK_main_FG.e1acb996.webp),
url(/static/media/800BK_main_PD.845cb611.webp),
url(/static/media/800BK_main_FD.fe2b29ea.webp),
url(/static/media/800BK_main_CD.fb2bd7fa.webp);
background-position: 100% 100%;
}

html {
scroll-behavior: smooth;
}

.cadreBK9 {
transform-style: preserve-3d;
perspective: 500px;
position: absolute;
width:100%;
height:100%;
top:0%;
left:0%;
}

img.imgFondBK{
width:100%;
}

.cadreBK99 {
width:100%;
height:36.93%;
top:0%;
left:0%;
position: relative;
}

.cadreBK999 {
height:100%;
position: absolute;
top:0;
left:0;
width:100%;
}

.cadreBK99MiChoix{
height:100%;
position: absolute;
top:0;
left:0;
width:60%;
left:22%;
}

.cadreBK99Mi {
width:100%;
height:26.14%;
top:0%;
left:0%;
position: relative;
background-color: antiquewhite;
}

div.cadreBK_01 {
background-image: url(/static/media/111_BB1.4e16c236.webp),
url(/static/media/111_BB2.7c9cdfe6.webp),
url(/static/media/111_BB3.36be504d.webp),linear-gradient(#6979a1,#0e3494);
}

div.cadreBK_02 {
background-image: url(/static/media/111_BB1.4e16c236.webp),
url(/static/media/111_BB3.36be504d.webp),
url(/static/media/111_BB2.7c9cdfe6.webp),linear-gradient(#6979a1,#0e3494);
}

div.cadreBK_03 {
background-image: url(/static/media/111_BB2.7c9cdfe6.webp),
url(/static/media/111_BB1.4e16c236.webp),
url(/static/media/111_BB3.36be504d.webp),linear-gradient(#6979a1,#0e3494);
}

div.cadreBK_04 {
background-image: url(/static/media/111_BB2.7c9cdfe6.webp),
url(/static/media/111_BB3.36be504d.webp),
url(/static/media/111_BB1.4e16c236.webp),linear-gradient(#6979a1,#0e3494);
}

div.cadreBK_05 {
background-image: url(/static/media/111_BB3.36be504d.webp),
url(/static/media/111_BB1.4e16c236.webp),
url(/static/media/111_BB2.7c9cdfe6.webp),linear-gradient(#6979a1,#0e3494);
}

div.cadreBK_06 {
background-image: url(/static/media/111_BB3.36be504d.webp),
url(/static/media/111_BB2.7c9cdfe6.webp),
url(/static/media/111_BB1.4e16c236.webp),linear-gradient(#6979a1,#0e3494);
}

div.cadreBK {
position:relative;
overflow: hidden;
width: 100%;
padding: 0;
}

.imgvideBK{
width:100%;
}

.imgvideBKa {
position:relative;
width:100%;
height: 100%;
} 

.cadreBK .monstreBK_01 {
position:absolute;
background-image: url(/static/media/111_M1.c746f2d6.webp);
border-radius: 0;
}

.cadreBK .monstreBK_02 {
position:absolute;
background-image: url(/static/media/111_M2.875875ae.webp);
border-radius: 0;
}

.cadreBK .monstreBK_03 {
position:absolute;
background-image: url(/static/media/111_M3.3832fe7d.webp);
border-radius: 0;
}

.cadreBK .monstreBK_04 {
position:absolute;
background-image: url(/static/media/111_M4.d5602a83.webp);
border-radius: 0;
}

.cadreBK .monstreBK_05 {
position:absolute;
background-image: url(/static/media/111_M5.7768232f.webp);
border-radius: 0;
}

.cadreBK .monstreBK_06 {
position:absolute;
background-image: url(/static/media/111_M6.4ea8307d.webp);
border-radius: 0;
}

.cadreBK .monstreBK_07 {
position:absolute;
background-image: url(/static/media/111_M7.0ac08ec5.webp);
border-radius: 0;
}

.cadreBK .monstreBK_08 {
position:absolute;
background-image: url(/static/media/111_M8.217989c5.webp);
border-radius: 0;
}

.cadreBK .monstreBK_09 {
position:absolute;
background-image: url(/static/media/111_M9.76afd484.webp);
border-radius: 0;
}

.cadreBK .villeBK_01 {
position:absolute;
background-image: url(/static/media/111_B1.bf738e91.webp),linear-gradient(#6979a1,#0e3494);
border-radius: 0;
}

.cadreBK .villeBK_02 {
position:absolute;
background-image: url(/static/media/111_B2.5d5625f2.webp),linear-gradient(#6979a1,#0e3494);
border-radius: 0;
}

.cadreBK .villeBK_03 {
position:absolute;
background-image: url(/static/media/111_B3.5afb1be4.webp),linear-gradient(#6979a1,#0e3494);
border-radius: 0;
}

.cadreBK .villeBK_04 {
position:absolute;
background-image: url(/static/media/111_B4.36ee98dc.webp),linear-gradient(#6979a1,#0e3494);
border-radius: 0;
}

.cadreBK .villeBK_05 {
position:absolute;
background-image: url(/static/media/111_B5.18ebe46d.webp),linear-gradient(#6979a1,#0e3494);
border-radius: 0;
}

.cadreBK .villeBK_06 {
position:absolute;
background-image: url(/static/media/111_B6.3698680a.webp),linear-gradient(#6979a1,#0e3494);
border-radius: 0;
}

.cadreBK .villeBK_07 {
position:absolute;
background-image: url(/static/media/111_B7.34a64c61.webp),linear-gradient(#6979a1,#0e3494);
border-radius: 0;
}

.cadreBK .villeBK_08 {
position:absolute;
background-image: url(/static/media/111_B8.2b1af5ad.webp),linear-gradient(#6979a1,#0e3494);
border-radius: 0;
}

.cadreBK .villeBK_09 {
position:absolute;
background-image: url(/static/media/111_B9.eadc08ff.webp),linear-gradient(#6979a1,#0e3494);
border-radius: 0;
}

.cadreBK .imgBK_over01, .cadreBK .imgBK_over02, .cadreBK .imgBK_over03, .cadreBK .imgBK_over04, .cadreBK .imgBK_over05, .cadreBK .imgBK_over06  {
position:relative;
float:left;
height: 100%;
width:33.33%;
bottom:0;
background-size: contain;
}

.cadreBK .imgBK_monstreR{
position:relative;
float:left;
height: 100%;
width:33.33%;
bottom:0;
left:0%;
background-size: contain;
transition: all 2s ease;
}

.cadreBK .imgBK_monstreR1{
position:relative;
float:left;
height: 100%;
width:33.33%;
bottom:0;
background-size: contain;
left:20%;
transition: all 2s ease;
}

.cadreBK .imgBK_monstreR2{
position:relative;
float:left;
height: 100%;
width:33.33%;
bottom:0;
background-size: contain;
left:40%;
transition: all 2s ease;
}

.cadreBK .imgBK_monstreR3{
position:relative;
float:left;
height: 100%;
width:33.33%;
bottom:0;
background-size: contain;
left:60%;
transition: all 2s ease;
}

.cadreBK .imgBK_monstreRD{
position:relative;
float:left;
height: 100%;
width:33.33%;
bottom:0;
background-size: contain;
transform: rotate(180deg);
transition: all 2s ease;
}

.cadreBK .imgBK_monstreB{
position:relative;
float:right;
height: 100%;
width:33.33%;
bottom:0;
background-size: contain;
transform: scale(-1,1);
right: 0%;
transition: all 2s ease;
}

.cadreBK .imgBK_monstreB1{
position:relative;
float:right;
height: 100%;
width:33.33%;
bottom:0;
background-size: contain;
transform: scale(-1,1);
right: 20%;
transition: all 2s ease;
}

.cadreBK .imgBK_monstreB2{
position:relative;
float:right;
height: 100%;
width:33.33%;
bottom:0;
background-size: contain;
transform: scale(-1,1);
right:40%;
transition: all 2s ease;
}

.cadreBK .imgBK_monstreB3{
position:relative;
float:right;
height: 100%;
width:33.33%;
bottom:0;
background-size: contain;
transform: scale(-1,1);
right:60%;
transition: all 2s ease;
}

.cadreBK .imgBK_monstreBD{
position:relative;
float:right;
height: 100%;
width:33.33%;
bottom:0;
background-size: contain;
transform: scale(-1,1);
transform: rotate(180deg);
}

.cadreBK .eltBKR {
    position:relative;
    top:10%;
    height:80%;
    width: 32%;
    background-size: contain;
    cursor: pointer;
}

.cadreBK .pierreBKR {
float:left;
background-image: url(/static/media/800BK_main_PG.cdadd67c.webp);

}

.cadreBK .feuilleBKR {
float:left;
background-image: url(/static/media/800BK_main_FG.e1acb996.webp);
}

.cadreBK .ciseauxBKR {
float:left;
background-image: url(/static/media/800BK_main_CG.f03e50a2.webp);
}

.cadreBK .pierreBKB {
float:right;
background-image: url(/static/media/800BK_main_PD.845cb611.webp);
}

.cadreBK .feuilleBKB {
float:right;
background-image: url(/static/media/800BK_main_FD.fe2b29ea.webp);
}

.cadreBK .ciseauxBKB {
float:right;
background-image: url(/static/media/800BK_main_CD.fb2bd7fa.webp);
}

.cadreBK_msg {
background: rgba(0, 0, 0, 0.3);
position: absolute;
top: 0;
left: 0;
width: 100%;
height: 100%;
}

.cadreBK7_msg {
font-family: 'Fjalla One', sans-serif;
display: flex;
text-align: center;
align-items: center;
justify-content: center;
width: 100%;
height: 100%;
}

.cadreBK77_msg {
font-family: 'Fjalla One', sans-serif;
position: relative;
width: 80%;
height:60%;
margin: 20%;
margin-left: 2%;
margin-right: 2%;
/*Background-color: white;*/
}













div.cadre42 {
    position: relative;
    width: 33%;
    margin: 0 auto;
    background-color: black;
 }

 div.cadre42_pl {
    position: absolute;
    width: 1%;
    height: 1%;
    left: 0%;
    top: 0%;
    background-position: 100% 100%;
 }

 
.cadre427 {
    transform-style: preserve-3d;
    perspective: 500px;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0%;
    left: 0%;
 }
 
 img.imgFond42 {
    width: 100%;
 }
 
 div.canvas42 {
    position: relative;
    width: 100%;
    height: 100%;
    background-color: silver;
    background-image:  url(/static/media/600_Mino_fond.8c9f39a3.webp);
    background-size: 100% 100%;
  }

  div.canvas422 {
    position: relative;
    width: 100%;
    height: 100%;
    background-color: silver;
    background-size: 100% 100%;
    opacity: 0.75;
  }

  div.canvas4222 {
    position: relative;
    width: 66%;
    height: 98%;
    left:33%;
    border:0;
    margin: 0;
    padding: 0;
    top:1%;
  }

  div.piece42{
    top:0%;
    left:0%;
    border:0;
    margin: 0%;
    padding: 0%;
    position: absolute;
    float: left;
    height: 24.5%;
    width:22%;
  }

  img.imgPiece42{
    top:0%;
    left:0%;
    border:0;
    margin: 0%;
    padding: 0%;
width: 100%;
height: 100%;
  }

  
.cadre42_msg {
  background: rgba(0, 0, 0, 0.3);
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 10;
  }
  
  .cadre427_msg {
  font-family: 'Fjalla One', sans-serif;
  display: flex;
  text-align: center;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  z-index: 10;
  }
  
  .cadre4277_msg {
  font-family: 'Fjalla One', sans-serif;
  position: relative;
  width: 80%;
  height:60%;
  margin: 20%;
  margin-left: 2%;
  margin-right: 2%;
  z-index: 10;
  /*Background-color: white;*/
  }
