div.cadreMN {
   position: relative;
   width: 33%;
   margin: 0 auto;
   background-color: black;
}

div.cadreMN_pl {
   position: absolute;
   width: 1%;
   height: 1%;
   left: 0%;
   top: 0%;
   background-image: url('../assets/img/img_mer/102_PlageATouteHeurePerso00h00.webp'),
      url('../assets/img/img_space/MemoryFondElts003_01Y.webp'),
      url('../assets/img/img_space/MemoryFondElts003_01Z.webp'),
      url('../assets/img/img_space/MemoryFondElts003_02Y.webp'),
      url('../assets/img/img_space/MemoryFondElts003_02Z.webp'),
      url('../assets/img/img_space/MemoryFondElts003_03Y.webp'),
      url('../assets/img/img_space/MemoryFondElts003_03Z.webp'),
      url('../assets/img/img_space/MemoryFondElts003_04Y.webp'),
      url('../assets/img/img_space/MemoryFondElts003_04Z.webp'),
      url('../assets/img/img_space/MemoryFondElts003_05Y.webp'),
      url('../assets/img/img_space/MemoryFondElts003_05Z.webp'),
      url('../assets/img/img_space/MemoryFondElts003_06Y.webp'),
      url('../assets/img/img_space/MemoryFondElts003_06Z.webp'),
      url('../assets/img/img_space/MemoryFondElts003_07Y.webp'),
      url('../assets/img/img_space/MemoryFondElts003_07Z.webp'),
      url('../assets/img/img_space/MemoryFondElts003_08Y.webp'),
      url('../assets/img/img_space/MemoryFondElts003_08Z.webp'),
      url('../assets/img/img_space/Memory_titr.webp');
   background-position: 100% 100%;
}



html {
   scroll-behavior: smooth;
}

.cadreMN7 {
   transform-style: preserve-3d;
   perspective: 500px;
   position: absolute;
   width: 100%;
   height: 100%;
   top: 0%;
   left: 0%;
}

img.imgFondMN {
   width: 100%;
}

@keyframes matchAnim {
   0% {
      background: #bcffcc;
   }

   100% {
      background: white;
   }
}

.card {
   float: left;
   width: 16.66%;
   height: 50%;
   position: relative;
   cursor: pointer;
   padding: 0;
   margin: 0;
   border: 0;
}

.insideMN {
   width: 100%;
   height: 100%;
   display: block;
   transform-style: preserve-3d;
   transition: .4s ease-in-out;
   background: white;
   padding: 0;
   margin: 0;
   border: 0;
}

div.pickedMN {
   transform: rotateY(180deg);
}

div.matchMN {
   transform: rotateY(180deg);
}

.insideMN .matchMN {
   animation: 1s matchAnim ease-in-out;
   animation-delay: .4s;
}

.frontMN,
.backMN {
   padding: 0;
   margin: 0;
   border: 0;
   backface-visibility: hidden;
   position: absolute;
   top: 0;
   left: 0;
   width: 100%;
   height: 100%;
   background-color: white;
   z-index: 300;
}

.frontMN:hover .frontMN2 {
   display: block;
}

.frontMN:hover .frontMN1 {
   display: none;
}

.frontMN1 {
   display: block;
   position: absolute;
   top: 0;
   left: 0;
   width: 100%;
   height: 100%;
}

.frontMN2 {
   display: none;
   position: absolute;
   top: 0;
   left: 0;
   width: 100%;
   height: 100%;
}

.frontMN1_perso01 {
   z-index: 500;
   animation: anim1_perso01 15s linear infinite;
}

@keyframes anim1_perso01 {
   0% {
      background-image: url('../assets/img/img_space/MemoryFondElts003_01Y.webp');
      background-size: cover;
      top: 0%;
      opacity: 1;
   }

   10% {
      top: 3%;
      opacity: 1;
   }

   20% {
      top: -3%;
      opacity: 1;
   }

   30% {
      top: 4%;
      opacity: 0;
   }

   40% {
      top: 3%;
      opacity: 0;
   }

   50% {
      top: -3%;
      opacity: 0;
   }

   60% {
      top: 5%;
      opacity: 0;
   }

   70% {
      top: 0%;
      opacity: 0;
   }

   80% {
      top: 4%;
      opacity: 0;
   }

   90% {
      top: 5%;
      opacity: 0;
   }

   100% {
      top: -3%;
      opacity: 0;
   }
}

.frontMN2_perso01 {
   z-index: 500;
   animation: anim2_perso01 15s linear infinite;
}

@keyframes anim2_perso01 {
   0% {
      background-image: url('../assets/img/img_space/MemoryFondElts003_01Y.webp');
      background-size: cover;
      top: 0%;
   }

   10% {
      background-image: url('../assets/img/img_space/MemoryFondElts003_01Z.webp');
      background-size: cover;
      top: 3%;
   }

   20% {
      top: -3%;
   }

   30% {
      top: 4%;
   }

   40% {
      top: 3%;
   }

   50% {
      top: -3%;
   }

   60% {
      top: 5%;
   }

   70% {
      top: 0%;
   }

   80% {
      top: 4%;
   }

   90% {
      background-image: url('../assets/img/img_space/MemoryFondElts003_01Z.webp');
      background-size: cover;
      top: 5%;
   }

   100% {
      background-image: url('../assets/img/img_space/MemoryFondElts003_01Y.webp');
      background-size: cover;
      top: -3%;
   }
}

.frontMN1_perso02 {
   z-index: 500;
   animation: anim1_perso02 15s linear infinite;
}

@keyframes anim1_perso02 {
   0% {
      top: 0%;
      opacity: 0;
   }

   10% {
      background-image: url('../assets/img/img_space/MemoryFondElts003_02Y.webp');
      background-size: cover;
      top: 3%;
      opacity: 1;
   }

   20% {
      top: -3%;
      opacity: 1;
   }

   30% {
      top: 4%;
      opacity: 1;
   }

   40% {
      top: 3%;
      opacity: 0;
   }

   50% {
      top: -3%;
      opacity: 0;
   }

   60% {
      top: 5%;
      opacity: 0;
   }

   70% {
      top: 0%;
      opacity: 0;
   }

   80% {
      top: 4%;
      opacity: 0;
   }

   90% {
      top: 5%;
      opacity: 0;
   }

   100% {
      top: -3%;
      opacity: 0;
   }
}

.frontMN2_perso02 {
   z-index: 500;
   animation: anim2_perso02 15s linear infinite;
}

@keyframes anim2_perso02 {
   0% {
      background-image: url('../assets/img/img_space/MemoryFondElts003_02Y.webp');
      background-size: cover;
      top: 0%;
   }

   10% {
      background-image: url('../assets/img/img_space/MemoryFondElts003_02Z.webp');
      background-size: cover;
      top: 3%;
   }

   20% {
      top: -3%;
   }

   30% {
      top: 4%;
   }

   40% {
      top: 3%;
   }

   50% {
      top: -3%;
   }

   60% {
      top: 5%;
   }

   70% {
      top: 0%;
   }

   80% {
      top: 4%;
   }

   90% {
      background-image: url('../assets/img/img_space/MemoryFondElts003_02Z.webp');
      background-size: cover;
      top: 5%;
   }

   100% {
      background-image: url('../assets/img/img_space/MemoryFondElts003_02Y.webp');
      background-size: cover;
      top: -3%;
   }
}

.frontMN1_perso03 {
   z-index: 500;
   animation: anim1_perso03 15s linear infinite;
}

@keyframes anim1_perso03 {
   0% {
      top: 0%;
      opacity: 0;
   }

   10% {
      top: 3%;
      opacity: 0;
   }

   20% {
      background-image: url('../assets/img/img_space/MemoryFondElts003_03Y.webp');
      background-size: cover;
      top: -3%;
      opacity: 1;
   }

   30% {
      top: 4%;
      opacity: 1;
   }

   40% {
      top: 3%;
      opacity: 1;
   }

   50% {
      top: -3%;
      opacity: 0;
   }

   60% {
      top: 5%;
      opacity: 0;
   }

   70% {
      top: 0%;
      opacity: 0;
   }

   80% {
      top: 4%;
      opacity: 0;
   }

   90% {
      top: 5%;
      opacity: 0;
   }

   100% {
      top: -3%;
      opacity: 0;
   }
}

.frontMN2_perso03 {
   z-index: 500;
   animation: anim2_perso03 15s linear infinite;
}

@keyframes anim2_perso03 {
   0% {
      background-image: url('../assets/img/img_space/MemoryFondElts003_03Y.webp');
      background-size: cover;
      top: 0%;
   }

   10% {
      background-image: url('../assets/img/img_space/MemoryFondElts003_03Z.webp');
      background-size: cover;
      top: 3%;
   }

   20% {
      top: -3%;
   }

   30% {
      top: 4%;
   }

   40% {
      top: 3%;
   }

   50% {
      top: -3%;
   }

   60% {
      top: 5%;
   }

   70% {
      top: 0%;
   }

   80% {
      top: 4%;
   }

   90% {
      background-image: url('../assets/img/img_space/MemoryFondElts003_03Z.webp');
      background-size: cover;
      top: 5%;
   }

   100% {
      background-image: url('../assets/img/img_space/MemoryFondElts003_03Y.webp');
      background-size: cover;
      top: -3%;
   }
}

.frontMN1_perso04 {
   z-index: 500;
   animation: anim1_perso04 15s linear infinite;
}

@keyframes anim1_perso04 {
   0% {
      top: 0%;
      opacity: 0;
   }

   10% {
      top: 3%;
      opacity: 0;
   }

   20% {
      top: -3%;
      opacity: 0;
   }

   30% {
      background-image: url('../assets/img/img_space/MemoryFondElts003_04Y.webp');
      background-size: cover;
      top: 4%;
      opacity: 1;
   }

   40% {
      top: 3%;
      opacity: 1;
   }

   50% {
      top: -3%;
      opacity: 1;
   }

   60% {
      top: 5%;
      opacity: 0;
   }

   70% {
      top: 0%;
      opacity: 0;
   }

   80% {
      top: 4%;
      opacity: 0;
   }

   90% {
      top: 5%;
      opacity: 0;
   }

   100% {
      top: -3%;
      opacity: 0;
   }
}

.frontMN2_perso04 {
   z-index: 500;
   animation: anim2_perso04 15s linear infinite;
}

@keyframes anim2_perso04 {
   0% {
      background-image: url('../assets/img/img_space/MemoryFondElts003_04Y.webp');
      background-size: cover;
      top: 0%;
   }

   10% {
      background-image: url('../assets/img/img_space/MemoryFondElts003_04Z.webp');
      background-size: cover;
      top: 3%;
   }

   20% {
      top: -3%;
   }

   30% {
      top: 4%;
   }

   40% {
      top: 3%;
   }

   50% {
      top: -3%;
   }

   60% {
      top: 5%;
   }

   70% {
      top: 0%;
   }

   80% {
      top: 4%;
   }

   90% {
      background-image: url('../assets/img/img_space/MemoryFondElts003_04Z.webp');
      background-size: cover;
      top: 5%;
   }

   100% {
      background-image: url('../assets/img/img_space/MemoryFondElts003_04Y.webp');
      background-size: cover;
      top: -3%;
   }
}

.frontMN1_perso05 {
   z-index: 500;
   animation: anim1_perso05 15s linear infinite;
}

@keyframes anim1_perso05 {
   0% {
      top: 0%;
      opacity: 0;
   }

   10% {
      top: 3%;
      opacity: 0;
   }

   20% {
      top: -3%;
      opacity: 0;
   }

   30% {
      top: 4%;
      opacity: 0;
   }

   40% {
      background-image: url('../assets/img/img_space/MemoryFondElts003_05Y.webp');
      background-size: cover;
      top: 3%;
      opacity: 1;
   }

   50% {
      top: -3%;
      opacity: 1;
   }

   60% {
      top: 5%;
      opacity: 1;
   }

   70% {
      top: 0%;
      opacity: 0;
   }

   80% {
      top: 4%;
      opacity: 0;
   }

   90% {
      top: 5%;
      opacity: 0;
   }

   100% {
      top: -3%;
      opacity: 0;
   }
}

.frontMN2_perso05 {
   z-index: 500;
   animation: anim2_perso05 15s linear infinite;
}

@keyframes anim2_perso05 {
   0% {
      background-image: url('../assets/img/img_space/MemoryFondElts003_05Y.webp');
      background-size: cover;
      top: 0%;
   }

   10% {
      background-image: url('../assets/img/img_space/MemoryFondElts003_05Z.webp');
      background-size: cover;
      top: 3%;
   }

   20% {
      top: -3%;
   }

   30% {
      top: 4%;
   }

   40% {
      top: 3%;
   }

   50% {
      top: -3%;
   }

   60% {
      top: 5%;
   }

   70% {
      top: 0%;
   }

   80% {
      top: 4%;
   }

   90% {
      background-image: url('../assets/img/img_space/MemoryFondElts003_05Z.webp');
      background-size: cover;
      top: 5%;
   }

   100% {
      background-image: url('../assets/img/img_space/MemoryFondElts003_05Y.webp');
      background-size: cover;
      top: -3%;
   }
}

.frontMN1_perso06 {
   z-index: 500;
   animation: anim1_perso06 15s linear infinite;
}

@keyframes anim1_perso06 {
   0% {
      top: 0%;
      opacity: 0;
   }

   10% {
      top: 3%;
      opacity: 0;
   }

   20% {
      top: -3%;
      opacity: 0;
   }

   30% {
      top: 4%;
      opacity: 0;
   }

   40% {
      top: 3%;
      opacity: 0;
   }

   50% {
      background-image: url('../assets/img/img_space/MemoryFondElts003_06Y.webp');
      background-size: cover;
      top: -3%;
      opacity: 1;
   }

   60% {
      top: 5%;
      opacity: 1;
   }

   70% {
      top: 0%;
      opacity: 1;
   }

   80% {
      top: 4%;
      opacity: 0;
   }

   90% {
      top: 5%;
      opacity: 0;
   }

   100% {
      top: -3%;
      opacity: 0;
   }
}

.frontMN2_perso06 {
   z-index: 500;
   animation: anim2_perso06 15s linear infinite;
}

@keyframes anim2_perso06 {
   0% {
      background-image: url('../assets/img/img_space/MemoryFondElts003_06Y.webp');
      background-size: cover;
      top: 0%;
   }

   10% {
      background-image: url('../assets/img/img_space/MemoryFondElts003_06Z.webp');
      background-size: cover;
      top: 3%;
   }

   20% {
      top: -3%;
   }

   30% {
      top: 4%;
   }

   40% {
      top: 3%;
   }

   50% {
      top: -3%;
   }

   60% {
      top: 5%;
   }

   70% {
      top: 0%;
   }

   80% {
      top: 4%;
   }

   90% {
      background-image: url('../assets/img/img_space/MemoryFondElts003_06Z.webp');
      background-size: cover;
      top: 5%;
   }

   100% {
      background-image: url('../assets/img/img_space/MemoryFondElts003_06Y.webp');
      background-size: cover;
      top: -3%;
   }
}

.frontMN1_perso07 {
   z-index: 500;
   animation: anim1_perso07 15s linear infinite;
}

@keyframes anim1_perso07 {
   0% {
      top: 0%;
      opacity: 0;
   }

   10% {
      top: 3%;
      opacity: 0;
   }

   20% {
      top: -3%;
      opacity: 0;
   }

   30% {
      top: 4%;
      opacity: 0;
   }

   40% {
      top: 3%;
      opacity: 0;
   }

   50% {
      top: -3%;
      opacity: 0;
   }

   60% {
      background-image: url('../assets/img/img_space/MemoryFondElts003_07Y.webp');
      background-size: cover;
      top: 5%;
      opacity: 1;
   }

   70% {
      top: 0%;
      opacity: 1;
   }

   80% {
      top: 4%;
      opacity: 1;
   }

   90% {
      top: 5%;
      opacity: 0;
   }

   100% {
      top: -3%;
      opacity: 0;
   }
}

.frontMN2_perso07 {
   z-index: 500;
   animation: anim2_perso07 15s linear infinite;
}

@keyframes anim2_perso07 {
   0% {
      background-image: url('../assets/img/img_space/MemoryFondElts003_07Y.webp');
      background-size: cover;
      top: 0%;
   }

   10% {
      background-image: url('../assets/img/img_space/MemoryFondElts003_07Z.webp');
      background-size: cover;
      top: 3%;
   }

   20% {
      top: -3%;
   }

   30% {
      top: 4%;
   }

   40% {
      top: 3%;
   }

   50% {
      top: -3%;
   }

   60% {
      top: 5%;
   }

   70% {
      top: 0%;
   }

   80% {
      top: 4%;
   }

   90% {
      background-image: url('../assets/img/img_space/MemoryFondElts003_07Z.webp');
      background-size: cover;
      top: 5%;
   }

   100% {
      background-image: url('../assets/img/img_space/MemoryFondElts003_07Y.webp');
      background-size: cover;
      top: -3%;
   }
}

.frontMN1_perso08 {
   z-index: 500;
   animation: anim1_perso08 15s linear infinite;
}

@keyframes anim1_perso08 {
   0% {
      top: 0%;
      opacity: 0;
   }

   10% {
      top: 3%;
      opacity: 0;
   }

   20% {
      top: -3%;
      opacity: 0;
   }

   30% {
      top: 4%;
      opacity: 0;
   }

   40% {
      top: 3%;
      opacity: 0;
   }

   50% {
      top: -3%;
      opacity: 0;
   }

   60% {
      top: 5%;
      opacity: 0;
   }

   70% {
      background-image: url('../assets/img/img_space/MemoryFondElts003_08Y.webp');
      background-size: cover;
      top: 0%;
      opacity: 1;
   }

   80% {
      top: 4%;
      opacity: 1;
   }

   90% {
      top: 5%;
      opacity: 1;
   }

   100% {
      top: -3%;
      opacity: 0;
   }
}

.frontMN2_perso08 {
   z-index: 500;
   animation: anim2_perso08 15s linear infinite;
}

@keyframes anim2_perso08 {
   0% {
      background-image: url('../assets/img/img_space/MemoryFondElts003_08Y.webp');
      background-size: cover;
      top: 0%;
   }

   10% {
      background-image: url('../assets/img/img_space/MemoryFondElts003_08Z.webp');
      background-size: cover;
      top: 3%;
   }

   20% {
      top: -3%;
   }

   30% {
      top: 4%;
   }

   40% {
      top: 3%;
   }

   50% {
      top: -3%;
   }

   60% {
      top: 5%;
   }

   70% {
      top: 0%;
   }

   80% {
      top: 4%;
   }

   90% {
      background-image: url('../assets/img/img_space/MemoryFondElts003_08Z.webp');
      background-size: cover;
      top: 5%;
   }

   100% {
      background-image: url('../assets/img/img_space/MemoryFondElts003_08Y.webp');
      background-size: cover;
      top: -3%;
   }
}

.frontMN img,
.backMN img {
   width: 100%;
   height: 100%;
}

.frontMN {
   transform: rotateY(-180deg);
}

.backMN {
   transform: rotateX(0);
}

.cadreMN_msg {
   background: rgba(0, 0, 0, 0.3);
   position: absolute;
   top: 0;
   left: 0;
   width: 100%;
   height: 100%;
}

.cadreMN7_msg {
   font-family: 'Fjalla One', sans-serif;
   display: flex;
   text-align: center;
   align-items: center;
   justify-content: center;
   width: 100%;
   height: 100%;
}

.cadreMN77_msg {
   font-family: 'Fjalla One', sans-serif;
   position: relative;
   width: 60%;
   margin: 20%;
   Background-color: white;
}

.winner {
   font-family: 'Fjalla One', sans-serif;
   text-align: center;
   font-family: 'Oswald', sans-serif;
   color: #4d4d4d;
   font-size: 400%;
}

@keyframes anim27_titre {
   0% {
      top: -100%;
      left: 0%;
      background-image: url('../assets/img/img_space/Memory_titr.webp');
      background-size: contain;
      transform: rotate(-5deg);
      transform-origin: bottom left;
      opacity: 0;
   }

   0.5% {
      opacity: 1;
   }

   1% {
      top: 0%;
      left: 0%;
   }

   1.25% {
      top: 0%;
      left: 0%;
      transform: rotate(-5deg);
      transform-origin: bottom left;
   }

   1.5% {
      top: 0%;
      left: 0%;
      transform: rotate(0deg);
      transform-origin: bottom left;
   }

   6.5% {
      top: 0%;
      left: 0%;
      opacity: 1;
   }

   8% {
      top: 0%;
      left: 0%;
      opacity: 0;
   }

   8.25% {
      top: -100%;
      left: 0%;
      opacity: 0;
   }

   100% {
      top: -100%;
      left: 0%;
      opacity: 0;
   }
}

div.cadreMN7_titre {
   position: absolute;
   top: 0%;
   left: 0%;
   width: 100%;
   animation: anim27_titre 30s forwards 1;
}