/*@import url(font-awesome.min.css);*/
@import url(https://fonts.googleapis.com/css?family=Pattaya|Work+Sans:400,200,300);


html, body, div, span, applet, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr, acronym, address, big, cite, code, del, dfn, em, img, ins, kbd, q, s, samp, small, strike, strong, sub, sup, tt, var, b, u, i, center, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, canvas, details, embed, figure, figcaption, footer, header, hgroup, menu, nav, output, ruby, section, summary, time, mark, audio, video {
	margin: 0;
	padding: 0;
	border: 0;
	font-size: 100%;
	font: inherit;
	vertical-align: baseline;
}

article, aside, details, figcaption, figure, footer, header, hgroup, menu, nav, section {
	display: block;
}

body {
	line-height: 1;
	background: #feb914;
}

body {
	-webkit-text-size-adjust: none;
}

/* Basic */

body.is-loading *, body.is-loading *:before, body.is-loading *:after {
	-moz-animation: none !important;
	-webkit-animation: none !important;
	-ms-animation: none !important;
	animation: none !important;
	-moz-transition: none !important;
	-webkit-transition: none !important;
	-ms-transition: none !important;
	transition: none !important;
}

/* Type */

body {
	color: #feb914;
}


/*@import url(https://fonts.googleapis.com/css2?family=Mochiy+Pop+One&display=swap);
/*font-family: 'Mochiy Pop One', sans-serif;*/
@import url(https://fonts.googleapis.com/css2?family=Gloria+Hallelujah&display=swap);
/*font-family: 'Gloria Hallelujah', cursive;*/
/*@import url(https://fonts.googleapis.com/css2?family=Acme&display=swap);
/*font-family:'Acme', sans-serif;*/
/*@import url(https://fonts.googleapis.com/css2?family=Pacifico&display=swap);
/*font-family: 'Pacifico', cursive;*/
@import url(https://fonts.googleapis.com/css2?family=Fjalla+One&display=swap);
/*font-family: 'Fjalla One', sans-serif;*/
/*@import url(https://fonts.googleapis.com/css2?family=Dosis:wght@500&display=swap);
/*font-family: 'Dosis', sans-serif;*/
/*@import url(https://fonts.googleapis.com/css2?family=Square+Peg&display=swap);
/*font-family: 'Square Peg', cursive;*/
/*@import url(https://fonts.googleapis.com/css2?family=Edu+NSW+ACT+Foundation:wght@500&display=swap);
/*font-family: 'Edu NSW ACT Foundation', cursive;*/
/*@import url(https://fonts.googleapis.com/css2?family=Carrois+Gothic+SC&display=swap);
/*font-family: 'Carrois Gothic SC', sans-serif;*/
/*@import url(https://fonts.googleapis.com/css2?family=League+Gothic&display=swap);
/*font-family: 'League Gothic', sans-serif;*/

html, body {
	height: 100%;
}
	html, body {
	font-size: 0.5vw;
}

h2 {
	font-size:1.1vw;
}

h3 {
	font-size:0.85vw;
	font-weight: normal;
}

html {
	scroll-behavior: smooth;
}


div.clear {
	clear: both;
 	height: 0; 
	overflow: hidden; /* Précaution pour IE 7 */
}
/*Avantage: technique éprouvée, pas ou peu d'effets indésirables.*/

a {
	text-decoration: underline;
	outline: 0;
}

a:hover {
	text-decoration: none;
}



div.content h1 {
	font-size: 4em;
	margin: 0 0 .25em 0;
	color: #fff;
}

div.content span {
	font-family: 'Pattaya', sans-serif;
	font-family: 'Fjalla One', sans-serif;
}

div.inner div.content {
	margin-top:5%;
	padding-bottom:0;
}

div.inner div.content h4 {
	color:white;
	font-size: 125%;
}

h1 a, h2 a, h3 a, h4 a, h5 a, h6 a {
	color: inherit;
	text-decoration: none;
}




/* Button */

input[type="submit"],
input[type="reset"],
input[type="button"],
button,
.button {
	-moz-appearance: none;
	-webkit-appearance: none;
	-ms-appearance: none;
	appearance: none;
	-moz-transition: background-color 0.2s ease-in-out, color 0.2s ease-in-out;
	-webkit-transition: background-color 0.2s ease-in-out, color 0.2s ease-in-out;
	-ms-transition: background-color 0.2s ease-in-out, color 0.2s ease-in-out;
	transition: background-color 0.2s ease-in-out, color 0.2s ease-in-out;
	border-radius: 2px;
	border: 0;
	cursor: pointer;
	display: inline-block;
	font-weight: 300;
	height: 2.85em;
	line-height: 3em;
	padding: 0 2.5em;
	text-align: center;
	text-decoration: none;
	white-space: nowrap;
}

input[type="submit"].alt,
input[type="reset"].alt,
input[type="button"].alt,
button.alt,
.button.alt {
	background: #FFF;
	color: #feb914;
}

input[type="submit"].big,
input[type="reset"].big,
input[type="button"].big,
button.big,
.button.big {
	font-size: 2em;
}



#header {
	-moz-transition: background 1s ease-in-out, height 0.2s ease-in-out;
	-webkit-transition: background 1s ease-in-out, height 0.2s ease-in-out;
	-ms-transition: background 1s ease-in-out, height 0.2s ease-in-out;
	transition: background 1s ease-in-out, height 0.2s ease-in-out;
	display: -moz-flex;
	display: -webkit-flex;
	display: -ms-flex;
	display: flex;
	-moz-justify-content: center;
	-webkit-justify-content: center;
	-ms-justify-content: center;
	justify-content: center;
	-moz-align-items: center;
	-webkit-align-items: center;
	-ms-align-items: center;
	align-items: center;
	display: -ms-flexbox;
	-ms-flex-align: center;
	-ms-flex-pack: center;
	background: rgba(254, 254, 254, 0.85);
	position: fixed;
	top: 0;
	width: 100%;
	height: 100vh;
	z-index: 10001;
}

#header .inner {
	-moz-transition: height 0.2s ease-in-out, width 0.2s ease-in-out;
	-webkit-transition: height 0.2s ease-in-out, width 0.2s ease-in-out;
	-ms-transition: height 0.2s ease-in-out, width 0.2s ease-in-out;
	transition: height 0.2s ease-in-out, width 0.2s ease-in-out;
	display: -moz-flex;
	display: -webkit-flex;
	display: -ms-flex;
	display: flex;
	-moz-flex-direction: column;
	-webkit-flex-direction: column;
	-ms-flex-direction: column;
	flex-direction: column;
	-moz-justify-content: center;
	-webkit-justify-content: center;
	-ms-justify-content: center;
	justify-content: center;
	-moz-align-items: center;
	-webkit-align-items: center;
	-ms-align-items: center;
	align-items: center;
	display: -ms-flexbox;
	-ms-flex-align: center;
	-ms-flex-pack: center;
	background: #feb914;
	border-radius: 100%;
	width: 45em;
	height: 45em;
	padding: 4em;
	text-align: center;
	box-shadow: 0 0 0 1em #FFF;
	cursor: default;
}

#header .inner .content {
	-moz-transition: opacity 1s ease-in-out;
	-webkit-transition: opacity 1s ease-in-out;
	-ms-transition: opacity 1s ease-in-out;
	transition: opacity 1s ease-in-out;
	opacity: 1;
}

#header .inner .content h1 {
	font-size: 4em;
	font-family: 'Pattaya', sans-serif;
	margin: 0 0 .25em 0;
	color: #fff;
}

#header .inner .content h2 {
	font-size: 2.5em;
	color: #fff;
	margin: 0 0 1em 0;
	line-height: 1.5em;
}

#header .inner .button {
	-moz-transition: background 1s ease-in-out;
	-webkit-transition: background 1s ease-in-out;
	-ms-transition: background 1s ease-in-out;
	transition: background 1s ease-in-out;
	margin: 0;
	border-radius: 30px;
}

#header .inner .button.hidden {
	display: none;
	cursor: pointer;
}

#header .inner.hidden {
	display: none;
}

#header.hide, #header.preview {
	-moz-transition: background 0.2s ease-in-out, height 1s ease-in-out;
	-webkit-transition: background 0.2s ease-in-out, height 1s ease-in-out;
	-ms-transition: background 0.2s ease-in-out, height 1s ease-in-out;
	transition: background 0.2s ease-in-out, height 1s ease-in-out;
	background: none;
	height: 0;
}

#header.hide .inner, #header.preview .inner {
	height: 6em;
	width: 6em;
	padding: 2em;
}

#header.hide .inner .content, #header.preview .inner .content {
	opacity: 0;
}

#header.hide .inner .content h1,
#header.preview .inner .content h1 {
display: none;
}

#header.hide .inner .content h2,
#header.preview .inner .content h2 {
	display: none;
}

#header.hide .button.big,
#header.preview .button.big {
	display: none;
}

#header.hide .button.hidden,
#header.preview .button.hidden {
	text-decoration: none;
	padding-top: 1.5em;
	padding-left: 0;
	padding-right: 0;
	background: none;
	display: inline-block;
}

#header.hide .button.hidden:before,
#header.preview .button.hidden:before {
	-moz-osx-font-smoothing: grayscale;
	-webkit-font-smoothing: antialiased;
	font-family: FontAwesome;
	font-style: normal;
	font-weight: normal;
	text-transform: none !important;
}

#header.hide .button.hidden:before,
#header.preview .button.hidden:before {
	content: '\f015';
	color: #FFF;
	font-size: 1.5em;
}

#header.hide .button.hidden span,
#header.preview .button.hidden span {
	display: none;
}

/* Main */

#main {
	padding: 2em;
	padding-top:  0.5em;
	padding-bottom: 0.5em;
}

#main .inner {
	/*max-width: 100%;*/
	/*max-width: 800px;*/
	margin: auto;
	background-color: #fff;
	padding:10px;
}

/* Footer */

#footer {
	-moz-transition: bottom 0.2s ease;
	-webkit-transition: bottom 0.2s ease;
	-ms-transition: bottom 0.2s ease;
	transition: bottom 0.2s ease;
	position: fixed;
	bottom: -45em;
	color: #000;
	left: 0;
	width: 100%;
	text-align: center;
	z-index: 9999;
}

#footer.show {
	bottom: 0;
}

#footer .info {
	position: relative;
	display: inline-block;
	text-decoration: none;
	border-radius: 50% 50% 0 0;
	background: #feb914;
	width: 3em;
	height: 3em;
	margin-bottom: -1.75em;
	color: #000;
	font-size: 1.25em;
	padding-top: .35em;
}

#footer .info span {
	display: none;
}


#footer .inner {
	display: -moz-flex;
	display: -webkit-flex;
	display: -ms-flex;
	display: flex;
	-moz-justify-content: center;
	-webkit-justify-content: center;
	-ms-justify-content: center;
	justify-content: center;
	-moz-align-items: stretch;
	-webkit-align-items: stretch;
	-ms-align-items: stretch;
	align-items: stretch;
	display: -ms-flexbox;
	-ms-flex-align: center;
	-ms-flex-pack: center;
	position: relative;
	z-index: 10003;
	background: #feb914;
	padding: 2em;
	/*height: 50em;*/
	height: 45em;
}

#footer .inner .content,
#footer .inner .copyright {
	width: 75%;
	text-align: left;
	color: rgba(255, 255, 255, 0.75);
	padding: 2em;
	display: inline-block;
}

#footer .inner .content h3,
#footer .inner .copyright h3 {
	color: #000;
	font-size: 1.75em;
}


		
	


img.icone_change {
  width: 18px;
  height: 18px;
margin-top:15px;
}



/**icone **/


#footer  a.info-foot {
  /*background-image: url('img_t/info-circle-solid.svg');*/
  background-repeat: no-repeat;
  background-position: center right;
  padding-right: 18px;
}
#footer  a.info-foot span{
 display: none;
}


p.ceel_hide{
	Visibility: hidden;
	height: 1px;
	margin:0px;
	padding:0px;
}

div.content div.content2{
	padding:1%;
	margin:0;
	color:#fff;
	font-family: 'Pattaya', sans-serif;
	line-height: 2em;
	font-size: 2.5em;
}




div.lazy{
 visibility: hidden;
}




