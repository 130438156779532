h1,
h2,
h3,
h4,
h5,
h6 {
	font-size: 100%;
	font-family: "Pattaya", sans-serif;
}

div.bande_large00:hover {
	z-index: 500;
	box-shadow: 0 3em 2em -1em #000;
	transform: scale(1.03);
}

div.rect00:hover {
	z-index: 500;
	box-shadow: 0 3em 2em -1em #000;
	transform: scale(1.03);
}

div.cadreanim00:hover {
	z-index: 500;
	box-shadow: 0 3em 2em -1em #000;
	transform: scale(1.03);
}

div.bande_large00,
div.rect00,
div.cadreanim00 {
	box-shadow: 0 3em 2em -2em #000;
	-webkit-transition: transform 1s linear;
	-moz-transition: transform 1s linear;
	-o-transition: transform 1s linear;
	transition: transform 1s linear;
}

div.mega_cadre {
	position: absolute;
	width: 80%;
	top: 12em;
	left: 0;
	margin-left: 10%;
	margin-right: 10%;
}

#parallax-world-of-ugg .parallax-one,
#parallax-world-of-ugg .parallax-two,
#parallax-world-of-ugg .parallax-three,
#parallax-world-of-ugg .parallax-foor,
#parallax-world-of-ugg .parallax-five {
	padding-top: 20%;
	padding-bottom: 20%;
}

div.cadreanim00 {
	width: 47%;
	padding: 3%;
	z-index: 50;
	margin-top: 3%;
	margin-bottom: 3%;
	-webkit-transition: transform 1s linear;
	-moz-transition: transform 1s linear;
	-o-transition: transform 1s linear;
	transition: transform 1s linear;
}

div.bande_large00 {
	width: 70%;
	padding: 1%;
	z-index: 50;
	margin-left: 10%;
	float: left;
}

div.rect00 {
	width: 22%;
	padding: 2%;
	margin-top: 3%;
	margin-bottom: 3%;
	z-index: 50;
	-webkit-transition: transform 1s linear;
	-moz-transition: transform 1s linear;
	-o-transition: transform 1s linear;
	transition: transform 1s linear;
}

h2.h2-00 {
	font-size: 100%;
	font-family: "Pattaya", sans-serif;
	text-align: center;
	margin: 2%;
	padding: 1%;
	color: #fff;
}

@keyframes cadre_out_a {
	0% {
		position: relative;
		margin: 0;
		margin-left: 5%;
		margin-top: 3%;
		margin-bottom: 3%;
		float: left;
		top: 4em;
		Left: 0%;
		opacity: 0;
	}

	45% {
		position: relative;
		margin: 0;
		margin-left: 3%;
		margin-top: 3%;
		margin-bottom: 3%;
		float: left;
		top: 2em;
		Left: 0%;
		opacity: 1;
	}

	100% {
		position: relative;
		margin: 0;
		margin-left: 1%;
		margin-top: 3%;
		margin-bottom: 3%;
		float: left;
		top: 0em;
		Left: 0%;
	}
}

div.cadre_a {
	position: relative;
	margin: 0;
	margin-left: 1%;
	margin-top: 3%;
	margin-bottom: 3%;
	float: left;
	top: 0em;
	Left: 0%;
	animation: cadre_out_a 4s;
}

@keyframes cadre_out_b {
	0% {
		position: relative;
		margin: 0;
		margin-left: 7%;
		margin-top: 3%;
		margin-bottom: 3%;
		float: left;
		top: 6em;
		Left: 0%;
		opacity: 0;
	}

	45% {
		position: relative;
		margin: 0;
		margin-left: 5%;
		margin-top: 3%;
		margin-bottom: 3%;
		float: left;
		top: 4em;
		Left: 0%;
		opacity: 1;
	}

	100% {
		position: relative;
		margin: 0;
		margin-left: 3%;
		margin-top: 3%;
		margin-bottom: 3%;
		float: left;
		top: 2em;
		Left: 0%;
	}
}

div.cadre_b {
	position: relative;
	margin: 0;
	margin-left: 3%;
	margin-top: 3%;
	margin-bottom: 3%;
	float: left;
	top: 2em;
	Left: 0%;
	animation: cadre_out_b 4s;
}

@keyframes cadre_out_c {
	0% {
		position: relative;
		margin: 0;
		margin-left: 1%;
		margin-top: 3%;
		margin-bottom: 3%;
		float: left;
		top: 0em;
		Left: 0%;
		opacity: 0;
	}

	45% {
		position: relative;
		margin: 0;
		margin-left: 7%;
		margin-top: 3%;
		margin-bottom: 3%;
		float: left;
		top: 6em;
		Left: 0%;
		opacity: 1;
	}

	100% {
		position: relative;
		margin: 0;
		margin-left: 5%;
		margin-top: 3%;
		margin-bottom: 3%;
		float: left;
		top: 4em;
		Left: 0%;
	}
}

div.cadre_c {
	position: relative;
	margin: 0;
	margin-left: 5%;
	margin-top: 3%;
	margin-bottom: 3%;
	float: left;
	top: 4em;
	Left: 0%;
	animation: cadre_out_c 4s;
}

@keyframes cadre_out_d {
	0% {
		position: relative;
		margin: 0;
		margin-left: 3%;
		margin-top: 3%;
		margin-bottom: 3%;
		float: left;
		top: 2em;
		Left: 0%;
		opacity: 0;
	}

	45% {
		position: relative;
		margin: 0;
		margin-left: 1%;
		margin-top: 3%;
		margin-bottom: 3%;
		float: left;
		top: 0em;
		Left: 0%;
		opacity: 1;
	}

	100% {
		position: relative;
		margin: 0;
		margin-left: 7%;
		margin-top: 3%;
		margin-bottom: 3%;
		float: left;
		top: 6em;
		Left: 0%;
	}
}

div.cadre_d {
	position: relative;
	margin: 0;
	margin-left: 7%;
	margin-top: 3%;
	margin-bottom: 3%;
	float: left;
	top: 6em;
	Left: 0%;
	animation: cadre_out_d 4s;
}


@keyframes cadre_out_e {
	0% {
		position: relative;
		margin: 0;
		margin-left: 1%;
		margin-top: 3%;
		margin-bottom: 3%;
		float: left;
		top: 0em;
		Left: 0%;
		opacity: 0;
	}

	45% {
		position: relative;
		margin: 0;
		margin-left: 7%;
		margin-top: 3%;
		margin-bottom: 3%;
		float: left;
		top: 6em;
		Left: 0%;
		opacity: 1;
	}

	100% {
		position: relative;
		margin-left: 1%;
		margin-top: 3%;
		margin-bottom: 3%;
		float: left;
		top: 0%;
		Left: 0%;
	}
}

@keyframes cadre_out_f {
	0% {
		position: relative;
		margin: 0;
		margin-left: 3%;
		margin-top: 3%;
		margin-bottom: 3%;
		float: left;
		top: 2em;
		Left: 0%;
		opacity: 0;
	}

	45% {
		position: relative;
		margin: 0;
		margin-left: 1%;
		margin-top: 3%;
		margin-bottom: 3%;
		float: left;
		top: 0em;
		Left: 0%;
		opacity: 1;
	}

	100% {
		margin-left: 12%;
		margin-top: 3%;
		margin-bottom: 3%;
		float: left;
		top: 0%;
		Left: 0%;
	}
}

@keyframes cadre_out_g {
	0% {
		position: relative;
		margin: 0;
		margin-left: 5%;
		margin-top: 3%;
		margin-bottom: 3%;
		float: left;
		top: 4em;
		Left: 0%;
		opacity: 0;
	}

	45% {
		position: relative;
		margin: 0;
		margin-left: 3%;
		margin-top: 3%;
		margin-bottom: 3%;
		float: left;
		top: 2em;
		Left: 0%;
		opacity: 1;
	}

	100% {
		position: relative;
		margin-left: 23%;
		margin-top: 3%;
		margin-bottom: 3%;
		float: left;
		top: 0%;
		Left: 0%;
	}
}

@keyframes cadre_out_h {
	0% {
		position: relative;
		margin: 0;
		margin-left: 7%;
		margin-top: 3%;
		margin-bottom: 3%;
		float: left;
		top: 6em;
		Left: 0%;
		opacity: 0;
	}

	45% {
		position: relative;
		margin: 0;
		margin-left: 5%;
		margin-top: 3%;
		margin-bottom: 3%;
		float: left;
		top: 4em;
		Left: 0%;
		opacity: 1;
	}

	100% {
		position: relative;
		margin-left: 34%;
		margin-top: 3%;
		margin-bottom: 3%;
		float: left;
		top: 0%;
		Left: 0%;
	}
}

div.cadre_e {
	position: relative;
	margin-left: 1%;
	margin-top: 3%;
	margin-bottom: 3%;
	float: left;
	top: 0%;
	Left: 0%;
	animation: cadre_out_e 4s;
}

div.cadre_f {
	margin-left: 12%;
	margin-top: 3%;
	margin-bottom: 3%;
	float: left;
	top: 0%;
	Left: 0%;
	animation: cadre_out_f 4s;
}

div.cadre_g {
	position: relative;
	margin-left: 23%;
	margin-top: 3%;
	margin-bottom: 3%;
	float: left;
	top: 0%;
	Left: 0%;
	animation: cadre_out_g 4s;
}

div.cadre_h {
	position: relative;
	margin-left: 34%;
	margin-top: 3%;
	margin-bottom: 3%;
	float: left;
	top: 0%;
	Left: 0%;
	animation: cadre_out_h 4s;
}

div.idAncre00 {
	position: absolute;
	width: 1px;
	height: 1px;
	top: -20%;
}

.restart000,
.reponse000 {
	font-family: 'Fjalla One', sans-serif;
	font-size: 200%;
	margin: 03% auto;
	/*padding: 8% 20%;*/
	display: block;
	border: none;
	background: #4d4d4d;
	background: linear-gradient(#4d4d4d, #222);
	border: 0.1em solid #222;
	border-radius: 2em;
	color: white;
	text-shadow: 0em 0.2em 0 black;
	cursor: pointer;
	font-size: 2.8em;
	line-height: 0.5em;
}

h2.winner000 {
	text-align: center;
	font-family: 'Fjalla One', sans-serif;
	line-height: normal;
	color: #4d4d4d;
	font-size: 2.8em;
	Background-color: white;
	padding: 5%;
	border-radius: 1em;
}

h2.winner000 span {
	font-size: 1.0em;
}

.restart000,
.reponse000 {
	font-size: 2.6em;
	line-height: normal;
}

h2.winner000 {
	font-size: 3.8em;
	line-height: normal;
}

h2.winner000 span {
	font-size: 0.6em;
	line-height: normal;
}


@media screen and (max-width: 1680px) {
	div.mega_cadre {
		position: absolute;
		width: 84%;
		top: 12em;
		left: 0;
		margin-left: 8%;
		margin-right: 8%;
	}

	div.cadreanim00 {
		width: 49%;
		padding: 3%;
		z-index: 50;
	}

	div.bande_large00 {
		width: 72%;
		padding: 1%;
	}

	div.rect00 {
		width: 23%;
		padding: 2%;
	}
}


@media screen and (max-width: 1258px) {

	div.mega_cadre {
		position: absolute;
		width: 90%;
		top: 12em;
		left: 0;
		margin-left: 5%;
		margin-right: 5%;
	}

	#parallax-world-of-ugg .parallax-one,
	#parallax-world-of-ugg .parallax-two,
	#parallax-world-of-ugg .parallax-three,
	#parallax-world-of-ugg .parallax-foor,
	#parallax-world-of-ugg .parallax-five {
		padding-top: 40%;
		padding-bottom: 40%;
	}

	div.cadreanim00 {
		width: 51%;
		padding: 3%;
		z-index: 50;
	}

	div.bande_large00 {
		width: 81%;
		padding: 1%;
	}

	div.rect00 {
		width: 30%;
		padding: 2%;
	}

	.restart000,
	.reponse000 {
		font-size: 3.6em;
		line-height: normal;
	}

	h2.winner000 {
		font-size: 3.8em;
		line-height: normal;
	}

	h2.winner000 span {
		font-size: 1.3em;
		line-height: normal;
	}
}


@media screen and (max-width: 1024px) {

	div.mega_cadre {
		position: absolute;
		width: 94%;
		top: 5em;
		left: 0;
		margin-left: 3%;
		margin-right: 3%;
	}

	div.cadreanim00 {
		width: 65%;
		padding: 3%;
		z-index: 50;
	}

	div.bande_large00 {
		width: 80%;
		padding: 1%;
	}

	div.rect00 {
		width: 36%;
		padding: 2%;
	}

	.restart000,
	.reponse000 {
		font-size: 4.0em;
		line-height: normal;
	}

	h2.winner000 {
		font-size: 3.6em;
		line-height: normal;
	}


	h2.winner000 span {
		font-size: 1.3em;
		line-height: normal;
	}
}


@media screen and (max-width: 812px) {

	div.mega_cadre {
		width: 100%;
		position: absolute;
		top: 15em;
		left: 0;
		margin-left: 0%;
		margin-right: 0%;
		padding-left: 0%;
		padding-bottom: 0%;
	}

	#parallax-world-of-ugg .parallax-one,
	#parallax-world-of-ugg .parallax-two,
	#parallax-world-of-ugg .parallax-three,
	#parallax-world-of-ugg .parallax-foor,
	#parallax-world-of-ugg .parallax-five {
		display: none;
	}

	div.cadreanim00 {
		width: 97%;
		top: auto;
		left: auto;
		padding: 1%;
	}

	div.bande_large00 {
		width: 97%;
		top: auto;
		left: auto;
		margin: 0;
		padding: 1%;
	}

	div.rect00 {
		width: 47%;
		margin: 0%;
		top: auto;
		left: auto;
		padding: 1%;
	}

	
@keyframes cadre_out_a {
	0% {
		opacity: 0;
	}

	45% {
		opacity: 1;
	}

	100% {
		opacity: 1;
	}
}


@keyframes cadre_out_b {
	0% {
		opacity: 0;
	}

	45% {
		opacity: 1;
	}

	100% {
		opacity: 1;
	}
}

@keyframes cadre_out_c {
	0% {
		opacity: 0;
	}

	45% {
		opacity: 1;
	}

	100% {
		opacity: 1;
	}
}


@keyframes cadre_out_d {
	0% {
		opacity: 0;
	}

	45% {
		opacity: 1;
	}

	100% {
		opacity: 1;
	}
}


@keyframes cadre_out_e {
	0% {
		opacity: 0;
	}

	45% {
		opacity: 1;
	}

	100% {
		opacity: 1;
	}
}

@keyframes cadre_out_f {
	0% {
		opacity: 0;
	}

	45% {
		opacity: 1;
	}

	100% {
		opacity: 1;
	}
}

@keyframes cadre_out_g {
	0% {
		opacity: 0;
	}

	45% {
		opacity: 1;
	}

	100% {
		opacity: 1;
	}
}

@keyframes cadre_out_h {
	0% {
		opacity: 0;
	}

	45% {
		opacity: 1;
	}

	100% {
		opacity: 1;
	}
}

	div.cadre_a {
		position: relative;
		float: left;
		margin: 0.5%;
		margin-bottom: 3em;
	}

	div.cadre_b {
		position: relative;
		float: left;
		margin: 0.5%;
		margin-bottom: 3em;
	}

	div.cadre_c {
		position: relative;
		float: left;
		margin: 0.5%;
		margin-bottom: 3em;
	}

	div.cadre_d {
		position: relative;
		float: left;
		margin: 0.5%;
		margin-bottom: 3em;
	}

	div.cadre_e {
		position: relative;
		float: left;
		margin: 0.5%;
		margin-bottom: 3em;
	}

	div.cadre_f {
		position: relative;
		float: left;
		margin: 0.5%;
		margin-bottom: 3em;
	}

	div.cadre_g {
		position: relative;
		float: left;
		margin: 0.5%;
		margin-bottom: 3em;
	}

	div.cadre_h {
		position: relative;
		float: left;
		margin: 0.5%;
		margin-bottom: 3em;
	}

	div.cadre_z {
		position: relative;
		float: left;
	}

	.restart000,
	.reponse000 {
		font-size: 12.0em;
		line-height: normal;
	}

	h2.winner000 {
		line-height: normal;
	}

	h2.winner000 span {
		font-size: 2.8em;
		line-height: normal;
	}
}


@media screen and (max-width: 480px) {

	body {
		/*font-size: 1.6vw;*/
	}

	h2 {
		font-size: 3.5vw;
		line-height: 2.9vw;
	}

	.restart000,
	.reponse000 {
		font-size: 15.0em;
		line-height: normal;
	}

	h2.winner000 {
		font-size: 5.0em;
		line-height: normal;
	}

	div.cadreanim00 {
		width: 98%;
		top: auto;
		left: auto;
		padding: 1%;
		margin: 0;
		box-shadow: none;
		margin-bottom: 1%;
	}

	div.cadreanim00:hover {
		z-index: 500;
		box-shadow: none;
		transform: none;
		margin-bottom: 1%;
	}

	div.bande_large00 {
		width: 98%;
		top: auto;
		left: auto;
		margin: 0;
		padding: 1%;
		margin: 0;
		box-shadow: none;
	}

	div.bande_large00:hover {
		z-index: 500;
		box-shadow: none;
		transform: none;
	}

	div.rect00 {
		width: 48%;
		margin: 0%;
		top: auto;
		left: auto;
		padding: 1%;
		margin: 0;
		box-shadow: none;
	}

	div.rect00:hover {
		z-index: 500;
		box-shadow: none;
		transform: none;
	}

	.restart000,
	.reponse000 {
		font-family: 'Fjalla One', sans-serif;
		font-size: 200%;
		/*padding: 8% 20%;*/
		display: block;
		border: none;
		background: #4d4d4d;
		background: linear-gradient(#4d4d4d, #222);
		border: 0.1em solid #222;
		border-radius: 2em;
		color: white;
		text-shadow: 0em 0.2em 0 black;
		cursor: pointer;
		font-size: 8.0em;
		line-height: 0.5em;
	}

	h2.winner000 {
		text-align: center;
		font-family: 'Fjalla One', sans-serif;
		line-height: normal;
		color: #4d4d4d;
		font-size: 6.5em;
		Background-color: white;
		padding: 5%;
		border-radius: 1em;
	}

	h2.winner000 span {
		font-size: 1.5em;
		line-height: normal;
	}
}