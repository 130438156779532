div.cadre42 {
    position: relative;
    width: 33%;
    margin: 0 auto;
    background-color: black;
 }

 div.cadre42_pl {
    position: absolute;
    width: 1%;
    height: 1%;
    left: 0%;
    top: 0%;
    background-position: 100% 100%;
 }

 
.cadre427 {
    transform-style: preserve-3d;
    perspective: 500px;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0%;
    left: 0%;
 }
 
 img.imgFond42 {
    width: 100%;
 }
 
 div.canvas42 {
    position: relative;
    width: 100%;
    height: 100%;
    background-color: silver;
    background-image:  url('../assets/img/img_lev/600_Mino_fond.webp');
    background-size: 100% 100%;
  }

  div.canvas422 {
    position: relative;
    width: 100%;
    height: 100%;
    background-color: silver;
    background-size: 100% 100%;
    opacity: 0.75;
  }

  div.canvas4222 {
    position: relative;
    width: 66%;
    height: 98%;
    left:33%;
    border:0;
    margin: 0;
    padding: 0;
    top:1%;
  }

  div.piece42{
    top:0%;
    left:0%;
    border:0;
    margin: 0%;
    padding: 0%;
    position: absolute;
    float: left;
    height: 24.5%;
    width:22%;
  }

  img.imgPiece42{
    top:0%;
    left:0%;
    border:0;
    margin: 0%;
    padding: 0%;
width: 100%;
height: 100%;
  }

  
.cadre42_msg {
  background: rgba(0, 0, 0, 0.3);
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 10;
  }
  
  .cadre427_msg {
  font-family: 'Fjalla One', sans-serif;
  display: flex;
  text-align: center;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  z-index: 10;
  }
  
  .cadre4277_msg {
  font-family: 'Fjalla One', sans-serif;
  position: relative;
  width: 80%;
  height:60%;
  margin: 20%;
  margin-left: 2%;
  margin-right: 2%;
  z-index: 10;
  /*Background-color: white;*/
  }