@import url(https://fonts.googleapis.com/css2?family=Mochiy+Pop+One&display=swap);
/*font-family: 'Mochiy Pop One', sans-serif;*/
@import url(https://fonts.googleapis.com/css2?family=Gloria+Hallelujah&display=swap);
/*font-family: 'Gloria Hallelujah', cursive;*/
@import url(https://fonts.googleapis.com/css2?family=Acme&display=swap);
/*font-family:'Acme', sans-serif;*/
@import url(https://fonts.googleapis.com/css2?family=Pacifico&display=swap);
/*font-family: 'Pacifico', cursive;*/
@import url(https://fonts.googleapis.com/css2?family=Fjalla+One&display=swap);
/*font-family: 'Fjalla One', sans-serif;*/
@import url(https://fonts.googleapis.com/css2?family=Dosis:wght@500&display=swap);
/*font-family: 'Dosis', sans-serif;*/
@import url(https://fonts.googleapis.com/css2?family=Square+Peg&display=swap);
/*font-family: 'Square Peg', cursive;*/
@import url(https://fonts.googleapis.com/css2?family=Edu+NSW+ACT+Foundation:wght@500&display=swap);
/*font-family: 'Edu NSW ACT Foundation', cursive;*/
@import url(https://fonts.googleapis.com/css2?family=Carrois+Gothic+SC&display=swap);
/*font-family: 'Carrois Gothic SC', sans-serif;*/
@import url(https://fonts.googleapis.com/css2?family=League+Gothic&display=swap);
/*font-family: 'League Gothic', sans-serif;*/


header.heade000 {
  font-family: 'Fjalla One', sans-serif;
  font-size: 0.5vw;
}

h2 {
  font-family: 'Fjalla One', sans-serif;
  font-size: 1.1vw;
}


@media screen and (max-width: 1250px) {

  header.heade000 {
    font-size: 0.95vw;
  }

  h2 {
    font-family: 'Fjalla One', sans-serif;
    font-size: 2.3vw;
  }
}

@media screen and (max-width: 512px) {
  header.heade000 {
    font-size: 1.6vw;
  }

  h2 {
    font-size: 3.5vw;
    line-height: 2.9vw;
  }
}

.buttonH000 {
  font-family: 'Fjalla One', sans-serif;
  -moz-appearance: none;
  -webkit-appearance: none;
  -ms-appearance: none;
  appearance: none;
  -moz-transition: background-color 0.2s ease-in-out, color 0.2s ease-in-out;
  -webkit-transition: background-color 0.2s ease-in-out, color 0.2s ease-in-out;
  -ms-transition: background-color 0.2s ease-in-out, color 0.2s ease-in-out;
  transition: background-color 0.2s ease-in-out, color 0.2s ease-in-out;
  border-radius: 2px;
  border: 0;
  cursor: pointer;
  display: inline-block;
  font-weight: 300;
  height: 2.85em;
  line-height: 3em;
  padding: 0 2.5em;
  text-align: center;
  text-decoration: none;
  white-space: nowrap;
}

.buttonH000.alt {
  background: #FFF;
  color: #feb914;
}

.buttonH000.big {
  font-size: 2.8em;
}

header.heade000 {
  -moz-transition: background 1s ease-in-out, height 0.2s ease-in-out;
  -webkit-transition: background 1s ease-in-out, height 0.2s ease-in-out;
  -ms-transition: background 1s ease-in-out, height 0.2s ease-in-out;
  transition: background 1s ease-in-out, height 0.2s ease-in-out;
  display: -moz-flex;
  display: -webkit-flex;
  display: -ms-flex;
  display: flex;
  -moz-justify-content: center;
  -webkit-justify-content: center;
  -ms-justify-content: center;
  justify-content: center;
  -moz-align-items: center;
  -webkit-align-items: center;
  -ms-align-items: center;
  align-items: center;
  display: -ms-flexbox;
  -ms-flex-align: center;
  -ms-flex-pack: center;
  background: rgba(254, 254, 254, 0.85);
  position: fixed;
  top: 0;
  width: 100%;
  height: 100vh;
  z-index: 10001;
  padding: 0;
  margin: 0;
  border: 0;
}

header.heade000 .inner {
  -moz-transition: height 0.2s ease-in-out, width 0.2s ease-in-out;
  -webkit-transition: height 0.2s ease-in-out, width 0.2s ease-in-out;
  -ms-transition: height 0.2s ease-in-out, width 0.2s ease-in-out;
  transition: height 0.2s ease-in-out, width 0.2s ease-in-out;
  display: -moz-flex;
  display: -webkit-flex;
  display: -ms-flex;
  display: flex;
  -moz-flex-direction: column;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -moz-justify-content: center;
  -webkit-justify-content: center;
  -ms-justify-content: center;
  justify-content: center;
  -moz-align-items: center;
  -webkit-align-items: center;
  -ms-align-items: center;
  align-items: center;
  display: -ms-flexbox;
  -ms-flex-align: center;
  -ms-flex-pack: center;
  background: #feb914;
  border-radius: 100%;
  width: 45em;
  height: 45em;
  padding: 4em;
  text-align: center;
  box-shadow: 0 0 0 1em #FFF;
  cursor: default;
}

header.heade000 .inner .content {
  -moz-transition: opacity 1s ease-in-out;
  -webkit-transition: opacity 1s ease-in-out;
  -ms-transition: opacity 1s ease-in-out;
  transition: opacity 1s ease-in-out;
  opacity: 1;
}

header.heade000 .inner .content h1 {
  font-size: 4em;
  font-family: 'Fjalla One', sans-serif;
  margin: 0 0 .25em 0;
  color: #fff;
}

header.heade000 .inner .content h2 {
  font-size: 2.5em;
  color: #fff;
  margin: 0 0 1em 0;
  line-height: 1.5em;
}

header.heade000 .inner .buttonH000 {
  font-family: 'Fjalla One', sans-serif;
  -moz-transition: background 1s ease-in-out;
  -webkit-transition: background 1s ease-in-out;
  -ms-transition: background 1s ease-in-out;
  transition: background 1s ease-in-out;
  margin: 0;
  border-radius: 30px;
}

header.heade000 .inner .buttonH000.hidden {
  display: none;
  cursor: pointer;
}

header.heade000 .inner.hidden {
  display: none;
}

header.hide,
header.preview {
  -moz-transition: background 0.2s ease-in-out, height 2s ease-in-out;
  -webkit-transition: background 0.2s ease-in-out, height 2s ease-in-out;
  -ms-transition: background 0.2s ease-in-out, height 2s ease-in-out;
  transition: background 0.2s ease-in-out, height 2s ease-in-out;
  background: none;
  height: 0;
}

header.hide .inner,
header.preview .inner {
  height: 3em;
  width: 3em;
  padding: 2em;
}

header.hide .inner .content,
header.preview .inner .content {
  opacity: 0;
}

header.hide .inner .content h1,
header.preview .inner .content h1 {
  display: none;
}

header.hide .inner .content h2,
header.preview .inner .content h2 {
  display: none;
}

header.hide .buttonH000.big,
header.preview .buttonH000.big {
  display: none;
}

header.hide .buttonH000.hidden,
header.preview .buttonH000.hidden {
  text-decoration: none;
  padding-top: 1.5em;
  padding-left: 0;
  padding-right: 0;
  background: none;
  display: inline-block;
}

header.hide .buttonH000.hidden:before,
header.preview .buttonH000.hidden:before {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: FontAwesome;
  font-family: 'Fjalla One', sans-serif;
  font-style: normal;
  font-weight: normal;
  text-transform: none !important;
}

header.hide .buttonH000.hidden:before,
header.preview .buttonH000.hidden:before {
  content: '\f015';
  color: #FFF;
  font-size: 1.5em;
}

header.hide .buttonH000.hidden span,
header.preview .buttonH000.hidden span {
  display: none;
}


img.icone_change {
  width: 18px;
  height: 18px;
  margin-top: 15px;
}


p.ceel_hide {
  Visibility: hidden;
  height: 1px;
  margin: 0px;
  padding: 0px;
}

div.content div.content2 {
  padding: 1%;
  margin: 0;
  color: #fff;
  font-family: 'Fjalla One', sans-serif;
  line-height: 2em;
  font-size: 2.5em;
}
